import { formatDateTime, formatLocale } from './date';

/**
 * Validate string as an email address
 * @param {string} email
 * @returns {boolean}
 */
export const emailIsValid = (email) => {
  if (typeof email !== 'string') return false;

  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export default emailIsValid;

/**
 * Takes an email data object returned by historical API
 * and returns an array of values in the order matching
 * the table columns in Emails Table
 *
 * @param {object} email
 * @param {string} resendString Translated string for "Resend"
 *
 * @returns {array} [
 * Date,
 * Sent By,
 * Sent To,
 * Category,
 * Type
 * ]
 */
export const transformEmailData = (email = {}, resendString = 'Resend') => {
  return [
    formatDateTime(email.created_date, formatLocale(email.locale_language, email.locale_country)),
    email.initiator,
    email.recipient_email,
    email.notification_name,
    email.is_resend ? resendString : '',
  ];
};
