import React, { createContext, useReducer } from 'react';
import { snackReducer } from '../reducers/snackReducer';

export const SnackContext = createContext({});
const { Provider } = SnackContext;

export const defaultState = {
  text: '',
  type: '',
  slowLoading: false,
  open: false,
  loading: false,
};

/**
 * Provider for snacks
 *
 * @param {Object} props – React props
 */
export const SnackProvider = ({ children }) => {
  const [state, reducer] = useReducer(snackReducer, defaultState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};
