import React, { useContext } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { NikeI18nContext } from '@nike/i18n-react';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import {
  ResponseStatuses,
  InspectionStatus,
  ReasonCodeTypes,
  DialogTypes,
  ApiTimeOut,
} from '../../../../constants/dialog.const';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import INSPECT_RETURN from '../../../../mutations/inspectReturn.mutation';
import translations from './inspectReturn.i18n';
import {
  areReasonsMissingFrom,
  isReturnDispositionMissing,
  isEscalationNotesMissing,
} from '../../../../utils/dialog';
import mapValues from 'lodash/mapValues';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import useSnacks from '../../../../hooks/useSnacks';
import { stepControlSharedStyles } from '../sharedStyles';

/**
 * Component to handle step actions and form submission based on dialog state
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
export default function StepControl() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const { setSlowLoading, setSnack, setError } = useSnacks();
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail, setOrderDetail] = useContext(OrderContext);
  const [athleteInfo] = useContext(AthleteContext);
  const { prevStep, nextStep, reset, setHasTimedOut } = dialogActions;
  const { selectedLines, activeStep, submissionSteps, lock, hasTimedOut } = dialogState;
  const {
    BACK,
    INSPECT_ITEMS,
    NEXT,
    RETURN_INSPECT_TIME_OUT_ERROR_MESSAGE,
    INSPECT,
    SUCCESS,
    ERROR,
  } = mapValues(translations, i18nString);

  const [
    queryOrderDetails,
    { data: responseFromOrderDetailCall, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    onError: () => {
      dispatchError(errorFromOrderDetailCall.message);
    },
    onCompleted: () => {
      dialogDispatch(reset());
      setSnack(`${INSPECT} ${SUCCESS}`);
      setOrderDetail(responseFromOrderDetailCall.orderDetail);
    },
  });

  const dispatchError = (errorMessage) => {
    dialogDispatch(reset());
    setError(`${INSPECT} ${ERROR} ${errorMessage}`);
  };

  // if locked, don't dispatch reset
  const dispatchReset = () => {
    !lock && dialogDispatch(reset());
  };

  // If locked, we will display success snack and message to close the tab - not close the dialog
  const lockedCompletedAction = () => {
    setSnack(`${INSPECT} ${SUCCESS}`);
    dialogDispatch(dialogActions.open(DialogTypes.ACTION_COMPLETE, true));
  };

  // Mutation that calls Inspect Return
  const [sendLineItems] = useMutation(INSPECT_RETURN, {
    onError: (err) => {
      dispatchReset();
      setError(`${INSPECT} ${ERROR} ${err.message}`);
    },
    onCompleted: (response) => {
      const { returnInspections } = response;
      if ((returnInspections.status = ResponseStatuses.COMPLETED && !returnInspections.error)) {
        // if locked, display close tab message, else query order details + close dialog
        lock
          ? lockedCompletedAction()
          : queryOrderDetails({
              variables: {
                orderNumber: orderDetail.orderNumber,
              },
            });
      } else if (
        returnInspections.status === ResponseStatuses.IN_PROGRESS ||
        returnInspections.status === ResponseStatuses.PENDING
      ) {
        dispatchError(RETURN_INSPECT_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (returnInspections.error) {
        const errorMessageOnJobCompletion = `${returnInspections.error.httpStatus}: ${returnInspections.error.message}`;
        dispatchError(errorMessageOnJobCompletion);
        dialogDispatch(reset());
      }
    },
  });

  const thereAreReasonsMissing = areReasonsMissingFrom(selectedLines, DialogTypes.INSPECT);
  const isSubmissionStep = submissionSteps.includes(activeStep);
  const selectedItemKeys = Object.keys(selectedLines);
  const isDispositionMissing = isReturnDispositionMissing(selectedLines);
  const isNotesMissing = isEscalationNotesMissing(selectedLines);

  const handleSubmit = () => {
    setSlowLoading();
    const input = structureReturnInspectInput(selectedLines, orderDetail, athleteInfo.email);
    sendLineItems({
      variables: { input, timeout: ApiTimeOut },
    });
  };

  // reused buttons:
  const BackButton = () => (
    <Button
      disabled={activeStep === 0}
      onClick={() => dialogDispatch(prevStep())}
      aria-label={BACK.toLowerCase()}
      className={classes.stepperButton}>
      {BACK}
    </Button>
  );

  const NextButton = () => (
    <Button
      disabled={selectedItemKeys.length === 0}
      variant='contained'
      color='primary'
      data-testid='inspection-next-button'
      aria-label={NEXT.toLowerCase()}
      onClick={() => dialogDispatch(nextStep())}
      className={classes.stepperButton}>
      {NEXT}
    </Button>
  );

  const SubmitButton = ({ label }) => (
    <>
      <Button
        variant='contained'
        color='primary'
        data-testid='inspection-submit-button'
        disabled={thereAreReasonsMissing || isDispositionMissing || isNotesMissing || hasTimedOut}
        onClick={handleSubmit}
        type='submit'
        className={classes.stepperButton}>
        {label}
      </Button>
    </>
  );

  if (!isSubmissionStep)
    return (
      <div className={classes.actionsContainer}>
        <BackButton />
        <NextButton />
      </div>
    );
  else
    return (
      <div className={classes.actionsContainer}>
        <BackButton />
        <SubmitButton label={INSPECT_ITEMS} />
      </div>
    );
}

/**
 *  The values from reason code api and the values accepted
 *  by return inspection API is not same.
 *  Hence we are doing this conversion
 *  @param reasonCodeType from reason codes API
 *  @returns reason code that is accepted by return inspection API
 */
export const getInspectionStatus = (reasonCodeType) => {
  switch (reasonCodeType) {
    case ReasonCodeTypes.INSPECTION_PASS:
      return InspectionStatus.APPROVE;
    case ReasonCodeTypes.REJECT:
      return InspectionStatus.DENY;
    case ReasonCodeTypes.ESCALATION:
      return InspectionStatus.ESCALATE;
    default:
      return null;
  }
};

/**
 * Function that returns the region warehouse and inspector source
 * @param region: the order region
 * @returns an object with the warehouse and inspector source
 */
export const getWarehouseAndInspectorSource = (region) => {
  let warehouse = '';
  let inspectorSource = '';

  if (region === 'NIKEUS') {
    warehouse = 'BRD';
    inspectorSource = 'XPO';
  } else if (region === 'NIKEEUROPE') {
    warehouse = 'Cycleon RRC';
    inspectorSource = 'CountryHub';
  } else if (region === 'NIKEJP') {
    warehouse = '1085_0008008000';
    inspectorSource = 'TOMISATO';
  } else {
    warehouse = 'BZNIKE';
    inspectorSource = 'CountryHub';
  }

  return {
    warehouse: warehouse,
    inspectorSource: inspectorSource,
  };
};

/**
 * Function that returns request body to call inspect returns
 * @param selectedLines : order lines selected in UI
 * @param orderDetail response from order details call
 * @param contactUser email of athlete taking this action
 * @returns request body to call return inspection
 */
export const structureReturnInspectInput = (selectedLines, orderDetail, contactUser) => {
  const receiptLines = Object.values(selectedLines).map((selectedLine) => {
    let receiptLineNotes = [];

    // default receipt line fields
    let receiptLine = {
      status: getInspectionStatus(selectedLine.inspectReasonCodeType) || '',
      quantity: selectedLine.quantity || '',
      refundReasonCode: selectedLine.inspectionReason.code || '',
      returnDispositionCode: selectedLine.disposition.code,
      orderLineKey: selectedLine.orderLineKey,
      lineNumber: selectedLine.lineNumber.toString(),
    };

    // EU needs gradingResult as an extra field
    if (orderDetail.omsRegionReference === 'NIKEEUROPE') {
      receiptLine.gradingResult = 'GRADE_A';
    }

    if (selectedLine.inspectReasonCodeType) {
      receiptLineNotes.push({
        contactReference: 'CSR',
        contactTime: new Date().toISOString(),
        contactUser,
        contactType: 'Inspection', // From csp code
        noteText: selectedLine.escalationNotes || 'Inspection', // this field can't be empty
        reasonCode: 'RETURN', // Based on csp code
      });
      receiptLine.notes = receiptLineNotes;
    }
    return receiptLine;
  });

  const { warehouse, inspectorSource } = getWarehouseAndInspectorSource(
    orderDetail.omsRegionReference
  );

  return {
    warehouse: warehouse,
    inspectorSource: inspectorSource,
    documentType: orderDetail.orderType || '',
    shipment: {
      omsRegionReference: orderDetail.omsRegionReference || '',
      orderNumber: orderDetail.orderNumber || '',
    },
    receiptLines: receiptLines || [],
  };
};

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
}));
