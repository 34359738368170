import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import React, { useContext, useEffect } from 'react';
import InputMask from 'react-input-mask';
import dialogActions from '../../store/actions/dialogActions';
import { DialogContext } from '../../store/contexts/dialogContext';
import translations from './addressForm.i18n';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FirstNameField, LastNameField, KanaFirstNameField, KanaLastNameField } from './nameFields';
import { handleFieldChange } from './addressFieldChangeHandler';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import JPStates from '../../constants/address/JPStates.const';
import { Country } from '../../constants/country.const';
import { ConsumerContext } from '../../store/contexts/consumerContext';
import { getEmailForReturn } from '../../utils/dialog';

/**
 * This is a reusable component for EMEA consumer addresses.
 *
 * @param {React.props} props – React props containing optional class name
 */
const JPAddressForm = ({ isGiftCardAddress = false, className = '' }) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [consumerState] = useContext(ConsumerContext);
  const { i18nString } = useContext(NikeI18nContext);
  const classes = useStyles();

  const { setAddressField, setGCShippingAddressField } = dialogActions;
  const address = isGiftCardAddress ? dialogState.gcShippingAddress : dialogState.address;
  const { address1, address2, address3, city, state, postalCode } = address;
  const { consumerEmail } = consumerState;
  const email = getEmailForReturn(address, dialogState.gcShippingAddress, consumerEmail);
  const dayPhoneNumber =
    address.dayPhoneNumber ||
    dialogState.gcShippingAddress?.dayPhoneNumber ||
    dialogState.address?.dayPhoneNumber;
  const {
    MUNICIPALITY,
    AREA_VILLAGE,
    STREET_ADDRESS,
    BUILDING_NAME,
    PREFECTURE,
    COUNTRY,
    JAPAN,
    POSTAL_CODE,
    PHONE_NUMBER,
    EMAIL,
  } = mapValues(translations, i18nString);

  /**
   * This use effect is only intended to execute once, when the component loads, to ensure the
   * address country field is 'JP'.
   */
  useEffect(() => {
    if (address.country !== Country.JAPAN) {
      dialogDispatch(setAddressField('country', Country.JAPAN));
    }
    if (isGiftCardAddress && !address.email) {
      dialogDispatch(setGCShippingAddressField('email', email));
    }
  }, [address]);

  // Updating the address and gcShippingAddress (billing address) phone numbers
  useEffect(() => {
    if (dayPhoneNumber && !dialogState.address?.dayPhoneNumber) {
      dialogDispatch(setAddressField('dayPhoneNumber', dayPhoneNumber));
    } else if (dayPhoneNumber && !dialogState.gcShippingAddress?.dayPhoneNumber) {
      dialogDispatch(setGCShippingAddressField('dayPhoneNumber', dayPhoneNumber));
    }
  }, [dayPhoneNumber]);

  /**
   * This function offers a concise means of handling basic changes.
   *
   * @param {Object} event – the event object created by the change
   */
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  const addressGridClassName = isGiftCardAddress ? classes.gcAddressGrid : classes.addressGrid;

  return (
    <div className={clsx(addressGridClassName, className)}>
      {/* If isGCAddress, show email field for step 3. */}
      {isGiftCardAddress && (
        <FormControlLabel
          aria-label={EMAIL}
          className={classes.email}
          control={
            <TextField
              required
              defaultValue={email}
              onChange={handleChange('email')}
              aria-required={EMAIL}
              label={EMAIL}
              className={classes.widthField}
              data-testid={'input-email'}
            />
          }
        />
      )}
      <LastNameField classes={classes} isGiftCardAddress={isGiftCardAddress} required={true} />
      <FirstNameField classes={classes} isGiftCardAddress={isGiftCardAddress} required={true} />
      <KanaLastNameField classes={classes} isGiftCardAddress={isGiftCardAddress} required={true} />
      <KanaFirstNameField classes={classes} isGiftCardAddress={isGiftCardAddress} required={true} />
      {!isGiftCardAddress && (
        <>
          {/* Area/Village maps to the address3 field */}
          <FormControlLabel
            aria-label={AREA_VILLAGE}
            className={classes.address3}
            control={
              <TextField
                required
                value={address3}
                onChange={handleChange('address3')}
                label={AREA_VILLAGE}
                className={classes.wideField}
                data-testid={'input-area-village'}
              />
            }
          />
          {/* Street Address maps to the address1 field */}
          <FormControlLabel
            aria-label={STREET_ADDRESS}
            className={classes.address1}
            control={
              <TextField
                required
                value={address1}
                onChange={handleChange('address1')}
                label={STREET_ADDRESS}
                className={classes.wideField}
                data-testid={'input-street-address'}
              />
            }
          />
          {/* Building Name maps to the address2 field */}
          <FormControlLabel
            aria-label={BUILDING_NAME}
            className={classes.address2}
            control={
              <TextField
                value={address2 || undefined}
                onChange={handleChange('address2')}
                label={BUILDING_NAME}
                aria-labelledby={BUILDING_NAME}
                className={classes.wideField}
                data-testid={'input-building-name'}
              />
            }
          />
          {/* Municipality maps to the city field */}
          <FormControlLabel
            aria-label={MUNICIPALITY}
            className={classes.city}
            control={
              <TextField
                required
                value={city}
                onChange={handleChange('city')}
                label={MUNICIPALITY}
                className={classes.wideField}
                data-testid={'input-municipality'}
              />
            }
          />
          <FormControl className={classes.prefecture} aria-label={PREFECTURE} required>
            <InputLabel id='chooseState' shrink>
              {PREFECTURE}
            </InputLabel>
            <Select
              value={state || 'none'}
              onChange={handleChange('state')}
              data-testid={'select-prefecture'}
              aria-labelledby='chooseState'
              labelId='chooseState'>
              <MenuItem disabled value='none'>
                —
              </MenuItem>
              {/* TODO When implementing support for multiple geos,
               * determine when to use en or jp. */}
              {JPStates.en.map((state, key) => (
                <MenuItem
                  data-key='state'
                  value={state.abbreviation}
                  key={key}
                  data-testid={`select-prefecture-${key}`}>
                  {state.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            aria-label={COUNTRY}
            className={classes.country}
            control={
              <TextField
                disabled
                value={JAPAN}
                onChange={handleChange('country')}
                label={COUNTRY}
                className={classes.widthField}
                data-testid={'input-country'}
              />
            }
          />
          <FormControlLabel
            aria-label={POSTAL_CODE}
            className={classes.postalCode}
            control={
              <TextField
                required
                value={postalCode}
                onChange={handleChange('postalCode')}
                label={POSTAL_CODE}
                className={classes.widthField}
                data-testid={'input-postal-code'}
              />
            }
          />
          <InputMask
            mask='(999) 999-9999'
            value={dayPhoneNumber}
            onChange={handleChange('dayPhoneNumber')}>
            {() => (
              <FormControlLabel
                aria-label={PHONE_NUMBER}
                className={classes.phoneField}
                control={
                  <TextField
                    required
                    aria-required={true}
                    inputProps={{ 'data-key': 'dayPhoneNumber' }}
                    label={PHONE_NUMBER}
                    className={classes.wideField}
                    data-testid={'input-phone-number'}
                  />
                }
              />
            )}
          </InputMask>
        </>
      )}
    </div>
  );
};

export default JPAddressForm;

const useStyles = makeStyles({
  addressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridTemplateAreas: `
      " LN LN LN FN FN FN .. .. "
      " KL KL KL KF KF KF .. .. "
      " A3 A3 A3 A3 A3 A3 .. .. "
      " A1 A1 A1 A1 A1 A1 .. .. "
      " A2 A2 A2 A2 A2 A2 .. .. "
      " CI CI CO PR PR PO .. .. "
      " PH PH .. .. .. .. .. .. "
    `,
    gridGap: '1rem 3rem',
  },
  gcAddressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridTemplateAreas: `
      " EM EM EM EM EM EM .. .. "
      " LN LN LN FN FN FN .. .. "
      " KL KL KL KF KF KF .. .. "
      " PH PH .. .. .. .. .. .. "
    `,
    gridGap: '1rem 3rem',
  },
  email: {
    gridArea: 'EM',
    width: '100%',
    margin: 0,
  },
  lastNameField: {
    gridArea: 'LN',
    margin: 0,
  },
  firstNameField: {
    gridArea: 'FN',
  },
  kanaLastNameField: {
    gridArea: 'KL',
    margin: 0,
  },
  kanaFirstNameField: {
    gridArea: 'KF',
  },
  address1: {
    gridArea: 'A1',
    width: '100%',
    margin: 0,
  },
  address2: {
    gridArea: 'A2',
    width: '100%',
    margin: 0,
  },
  address3: {
    gridArea: 'A3',
    width: '100%',
    margin: 0,
  },
  city: {
    gridArea: 'CI',
    width: '100%',
    margin: 0,
  },
  prefecture: {
    gridArea: 'PR',
    width: '100%',
    margin: 0,
  },
  country: {
    gridArea: 'CO',
    width: '100%',
    margin: 0,
  },
  postalCode: {
    gridArea: 'PO',
    width: '100%',
    margin: 0,
  },
  phoneField: {
    gridArea: 'PH',
    margin: 0,
  },
  wideField: {
    width: '100%',
  },
});
