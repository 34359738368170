/**
 * Stitches a string name from the given nameObject.
 * The stitched name is either from fields givenName and familyName, or if those fields aren't
 * present, then from fields firstName and lastName, or returns an empty string if none of these
 * fields are available.
 *
 * @param {Object} nameObject the object from which to stitch a string name
 * @returns {string} the stitched string name
 */
export const stitchName = (nameObject) => {
  if (nameObject?.givenName) {
    return nameObject.givenName + ' ' + nameObject.familyName;
  } else if (nameObject?.firstName) {
    return nameObject.firstName + ' ' + nameObject.lastName;
  } else {
    return '';
  }
};
