import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import React, { useContext } from 'react';
import dialogActions from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { determineLineInspectability, getUnInspectableMessage } from '../../../../utils/dialog';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { step1SharedStyles } from '../sharedStyles';
import { OrderContext } from './../../../../store/contexts/orderContext';

export default function Step1() {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);

  const { selectLine } = dialogActions;
  const { selectedLines } = dialogState;

  let { orderLines } = orderDetail;
  orderLines = Array.from(orderLines);

  const handleSelectLine = (line) => () => {
    dialogDispatch(selectLine(line));
  };

  return (
    <div className={classes.productGrid}>
      {orderLines.map((line, i) => {
        const isInspectable = determineLineInspectability(line, selectedLines);
        if (isInspectable) {
          return (
            <div key={'return-item-thumb-' + i}>
              <div
                role='button'
                onKeyDown={handleSelectLine(line)}
                onClick={handleSelectLine(line)}
                tabIndex={0}>
                <Card
                  className={selectedLines[line.lineNumber] ? classes.selectedItem : classes.item}
                  elevation={selectedLines[line.lineNumber] ? 3 : 1}
                  data-testid={`item-to-select-${i}`}>
                  <CardMedia
                    className={classes.productThumb}
                    image={getProductImageFromOrderLine(line)}
                    title={line.item.itemDescription}
                    children={
                      selectedLines[line.lineNumber] && (
                        <CheckIcon className={classes.productSelectCheckIcon} />
                      )
                    }
                  />
                  <Box p={1}>
                    <Typography noWrap variant='body1' gutterBottom>
                      {line.item.itemDescription}
                    </Typography>
                    <Typography noWrap variant='caption'>
                      {line.styleNumber + '-' + line.colorCode}
                    </Typography>
                  </Box>
                </Card>
              </div>
            </div>
          );
        } else {
          return (
            <div key={'return-item-thumb-' + i}>
              <Tooltip
                title={getUnInspectableMessage(line, selectedLines)}
                placement='bottom'
                enterDelay={200}
                leaveDelay={100}
                classes={{ tooltip: classes.tooltip }}>
                <Card className={classes.unselectableItem} elevation={0}>
                  <CardMedia
                    className={classes.disabledProductThumb}
                    image={getProductImageFromOrderLine(line)}
                    title={line.item.itemDescription}
                  />
                  <Box p={1}>
                    <Typography variant='body1' className={classes.halfOpacity} gutterBottom>
                      {line.item.itemDescription}
                    </Typography>
                    <Typography variant='caption' className={classes.halfOpacity}>
                      {line.styleNumber + '-' + line.colorCode}
                    </Typography>
                  </Box>
                </Card>
              </Tooltip>
            </div>
          );
        }
      })}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  ...step1SharedStyles(theme),
}));
