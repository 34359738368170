import ORDER_DETAIL_QUERY from '../queries/orderDetail.query';
import { logErrorAction } from '../newrelic/logErrorAction';

// global cache for object, so we're only building it once per instance.
let cachedOrderObject = null;

/**
 * A utility function for translating Apollo query strings into JSON objects.
 *
 * note: this function should not throw errors, favoring error logging, and continuity of user
 * experience.
 *
 * @param {String} query - the query string to be parsed into an object
 * @param  {...String} fragments - optional fragments for the above query
 */
export const parseQueryToJson = (query, ...fragments) => {
  try {
    // first, get rid of extra white space
    query = query.trim();

    // then we handle any fragments, if present
    if (query.indexOf('...')) {
      fragments = prepareFragments(fragments);

      /*
      first, we'll loop over the query, replacing fragment calls with the associated fragment. 
      we loop because fragments can contain sub-fragments
      */
      while (query.indexOf('...') > -1) {
        query = query.replace(/\.\.\..*$/gm, (line) => replaceFragment(line, fragments));
      }

      /*
      then we strip out the empty lines we may have caused, as they have the potential to cause 
      havok later
      */
      query = query.replace(/^\s*$(?:\r\n?|\n)/gm, '');
    }

    return JSON.parse(
      query
        // remove any parameters
        .substr(query.indexOf('{'))
        // remove comments
        .replace(/#.*$(\r\n|\r|\n)/gm, '')
        // remove the parenteticals (gql variables)
        .replace(/ *\([^)]*\) */gm, ' ')
        // surround words with quotation marks
        .replace(/(\w+)/gm, (word) => '"' + word + '"')
        // add semicolon to object field definitions
        .replace(/( {)/gm, ': {')
        // add a semicolon, an empty string value, and a comma to other field definitions
        .replace(/^((?!{|}).)+$/gm, (line) => line.concat(': "",'))
        // add a comma to the end of each object field which is followed by another field
        .replace(/.*[}]$(\r\n|\r|\n)[ \t]+"/gm, '},"')
        // replace all whitespace with single spaces (for consistency's sake)
        .replace(/\s+/g, ' ')
        // remove trailing commas
        .replace(/, }/gm, ' }')
      // and there you go, you got yourself a JSON parsable string. ow.
    );
  } catch (error) {
    console.error(
      'Error parsing GraphQL query into JSON object. There is probably an error in your query syntax',
      error
    );
    logErrorAction(error, {
      customMessage:
        'Error parsing GraphQL query into JSON object. There is probably an error in your query syntax',
    });
    return {};
  }
};

/**
 * A helper function for parseQueryToJson which converts an array of strings into an object,
 * mapping fragment names to their associated fragment.
 *
 * note: this function should not throw errors, favoring error logging, and continuity of user
 * experience.
 *
 *  @param {[String]} fragments - an array of fragments (Strings) to be prepared
 */
export const prepareFragments = (fragments) => {
  // the map to return
  const fragmentMap = {};
  // regex for identifying the beginning of a fragment
  const fragmentRegex = /[ \s]*fragment /gm;
  // and a more uniform string to replace it with. you'll see.
  const fragmentDeclaration = 'fragment ';

  for (let i = 0; i < fragments.length; i++) {
    try {
      // get the fragment
      let fragment = fragments[i].trim();

      // if a fragment contains more than one fragment, which happens if there are subfragments
      if (fragment.match(fragmentRegex).length > 1) {
        let splitFragments = fragment
          .split(fragmentRegex) // this step removes the fragment declaration, so we'll add it back in
          .map((splitFragment) => [fragmentDeclaration, splitFragment].join('')); // this maybe a weird syntax to do so, but it's the fastest way

        /*
        as we split by fragment declaration, we have an empty element at the begining of our 
        fragments array
        */
        splitFragments.shift();
        // reassign our active fragment to the first element
        fragment = splitFragments.shift();
        /*
        and push the rest on to the parent array

        note: this is why we're using a traditional for loop – it is the only loop which allows for
        internal mutation, as it reevaluates the criteria on each loop.
        */
        fragments.push(...splitFragments);
      }

      // grab where the object starts and ends
      const fragmentStartIndex = fragment.indexOf('{');
      const fragmentEndIndex = fragment.lastIndexOf('}');
      // use that index to extract the parameters
      const parameters = fragment
        .substr(0, fragmentStartIndex)
        .trim()
        .split(' ');
      // and then the fragment name from that
      const fragmentName = parameters[1];

      // remove whitespace, parameters, and braces, leaving only the body of the fragment
      fragment = fragment.substring(fragmentStartIndex + 1, fragmentEndIndex);

      // and add it to our map
      fragmentMap[fragmentName] = fragment.trim();
    } catch (error) {
      logErrorAction(error, { customMessage: 'Error encountered while processing fragments' });
      console.error('Error encountered while processing fragments:', error);
    }
  }
  return fragmentMap;
};

/**
 * Helper function which expects a string containing fragment syntax (line), and a map of fragments
 * (fragments) and returns the associated fragment, if one exists. otherwise, it returns an empty
 * string.
 *
 * @param {String} line - the line containing fragment syntax
 * @param {Object} fragments - the map of fragments
 */
export const replaceFragment = (line, fragments) => {
  /*
  we want to error log in these situations, as if the function is called without them, we are in
  error territory. errortory. no.
  */
  if (!line) {
    console.error('Missing first parameter: line');
    return '';
  } else if (!fragments) {
    console.error(`Missing second parameter: fragments`);
    return '';
  } else if (!(typeof line === 'string' || line instanceof String)) {
    console.error('First parameter must be a string');
    return '';
  }
  // remove the leading dots
  line = line.replace(/\.\.\./g, '');
  // grab the associated fragment, if one exists
  const fragment = fragments[line.trim()];
  // and deal with the case that it's not
  if (!fragment) {
    console.error(`Required fragment not present: ${line}`);
    return '';
  }
  return fragment;
};

/**
 * A utility function specific to our application, which returns the JSON object associated with
 * our query string.
 */
export const getOrderDetailQuery = () => {
  if (!cachedOrderObject) {
    // .loc.source.body is where the query string is available when we are using gql
    cachedOrderObject = parseQueryToJson(ORDER_DETAIL_QUERY.loc.source.body);
  }
  return cachedOrderObject;
};
