/** React / Utils */
import React, { useContext, useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { v4 as uuidv4 } from 'uuid';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';

/** Material UI */
import { Box, makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

/** Local */
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import AthleteContext from '../../../../store/contexts/athleteContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { step1SharedStyles } from '../../dialog/sharedStyles';
import translations from './commentStep1.i18n';
import { isVASOrderLineJerseyId, getOrderLineContainers } from '../../../../utils/order';
import { swapSpots } from '../../../../utils/dialog';
import useSnacks from '../../../../hooks/useSnacks';
import ADD_COMMENT_MUTATION from '../../../../mutations/addComment.mutation';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import { ApiTimeOut } from '../../../../constants/dialog.const';

/**
 * This component is step 1 of the Add Comment flow
 *
 * @param {Object} props – props object for a React component
 */
const CommentStep1 = (props) => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail, setOrderDetail] = useContext(OrderContext);
  const [, dialogDispatch] = useContext(DialogContext);

  const [frontRowMap, setFrontRowMap] = useState({});
  const [showCommentBox, setShowCommentBox] = useState(false);
  const [showIndividualItems, setShowIndividualItems] = useState(false);
  const [itemLevelValue, setItemLevelValue] = useState(null);
  const [orderLevelValue, setOrderLevelValue] = useState(null);
  const [selectedLine, setSelectedLine] = useState(null);
  const [athleteInfo] = useContext(AthleteContext);
  const { setSnack, setSlowLoading, setError } = useSnacks();

  const { selectLine, reset } = dialogActions;
  const inlineVasLineNumberMap = {};
  const {
    COMMENT_ON_ENTIRE_ORDER,
    COMMENT_ON_INDIVIDUAL_ITEM,
    GIFT_CARD_VALUE,
    GIFT_CARD_BALANCE,
    ITEM_LEVEL_COMMENT,
    ORDER_LEVEL_COMMENT,
    SUBMIT,
    ADD_COMMENT_ERROR,
    ADD_COMMENT_SUCCESS,
  } = mapValues(translations, i18nString);

  let { orderLines, omsRegionReference } = orderDetail;
  orderLines = orderLines && Array.from(orderLines);

  /*
    Iterate through the orderLines and add them to an object where the value is a boolean that 
    represents whether an item is of a service type or not. This map will be used to determine
    which items will be in the 'front row' or 'back row' in the UI.
   */
  useEffect(() => {
    let newFrontRowMap = {};
    orderLines &&
      orderLines.forEach((line) => {
        if (line.orderLineType !== 'SERVICE') {
          newFrontRowMap[line.lineNumber] = true;
        } else {
          newFrontRowMap[line.lineNumber] = false;
        }
      });
    setFrontRowMap(newFrontRowMap);
  }, []);

  /**
   * Handles the onClick events for items.
   * @param {object} line orderLine item the action is being executed against.
   */
  const handleSelectLine = (line, isVasItem) => () => {
    if (frontRowMap[line.lineNumber]) {
      // if the current line being clicked is already selected, unselect it
      // else, select the clicked line
      selectedLine?.lineNumber === line.lineNumber ? setSelectedLine(null) : setSelectedLine(line);
      // show the comment box only when an item is selected
      selectedLine?.lineNumber === line.lineNumber
        ? setShowCommentBox(false)
        : setShowCommentBox(true);
      // Check if item either is or has a VAS Jersey ID. If so, also select the corresponding item.
      if (line.hasNestedVasOrderLines && isVASOrderLineJerseyId(line.nestedVasOrderLines[0])) {
        dialogDispatch(selectLine(line.nestedVasOrderLines[0]));
      }
    } else {
      // Item is not in the front and needs to be brought forth.
      const newFrontRowMap = swapSpots(
        frontRowMap,
        inlineVasLineNumberMap,
        line.lineNumber,
        isVasItem
      );
      setFrontRowMap(newFrontRowMap);
    }
  };

  /**
   * Wraps each item in either a selectable or unselected card, depending
   * on the type of item it is and if it is returnable or not.
   *
   * @param {object} line orderLine item that needs to be wrapped in a card.
   * @param {string} i is the key/identifier to be used in the div component due
   *              to the grid layout.
   */
  const wrapEachCard = (line, i) => {
    const isVasItem = line.orderLineType === 'SERVICE';
    const key = `comment-item-thumb-${i}`;
    let cardClassName = !isVasItem ? classes.firstRowItem : classes.secondRowItem;
    // If the item belongs in the front, attach the primaryItem styling.
    if (frontRowMap[line.lineNumber]) {
      cardClassName = `${cardClassName} ${classes.primaryItem}`;
    }
    // If item is not a vas item then it should be displayed in the first row.

    return (
      <div key={key} className={cardClassName}>
        <div
          role='checkbox'
          aria-checked={Boolean(selectedLine?.lineNumber === line.lineNumber)}
          aria-label={line.item.itemDescription}
          onKeyPress={handleSelectLine(line, isVasItem)}
          onClick={handleSelectLine(line, isVasItem)}
          tabIndex={0}>
          <Card
            className={
              selectedLine?.lineNumber === line.lineNumber ? classes.selectedItem : classes.item
            }
            elevation={selectedLine?.lineNumber ? 3 : 1}
            data-testid={`item-to-select-${i}`}>
            <CardMedia
              className={classes.productThumb}
              image={getProductImageFromOrderLine(line)}
              title={line.item.itemDescription}
              children={
                selectedLine?.lineNumber === line.lineNumber && (
                  <CheckIcon className={classes.productSelectCheckIcon} />
                )
              }
            />
            <Box p={1}>
              <Typography variant='body1' noWrap gutterBottom>
                {line.item.itemDescription}
              </Typography>
              <Typography variant='caption'>{line.styleNumber + '-' + line.colorCode}</Typography>
              {line.styleNumber === 'GIFTCARD' && (
                <>
                  <Typography className={classes.giftCardDetails} noWrap gutterBottom>
                    {GIFT_CARD_VALUE} - {line.giftCardValue}
                  </Typography>
                  <Typography className={classes.giftCardDetails}>
                    {GIFT_CARD_BALANCE} - {line.giftCardBalance}
                  </Typography>
                </>
              )}
            </Box>
          </Card>
        </div>
      </div>
    );
  };

  /**
   * Create a container with the appropriate className depending on whether it is a single item
   * or an item that has a VAS associated with it.
   * @param {string} key Unique identifer of the div
   * @param {React.Component} children Children objects to be displayed within this container.
   */
  const createContainer = (key, ...children) => {
    return (
      <div
        key={`'comment-item-container-thumb-'${key}-container`}
        className={children.length > 1 ? classes.vasItemContainer : classes.singleItemContainer}>
        {children}
      </div>
    );
  };

  /**
   * Wraps each of the items that belong together. This will wrap vas items with their
   * item counterparts. This allows the vas items to be shown behind their counterparts
   * and to do the switching to make them visible when clicked.
   *
   * @param {array} orderLines list of orderline items.
   */
  const createOrderLineContainers = (orderLines, omsRegionReference) => {
    const orderLineContainers = getOrderLineContainers(orderLines, omsRegionReference);
    const wrappedOrderLineContainers = [];

    for (let i = 0; i < orderLineContainers.length; i++) {
      const container = orderLineContainers[i];
      const WrappedInlineItem = wrapEachCard(container.item, `${i}a`);

      if (container.service) {
        const wrappedServiceItem = wrapEachCard(container.service, `serviceItem${i}`);
        inlineVasLineNumberMap[container.item.lineNumber] = container.service.lineNumber;
        wrappedOrderLineContainers.push(createContainer(i, WrappedInlineItem, wrappedServiceItem));
      } else {
        // Inline item is standalone with no other references.
        wrappedOrderLineContainers.push(createContainer(i, WrappedInlineItem));
        // Added using an empty string to signal that there is no second row ui item.
        inlineVasLineNumberMap[container.item.lineNumber] = '';
      }
    }

    return wrappedOrderLineContainers;
  };

  const orderLineContainers = createOrderLineContainers(orderLines, omsRegionReference);

  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: errorFromOrderDetailCall },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    onError: () => {
      setError(errorFromOrderDetailCall.message);
      dialogDispatch(reset());
    },
    onCompleted: () => {
      const { orderDetail } = queryOrderDetailsData;
      setOrderDetail(orderDetail);
      setSnack(`${ADD_COMMENT_SUCCESS}`);
      dialogDispatch(reset());
    },
  });

  const [addComment] = useMutation(ADD_COMMENT_MUTATION, {
    onError: (err) => {
      setError(`${ADD_COMMENT_ERROR} : ${err.message}`);
      dialogDispatch(reset());
    },
    onCompleted: (data) => {
      // error for initial add comment post
      if (data?.addComment?.errors) {
        setError(`${ADD_COMMENT_ERROR} : ${data.addComment.errors?.[0]?.error?.message}`);
        dialogDispatch(reset());
        // error for handleCQRSAPI job status get
      } else if (data?.addComment?.error) {
        setError(`${ADD_COMMENT_ERROR} : ${data.addComment.error?.message}`);
        dialogDispatch(reset());
      } else {
        queryOrderDetails({
          variables: {
            orderNumber: orderDetail.orderNumber,
          },
        });
      }
    },
  });

  const handleSubmit = async () => {
    const date = new Date();
    const orderNumber = orderDetail.orderNumber;
    const commentDetails = {
      request: {
        requestId: `${uuidv4()}`,
        lineNumber: `${selectedLine ? selectedLine.lineNumber : ''}`,
        comment: {
          contactType: '',
          contactReference: '',
          contactUser: athleteInfo.name,
          contactDate: date.toISOString(),
          details: selectedLine ? itemLevelValue : orderLevelValue,
          reasonCode: '',
        },
      },
    };
    setSlowLoading();
    addComment({ variables: { orderNumber, commentDetails, timeout: ApiTimeOut } });
  };

  return (
    <main data-testid='comment-step-1'>
      <RadioGroup className={classes.radioSection}>
        <FormControlLabel
          control={<Radio color='primary' style={{ marginBottom: 2 }} />}
          checked={!showIndividualItems}
          aria-checked={!showIndividualItems}
          onChange={() => {
            setShowIndividualItems(false);
            setSelectedLine(null);
          }}
          label={COMMENT_ON_ENTIRE_ORDER}
        />
        <FormControlLabel
          control={
            <Radio
              type='radio'
              color='primary'
              data-testid={'show-individual-items'}
              style={{ marginBottom: 2 }}
            />
          }
          checked={showIndividualItems}
          aria-checked={showIndividualItems}
          onChange={() => setShowIndividualItems(true)}
          label={COMMENT_ON_INDIVIDUAL_ITEM}
        />
      </RadioGroup>
      {!showIndividualItems && (
        <div>
          <TextField
            multiline
            rows={4}
            width={1}
            margin='normal'
            label={ORDER_LEVEL_COMMENT}
            id='outlined'
            variant='outlined'
            value={orderLevelValue || ''}
            onChange={(event) => {
              setOrderLevelValue(event.target.value);
            }}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 2000 }}
            data-testid={'order-level-comment-box'}
            style={{ width: 445 }}
          />
        </div>
      )}
      {showIndividualItems && (
        <div>
          <div
            className={classes.productGrid}
            style={{ marginLeft: -15 }}
            data-testid={'individual-items'}>
            {orderLineContainers.map((line) => {
              return line;
            })}
          </div>
          {showCommentBox ? (
            <TextField
              multiline
              rows={4}
              margin='normal'
              label={ITEM_LEVEL_COMMENT}
              id='outlined'
              variant='outlined'
              data-testid={'item-level-comment-box'}
              value={itemLevelValue || ''}
              onChange={(event) => {
                setItemLevelValue(event.target.value);
              }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 2000 }}
              style={{ width: 445 }}
            />
          ) : null}
        </div>
      )}
      <Button
        classes={{ root: classes.stepperButton, disabled: classes.buttonDisabled }}
        variant='contained'
        color='primary'
        type='submit'
        disabled={!(itemLevelValue && selectedLine) && !(orderLevelValue && !showIndividualItems)}
        data-testid={'submit-comment-button'}
        onClick={(e) => handleSubmit(e)}
        style={{ marginTop: 15 }}>
        {SUBMIT}
      </Button>
    </main>
  );
};

const useStyles = makeStyles((theme) => ({
  ...step1SharedStyles(theme),
  radioSection: {
    display: 'flex',
    flexDirection: 'row',
  },
  buttonDisabled: {},
}));

export default CommentStep1;
