/**
 * Map of GraphQL operations to human-readable operation types that an be interpreted
 * and analyzed by New Relic.
 *
 * ALL new GraphQL operations should be added to an appropraite category
 * Categories can be added and rearranged if run by the team
 */
const operationTypeMap = {
  return: ['postReturnCaptures'],
  cancel: ['postCancelLineItem'],
  inspect: ['postReturnInspections'],
  returnLabel: ['postReturnLabelsDispatch'],
  identity: ['postReadIdentityUser'],
  priceModification: ['addLineItemDiscount'],
  shippingDiscount: ['addShippingDiscount'],
  browse: [
    'getOrderDetail',
    'getReasonCodesV2',
    'getOrderNotifications',
    'getOrderSummaryV2',
    'getNotificationDetails',
    'getOrderNotifications',
    'postAddressValidator',
    'postEmailResend',
    'postReturnLocation',
    'getGiftCardBalance',
    'storeViews',
    'assetSearchV2',
  ],
};

/**
 * converts the above operationTypeMap to a usable dictionary of operation names with
 * the assigned operation type as the defintion
 *
 * @param {object} obj – operationTypeMap
 */
function reverseMap(obj) {
  return Object.keys(obj).reduce(
    (acc, type) =>
      obj[type].reduce((a, num) => {
        a[num] = type;
        return a;
      }, acc),
    {}
  );
}

/**
 * classifies any given operation name by its operation type based on the operationTypeMap
 *
 * @param {object} obj – operationTypeMap
 */
export const classifyOperation = (operation) => {
  const typeLookup = reverseMap(operationTypeMap);
  const foundType = typeLookup[operation];

  if (foundType) {
    return foundType;
  } else {
    console.error(
      // eslint-disable-next-line no-template-curly-in-string
      `Unclassified OperationType, please add ${operation} to the operationTypeMap in ./utils/newRelic`
    );
    return 'unclassified';
  }
};
