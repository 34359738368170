const translations = {
  NON_RETURNABLE_ITEM: {
    description: 'Tooltip that explains an item is not returnable',
    primaryValue: 'Item is not returnable',
    stringKey: 'nonReturnableItem',
  },
  NON_RETURNABLE_VAS_ITEM: {
    description: 'Tooltip that explains a vas item is not returnable',
    primaryValue: 'VAS cannot be returned',
    stringKey: 'nonReturnableVasItem',
  },
  GIFT_CARD_VALUE: {
    description: 'value of the original gift card',
    primaryValue: 'GIFT CARD VALUE',
    stringKey: 'giftCardValue',
  },
  GIFT_CARD_BALANCE: {
    description: 'current gift card balance',
    primaryValue: 'GIFT CARD BALANCE',
    stringKey: 'giftCardBalance',
  },
  NON_RETURNABLE_GIFT_CARD_ITEM: {
    description: 'Tooltip that explains an item is not returnable as gift card is used',
    primaryValue: 'Cannot return a used gift card',
    stringKey: 'nonReturnableGiftCardItem',
  },
};

export default translations;
