import React, { useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import { SnackContext } from '../../../store/contexts/snackContext';
import Snackbar from '@material-ui/core/Snackbar';
import snackActions from '../../../store/actions/snackActions';
import Loading from '../loading';

/**
 * Component to show the response notifications
 */
export default function ResponseSnackBar() {
  const [snackState, snackDispatch] = useContext(SnackContext);
  const { slowLoading, text, status, isOpen, loading } = snackState;
  const { clearSnack, setOpen } = snackActions;

  const handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    snackDispatch(clearSnack());
  };

  if (loading) return <Loading toWait={false} />;
  else if (slowLoading) return <Loading toWait={true} />;
  else if (isOpen)
    return (
      <Snackbar
        open={isOpen}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={10000}
        onClose={handleClose}
        data-testid='snackbar'>
        <Alert onClose={handleClose} severity={status}>
          {text}
        </Alert>
      </Snackbar>
    );
  else return null;
}
