const translations = {
  ITEM_DISCOUNTS: {
    description: 'label for item discounts total in totals panel',
    primaryValue: 'Item Discounts',
    stringKey: 'itemDiscounts',
  },
  ORDER_TOTAL: {
    description: 'label for order total in totals panel',
    primaryValue: 'Order Total',
    stringKey: 'orderTotal',
  },
  PRODUCT: {
    description: 'label for product total in totals panel',
    primaryValue: 'Product',
    stringKey: 'product',
  },
  SHIPPING: {
    description: 'label for shipping total in totals panel',
    primaryValue: 'Shipping',
    stringKey: 'shipping',
  },
  TAX: {
    description: 'label for tax total in totals panel',
    primaryValue: 'Tax',
    stringKey: 'tax',
  },
  TOTALS: {
    description: 'title of totals panel',
    primaryValue: 'Totals',
    stringKey: 'totals',
  },
  RECALCULATE: {
    description: 'label for button to recalculate tax',
    primaryValue: 'Recalculate',
    stringKey: 'recalculate',
  },
};

export default translations;
