/* React/Utils */
import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/* Material-UI */
import MoneyIcon from '@material-ui/icons/AttachMoney';
import CachedIcon from '@material-ui/icons/Cached';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';

/* Local */
import { Partners } from '../../../constants/origin.const';
import { RecalculateTax } from '../../../constants/permissions.const';
import config from '../../../utils/config';
import getArrayProperty from '../../../utils/getArrayProperty';
import { getDiscount, getShippingCharges, getTax, getTaxDiscounts } from '../../../utils/order';
import { OrderContext } from '../../../store/contexts/orderContext';
import { FormattedCurrency } from '../../shared/formatCurrency';
import HasPermission from '../../shared/hasPermission';
import translations from './charges.i18n';
import SidebarItem from './sidebarItem';

/**
 * @todo implement math library for summing and floating point correction
 */
const Charges = () => {
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const { currency, channel } = orderDetail;
  const orderLines = getArrayProperty(orderDetail, 'orderLines');
  const headerCharges = getArrayProperty(orderDetail, 'headerCharges');
  const headerTaxes = getArrayProperty(orderDetail, 'headerTaxes');
  const classes = useStyles();
  const { ITEM_DISCOUNTS, ORDER_TOTAL, PRODUCT, SHIPPING, TAX, TOTALS, RECALCULATE } = mapValues(
    translations,
    i18nString
  );

  const partner = channel ? Partners[channel] : '';

  /**
   * simple sums of values
   */
  const shippingCharges = getShippingCharges(headerCharges);

  const discountsFromHeaderCharges = headerCharges
    .filter(
      (charge) =>
        typeof charge.chargeCategory === 'string' && charge.chargeCategory.includes('DISCOUNT')
    )
    .reduce((acc, cur) => acc + Number(cur.chargeAmount || 0), 0);

  /*
    get discounts per orderline.
    discounts are multiplied by orderline quantity to get total discount.  
  */
  const discountsFromOrderLines = orderLines.reduce(
    (total, orderLine) => total + getDiscount(orderLine),
    0
  );

  const totalHeaderTax = headerTaxes.reduce((acc, cur) => acc + Number(cur.taxAmount || 0), 0);

  const totalItemTax = orderLines.reduce(
    (total, orderLine) => total + getTax(orderLine) - getTaxDiscounts(orderLine),
    0
  );

  const totalTax = totalHeaderTax + totalItemTax;

  /**
   * Calculate product cost by multiplying all of the quantities and list prices
   * of non-cancelled items then summing using reduce
   */
  const totalProduct = orderLines
    .map(
      (orderLine) =>
        orderLine.linePriceInformation &&
        orderLine.statuses.every((status) => !status.statusCode.startsWith('9000')) &&
        (orderLine.linePriceInformation.listPrice || 0) * (orderLine.quantity || 0)
    )
    .reduce((acc, cur) => acc + cur, 0);

  /**
   * Get every line charge under category DISCOUNT, sum charge per quantity and multiply by quantity
   * then summing using reduce
   * @todo need to find out if this calc is correct
   */
  const totalDiscount = discountsFromHeaderCharges + discountsFromOrderLines;

  const formatCurrency = (amount, currency) => (
    <FormattedCurrency amount={amount} currency={currency} />
  );

  return (
    <SidebarItem dataTestId='orderTotals' title={TOTALS} icon={<MoneyIcon />}>
      <Table className={classes.chargesTable}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.chargesCell}>{PRODUCT}</TableCell>
            <TableCell className={classes.chargesCell} data-testid='product-total' align='right'>
              {formatCurrency(totalProduct, currency)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.chargesCell}>{SHIPPING}</TableCell>
            <TableCell className={classes.chargesCell} align='right'>
              {formatCurrency(shippingCharges, currency)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.chargesCell}>{ITEM_DISCOUNTS}</TableCell>
            <TableCell className={classes.chargesCell} align='right'>
              {formatCurrency(totalDiscount, currency)}
            </TableCell>
          </TableRow>
          <TableRow>
            {/* only recalculate tax if sales order and NOT a partner order */}
            {orderDetail?.orderType === 'SALES_ORDER' &&
            !orderDetail?.parentReturnOrder &&
            !partner ? (
              <TableCell className={classes.chargesCellWithButton}>
                {TAX}
                <HasPermission permission={RecalculateTax}>
                  <Button
                    className={classes.button}
                    href={`${config.cspUrl}csr/cloud/orderDetail?orderNumber=${orderDetail?.orderNumber}&orderHeaderKey=`}
                    data-testid='recalculate-tax-button'
                    target='_blank'>
                    <CachedIcon className={classes.buttonIcon} />
                    {RECALCULATE}
                  </Button>
                </HasPermission>
              </TableCell>
            ) : (
              <TableCell className={classes.chargesCell}>{TAX}</TableCell>
            )}
            <TableCell className={classes.chargesCell} align='right'>
              {formatCurrency(totalTax, currency)}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.chargesCell}>{ORDER_TOTAL}</TableCell>
            <TableCell className={classes.chargesCell} align='right'>
              {formatCurrency(
                Number(totalProduct) +
                  Number(shippingCharges) -
                  Number(totalDiscount) +
                  Number(totalTax),
                currency
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </SidebarItem>
  );
};

export default Charges;

const useStyles = makeStyles((theme) => ({
  chargesTable: {
    marginRight: '0',
  },
  button: {
    padding: '0',
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  buttonIcon: {
    marginRight: theme.spacing(0.25),
  },
  chargesCell: {
    border: '0',
    padding: theme.spacing(0.5),
  },
  chargesCellWithButton: {
    border: '0',
    padding: '0',
    paddingLeft: theme.spacing(0.5),
  },
}));
