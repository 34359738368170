// dictionary to transform capitalcase paymentTypes to titlecase
export const paymentTypes = {
  ALIPAY: 'Alipay',
  ASTROPAY: 'AstroPay',
  BILLPAY: 'BillPay',
  CASH: 'Cash',
  CHECK: 'Check',
  COD: 'Cash on Delivery',
  CONV_STORE: 'Convenience Store',
  CREDIT_CARD: 'Credit Card',
  CUSTOMER_ACCOUNT: 'Customer Account',
  CUSTOMER_OFBT: 'Customer Offline Bank Transfer',
  DEBIT_CARD: 'Debit Card',
  GIFT_CERTIFICATE: 'Gift Certificate',
  IYZICO: 'Iyzico',
  KLARNA: 'Klarna',
  MALL_CERTIFICATE: 'Mall Certificate',
  OFFLINE_BANK: 'Offline Bank Transfer',
  OTHER_CREDIT: 'Other Credit',
  OTHER_DEBIT: 'Other Debit',
  PAYPAL: 'PayPal',
  POS_PURCHASE: 'Point of Sale',
  PROMO_VOUCHER: 'Voucher',
  QIWI: 'Qiwi',
  REALTIME_BANK: 'Realtime Bank Transfer',
  RFO: 'Request for Offer',
  SAFETYPAY: 'SafetyPay',
  SOFORT: 'SOFORT',
  STORE_CREDIT: 'Store Credit',
  STORE_GIFTCERT: 'Store Gift Certificate',
  TENPAY: 'TenPay',
  TRAVELERS_CHECK: "Traveler's Check",
  UNIONPAY: 'UnionPay',
  WECHAT: 'WeChat',
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
};

/**
 * A handler function to proxy parameter lookup on our dictionary.
 */
const handler = {
  get: function(target, name) {
    return target.hasOwnProperty(name)
      ? // if the value exists in the dictionary, return it
        target[name]
      : // else, if the key is truthy, return that
        name || 'Unknown';
  },
};

// A proxied version of the Payment Types dictionary, allowing us to provide a default value.
export const PaymentTypesDict = new Proxy(paymentTypes, handler);
