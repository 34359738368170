import { formatDate } from './date';
/**
 * Gets an omoboFlags object derived from the cumulative omoboFlags from each
 * orderLine in the given orderLines. The given orderLines can either be an array
 * or an object where each orderLine is mapped to its own field. An object value
 * will always be returned, even if the input is null or invalid.
 *
 * @param orderLines
 * @returns omoboFlags {object}
 */
export const getOmoboFlags = (orderLines) => {
  if (!orderLines) {
    return {};
  }

  // In some cases, the orderLines will not be in an array form, so we'll convert it.
  const orderLineArray = Array.isArray(orderLines)
    ? orderLines
    : Object.values(orderLines).filter(Boolean);

  const isBOPIS = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isBOPIS);
  const isShipToStore = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isShipToStore);
  const isPickUpPoint = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isPickUpPoint);
  const isReturnable = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isReturnable);
  const isCancellable = orderLineArray.some((orderLine) => orderLine?.omoboFlags?.isCancellable);

  return { isBOPIS, isShipToStore, isPickUpPoint, isReturnable, isCancellable };
};

/**
 * returns the fields from orderLine needed in shipmentDetails
 * returns fulfiller String and estimated delivery date
 * Prod orders need only shipNode and shipAdvice
 * Test orders need shipNode, contractNumber, and chainedOrderNumber
 *
 * @param orderLines
 * @param {String} orderLineKey key that ties a shipment and orderLine together
 * @returns {String} name and number of fulfiller
 */
export const getOrderLineShipmentDetails = (orderLines, orderLineKey) => {
  if (!orderLineKey || !orderLines || orderLines.length === 0) {
    return { fulfiller: 'n/a', estimatedDeliveryDate: 'n/a' };
  }

  const orderLine = orderLines.filter((orderLine) => orderLine.orderLineKey === orderLineKey)[0];
  // if a matching orderLine is not found
  if (!orderLine) return { fulfiller: 'n/a', estimatedDeliveryDate: 'n/a' };
  const { shipAdvice, chainedOrderNumber, contractNumber, shipNode } = orderLine?.statuses?.[0];

  if (shipAdvice) {
    return {
      fulfiller: `${shipNode} - ${shipAdvice}`,
      estimatedDeliveryDate: formatDate(orderLine.estimatedDeliveryDate),
    };
  } else {
    return {
      fulfiller: `${shipNode} - ${contractNumber} (${chainedOrderNumber})`,
      estimatedDeliveryDate: formatDate(orderLine.estimatedDeliveryDate),
    };
  }
};
