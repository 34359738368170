// enum of possible orderDetailVersions
export const OrderDetailVersion = {
  V1: 'v1',
  V2: 'v2',
};

// enum of possible userTypes
export const UserType = {
  BRAND: 'nike:plus',
  SWOOSH: 'nike:swoosh',
  GUEST: 'nike:guest',
};

export const BopisOrderStatus = {
  ReadyForPickUp: 'ReadyForPickUp',
  PartiallyReadyForPickUp: 'Partially ReadyForPickUp',
  PickedUp: 'PickedUp',
  PartiallyPickedUp: 'Partially PickedUp',
};

export const orderLineTypes = [
  {
    name: 'Inline',
    value: 'INLINE',
  },
  {
    name: 'Nike By You',
    value: 'NIKEID',
  },
  {
    name: 'Digital Gift Card',
    value: 'EGC',
  },
  {
    name: 'Physical Gift Card',
    value: 'GC',
  },
  {
    name: 'Voucher',
    value: 'VOUCHER',
  },
];
