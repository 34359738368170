import ReceiptIcon from '@material-ui/icons/Receipt';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import React, { useContext } from 'react';
import { Partners, Enterprises, Apps, WQDivisions } from '../../../constants/origin.const';
import { OrderContext } from '../../../store/contexts/orderContext';
import { formatDateTime } from '../../../utils/date';
import { formatOrderType } from '../../../utils/order';
import DataTable from '../../shared/table/dataTable';
import SidebarItem from './sidebarItem';
import translations from './summary.i18n';

const Summary = ({ data }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);

  const {
    CONSUMER,
    CREATED_BY,
    ENTERPRISE,
    ORDER_CLASSIFICATION,
    ORDER_DATE,
    ORDER_NUMBER,
    ORDER_SUMMARY,
    ORDER_TYPE,
    ORIGIN,
    PARTNER_ORDER_NUMBER,
    PARTNER_REFERENCE_NUMBER,
    STATUS,
  } = mapValues(translations, i18nString);

  const {
    assistingAgentReference,
    channel,
    division,
    enterpriseCode,
    locale,
    marketplaceDetail,
    omsRegionReference,
    orderNumber,
    orderCreateDate,
    orderType,
    orderClassification,
    references,
    status,
  } = orderDetail;

  /* Assemble the fullOrigin value, leaving '-' in lieu of a missing value */
  const partner = Partners?.[channel];
  const enterprise = Enterprises?.[enterpriseCode];
  const specialtyDivision = WQDivisions?.[division];
  const appId = references?.filter((ref) => ref.name === 'AppId')?.[0]?.value;
  const app = Apps?.[appId];
  let fullOrigin = division ? `(${division}) ` : '';
  if (enterprise) {
    fullOrigin += enterprise;
  } else if (orderNumber?.substring(0, 2) === 'NE') {
    fullOrigin += Enterprises.NIKEELITE;
  } else if (partner) {
    fullOrigin += partner;
  } else if (channel) {
    fullOrigin += channel;
  } else if (app) {
    fullOrigin += app;
  } else if (specialtyDivision) {
    fullOrigin += specialtyDivision;
  }

  const map = {
    [ORDER_NUMBER]: orderNumber,
    [PARTNER_ORDER_NUMBER]: marketplaceDetail?.partnerOrderNumber || '-',
    [PARTNER_REFERENCE_NUMBER]: marketplaceDetail?.partnerReferenceNumber || '-',
    [ORDER_TYPE]: formatOrderType(orderType),
    [ORDER_DATE]: formatDateTime(orderCreateDate, locale),
    [ORDER_CLASSIFICATION]: orderClassification,
    [STATUS]: status,
    [ORIGIN]: fullOrigin,
    [ENTERPRISE]: omsRegionReference,
    [CREATED_BY]: assistingAgentReference || CONSUMER,
  };

  // If order is not a partner order OR it is a return order, remove partner order details.
  if (!Partners[channel] || orderType === 'RETURN_ORDER') {
    delete map[PARTNER_ORDER_NUMBER];
    delete map[PARTNER_REFERENCE_NUMBER];
  }

  // If order is a return order, remove order classification and origin.
  if (orderType === 'RETURN_ORDER') {
    delete map[ORDER_CLASSIFICATION];
    delete map[ORIGIN];
  }

  return (
    <SidebarItem data-testid='order-summary-table' title={ORDER_SUMMARY} icon={<ReceiptIcon />}>
      <DataTable map={map} tight />
    </SidebarItem>
  );
};

export default Summary;
