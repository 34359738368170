import React, { createContext, useEffect, useReducer, useState } from 'react';
import config from '../../utils/config';
import { adminReducer } from '../reducers/adminReducer';
import { actions } from '../actions/adminActions';
import useSnacks from '../../hooks/useSnacks';
import useAuth from '../../hooks/useAuth';
import { logErrorAction } from '../../newrelic/logErrorAction';

export const AdminContext = createContext([{}, () => {}]);
export const defaultState = {
  permissions: {},
  groups: {},
};

const { Provider } = AdminContext;

/**
 * A provider for the OMOBO admin page
 *
 * @param {Object} props  – React props
 */
export const AdminProvider = ({ children }) => {
  const { setSnack, setError } = useSnacks();
  const [state, reducer] = useReducer(adminReducer, defaultState);
  const { oktaToken } = useAuth();
  const [refetchGroups, setRefetchGroups] = useState(false);

  const fetchGroups = async (snackMessage = false) => {
    const response = await fetch(config.foundry.allGroups, {
      headers: {
        authorization: oktaToken,
      },
      cors: true,
    });

    if (response.ok) {
      const groups = await response.json();
      const sortedGroups = groups.sort((a, b) => a.name.localeCompare(b.name));
      reducer(actions.setGroups(sortedGroups));
      // After a successful retrieval, reset the flag so it can be retrigged as needed.
      if (snackMessage) {
        setSnack('Successfully updated groups.');
      }
    } else {
      setError(`${response.statusText} ${response.status}`);
    }
  };

  /*
   Retrieve the list of permissions as well as a list of groups upon having the authState param set.
   */
  useEffect(() => {
    // TODO: check for admin here?
    const fetchPermissions = async () => {
      const response = await fetch(config.foundry.allPermissions, {
        headers: {
          authorization: oktaToken,
        },
        cors: true,
      });

      if (response.ok) {
        const permissions = await response.json();
        reducer(actions.setPermissions(permissions));
      } else {
        setError(`${response.statusText} ${response.status}`);
      }
    };
    // We don't want to call foundry if the okta token is not available
    if (oktaToken) {
      try {
        fetchPermissions();
      } catch (error) {
        setError(error);
        logErrorAction(error, { customMessage: 'fetchPermissions, adminContex error' });
      }

      try {
        fetchGroups();
      } catch (error) {
        setError(error);
        logErrorAction(error, { customMessage: 'fetchGroups, adminContex error' });
      }
    }
  }, [oktaToken]);

  /*
  The allows the children components to be able to trigger the fetchGroups call from outside of
  the context. This type of action will be needed from the Admin page that edits permissions.
   */
  useEffect(() => {
    if (refetchGroups) {
      try {
        fetchGroups(true);
      } catch (error) {
        setError(error);
        logErrorAction(error, { customMessage: 'refetchGroups, adminContex error' });
      } finally {
        setRefetchGroups(false);
      }
    }
  }, [refetchGroups]);

  // Adding setRefectGroups into the state so that it can be accessed from the children components.
  const newState = { ...state, refetchGroups: () => setRefetchGroups(true) };
  return <Provider value={[newState, reducer]}>{children}</Provider>;
};

export default AdminContext;
