import { makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import { NikeI18nContext } from '@nike/i18n-react';
import React, { useContext, useEffect, useState } from 'react';
import mapValues from 'lodash/mapValues';
import useHasPermission from './../../../../hooks/useHasPermission';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { getBillToAddressFromOrder } from '../../../../utils/address';
import AddressForm from '../../../address/addressForm';
import translations from './step3.i18n';
import { HasPermissionComponent } from './../../../shared/hasPermission';
import { MarkAsGiftReturn, RefundToGiftCard } from './../../../../constants/permissions.const';
import Geo from '../../../../constants/geos.const';
import { isAllGiftCardOrderLines } from './../../../../utils/dialog';
/*
Commenting imports required for the commented code
import { getRefundTotal } from '../../../../utils/dialog';
import { getPaymentAddress } from '../../../../utils/payment';
import StaticTextField from '../../../shared/staticTextField';
import { PaymentTypesDict } from '../../../../constants/paymentTypes.const';
import { FormattedCurrency } from '../../../shared/formatCurrency';
*/

/**
 * This component is step 3 of the Create Return flow
 *
 * @param {Object} props – props object for a React component
 */
const Step3 = () => {
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { hasPermission } = useHasPermission();
  const [expandedStatus, setExpandedStatus] = useState(true);
  const classes = useStyles();

  const {
    setGifteeEmail,
    setIsGiftReturn,
    setReturnPaymentOption,
    setGiftCardAddress,
    setAreAllOrderLinesGiftCards,
  } = dialogActions;

  useEffect(() => {
    /*
      We don't want to load the address from order details if we already have the details in the 
      state. if we don't have this condition the gift card shipping address is always pulled from 
      order details which means changes made to the address is not retained e.g: when we move to 
      step 3 from step 4 of return process then changes made in step3 is not retained.
     */

    if (!dialogState.gcShippingAddress.firstName) {
      const billToAddress = getBillToAddressFromOrder(orderDetail);
      if (billToAddress) {
        dialogDispatch(setGiftCardAddress(billToAddress));
      }
    }
  }, []);

  /*
    If all the order lines selected for returns are gift card order lines 
    then we are setting the flag areAllOrderLinesGiftCards to true which will
    be used in step control when creating return capture payload.
  */
  useEffect(() => {
    if (isAllGiftCardOrderLines(dialogState.selectedLines)) {
      dialogDispatch(setAreAllOrderLinesGiftCards(true));
    }
  }, []);

  const {
    ARIA_RETURN_PAYMENT,
    ARIA_REFUND_DETAILS,
    GIFTEE_EMAIL,
    NEW_GIFT_CARD,
    ORIGINAL_PAYMENT_METHOD,
    THIS_IS_A_GIFT_RETURN,
    PAYMENT_INFORMATION_HEADER,
    REFUND_WILL_BE_SENT_TO_ORIGINAL_PAYMENT_METHOD,
  } = mapValues(translations, i18nString);

  const { gifteeEmail, isGiftReturn, returnPaymentOption } = dialogState;
  const isNewGiftCard = returnPaymentOption === 'giftcard';

  /*
   * Returns true even if one of the selected line item is a gift card order line.
   */
  const isGiftCardSelectedForReturn = () => {
    return Object.values(dialogState.selectedLines).some(
      (selectedLine) => selectedLine.styleNumber === 'GIFTCARD'
    );
  };

  const renderReturnPaymentTypeRadioGroup = () => {
    // China does not allow refunding to gift cards.
    if (orderDetail.omsRegionReference === Geo.CHINA) {
      /*
       * Since there would be only one option left, we won't display the single radio button.
       * This keeps the display cleaner and improves accessibility.
       * The returnPaymentOption field is already set to 'original' by default.
       */
      return REFUND_WILL_BE_SENT_TO_ORIGINAL_PAYMENT_METHOD;
    } else {
      // No constraints on gift cards, so return the full radio group with both options.
      return (
        <RadioGroup
          value={returnPaymentOption}
          aria-label={ARIA_RETURN_PAYMENT}
          onChange={(event) => dialogDispatch(setReturnPaymentOption(event.target.value))}
          row>
          <FormControlLabel
            label={ORIGINAL_PAYMENT_METHOD}
            value='original'
            data-testid='original-payment-method'
            control={<Radio color='primary' />}
          />
          <HasPermissionComponent permission={RefundToGiftCard}>
            {isGiftCardSelectedForReturn() ? (
              <>
                <FormControlLabel
                  label={NEW_GIFT_CARD}
                  value='giftcard'
                  disabled={true}
                  data-testid='new-gift-card'
                  control={<Radio color='primary' />}
                />
                <Tooltip
                  title="Sorry, gift cards can't be refunded to new gift cards"
                  placement='right'>
                  <InfoIcon color='primary' />
                </Tooltip>
              </>
            ) : (
              <FormControlLabel
                label={NEW_GIFT_CARD}
                value='giftcard'
                data-testid='new-gift-card'
                control={<Radio color='primary' />}
              />
            )}
          </HasPermissionComponent>
        </RadioGroup>
      );
    }
  };

  return (
    <>
      <HasPermissionComponent permission={MarkAsGiftReturn}>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                role='checkbox'
                checked={isGiftReturn}
                aria-checked={isGiftReturn}
                tabIndex={0}
                data-testid='is_gift_return_checkbox'
                onChange={(event) => {
                  return dialogDispatch(
                    setIsGiftReturn(
                      event.target.checked,
                      hasPermission(RefundToGiftCard) && !isGiftCardSelectedForReturn()
                    )
                  );
                }}
              />
            }
            label={THIS_IS_A_GIFT_RETURN}
          />
          {isGiftReturn && (
            <TextField
              placeholder={GIFTEE_EMAIL}
              onBlur={() => {} /* validateGifteeEmail */}
              onChange={(event) => dialogDispatch(setGifteeEmail(event.target.value))}
              error={false /* isGifteeEmailError */}
              className={classes.gifteeEmailTextField}
              data-testid='giftee_email_field'
              value={gifteeEmail}
            />
          )}
        </FormGroup>
      </HasPermissionComponent>
      {renderReturnPaymentTypeRadioGroup()}

      <div className={classes.paymentInformation}>
        {/* Till we figure out how to show multiple original payment methods
            expansion panel would ne displayed for new gift card payment option */}
        {isNewGiftCard && (
          <Accordion
            className={classes.paymentExpansionSummary}
            elevation={2}
            expanded={expandedStatus}
            aria-expanded={expandedStatus}
            onChange={() => {
              setExpandedStatus(!expandedStatus);
            }}>
            <AccordionSummary
              className={classes.paymentExpansionSummary}
              expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>{PAYMENT_INFORMATION_HEADER}</Typography>
            </AccordionSummary>
            <AccordionDetails aria-label={ARIA_REFUND_DETAILS}>
              {/* Commenting below section as we are currently not showing 
              multiple payment methods in gift card return section */}
              {/* {paymentMethods.map((paymentMethod, i) => {
                return (
                  <div key={i}>
                    {i === 0 && (
                      <StaticTextField
                        label={REFUND_AMOUNT}
                        value={
                          <FormattedCurrency 
                            amount={getRefundTotal(selectedLines))} 
                            currency={currency} 
                          />}
                      />
                    )}
                    <StaticTextField
                      label={ORIGINAL_TOTAL}
                      value={
                        <FormattedCurrency 
                          amount={paymentMethod.totalChargedAmount} 
                          currency={currency} 
                        />
                      }
                    />
                    {!isNewGiftCard ? (
                      <>
                        <StaticTextField
                          label={PAYMENT_TYPE}
                          value={PaymentTypesDict[paymentMethod.paymentType]}
                        />
                        <StaticTextField
                          label={BILLING_ADDRESS}
                          value={getPaymentAddress(paymentMethod)}
                          multiline
                        />
                      </>
                    ) : (
                      <AddressForm
                        region={orderDetail.omsRegionReference}
                        className={classes.addressForm}
                        isGiftCardAddress={true}
                      />
                    )}
                  </div>
                );
              })} */}
              <AddressForm
                region={orderDetail.omsRegionReference}
                className={classes.addressForm}
                isGiftCardAddress={true}
              />
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  actionsContainer: {
    marginTop: theme.spacing(2),
  },
  addressTypography: {
    whiteSpace: 'pre-line',
  },
  gifteeEmailTextField: {
    marginTop: theme.spacing(1.125),
    marginLeft: theme.spacing(1),
    minWidth: 250,
  },
  paymentInformation: {
    maxWidth: '775px',
    width: '100%',
    paddingBottom: '16px',
  },
}));

export default Step3;
