import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { getRecentOrders } from '../../utils/recentOrders';
import TopBarContainer from '../orders/topBar/topBarContainer';
import OrderTable from './orderTable';
import WelcomeMessage from './../shared/welcomeMessage/index';

const recentOrders = getRecentOrders();

/**
 * Main react component housing the recent orders page
 */
export default function RecentOrders() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box className={classes.page}>
        <TopBarContainer />
        <div role='main'>
          <WelcomeMessage />
          <OrderTable
            className={classes.orderSearch}
            title={'Recent Orders'}
            orders={recentOrders}
          />
          {/* TODO - make this work :D */}
          {/* <OrderTable title={'Favorite Orders'} orders={favoriteOrders} /> */}
        </div>
      </Box>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  page: {
    flex: '1 1 auto',
    backgroundColor: '#f5f5f5',
    overflowX: 'hidden',
  },
}));
