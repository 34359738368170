/*
This file provides functions to determine the fulfillment scenario of a given orderLine.

The functions in this file are used in the orderDetailDecoration file, to set omoboFlags on
orderLines.  You will get better performance with the same result by using the flags under the
orderLine.omoboFlags field.

Note two things:
1. In the orderDetailDecoration file, the omoboFlags objects must be created on the orderLines of
the orderDetail first before calling these functions.
2. In the orderDetailDecoration file, these functions must be called BEFORE calling the functions
from the orderDetailFulfillmentScenarios file (to set the omoboFlags on the top-level orderDetail),
as the flags for the top-level orderDetail are based on the flags of each of the orderLines.
 */

/**
 * This util function checks if an orderLine is BOPIS by checking if
 * it matches the criteria provided by the athena team.
 * The orderVersion key refers to the schema version that this order is referencing.
 *
 * @param {Object} orderDetail – the response from an Order Details API call
 */
export const isBOPISOrderLine = (orderLine) => {
  if (orderLine.orderVersion === 'v1') {
    return (
      orderLine.fulfillmentMethod === 'PICKUP' &&
      orderLine.shipTo.address?.pickUpLocationType === 'store_pickup'
    );
  } else if (orderLine.orderVersion === 'v2') {
    return (
      /*
      If fulfillmentMethod is 'RESERVE', it's technically a ROPIS order (Reserve Online Pickup In
      Store), but for now we will count it as BOPIS as that is what CSP is currently doing. If we
      are later required to treat ROPIS differently, then remove the 'RESERVE' check from this logic
      and create a separate "isROPISOrderLine" function and omoboFlag.
       */
      (orderLine.fulfillmentMethod === 'PICKUP' || orderLine.fulfillmentMethod === 'RESERVE') &&
      orderLine.shipTo.location?.type === 'store/store_views'
    );
  } else return false;
};

/**
 * This util function checks if an order is a "Ship To Store" order by checking if
 * any of the line items match the criteria provided by the athena team.
 *
 * @param {Object} orderDetail – the response from an Order Details API call
 */
export const isShipToStoreOrderLine = (orderLine) => {
  if (orderLine.orderVersion === 'v1') {
    return orderLine.fulfillmentMethod === 'SHIP' && Boolean(orderLine.shipToStoreNumber);
  } else if (orderLine.orderVersion === 'v2') {
    return (
      orderLine.fulfillmentMethod === 'SHIP' &&
      orderLine.shipTo.location?.type === 'store/store_views'
    );
  }
};

/**
 * This util function checks if an orderLine is Pick Up Point by checking if
 * it matches the criteria provided by the athena team.
 * The orderVersion key refers to the schema version that this order is referencing.
 *
 * @param {Object} orderDetail – the response from an Order Details API call
 */
export const isPickUpPointOrderLine = (orderLine) => {
  if (orderLine.orderVersion === 'v1') {
    return (
      orderLine.fulfillmentMethod === 'SHIP' &&
      !orderLine.shipToStoreNumber &&
      orderLine.shippingMethod === 'CPG' &&
      !orderLine.shipTo.address?.pickUpLocationIdentifier
    );
  } else if (orderLine.orderVersion === 'v2') {
    return (
      orderLine.shippingMethod === 'CPG' &&
      orderLine.fulfillmentMethod === 'SHIP' &&
      orderLine.shipTo.location?.type === 'ship/pickup_points'
    );
  } else return false;
};
