import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { NikeI18nContext } from '@nike/i18n-react';
import OrderContext from '../../../../store/contexts/orderContext';
import getArrayProperty from '../../../../utils/getArrayProperty';
import {
  transformTransactionDetails,
  calculateTotalTransferInAndOut,
} from '../../../../utils/payment';
import BasicTableRow from '../../../shared/table/tableRow';
import PaymentDetails from './paymentDetails';
import translations from './payments.i18n';
import ErrorBoundary from '../../../error/errorBoundary';
import config from '../../../../utils/config';
import mapValues from 'lodash/mapValues';
import { prepareAddress } from '../../../../utils/address';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import HasPermission from '../../../shared/hasPermission';
import { SubmitPayment, AddPaymentMethod } from '../../../../constants/permissions.const';
import { Partners } from '../../../../constants/origin.const';

export default function Payments() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const {
    paymentMethods,
    orderNumber,
    orderType,
    parentReturnOrder,
    currency,
    channel,
  } = orderDetail;

  const isReturnOrder = orderDetail?.orderType === 'RETURN_ORDER';
  const cspUrl = `${config.cspUrl}csr/cloud/orderDetail?orderNumber=${orderDetail?.orderNumber}&orderHeaderKey=`;

  const {
    PAYMENT_SUMMARY,
    AMOUNT,
    AUTH_DATE,
    AUTH_NUM,
    ALL_TRANS,
    DATE,
    INVOICES,
    PAYMENT_DETAILS,
    STATUS,
    TOTALS,
    TOTAL_TRANSF_IN,
    TOTAL_TRANSF_OUT,
    ARIA_TRANS_DETAILS,
    ARIA_TRANS_TOTALS,
    TYPE,
    ARIA_PAY_TYPE_TRANS_DETAILS,
    SUBMIT_PAYMENT,
    ADD_PAYMENT,
    REFUND,
    REFUNDED_TO,
    NEW_GC,
    OG_PAYMENT,
  } = mapValues(translations, i18nString);

  const chargeTransactionDetails = getArrayProperty(orderDetail, 'chargeTransactionDetails');
  const totalTransferAmounts = calculateTotalTransferInAndOut(chargeTransactionDetails);

  // header values for transactions table
  const transactionsHeaders = [DATE, AUTH_DATE, TYPE, AMOUNT, AUTH_NUM, STATUS, INVOICES];

  const partner = channel ? Partners[channel] : '';

  return (
    <ErrorBoundary>
      <h1 className='accessibly-hidden'>{PAYMENT_DETAILS}</h1>
      <Card data-testid={'payments-cont'} className={classes.cardSpacing}>
        <CardContent className={classes.summaryGrid}>
          <Box p={0.5} fontWeight='fontWeightBold' clone>
            <CardHeader
              disableTypography
              classes={{ root: classes.cardHeaderRoot }}
              title={<h2 className={classes.cardHeading}>{PAYMENT_SUMMARY}</h2>}
            />
          </Box>
          <Box>
            <Typography
              aria-label={TOTALS.toLowerCase()}
              variant='h3'
              className={classes.cardSubHeading}>
              {TOTALS}
            </Typography>
            <Box pt={0.25} aria-label={ARIA_TRANS_TOTALS} data-testid='total-transfers'>
              <Typography variant='body2' className={classes.totalsValue}>
                {`${TOTAL_TRANSF_IN}: `}
                <FormattedCurrency amount={totalTransferAmounts.in} currency={currency} />
              </Typography>
              <Typography variant='body2' className={classes.totalsValue}>
                {`${TOTAL_TRANSF_OUT}: `}
                <FormattedCurrency amount={totalTransferAmounts.out} currency={currency} />
              </Typography>
            </Box>
          </Box>
          <Divider className={classes.divider} />
          {!isReturnOrder ? (
            <Box pt={0.25}>
              <Typography
                aria-label={ALL_TRANS.toLowerCase()}
                variant='h3'
                className={classes.cardSubHeading}>
                {ALL_TRANS}
              </Typography>
              <Table aria-label={ARIA_TRANS_DETAILS}>
                <BasicTableRow
                  header
                  data={transactionsHeaders}
                  cellClassName={classes.tableHeader}
                />
                {chargeTransactionDetails &&
                  chargeTransactionDetails.map((chargeDetail, i) => (
                    <BasicTableRow
                      key={i}
                      data={transformTransactionDetails(chargeDetail)}
                      cellRootClassName={classes.tableCell}
                    />
                  ))}
              </Table>
            </Box>
          ) : (
            <Box pt={0.25}>
              <Typography
                aria-label={REFUND.toLowerCase()}
                variant='h3'
                className={classes.cardSubHeading}>
                {REFUND}
              </Typography>
              <Box pt={1} aria-label={ARIA_TRANS_TOTALS} data-testid='refund-details'>
                <Typography
                  variant='body2'
                  data-testid='refunded-to'
                  className={classes.refundedTo}>{`${REFUNDED_TO}: ${
                  orderDetail.returnByGiftRecipient === 'true' ? NEW_GC : OG_PAYMENT
                }`}</Typography>
                {orderDetail.returnByGiftRecipient === 'true' && (
                  <>
                    <Typography
                      variant='body2'
                      data-testid='refunded-to-contact-name'
                      className={classes.refundedToContact}>
                      {`${orderDetail?.billTo?.recipient?.firstName || '--'} ${orderDetail?.billTo
                        ?.recipient?.lastName || '--'}`}
                    </Typography>
                    <Typography
                      variant='body2'
                      data-testid='refunded-to-contact-email'
                      className={classes.refundedToContact}>
                      {orderDetail?.billTo?.contactInformation.email || '--'}
                    </Typography>
                    <Typography
                      variant='body2'
                      data-testid='refunded-to-contact-address'
                      className={classes.refundedToAddress}>
                      {prepareAddress(orderDetail)}
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>

      {paymentMethods &&
        paymentMethods.map((payment, i) => {
          const typeEntries = chargeTransactionDetails.filter(
            (transaction) => transaction.paymentKey === payment.paymentKey
          );
          const typeRows = typeEntries.map((entry, k) => (
            <BasicTableRow
              key={k}
              data={transformTransactionDetails(entry)}
              cellRootClassName={classes.tableCell}
            />
          ));

          return (
            <PaymentDetails
              key={i}
              index={i}
              cardClass={classes.cardSpacing}
              cardSubHeadingClass={classes.cardSubHeading}
              tableHeadingClass={classes.tableHeader}
              cellClass={classes.tableCell}
              payment={payment}
              typeEntries={typeEntries}
              orderNumber={orderNumber}
              orderType={orderType}
              parentReturnOrder={parentReturnOrder}
              partner={partner}>
              <Table aria-label={ARIA_PAY_TYPE_TRANS_DETAILS}>
                <BasicTableRow
                  header
                  data={transactionsHeaders}
                  cellClassName={classes.tableHeader}
                />
                {typeRows}
              </Table>
            </PaymentDetails>
          );
        })}
      {/* only display these buttons if we have a sales order and not an exchange/partner order */}
      {orderType === 'SALES_ORDER' && !parentReturnOrder && !partner && (
        <Toolbar className={classes.paymentActionsBar}>
          <HasPermission permission={AddPaymentMethod}>
            <Button
              href={cspUrl}
              target='_blank'
              aria-label={ADD_PAYMENT.toLowerCase()}
              data-testid='add-payment-button'
              className={classes.button}>
              <AddIcon className={classes.buttonIcon} />
              {ADD_PAYMENT}
            </Button>
          </HasPermission>
          <HasPermission permission={SubmitPayment}>
            <Button
              href={cspUrl}
              target='_blank'
              aria-label={SUBMIT_PAYMENT.toLowerCase()}
              data-testid='submit-payment-button'
              className={classes.button}>
              <ArrowRightAltIcon className={classes.buttonIcon} />
              {SUBMIT_PAYMENT}
            </Button>
          </HasPermission>
        </Toolbar>
      )}
      <Box className={classes.cardSpacing} />
    </ErrorBoundary>
  );
}

const useStyles = makeStyles((theme) => ({
  divider: {
    margin: `${theme.spacing(2.5)}px 0`,
  },
  totalsValue: {
    display: 'inline',
    padding: theme.spacing(1.2),
  },
  refundedTo: {
    paddingLeft: theme.spacing(1.2),
  },
  refundedToContact: {
    paddingLeft: theme.spacing(2.4),
    lineHeight: '1.7',
  },
  refundedToAddress: {
    paddingLeft: theme.spacing(2.4),
    verticalAlign: 'top',
    whiteSpace: 'pre',
  },
  cardHeaderRoot: {
    'paddingLeft': '9px',
    'paddingBottom': theme.spacing(1.5),
    '&.child': {
      paddingTop: 0,
    },
  },
  cardHeading: {
    margin: 0,
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  cardSubHeading: {
    fontSize: '1rem',
    padding: `10px`,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
    letterSpacing: '0.03em',
  },
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  tableCell: {
    padding: theme.spacing(1.2),
  },
  tableHeader: {
    margin: 0,
    paddingTop: 0,
    padding: theme.spacing(1.2),
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  button: {
    color: theme.palette.primary.dark,
    marginRight: theme.spacing(1),
  },
  paymentActionsBar: {
    paddingLeft: '0',
  },
}));
