import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { NikeI18nContext } from '@nike/i18n-react';
import Tab from '@material-ui/core/Tab';
import DetailsIcon from '@material-ui/icons/Details';
import ChargesIcon from '@material-ui/icons/Receipt';
import PaymentsIcon from '@material-ui/icons/Payment';
import EmailsIcon from '@material-ui/icons/MailOutlineRounded';
import CommentsIcon from '@material-ui/icons/Chat';
import Tooltip from '@material-ui/core/Tooltip';
import translations from './navDrawer.i18n';
import mapValues from 'lodash/mapValues';
import clsx from 'clsx';

/**
 * Wraps individual tab to choose icon per route and control styling and accessibility
 */
export default function TabButton({ route, open, index, ...other }) {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);

  const strings = mapValues(translations, i18nString);
  const { Icon, title } = getTabDetails(route, classes, strings);
  const label = <div>{open && title}</div>;

  return (
    <Tooltip enterDelay={200} title={title}>
      <Tab
        id={`nav-tab-${index}`}
        icon={Icon}
        data-testid={getTestId(route)}
        label={label}
        key={route}
        className={clsx(classes.tabButton, open && classes.open)}
        classes={{ wrapper: classes.tabWrapper, labelIcon: classes.label }}
        aria-label={route}
        aria-controls={`nav-tabpanel-${index}`}
        {...other}
      />
    </Tooltip>
  );
}

TabButton.propTypes = {
  route: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
};

/**
 * Returns proper React component for navigation icon,
 * based on the current route.
 *
 * @param {string} route
 * @param {object} classes
 * @param {object} strings
 * @returns {object}
 */
const getTabDetails = (route, classes, strings) => {
  const { DETAILS, CHARGES, PAYMENTS, EMAILS, COMMENTS } = strings;

  switch (route) {
    case 'details':
      return {
        Icon: (
          <DetailsIcon
            name='details'
            data-testid='details-view-icon'
            className='details-tab-icon'
            classes={{ root: classes.iconWrapper }}
          />
        ),
        title: DETAILS,
      };
    case 'charges':
      return {
        Icon: (
          <ChargesIcon
            name='charges'
            data-testid='charges-view-icon'
            classes={{ root: classes.iconWrapper }}
          />
        ),
        title: CHARGES,
      };
    case 'payments':
      return {
        Icon: (
          <PaymentsIcon
            name='payments'
            data-testid='payments-view-icon'
            className='payments-tab-icon'
            classes={{ root: classes.iconWrapper }}
          />
        ),
        title: PAYMENTS,
      };
    case 'emails':
      return {
        Icon: (
          <EmailsIcon
            name='emails'
            data-testid='emails-view-icon'
            className='emails-tab-icon'
            classes={{ root: classes.iconWrapper }}
          />
        ),
        title: EMAILS,
      };
    case 'comments':
      return {
        Icon: (
          <CommentsIcon
            name='comments'
            data-testid='comments-view-icon'
            className='details-tab-icon'
            classes={{ root: classes.iconWrapper }}
          />
        ),
        title: COMMENTS,
      };
    default:
      return {
        Icon: null,
        title: '',
      };
  }
};

/**
 * Returns dynamic value for data-testid attribute based on current route.
 *
 * @param {string} route
 * @returns {string}
 */
const getTestId = (route) => {
  switch (route) {
    case 'details':
      return 'details-view-button';
    case 'payments':
      return 'payments-view-button';
    case 'emails':
      return 'emails-view-button';
    case 'comments':
      return 'comments-view-button';
    case 'charges':
      return 'charges-view-button';
    default:
      return 'default-view-button';
  }
};

const useStyles = makeStyles((theme) => ({
  tabButton: {
    minWidth: 'unset',
    minHeight: '60px',
  },
  tabWrapper: {
    justifyContent: 'unset',
  },
}));
