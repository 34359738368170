import gql from 'graphql-tag';

const ORDER_SUMMARY_QUERY = gql`
  query getOrderSummaryV2($filters: [Filter], $anchor: Int) {
    csOrderSummary(filters: $filters, anchor: $anchor) {
      objects {
        orderNumber
        orderSubmitDate
        status
        totalAmount
        orderLines {
          styleNumber
        }
        billTo {
          address {
            address1
            address2
            address3
            address4
            city
            country
            state
            zipCode
          }
          recipient {
            lastName
            firstName
          }
        }
      }
      pages {
        next
        prev
      }
    }
  }
`;

export default ORDER_SUMMARY_QUERY;
