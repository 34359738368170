import gql from 'graphql-tag';

const NOTIFICATION_SUMMARY_QUERY = gql`
  query getOrderNotifications($orderNumber: String!) {
    orderNotificationSummary(orderNumber: $orderNumber) {
      email {
        created_date
        initiator
        is_resend
        locale_country
        locale_language
        notification_name
        recipient_email
        request_id
      }
    }
  }
`;

export default NOTIFICATION_SUMMARY_QUERY;
