import config from './config';

/**
 * Forms the mockurl based on operation
 * @param {*} operationName - operation name passed to graphql call
 * @param {*} variables - request body to grapghql call
 */
const getMockUrl = (operationName, variables) => {
  const orderNumber = variables?.orderNumber;
  switch (operationName) {
    // Need to send type as reason code response is based on reason code type
    case 'getReasonCodesV2': {
      const type = variables.type;
      return `${config.mockUrl}?operationName=${operationName}&type=${type}`;
    }
    case 'assetSearchV2':
    case 'postAddressValidator':
    case 'storeViews':
    case 'postReturnLabelsDispatch':
    case 'postReturnLocation':
    case 'getOrderNotifications':
    case 'postReadIdentityUser': {
      return `${config.mockUrl}?operationName=${operationName}`;
    }
    case 'postReturnCaptures': {
      const orderNumber = variables?.input?.orderNumber;
      return `${config.mockUrl}?operationName=${operationName}&orderNumber=${orderNumber}`;
    }
    case 'getGiftCardBalance':
    case 'getOrderDetail':
    case 'postCancelLineItem': {
      return `${config.mockUrl}/${orderNumber}?operationName=${operationName}`;
    }
    case 'getOrderSummaryV2': {
      // Determines the mock url based on the filter criteria
      if (
        variables?.filters.filter(
          (variable) =>
            variable.key === 'orderNumber' || variable.key === `billTo.contactInformation.email`
        ).length === 0
      ) {
        return `${config.mockUrl}?operationName=${operationName}_advancedSearch`;
      }
      return `${config.mockUrl}?operationName=${operationName}`;
    }
    case 'postReturnInspections': {
      return `${config.mockUrl}?operationName=${operationName}`;
    }
    case 'addLineItemDiscount': {
      return `${config.mockUrl}?operationName=${operationName}`;
    }
    case 'addShippingDiscount': {
      return `${config.mockUrl}?operationName=${operationName}`;
    }
    default:
      return `${config.mockUrl}?operationName=${operationName}`;
  }
};
export default getMockUrl;
