import { actionTypes } from '../actions/snackActions';
import { defaultState } from '../contexts/snackContext';

/**
 * The reducer for the snack context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const snackReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    /*
    show snack with specified status and text, turns off loading if set.
    */
    case actionTypes.SET_SNACK: {
      if (action.text && action.status) {
        return {
          ...defaultState,
          text: action.text,
          status: action.status,
          isOpen: true,
          slowLoading: false,
          loading: false,
        };
      } else return state;
    }

    /*
    clears set snack and loading, if set.
    */
    case actionTypes.CLEAR_SNACK: {
      return {
        ...defaultState,
      };
    }

    /*
    sets loading to true
    */
    case actionTypes.SET_SLOW_LOADING: {
      return {
        ...defaultState,
        slowLoading: true,
      };
    }

    /*
    sets loading to true
    */
    case actionTypes.SET_LOADING: {
      return {
        ...defaultState,
        loading: true,
      };
    }

    /*
    sets open to boolean
    */
    case actionTypes.SET_OPEN: {
      return {
        ...state,
        isOpen: Boolean(action.isOpen),
      };
    }

    default: {
      return state;
    }
  }
};
