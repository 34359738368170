import React from 'react';
import ErrorSummary from './errorSummary';
import { logErrorAction } from '../../newrelic/logErrorAction';

/**
 * Error boundaries are React components that catch JavaScript errors anywhere in their child
 * component tree, log those errors, and display a fallback UI instead of the component tree
 * that crashed. Error boundaries catch errors during rendering, in lifecycle methods, and in
 * constructors of the whole tree below them.
 *
 * https://reactjs.org/docs/error-boundaries.html
 */
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });

    // try logging to the Fullstory console (like the browser console, but invisible to our users)
    try {
      FS.log('error', `${error}: ${errorInfo}`);
    } catch (e) {
      // if FS is undefined, we're not in production, or something is wrong. do nothing.
      logErrorAction(e, { customMessage: 'Fullstory error' });
    }
  }

  render() {
    if (this.state.error) {
      const { error, errorInfo } = this.state;
      const header = this.props.header || error.name;
      const message = this.props.message || error.messages;
      return (
        <ErrorSummary
          header={header}
          message={message}
          error={error}
          errorStack={errorInfo.componentStack}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
