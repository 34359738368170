const translations = {
  ARIA_EXPANDED_ITEM_DETAIL: {
    description: 'aria label on expanded item',
    primaryValue: 'expanded item details',
    stringKey: 'ariaExpandedItemDetail',
  },
  ARIA_REASON_CODES_LOADING: {
    description: 'aria label for reason code loading spinner',
    primaryValue: 'reason codes loading',
    stringKey: 'ariaReasonCodes',
  },
  HIDE_DETAIL: {
    description: 'label on collapse button for each individual item on the item details page',
    primaryValue: 'Hide Detail',
    stringKey: 'hideDetail',
  },
  HOLD: {
    description: 'label for order holds',
    primaryValue: 'Hold',
    stringKey: 'hold',
  },
  INSPECTION_REASON: {
    description: 'label on Details page for inspection reason',
    primaryValue: 'Inspection Reason',
    stringKey: 'inspectionReasonLabel',
  },
  LINE_TOTAL: {
    description: 'label on Details page for "line total"',
    primaryValue: 'Line Total',
    stringKey: 'lineTotal',
  },
  LIST_PRICE: {
    description: 'label on Details page for "list price"',
    primaryValue: 'List Price',
    stringKey: 'listPrice',
  },
  PICKUP_POINTS_ERROR: {
    description: 'error message for pickup point service err',
    primaryValue: 'Error fetching pickup point details',
    stringKey: 'pickupPointError',
  },
  QUANTITY: {
    description: 'label on Details page for "quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  RETURN_REASON: {
    description: 'label on Details page for return reason',
    primaryValue: 'Return Reason',
    stringKey: 'returnReason',
  },
  SEE_DETAIL: {
    description: 'label on expand button for each individual item on the item details page',
    primaryValue: 'See Detail',
    stringKey: 'seeDetail',
  },
  SIZE: {
    description: 'label on Details page for "size"',
    primaryValue: 'Size',
    stringKey: 'size',
  },
  STATUS: {
    description: 'label on Details page for "status"',
    primaryValue: 'Status',
    stringKey: 'status',
  },
  STORE_ERROR: {
    description: 'error message for store service error',
    primaryValue: 'Error fetching store details',
    stringKey: 'storeError',
  },
  STYLE_NAME: {
    description: 'label on Details page for "style name"',
    primaryValue: 'Style Name',
    stringKey: 'styleName',
  },
  STYLE_COLOR: {
    description: 'label on Details page for "style color"',
    primaryValue: 'Style-Color',
    stringKey: 'styleColor',
  },
  TO_GIFT_CARD_APP: {
    description: 'link to gift card app',
    primaryValue: 'link to gift card app',
    stringKey: 'toGiftCardApp',
  },
};

export default translations;
