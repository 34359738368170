/** React / Utils */
import React, { useContext, useState, Fragment, useEffect } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import { useLazyQuery } from 'react-apollo';
import { useQuery } from '@apollo/react-hooks';
import PropTypes from 'prop-types';

/** Material UI */
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import TodayIcon from '@material-ui/icons/Today';
import { Tooltip } from '@material-ui/core';

/** Local */
import translations from './itemDetails.i18n';
import Codes from '../../../../codes/codes';
import { ReasonCodeTypes, ReasonCodeAppId } from '../../../../constants/dialog.const';
import { Developer } from '../../../../constants/permissions.const';
import useHasPermission from '../../../../hooks/useHasPermission';
import useSnacks from '../../../../hooks/useSnacks';
import PICKUP_POINTS_QUERY from '../../../../queries/pickupPoints.query';
import STORE_VIEWS_QUERY from '../../../../queries/storeViews.query';
import ASSET_SEARCH_QUERY from '../../../../queries/assetSearch.query';
import REASON_CODES_QUERY from '../../../../queries/reasonCodes.query';
import BasicTableRow from '../../../shared/table/tableRow';
import BasicTableRowWithNestedRows from '../../../shared/table/tableRowWithNestedRows';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import Warning from '../../../shared/warning';
import NewTabButton from '../../../shared/newTabButton';
import { I18nContext } from '../../../../store/contexts/i18Context';
import { OrderContext } from '../../../../store/contexts/orderContext';
import config from '../../../../utils/config';
import { formatAddress } from '../../../../utils/addressFormatting';
import { formatDateTime } from '../../../../utils/date';
import { getTaxDiscounts } from '../../../../utils/order';
import { isLineItemGiftCard } from '../../../../utils/order';

/**
 * Main react component housing the card content for item details tab, each item can be expanded,
 * showing the Expanded Item component
 */
function ItemDetails({ orderLines, ExpandedContent }) {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [i18State] = useContext(I18nContext);
  const [expandedItems, setExpandedItems] = useState([]);
  const { setError } = useSnacks();
  const { hasPermission } = useHasPermission();

  const { currency } = orderDetail;

  const {
    ARIA_EXPANDED_ITEM_DETAIL,
    ARIA_REASON_CODES_LOADING,
    HIDE_DETAIL,
    HOLD,
    INSPECTION_REASON,
    LINE_TOTAL,
    LIST_PRICE,
    PICKUP_POINTS_ERROR,
    QUANTITY,
    RETURN_REASON,
    SEE_DETAIL,
    SIZE,
    STATUS,
    STORE_ERROR,
    STYLE_NAME,
    STYLE_COLOR,
    TO_GIFT_CARD_APP,
  } = mapValues(translations, i18nString);

  const { data: reasonCodeData, loading: reasonCodeLoading, error: reasonCodeError } = useQuery(
    REASON_CODES_QUERY,
    {
      variables: {
        appId: ReasonCodeAppId,
        omsRegionReference: orderDetail.omsRegionReference,
        type: ReasonCodeTypes.RETURN,
        language: i18State.language,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const { data: assetSearchData, loading: assetSearchLoading, error: assetSearchError } = useQuery(
    ASSET_SEARCH_QUERY,
    {
      variables: {
        anchor: 0,
        count: 500,
        facetFields: '',
        facetQuery: 'style==GIFTCARD;type==product;cropType==1X1&=',
        queryFields: '',
        searchTerms: '',
        sort: '',
        where: 'assetType==image',
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  // filter orderLines for PUP ones that need to fetch PUP service
  const pickupPointOrderLines = orderLines.filter(
    (ol) => ol?.omoboFlags?.isPickUpPoint && ol.orderVersion === 'v2'
  );
  // filter orderLines for Store ones that need to fetch StoreViews service
  const storeOrderLines = orderLines.filter(
    (ol) => (ol?.omoboFlags?.isBOPIS || ol?.omoboFlags?.isShipToStore) && ol.orderVersion === 'v2'
  );
  const pickupPointOrderLine = pickupPointOrderLines[0];
  const storeOrderLine = storeOrderLines[0];

  // Call Pickup Points service to get pickupPoint address.
  const [fetchPickupPoint, { data: pickupPointData }] = useLazyQuery(PICKUP_POINTS_QUERY, {
    onCompleted: () => {
      const { name, address } = pickupPointData?.pickupPoints;
      // set the PUP address as the PUP address for all PUP orderLines
      pickupPointOrderLines.forEach((ol) => {
        ol.shipTo.address = { ...address, locationName: name };
        formatAddress(ol.shipTo.address);
      });
    },
    onError: (err) => {
      setError(`${PICKUP_POINTS_ERROR}, ${err}`);
    },
  });

  // Call StoreViews service to get store address.
  const [fetchStoreView, { data: storeViewData }] = useLazyQuery(STORE_VIEWS_QUERY, {
    onCompleted: () => {
      const { name, address } = storeViewData?.storeViews;
      // set the store address as the store address for all store orderLines
      storeOrderLines.forEach((ol) => {
        ol.shipTo.address = { ...address, locationName: name };
        formatAddress(ol.shipTo.address);
      });
    },
    onError: (err) => {
      setError(`${STORE_ERROR}, ${err}`);
    },
  });

  /*
  This useEffect takes the first pickupPoint orderLine (if present) and fetches the PUP location via
  the PUP service. It also takes the first store orderLine and fetches (if present) the store
  location via the StoreViews service.
   */
  useEffect(() => {
    if (pickupPointOrderLine?.shipTo?.location?.id) {
      fetchPickupPoint({ variables: { id: pickupPointOrderLine.shipTo.location.id } });
    }
    if (storeOrderLine?.shipTo?.location?.id) {
      fetchStoreView({ variables: { id: storeOrderLine.shipTo.location.id } });
    }
  }, []);

  const toggleExpansion = (lineNumber) => {
    const index = expandedItems.indexOf(lineNumber);

    if (index !== -1) {
      const cloneItems = expandedItems.slice();
      delete cloneItems[index];
      setExpandedItems(cloneItems);
    } else {
      setExpandedItems([...expandedItems, lineNumber]);
    }
  };

  const columnClassNames = [
    classes.styleNameColumn,
    classes.sizeColumn,
    classes.listPriceColumn,
    classes.quantityColumn,
    classes.statusColumn,
    classes.returnColumn,
    classes.inspectionColumn,
    classes.lineTotalColumn,
    classes.lastColumn,
  ];

  let regularOrderLines = orderLines.filter((orderLine) => {
    return orderLine.orderLineType !== 'SERVICE';
  });

  const orderIsInspected = orderDetail.status.includes('Inspected');

  return (
    <Table className={classes.itemDetailTable}>
      <BasicTableRow
        header
        rowClassName={classes.headerRow}
        data={
          orderDetail.orderType === 'RETURN_ORDER' && orderIsInspected
            ? [
                STYLE_NAME,
                STYLE_COLOR,
                SIZE,
                LIST_PRICE,
                QUANTITY,
                STATUS,
                RETURN_REASON,
                INSPECTION_REASON,
                LINE_TOTAL,
                null,
              ]
            : orderDetail.orderType === 'RETURN_ORDER'
            ? [
                STYLE_NAME,
                STYLE_COLOR,
                SIZE,
                LIST_PRICE,
                QUANTITY,
                STATUS,
                RETURN_REASON,
                LINE_TOTAL,
                null,
              ]
            : [STYLE_NAME, STYLE_COLOR, SIZE, LIST_PRICE, QUANTITY, STATUS, LINE_TOTAL, null]
        }
        cellClassName={columnClassNames}
        cellRootClassName={classes.itemDetailCellRoot}
      />

      {regularOrderLines.map((line, i) => {
        const lineNumber = line && line.lineNumber;
        const lineTotal = line && line.lineTotal - getTaxDiscounts(line);

        const ExpansionButton = () => (
          <Button
            className={classes.expandButton}
            aria-label={
              expandedItems.includes(lineNumber)
                ? HIDE_DETAIL.toLowerCase()
                : SEE_DETAIL.toLowerCase()
            }
            aria-expanded={expandedItems.includes(lineNumber)}
            onClick={() => toggleExpansion(lineNumber)}
            data-testid={`see-hide-detail-button-${i + 1}`}>
            {expandedItems.includes(lineNumber) ? HIDE_DETAIL : SEE_DETAIL}
          </Button>
        );

        let giftCardData = '';
        let giftCardImage = '';

        // find gift card image according to color code
        if (line.styleNumber === 'GIFTCARD' && !assetSearchLoading && !assetSearchError) {
          giftCardData = assetSearchData.assetSearchV2.objects.find(
            (image) => image.results[0].color === line.colorCode
          );
        }

        // get the gift card url
        if (giftCardData) {
          giftCardImage = giftCardData.results[0].defaultURL;
        }

        let data = [];

        // nested conditions to determine display of return and inspection reasons on order details
        if (orderDetail.orderType === 'RETURN_ORDER') {
          if (reasonCodeError) {
            /* 
						if order is a return order and reason code query returned an error, 
						display error message
						*/
            data.push(<Warning message={reasonCodeError.message} />);
          } else if (reasonCodeLoading) {
            // if reason code query is loading, display a spinner
            /*
             initialize an array with the appropriate number of null elements to fill the table.
             this is kinda hacky, but Alan did it, so argue with him.
             */
            data = new Array(orderIsInspected ? 10 : 9).fill(null);
            // make the center (ish) element a loading spinner. again, Alan did it.
            data[4] = (
              <CircularProgress
                aria-label={ARIA_REASON_CODES_LOADING}
                className={classes.loading}
              />
            );
          } else {
            // if the query isn't loading and isn't giving an error, find the reason code id
            const returnReason = reasonCodeData.reasonCodesV2.reasons.find(
              (reason) => reason.id === line.returnReason
            );
            // add in the data which are common to all return orders
            data.push(
              line.item && line.item.itemDescription,
              line.styleNumber && line.styleNumber + '-' + line.colorCode,
              line.displaySize,
              line.linePriceInformation && (
                <FormattedCurrency
                  amount={line.linePriceInformation.unitPrice}
                  currency={currency}
                />
              ),
              line.quantity,
              Array.isArray(line.statuses) && line.statuses.length && line.statuses[0].description
            );
            if (orderIsInspected) {
              /*
							 if the return has been inspected, grab the ID of the inspection reason,
							 and look up its description in the Codes library
							 */
              if (line.refundReason != null) {
                const inspectionReason = Codes[line.refundReason].longDescription;
                data.push(returnReason.description, inspectionReason);
              } else {
                data.push('');
              }
            } else {
              if (returnReason != null) {
                // otherwise, add in the return reason
                data.push(returnReason.description);
              } else {
                data.push('');
              }
            }

            // finally, add the common final items
            data.push(
              <FormattedCurrency amount={line.lineTotal} currency={currency} />,
              <ExpansionButton />
            );
          }
        } else {
          // a normal sales order, so display usual details
          let holdText = '';
          // if holds are available and not resolved, set addHolds
          const addHolds = Boolean(line.lineHolds && line.lineHolds[0].status !== 'Resolved');

          // set holdText
          if (addHolds) {
            holdText = ' ' + line.lineHolds[0].type + ' ' + HOLD + ' ';
          }
          const giftCardNumber = line.giftCardDetail?.giftCards?.[0]?.giftCardNumber;
          data.push(
            isLineItemGiftCard(line.item) && hasPermission(Developer) ? (
              <NewTabButton
                ariaLabel={TO_GIFT_CARD_APP}
                label={giftCardNumber ? `${line.fulfillmentMethod}: ${giftCardNumber}` : ''}
                href={`${config.giftCardUrl}${line?.giftCardDetail?.giftCards?.[0]?.giftCardInfoKey}`}
              />
            ) : (
              line?.item?.itemDescription
            ),
            line?.styleNumber + '-' + line.colorCode,
            line.displaySize,
            line.linePriceInformation && (
              <FormattedCurrency amount={line.linePriceInformation.unitPrice} currency={currency} />
            ),
            line.quantity,
            Array.isArray(line.statuses) && line.statuses.length && (
              <>
                {line.statuses[0].description}

                {/* Add in holdText and calendar icon if applicable */}
                {addHolds && (
                  <>
                    <div className={classes.holdText} data-testid={`line-hold-${i}`}>
                      {holdText}{' '}
                    </div>
                    <Tooltip
                      title={formatDateTime(line.lineHolds[0].lastHoldDate, orderDetail.locale)}>
                      <TodayIcon name='hold' className={classes.holdIcon} />
                    </Tooltip>
                  </>
                )}
              </>
            ),
            <FormattedCurrency amount={lineTotal} currency={currency} />,
            <ExpansionButton />
          );
        }

        return (
          <Fragment key={i}>
            <BasicTableRowWithNestedRows
              data-testid={`order-line-details-${i}`}
              cellClassName={
                line.hasNestedVasOrderLines
                  ? classes.itemCellWithNoBorder
                  : classes.itemCellWithBorder
              }
              nestedCellClassName={classes.itemCellWithNoBorder}
              lastNestedCellClassName={classes.itemCellWithBorder}
              rowClassName={classes.itemRow}
              cellRootClassName={classes.itemDetailCellRoot}
              outerIndentationClass={classes.arrowBody}
              innerIndentationClass={classes.arrowHead}
              indentColumnIndex={1}
              data={data}
              nestedData={
                line.nestedVasOrderLines &&
                line.nestedVasOrderLines.map((vasLine) => {
                  return [
                    null,
                    vasLine.item && vasLine.item.itemDescription,
                    vasLine.styleNumber && vasLine.styleNumber + '-' + vasLine.colorCode,
                    null,
                    vasLine.linePriceInformation && (
                      <FormattedCurrency
                        amount={vasLine.linePriceInformation.unitPrice}
                        currency={currency}
                      />
                    ),
                    null,
                    null,
                    null,
                    null,
                  ];
                })
              }
            />
            {expandedItems.includes(lineNumber) && (
              <TableBody>
                <TableRow>
                  <TableCell
                    colSpan={9}
                    aria-label={ARIA_EXPANDED_ITEM_DETAIL}
                    classes={{ root: classes.itemDetailCellRoot }}>
                    <Collapse in key={i} className={classes.collapsePanel}>
                      <ExpandedContent
                        aria-expanded='true'
                        line={line}
                        currency={currency}
                        giftCardImage={giftCardImage}
                      />
                    </Collapse>
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Fragment>
        );
      })}
    </Table>
  );
}

ItemDetails.propTypes = {
  orderLines: PropTypes.shape([
    {
      omoboFlags: PropTypes.shape({
        isPickUpPoint: PropTypes.bool,
        isShipToStore: PropTypes.bool,
      }),
      orderVersion: PropTypes.string,
      refundReasonL: PropTypes.string,
      styleNumber: PropTypes.string,
      returnReason: PropTypes.string,
      orderLineType: PropTypes.string,
      lineNumber: PropTypes.string,
      lineTotal: PropTypes.string,
      colorCode: PropTypes.string,
      fulfillmentMethod: PropTypes.string,
      hasNestedVasOrderLines: PropTypes.bool,
      quantity: PropTypes.string,
      displaySize: PropTypes.string,
      giftCardDetail: PropTypes.shape({
        giftCards: PropTypes.shape([
          {
            giftCardNumber: PropTypes.string,
          },
        ]),
      }),
      linePriceInformation: PropTypes.shape({
        unitPrice: PropTypes.string,
      }),
      statuses: PropTypes.shape([
        {
          description: PropTypes.string,
        },
      ]),
      lineHolds: PropTypes.shape([
        {
          status: PropTypes.string,
          lastHoldDate: PropTypes.string,
        },
      ]),
      nestedVasOrderLines: PropTypes.shape([
        {
          item: PropTypes.shape({
            itemDescription: PropTypes.string,
          }),
          styleNumber: PropTypes.string,
          colorCode: PropTypes.string,
          linePriceInformation: PropTypes.shape({
            unitPrice: PropTypes.string,
          }),
        },
      ]),
    },
  ]),
  ExpandedContent: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  collapsePanel: {
    width: '100%',
  },
  itemDetailCellRoot: {
    userSelect: 'text',
    cursor: 'text',
    padding: '5px 0 5px 15px',
    margin: 'none',
  },
  headerRow: {
    height: '30px',
  },
  holdIcon: {
    paddingTop: '1px',
    marginBottom: '-4px',
    color: 'gray',
  },
  holdText: {
    color: '#c23a36',
    display: 'inline',
  },
  expandButton: {
    minWidth: '7.6em',
    color: theme.palette.primary.main,
  },
  giftCardLink: {
    color: theme.palette.primary.main,
  },
  itemRow: {
    padding: `0 0 ${theme.spacing(0.5)}px 0`,
    border: 'none',
  },
  itemCellWithBorder: {
    display: 'table-cell',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit',
    borderBottom: '1px solid rgb(224, 224, 224)',
  },
  itemCellWithNoBorder: {
    display: 'table-cell',
    fontSize: '0.875rem',
    textAlign: 'left',
    fontWeight: 400,
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    verticalAlign: 'inherit',
    border: 'none',
  },
}));

export default ItemDetails;
