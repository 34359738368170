/**
 * Formats date/time by locale.
 *
 * @param date - any value that can parse as a date
 * @param [dateOnly] - true if only want to display date, no time
 * @param [locale] - string of ISO locale code
 * @returns {string}
 */
export const format = (date, dateOnly, locale) => {
  if (!date) return;
  date = new Date(date);
  if (date.toString() === 'Invalid Date') return;
  /*
  If the locale string for some reason does not contain the character '_' 
  then use the original locale value in localDate/localString conversion
  */
  if (locale.lastIndexOf('_') > 0) {
    locale = locale.substring(0, locale.lastIndexOf('_')).replace('_', '-');
  } else {
    // This handles locale data thats comes in non-standard format
    return date.toString();
  }
  return dateOnly ? date.toLocaleDateString(locale) : date.toLocaleString(locale).replace(',', '');
};

/**
 *
 * @param {*} date - any value that can parse as a date
 * @param {*} [locale] - string of ISO locale code
 * @returns {string}
 */
export const formatDateTime = (date, locale = 'en_US') => {
  return format(date, false, locale);
};

/**
 *
 * @param {*} date - any value that can parse as a date
 * @param {*} [locale]
 * @returns {string} - string of ISO locale code
 */
export const formatDate = (date, locale = 'en_US') => {
  return format(date, true, locale);
};

/**
 * Calculate date time for relative date: x number of days ago.
 *
 * @param {int} days - number of days ago.
 * @return {string} - date time string in ISO format.
 */
export const getDateByRelativeDays = (days = 90) => {
  const today = new Date();
  const daysInMilliseconds = 1000 * 60 * 60 * 24 * days;
  const xDaysAgo = new Date(today - daysInMilliseconds);
  return xDaysAgo.toISOString(); // ISOString is the format utilized by the orderSummary service.
};

/**
 * Returns a locale string based on country code and language code
 *
 * @param {string} language Language code
 * @param {string} country Country code
 *
 * @return {string}
 */
export const formatLocale = (language, country) => {
  if (language && country) {
    return language + '-' + country;
  }
  if (language) {
    return language;
  }
  return '';
};
