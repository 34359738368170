/** React / Utils */
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';
import React, { useContext } from 'react';

/** Material UI */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

/** Local */
import DetailsTableRow from '../../../shared/table/detailsTableRow';
import ErrorBoundary from '../../../error/errorBoundary';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import ItemDetails from '../shared/itemDetails';
import ItemCharges from './itemCharges';
import OrderContext from '../../../../store/contexts/orderContext';
import translations from './charges.i18n';

/**
 * Charges tab which will contain the order and item line charges.
 */
function Charges() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);

  const {
    ITEM_CHARGES,
    ORDER_CHARGES,
    SHIPPING_COST,
    SHIPPING_DISCOUNT,
    SHIPPING_TAX,
    SHIPPING_TOTAL,
    PROMO_LIST_HEADER,
  } = mapValues(translations, i18nString);

  const { currency, headerCharges, headerTaxes, orderLines } = orderDetail;

  /**
   * Return the specified value in a FormattedCurrency object  with the
   * current set according to what is in the order details.
   * @param {number} value monetary value to wrap.
   * @returns FormattedCurrency object.
   */
  function formatCurrency(value) {
    return <FormattedCurrency amount={value} currency={currency} />;
  }

  /**
   * Find the amount in the header charges whose category matches the
   * specified category.
   * @param {string} category category of the header charge to retrieve the amount from.
   * @returns the charge amount nested within the header charge object for a specified category.
   */
  function findHeaderCharges(category) {
    if (Array.isArray(headerCharges)) {
      const chargeObject = headerCharges.find((charge) => charge.chargeCategory === category);
      return chargeObject ? chargeObject.chargeAmount : 0;
    }
  }

  /**
   * Finding the tax info from an order details header taxes.
   */
  function findingTaxInfo() {
    if (Array.isArray(headerTaxes)) {
      const result = {
        shippingTax: 0,
        taxPercentage: 0,
      };
      const taxFound = headerTaxes.find((tax) => tax.chargeCategory === 'SHIPPING');
      if (taxFound) {
        // These fields are stored as strings.
        result.shippingTax = taxFound.tax;
        result.taxPercentage = taxFound.taxPercentage;
      }
      return result;
    }
  }

  const shippingCost = findHeaderCharges('SHIPPING');
  const shippingDiscount = findHeaderCharges('DISCOUNT');
  const shippingTaxInfo = findingTaxInfo();

  const shippingTotal = shippingCost - shippingDiscount + shippingTaxInfo?.shippingTax;

  return (
    <ErrorBoundary>
      {/* List of Coupons / Promotions */}
      {Boolean(orderDetail?.couponCodes?.length) && (
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContent}>
            <CardHeader
              disableTypography
              classes={{ root: classes.cardHeaderRoot }}
              title={<h2 className={classes.cardHeading}>{PROMO_LIST_HEADER}</h2>}
            />
            <List className={classes.couponList} data-testid='list-coupon-codes'>
              {orderDetail?.couponCodes.map((code, i) => (
                <ListItem key={i} data-testid={`coupon-code-${i}`}>
                  {code}
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      )}

      {/* Order level charges */}
      <Card
        className={classes.cardSpacing}
        key='orderCharges'
        elevation={3}
        data-testid={'order-charges-content'}>
        <CardContent className={classes.cardContent}>
          <CardHeader
            disableTypography
            classes={{ root: classes.cardHeaderRoot }}
            title={<h2 className={classes.cardHeading}>{ORDER_CHARGES}</h2>}
          />
          <Table>
            <TableBody>
              <DetailsTableRow
                header
                withLeftShift
                label={SHIPPING_TOTAL}
                value={formatCurrency(shippingTotal)}
                data-testid={'shipping-total'}
              />
              <DetailsTableRow
                withLeftShift
                label={SHIPPING_COST}
                value={formatCurrency(shippingCost)}
                data-testid={'shipping-cost'}
              />
              {/* Display shipping discount only if there is one. */}
              {Boolean(shippingDiscount) && (
                <DetailsTableRow
                  withLeftShift
                  label={SHIPPING_DISCOUNT}
                  value={formatCurrency(shippingDiscount)}
                  data-testid={'shipping-discount'}
                />
              )}

              <DetailsTableRow
                withLeftShift
                label={SHIPPING_TAX}
                value={formatCurrency(shippingTaxInfo?.shippingTax)}
                secondValue={
                  shippingTaxInfo?.taxPercentage ? `(${shippingTaxInfo?.taxPercentage}%)` : null
                }
                data-testid={'shipping-tax'}
              />
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      {/* Line item level charges */}
      <Card
        className={classes.cardSpacing}
        key='itemCharges'
        elevation={3}
        data-testid={'item-charges-content'}>
        <CardContent className={classes.cardContent}>
          <CardHeader
            disableTypography
            classes={{ root: classes.cardHeaderRoot }}
            title={<h2 className={classes.cardHeading}>{ITEM_CHARGES}</h2>}
          />
          {Array.isArray(orderLines) && (
            <ItemDetails
              orderLines={orderLines}
              currency={currency}
              ExpandedContent={ItemCharges}
            />
          )}
        </CardContent>
      </Card>
    </ErrorBoundary>
  );
}

const useStyles = makeStyles((theme) => ({
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  cardHeaderRoot: {
    paddingLeft: '9px',
  },
  cardHeading: {
    margin: 0,
    paddingBottom: theme.spacing(1),
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  cardContent: {
    overflow: 'auto',
  },
  productInformationTableRow: {
    height: '100%',
  },
  shortTableCell: {
    border: 'none',
    borderBottom: 'none',
    height: '10px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
  },
  shortTableHeader: {
    border: 'none',
    borderBottom: 'none',
    color: theme.palette.grey[700],
    fontSize: '0.8125rem',
    height: '10px',
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    whiteSpace: 'pre',
  },
  couponList: {
    paddingLeft: theme.spacing(2),
  },
}));

export default Charges;
