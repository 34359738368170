import getArrayProperty from './getArrayProperty';
import { discounts } from '../constants/chargeCategory.const';
import Geo from '../constants/geos.const';
import { inspectionStatusCodes, reInspectionStatusCodes } from '../constants/statusCodes.const';
import { stitchName } from './name';
import { Partners } from '../constants/origin.const.js';
import cloneDeep from 'lodash/cloneDeep';

/**
 * a function which takes an orderType and formats it for display on order detail page
 * example: input 'RETURN_ORDER' returns 'Return Order'
 * @param {String} orderType
 */
export const formatOrderType = (orderType) => {
  if (typeof orderType === 'string') {
    return orderType
      .toLowerCase()
      .replace('_', ' ')
      .split(' ')
      .map((word) => word[0].toUpperCase() + word.substring(1))
      .join(' ');
  }
};

/**
 * a function to convert BOM labels and values to title case format.
 * examples:
 *  -'COLLAR/ TONGUE LINING' returns 'Collar/ Tongue Lining'
 *  -'TIP/EYESTAY/FOXING/BACKSTAY' returns 'Tip/EyeStay/Foxing/Backstay'
 * @param {String} bomString
 * @returns { String }
 */
export const formatBomString = (bomString) => {
  if (typeof bomString === 'string') {
    return bomString
      .split(' ') // Split up words using space separator.
      .map((str) => {
        // Condition to avoid formatting hex codes for colors.
        if (Number(str.charAt(0))) {
          return str;
        }
        const titleCaseStr = // Create title case string for each iteration.
          str.charAt(0).toUpperCase() + str.substring(1, str.length).toLowerCase();
        // Parse this new titleCaseStr so characters following a '/' can be uppercase.
        const parsedForSlashesStr = titleCaseStr
          .split('/') // Split up the title case string using '/' separator.
          .map(
            (reSplitStr) =>
              // Return title case String for each iteration.
              reSplitStr.charAt(0).toUpperCase() + reSplitStr.substring(1, str.length).toLowerCase()
          )
          .join('/'); // Reassemble title case string separated by '/'.
        return parsedForSlashesStr;
      })
      .join(' '); // Reassemble full string from space (' ') separated array.
  }
};

/**
 * a function to assemble the string for one BOM value.
 * @param {array} bomValueArr Array of objects for a given BOM value.
 * @returns {string}
 */
export const formatBomValue = (bomValueArr) => {
  if (Array.isArray(bomValueArr)) {
    let bomStr = '';
    for (let i = 0; i < bomValueArr.length; i++) {
      bomStr += formatBomString(bomValueArr[i].name);
      if (i !== bomValueArr.length - 1) {
        bomStr += ' ';
      }
    }
    return bomStr;
  }
  return typeof bomValueArr === 'string' ? formatBomString(bomValueArr) : '';
};

/**
 * a function which takes an order line and returns either the status therein, or an empty string
 *
 * @param {Object} line – the order line from which the status will be pulled
 */
export const getOrderlineStatusCode = (line) => {
  // chain up the object tree to avoid a TypeError
  const status =
    Boolean(line) &&
    Boolean(line.statuses) &&
    Boolean(line.statuses.length) &&
    line.statuses[0].statusCode;

  return status || '';
};

/**
 * a function which takes an order line and returns either the status therein, or an empty string
 *
 * @param {Object} line – the order line from which the status will be pulled
 */
export const getOrderlineStatusDescription = (line) => {
  // chain up the object tree to avoid a TypeError
  const status =
    Boolean(line) &&
    Array.isArray(line.statuses) &&
    Boolean(line.statuses.length) &&
    line.statuses[0].description;

  return status || '';
};

/**
 * Gets the parentLineNumber from the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number|*}
 */
export const getOrderLineParentLineNumber = (orderLine) => {
  let parentLineNumberReferences =
    Array.isArray(orderLine?.references) &&
    orderLine.references.filter((orderLineReference) => {
      return orderLineReference.name === 'ParentLineNo';
    });
  return (
    parentLineNumberReferences &&
    parentLineNumberReferences.length &&
    // There should only be one, so grab the member at the first index.
    parentLineNumberReferences[0].value
  );
};

/**
 * This util function checks if an order is for a Swoosh user by checking if
 * any of the payment methods contain the Swoosh merchant account number
 *
 * @param {Object} orderDetail – the response from an Order Details API call
 */
export const isSwooshOrder = (orderDetail) => {
  // this value is based on data derived from testing
  const swooshMerchantCode = 'swooshusa';
  if (!orderDetail) return false;

  const paymentMethods = getArrayProperty(orderDetail, 'paymentMethods');

  return paymentMethods
    .map((paymentMethod) => paymentMethod.merchantAccountNumber)
    .includes(swooshMerchantCode);
};

/**
 * Creates containers based on the orderLines provided. The containers will have references to the
 * item and a service/vas item, if there is one associated with it.
 *
 * @param {array} orderLines OrderLine objects.
 */
export const getOrderLineContainers = (orderLines, omsRegionReference) => {
  const result = [];

  if (Array.isArray(orderLines)) {
    const items = orderLines.filter((line) => line.orderLineType !== 'SERVICE');

    for (let i = 0; i < items.length; i++) {
      let orderLineContainer = {
        item: null,
        service: null,
      };
      const inlineItem = items[i];

      orderLineContainer.item = inlineItem;

      if (inlineItem.hasNestedVasOrderLines) {
        // Vas items in the US geo cannot be cancelled or returned.
        let matchingServiceItem = inlineItem.nestedVasOrderLines[0];
        if (omsRegionReference === Geo.US) {
          matchingServiceItem['returnableFlag'] = false;
          matchingServiceItem['cancellableFlag'] = false;
          matchingServiceItem['returnableQuantity'] = 0;
          matchingServiceItem['cancellableQuantity'] = 0;
        }
        orderLineContainer['service'] = matchingServiceItem;
      }

      result.push(orderLineContainer);
    }
  }

  return result;
};

/**
 * Calculate time difference in minutes between cancel request time and order create date
 *
 * @param {object} cancelRequestDate - date object referencing the date of the cancel request
 * @param {object} orderCreateDate - date object referencing the date the order was created
 */
export const calculateMinuteDifference = (cancelRequestDate, orderCreateDate) => {
  const millisecondDiff = cancelRequestDate.getTime() - orderCreateDate.getTime();
  const minutesDiff = millisecondDiff / 1000 / 60;
  return Math.abs(minutesDiff);
};

/**
 * Determine if the order is eligible for canceling.  CancellableQty is sufficient for all GEOs
 * except CN, currently.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsCancellable = (orderDetail) => {
  const hasCancellableQty = checkFieldCountOnOrderLines(orderDetail, 'cancellableQuantity');
  if (
    Partners[orderDetail?.channel] ||
    !orderDetail?.orderHeaderKey // if order is not synced with DOMS
  ) {
    return false;
  } else if (orderDetail?.omsRegionReference === 'NIKECN') {
    const orderCreateDate = new Date(orderDetail.orderCreateDate);
    const cancelRequestTime = new Date();
    const requestTimeDiff = calculateMinuteDifference(cancelRequestTime, orderCreateDate);

    const isFromBZN = (orderDetail) => {
      const hasShipNode = orderDetail.orderLines.reduce((acc, { statuses }) => {
        return acc || statuses?.some(({ shipNode }) => shipNode === 'BZNIKE');
      }, false);
      return Boolean(hasShipNode);
    };

    if (orderDetail.orderLines.length === 1 && isFromBZN(orderDetail) && hasCancellableQty) {
      // CN orders with a single item from BZN are cancellable with normal logic
      return true;
    } else if (requestTimeDiff < 30) {
      // all other CN orders are only cancellable until 30 minutes old
      return true;
    } else return false;
  } else return checkFieldCountOnOrderLines(orderDetail, 'cancellableQuantity');
};

/**
 * Determine if the order is eligible for returning.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsReturnable = (orderDetail) => {
  // todo uncomment this and delete the code below (and the function it calls) once this works
  // return checkFieldCountOnOrderLines(orderDetail, 'returnableQuantity');

  let isReturnable = false;

  if (
    !Boolean(orderDetail) ||
    !Array.isArray(orderDetail.orderLines) ||
    Partners[orderDetail?.channel] ||
    !orderDetail?.orderHeaderKey // if order is not synced with DOMS
  )
    return isReturnable;
  const { orderLines } = orderDetail;

  for (const orderLine of orderLines) {
    if (orderLine?.omoboFlags?.isReturnable) {
      isReturnable = true;
    }
  }
  return isReturnable;
};

/**
 * Determine if the selected line items are digital gift cards
 *
 * @param {Object} selectedLines - Object of selected line items for return or cancel.
 */
export const areLineItemsDigitalGiftCards = (selectedLines) => {
  return Object.values(selectedLines).every(
    (sl) => sl.styleNumber === 'GIFTCARD' && sl.fulfillmentMethod === 'DIGITAL'
  );
};

/**
 * Determine if the selected orderLine item is a gift card (either digital or physical)
 *
 * @param {Object} orderLine - Object of selected orderLine items for return or cancel.
 */
export const isLineItemGiftCard = (orderLine) => {
  return orderLine.itemDescription.includes('Gift Card') ? true : false;
};

/**
 * Returnable logic taken from the link here
https://confluence.nike.com/display/CE/Order+Review+V1+Rules?focusedCommentId=358893297
&refresh=1597771485220#comment-358893297
 *
 * I am not going to comment this function, because I don't know why. this logic was copied without
 * explanation
 * 
 * @param {object} orderLine – the order line to check returnability of
 */
export const isOrderLineReturnable = (orderLine) => {
  const { orderLineType, statuses, omoboFlags } = orderLine;

  const isStoreOrder = orderLineType === 'STORE';

  if (orderLineType === 'NONMERCH') return false;

  if (orderLineType === 'SERVICE') return false;

  const orderStatuses = Array.isArray(statuses) ? statuses : [];
  const statusCodes = orderStatuses ? orderStatuses.map((status) => status.statusCode) : [];
  const maxLineStatus = statusCodes.sort()[statusCodes.length - 1];

  /*
    For non-bopis and non-store orders if all of the order lines are in non-returnable status
    then return false.
   */
  if (!omoboFlags.isBOPIS && !isStoreOrder) {
    const isAllOrderLinesNotEligibleForReturn = statusCodes?.every(
      (statusCode) => statusCode < '3700' || statusCode >= '9000'
    );
    if (isAllOrderLinesNotEligibleForReturn) return false;
  }

  // if the orderLine is BOPIS, only return true if the item has been picked up.
  if (omoboFlags.isBOPIS) return maxLineStatus === '3200.1400';

  // Disable the orderline if the returnable quantity is less than 0
  return getReturnableQuantity(orderStatuses) > 0;
};

/**
 * Based on orderStatuses with in the orderlines, determines the returnable quantity
 * @param {*} orderStatuses - Order status with in the orderlines
 */
export const getReturnableQuantity = (orderStatuses) => {
  const unreturnableStatuses = [
    '3700.01',
    '3700.011',
    '3700.02',
    '3700.03',
    '3700.04',
    '3700.05',
    '3700.06',
  ];

  const additionalReturnableStatuses = ['3200.1400'];

  /*
   This logic is based on Gagarin confluence page mentioned above
   Following actions are performed in the below block
   1. Filtering out order statuses that can't returned
   2. Getting the order quantity from orderStatus that can be returned
  */
  if (orderStatuses) {
    return orderStatuses
      .filter((orderStatus) => {
        return (
          (orderStatus.statusCode >= '3700' &&
            orderStatus.statusCode < 9000 &&
            !unreturnableStatuses.includes(orderStatus.statusCode)) ||
          additionalReturnableStatuses.includes(orderStatus.statusCode)
        );
      })
      .map((orderStatus) => {
        return parseInt(orderStatus.quantity);
      })
      .filter((quantity) => {
        return isFinite(quantity);
      })
      .reduce((totalQuantity, quantity) => {
        return totalQuantity + quantity;
      }, 0);
  }
};

/**
 * Determines if the given VAS orderLine is returnable.
 *
 * @param vasOrderLine {object} - The VAS orderLine
 * @returns {boolean} - Whether or not the VAS orderLine is returnable
 */
export const isVASOrderLineReturnable = (vasOrderLine) => {
  if (!vasOrderLine?.parentOrderLine?.omoboFlags?.isReturnable) return false;
  return isVASOrderLineJerseyId(vasOrderLine);
};

/**
 * Determines if the given VAS orderLine is a Jersey ID.
 *
 * @param vasOrderLine {object} - The VAS orderLine
 * @returns {boolean} - Whether or not the VAS orderLine is a Jersey ID
 */
export const isVASOrderLineJerseyId = (vasOrderLine) => {
  return vasOrderLine?.styleNumber === 'VAS0020';
};

/**
 * Determine if the order is eligible for inspection.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsInspectable = (orderDetail) => {
  if (!orderDetail?.orderHeaderKey) {
    return false;
  }
  return determineOrderEligibilityByLineStatus(orderDetail, inspectionStatusCodes);
};

/**
 * Determine if the order is eligible for re-inspection.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 */
export const determineIfOrderIsReInspectable = (orderDetail) => {
  if (!orderDetail?.orderHeaderKey) {
    return false;
  }
  return determineOrderEligibilityByLineStatus(orderDetail, reInspectionStatusCodes);
};

/**
 * iterates over an order's orderlines, reading from a (provided) field, and returning true if any
 * of those fields is a numerical value greater than 0, and false otherwise.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 * @param {string} field - the (numerical) field to read from the orderlines
 */
export const checkFieldCountOnOrderLines = (orderDetail, field) => {
  // make sure we have the basics needed for determining eligibility
  if (!Boolean(orderDetail) || !Array.isArray(orderDetail.orderLines)) return false;
  const { orderLines } = orderDetail;
  if (!orderLines || !field) return false;

  for (let line of orderLines) {
    if (Number(line[field]) > 0) return true;
  }

  return false;
};

/**
 * Determines an orders eligibility for an action based on the status codes provided.
 *
 * @param {object} orderDetail - Response from an Order Details API call.
 * @param {array} eligibleStatusCodes - Array of string values of valid status codes.
 */
export const determineOrderEligibilityByLineStatus = (orderDetail, eligibleStatusCodes) => {
  if (!orderDetail || !Array.isArray(eligibleStatusCodes)) return false;
  const { orderLines } = orderDetail;
  if (!orderLines) return false;

  for (let line of orderLines) {
    const statusCode = getOrderlineStatusCode(line);
    if (eligibleStatusCodes?.includes(statusCode)) {
      return true;
    }
  }

  return false;
};

/**
 * Determines an order's eligibility for shipping discounts
 *
 * @param {object} orderDetail
 * @returns {boolean}
 */
export const canShippingBeDiscounted = (orderDetail) => {
  const { status, orderHeaderKey } = orderDetail;
  const headerCharges = getArrayProperty(orderDetail, 'headerCharges');

  let discountShippingAllowed = true;

  const isCancelled = status === 'Cancelled';
  const shippingCostIsZero = getShippingCharges(headerCharges) === 0;
  const isDiscountsMoreThanCharges =
    parseFloat(getHeaderDiscountCharges(headerCharges)) >=
    parseFloat(getNonDiscountHeaderCharges(headerCharges));
  const isNotSyncedToDOMS = !orderHeaderKey;

  if (isCancelled || shippingCostIsZero || isDiscountsMoreThanCharges || isNotSyncedToDOMS) {
    discountShippingAllowed = false;
  }

  return discountShippingAllowed;
};

/**
 * Gets the total discounts value for the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number}
 */
export const getDiscount = ({ lineCharges = [], quantity = 0 } = {}) => {
  return Array.isArray(lineCharges)
    ? lineCharges
        .filter((charge) => discounts.includes(charge.chargeCategory))
        .map((charge) => charge.chargePerQuantity * quantity)
        .reduce((acc, cur) => acc + cur, 0)
    : 0;
};

/**
 * Gets all discounts information for the given orderLine
 *
 * @param orderLine {Object}
 * @returns {array}
 */
export const getDiscounts = ({ lineCharges = [] } = {}) => {
  return Array.isArray(lineCharges)
    ? lineCharges.filter((charge) => discounts.includes(charge.chargeCategory))
    : 0;
};

/**
 * Gets the total charges, for the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number}
 */
export const getCharges = ({ lineCharges = [] } = {}) => {
  return Array.isArray(lineCharges)
    ? lineCharges
        .filter((charge) => !discounts.includes(charge.chargeCategory))
        .map((charge) => charge.chargePerQuantity)
        .reduce((acc, cur) => acc + cur, 0)
    : 0;
};

/**
 * Gets the total shipping charges for the given order
 *
 * @param {array} headerCharges Array of header charges from orderDetail
 * @returns {number}
 */
export const getShippingCharges = (headerCharges = []) =>
  Array.isArray(headerCharges)
    ? headerCharges
        .filter(
          (charge) =>
            typeof charge?.chargeCategory === 'string' &&
            !charge?.chargeCategory?.includes('DISCOUNT')
        )
        .reduce((acc, cur) => acc + Number(cur?.chargeAmount || 0), 0)
    : 0;

/**
 * Gets all discounts information for the given orderLine
 *
 * @param orderLine {Object}
 * @returns {array}
 */
export const getTaxDiscounts = ({ lineTaxes = [] } = {}) => {
  // if there are any tax discounts, add those together
  return Array.isArray(lineTaxes)
    ? lineTaxes
        .filter((tax) => tax.chargeCategory === 'DISCOUNT')
        .reduce((acc, taxDiscount) => acc + taxDiscount?.taxAmount, 0)
    : 0;
};

/**
 * Gets the tax amount for the given orderLine.
 *
 * @param orderLine {Object}
 * @returns {number}
 */
export const getTax = ({ lineTaxes = [] } = {}) => {
  // if there are any taxes, add only the taxes that aren't tax discounts
  return Array.isArray(lineTaxes)
    ? lineTaxes
        .filter((tax) => tax.chargeCategory !== 'DISCOUNT')
        .reduce((acc, lineTax) => acc + lineTax?.taxAmount, 0)
    : 0;
};

/**
 * Gets the consumerName by attempting to stitch a name from various objects in the given idnData
 * and the given orderDetail until it succeeds, or returns an empty string.
 *
 * @param {Object} orderDetail the orderDetail
 * @param {Object} idnData the idnData from the idnaccount API
 * @returns {string} the consumerName
 */
export const getConsumerName = (orderDetail, idnData) => {
  return (
    stitchName(idnData?.identityUser?.name?.latin) ||
    stitchName(orderDetail?.billTo?.recipient) ||
    stitchName(orderDetail?.paymentMethods?.[0]) ||
    stitchName(orderDetail?.paymentMethods?.[0]?.billTo?.recipient)
  );
};

/**
 * Gets the consumerEmail by checking fields in the given idnData and the given orderDetail, or
 * returns an empty string.
 *
 * @param {Object} orderDetail the orderDetail
 * @param {Object} idnData the idnData from the idnaccount API
 * @returns {string} the consumerEmail
 */
export const getConsumerEmail = (orderDetail, idnData) => {
  return (
    idnData?.identityUser?.contact?.email?.address ||
    orderDetail?.billTo?.contactInformation?.email ||
    orderDetail?.paymentMethods?.[0]?.billTo?.contactInformation?.email ||
    ''
  );
};

/**
 * Identifies sets of NikeID orderLines, removes all but one per
 * customizedProductReference (Metric ID / CPR) from top level orderLines array,
 * and stores the full set in a nested array under nikeIdLines.
 *
 * @param orderDetail {object} - The orderDetail to filter.
 */
export const filterNikeIDLines = (orderDetail) => {
  let { orderLines } = orderDetail;
  const allNikeIdOrderLines = orderLines.filter((line) => line.orderLineType === 'NIKEID');

  // transforms all NikeId orderLines into a dictionary by CPR
  const reduceNikeIdOrderLines = (nikeIds, orderLine) => {
    if (Array.isArray(nikeIds[orderLine.customizedProductReference])) {
      nikeIds[orderLine.customizedProductReference].push(orderLine);
    } else {
      nikeIds[orderLine.customizedProductReference] = [orderLine];
    }
    return nikeIds;
  };
  const cPRDict = allNikeIdOrderLines.reduce(reduceNikeIdOrderLines, {});

  /**
   * Attaches identified sets of NikeId lines to the NikeId line with unitOfMeasure === 'EA',
   * drops all other NikeId lines from top level array, and leave all non-NikeId lines in place.
   * */

  const attachNikeIds = (orderLines, line) => {
    if (line.orderLineType === 'NIKEID') {
      if (line.item?.unitOfMeasure === 'EA') {
        // attach matching dictionary set to the top level line we're keeping
        line.nikeIdLines = cPRDict[line.customizedProductReference];
        orderLines.push(line);
      }
      // if not an EA line, do not add to the accumulator
    } else {
      orderLines.push(line);
    }
    return orderLines;
  };

  // alter the original orderDetail object
  const orderLinesCopy = cloneDeep(orderLines);
  return orderLines.splice(0, orderLines.length, ...orderLinesCopy.reduce(attachNikeIds, []));
};

/**
 * This function takes in an array of orderlines, determines if there are cancelled orderlines
 * therein (or a quantity of cancelled items within an orderline)
 *
 * @param {Object} lines – the orderLines array from an order detail object
 * @returns {Array<array>} - [lines, cancelledLines], a separation of order lines arrays with
 * cancelled lines being on one side, and uncancelled on the other
 */
export const separateCancelledItems = (lines = []) => {
  // if we've encountered odd input, get outta here
  if (!Array.isArray(lines) || !lines.length) return [[], []];

  // first, let's make an array of the status codes
  const statusCodes = lines
    .map((line) => line.statuses)
    .flat()
    .map((status) => status.statusCode);
  /*
	 are some of the orderlines cancelled, status codes starting with 9000, as per
	 confluence.nike.com/pages/viewpage.action?spaceKey=MOM&title=Order+Status+Mapping+for+Consumers
	  */
  const someLinesCancelled = statusCodes?.some((code) => code?.startsWith('9000'));
  // are ALL of the lines cancelled?
  const allLinesCancelled = statusCodes?.every((code) => code?.startsWith('9000'));

  // if some of the lines are cancelled, we might need to separate some out
  if (someLinesCancelled) {
    // unless ALL of the lines are, in which case, let's short circuit out
    if (allLinesCancelled) {
      // no uncancelled order lines, as determined
      return [[], lines];
    }

    // instantiate arrays for our separate types
    const cancelledLines = [];
    const otherLines = [];
    // if we need a new lineNumber, let's make it. they're 1-indexed, so this should do.
    let newLineNumber = lines.length + 1;

    // iterate over the order lines
    for (const line of lines) {
      // make a new line object for each type of line (cancelled, and non), with clear statuses
      // a regular line object, with no cancellation reason
      const newLine = {
        ...line,
        statuses: [],
        cancellationReason: null,
      };
      // a cancelled line objet, with the requisite details
      const cancelledLine = {
        ...line,
        statuses: [],
        // cancelled items are without quantity
        quantity: 0,
        // it should be its own order line
        lineNumber: newLineNumber,
        // it's not cancellable
        cancellableQuantity: 0,
        // nor shuld details from other order lines cary over
        refundReason: null,
        returnableFlag: false,
        returnableQuantity: 0,
        returnReason: null,
        // obvs
        cancellableFlag: false,
      };
      // loop over the statuses on each order line
      for (const status of line.statuses) {
        // if the status starts with 9000, marking it as a cancel (ref linked above)
        if (status.statusCode.startsWith('9000')) {
          // mark it as a cancelled line
          cancelledLine.statuses.push(status);
        } else {
          // otherwise, it's gonne belong in our relguar order lines
          newLine.statuses.push(status);
        }
      }
      /*
			 if we have statuses in either order type, that means that it represents a real order,
			 as is dictated by the above.
			 */
      if (newLine.statuses.length) {
        otherLines.push(newLine);
      }
      if (cancelledLine.statuses.length) {
        newLineNumber++;
        cancelledLines.push(cancelledLine);
      }
    }

    // a function to sort each array by order line numbers
    const sorter = (a, b) => (a.lineNumber > b.lineNumber ? 1 : -1);

    // return the two. sorted
    return [otherLines.sort(sorter), cancelledLines.sort(sorter)];
  }

  // this case, as dictated above, means that there are no cancelled items
  return [lines, []];
};

/**
 * Gets the total discounts on order header charges
 *
 * @param headerCharges {Object}
 * @returns {number}
 */
export const getHeaderDiscountCharges = (headerCharges) => {
  const discountChargesArray = getHeaderDiscountsArray(headerCharges);
  return Array.isArray(discountChargesArray)
    ? discountChargesArray.reduce((acc, cur) => acc + Number(cur.chargeAmount || 0), 0)
    : 0;
};

/**
 * Gets the total headers charges exluding discounts.
 *
 * @param headerCharges {Object}
 * @returns {number}
 */
export const getNonDiscountHeaderCharges = (headerCharges) => {
  const headerChargesArray = getNonDiscountHeaderArray(headerCharges);
  return Array.isArray(headerChargesArray)
    ? headerChargesArray.reduce((acc, cur) => acc + Number(cur.chargeAmount || 0), 0)
    : 0;
};

/**
 * Gets the headers charges excluding discounts.
 *
 * @param headerCharges {Object}
 * @returns {object}
 */
export const getNonDiscountHeaderArray = (headerCharges) => {
  return Array.isArray(headerCharges)
    ? headerCharges.filter((charge) => !discounts.includes(charge.chargeCategory))
    : [];
};

/**
 * Gets the headers charges that are discounts.
 *
 * @param headerCharges {Object}
 * @returns {object}
 */
export const getHeaderDiscountsArray = (headerCharges) => {
  return Array.isArray(headerCharges)
    ? headerCharges.filter((charge) => discounts.includes(charge.chargeCategory))
    : [];
};
