import gql from 'graphql-tag';

const STORE_VIEWS_QUERY = gql`
  query storeViews($id: ID!) {
    storeViews(id: $id) {
      id
      name
      address {
        address1
        address2
        address3
        area
        city
        state
        postalCode
        country
        iso2country
        county
      }
    }
  }
`;

export default STORE_VIEWS_QUERY;
