import gql from 'graphql-tag';

const NOTIFICATION_DETAILS_QUERY = gql`
  query getNotificationDetails($requestId: String!) {
    notificationDetails(requestId: $requestId) {
      email {
        attachments {
          id
          name
          content_type
          attachment
        }
        content {
          FirstName
          LastName
        }
        created_date
        has_attachments
        initiator
        is_resend
        locale_country
        locale_language
        locale_variant
        notification_name
        notification_type
        order_id
        recipient_email
        request_id
        subject
        user_id
        vendor
        email_body {
          name
          content_type
          encoding
          body
        }
      }
    }
  }
`;

export default NOTIFICATION_DETAILS_QUERY;
