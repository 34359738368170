/* React/Utils */
import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';

/* Material-UI */
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';

/* Local */
import OrderSearchResultsRow from './orderSearchResultsRow';
import translations from './orderSearch.i18n';

/**
 * This component is used to display orders search results
 *
 * @param {object} props – React props object
 */
const OrderSearchTable = ({ title, searchResults, anchor, paginationLinks, handleNavigation }) => {
  const classes = useStyles();
  const { prev, next } = paginationLinks;
  const { i18nString } = useContext(NikeI18nContext);
  const {
    ARIA_ORDER_SEARCH,
    SEARCH_ORDER_NUMBER,
    SEARCH_RESULTS_ORDER_DATE,
    SEARCH_RESULTS_CONSUMER_NAME,
    SEARCH_RESULTS_BILL_TO,
    SEARCH_RESULTS_TOTAL,
    SEARCH_RESULTS_ORDER_STATUS,
    SEARCH_RESULTS_ITEMS,
    NO_SEARCH_RESULT,
    PREV,
    NEXT,
  } = mapValues(translations, i18nString);

  // Next link pagination component
  const Next = () => (
    <Link
      className={clsx(classes.navLink, classes.nextLink)}
      variant='body2'
      onClick={() => handleNavigation('next')}>
      {NEXT}
    </Link>
  );

  // Prev link pagination component
  const Prev = () => (
    <Link
      className={clsx(classes.navLink, classes.prevLink)}
      variant='body2'
      onClick={() => handleNavigation('prev')}>
      {PREV}
    </Link>
  );

  return (
    <>
      <TableContainer
        component={Paper}
        className={classes.tableContainer}
        data-testid={'search-results-table'}>
        {title && (
          <Typography className={classes.title} variant='h2' id='tableTitle'>
            {title}
          </Typography>
        )}
        <Box className={classes.paginationWrapper}>
          {Boolean(prev) && <Prev />}
          {Boolean(next) && <Next />}
        </Box>
        <Table className={classes.table} aria-label={ARIA_ORDER_SEARCH}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader} align='left'>
                {SEARCH_ORDER_NUMBER}
              </TableCell>
              <TableCell className={classes.tableHeader} align='left'>
                {SEARCH_RESULTS_ORDER_DATE}
              </TableCell>
              <TableCell className={classes.tableHeader} align='left'>
                {SEARCH_RESULTS_CONSUMER_NAME}
              </TableCell>
              <TableCell className={classes.tableHeader} align='left'>
                {SEARCH_RESULTS_BILL_TO}
              </TableCell>
              <TableCell className={classes.tableHeader} align='left'>
                {SEARCH_RESULTS_TOTAL}
              </TableCell>
              <TableCell className={classes.tableHeader} align='left'>
                {SEARCH_RESULTS_ORDER_STATUS}
              </TableCell>
              <TableCell className={classes.tableHeader} align='left'>
                {SEARCH_RESULTS_ITEMS}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid={'search-results-table-body'}>
            {searchResults.length > 0 &&
              searchResults.map((order) => (
                <OrderSearchResultsRow key={order.orderNumber} order={order} />
              ))}
          </TableBody>
        </Table>
        {searchResults.length > 0 && (
          <Box className={classes.paginationWrapper}>
            {Boolean(prev) && <Prev />}
            {Boolean(next) && <Next />}
          </Box>
        )}
        {!searchResults.length && (
          <Typography
            variant='subtitle1'
            id='noSearchResults'
            align='center'
            className={classes.zeroSearchResults}
            component='div'>
            {NO_SEARCH_RESULT}
          </Typography>
        )}
      </TableContainer>
    </>
  );
};

export default OrderSearchTable;

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    margin: theme.spacing(1),
    marginTop: 0,
    maxWidth: 1250,
    minWidth: 850,
  },
  tableHeader: {
    fontWeight: 'bold',
  },
  zeroSearchResults: {
    padding: theme.spacing(3.75),
  },
  title: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.75,
    margin: theme.spacing(2),
  },
  navLink: {
    fontWeight: 'bold',
    fontSize: '1.667rem',
  },
  prevLink: {
    paddingRight: theme.spacing(1.5),
  },
  nextLink: {
    paddingLeft: theme.spacing(1.5),
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
}));
