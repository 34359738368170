const translations = {
  ATTACHMENTS: {
    description: 'label for the attachments on the emails details page',
    primaryValue: 'Attachments',
    stringKey: 'attachments',
  },
  EMAIL_DETAILS_ERROR: {
    description: 'setup for an email details error message',
    primaryValue: 'Email Details Error',
    stringKey: 'emailDetailsError',
  },
  EXIT: {
    description: 'label on the Email Details Page for "Exit"',
    primaryValue: 'Exit',
    stringKey: 'exit',
  },
  NO_EMAIL_DETAILS_AVAILABLE: {
    description: 'setup for a no email details available message',
    primaryValue: 'No email details available',
    stringKey: 'noEmailDetailsAvailable',
  },
  RESEND_EMAIL: {
    description: 'button label for resending email',
    primaryValue: 'Resend Email',
    stringKey: 'resendEmail',
  },
  RESEND_EMAIL_ERROR: {
    description: 'error message when resend email fails',
    primaryValue: 'Email resend failed. Please try again.',
    stringKey: 'resendEmailError',
  },
  RESEND_EMAIL_SUCCESS: {
    description: 'success message when email resend is requested successfully',
    primaryValue: 'Email resend requested.',
    stringKey: 'resendEmailSuccess',
  },
  RESEND_TO: {
    description: 'label for resending email to consumer',
    primaryValue: 'Resend to',
    stringKey: 'resendTo',
  },
};

export default translations;
