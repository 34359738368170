import React, { useContext, useEffect } from 'react';
import { pageTitle } from '../../constants/page.const';
import { makeStyles } from '@material-ui/core';
import { NikeI18nContext } from '@nike/i18n-react';
import Box from '@material-ui/core/Box';
import ResponseSnackBar from '../shared/snackbar';
import Loading from '../shared/loading';
import { AdminContext } from '../../store/contexts/adminContext';
import TopBarContainer from '../orders/topBar/topBarContainer';
import AdminControl from './adminControl';
import Typography from '@material-ui/core/Typography';
import translations from './admin.i18n.js';
import mapValues from 'lodash/mapValues';

/**
 * Main react component housing the Admin page
 */
export default function Admin() {
  const classes = useStyles();
  const [adminState] = useContext(AdminContext);
  const { i18nString } = useContext(NikeI18nContext);
  const { ADMIN, ARIA_ADMIN_PAGE } = mapValues(translations, i18nString);

  const adminHasLoaded = adminState.permissions[0] && adminState.groups[0];

  /*
    Update page title to reflect admin page
  */
  useEffect(() => {
    document.title = `Admin ${pageTitle}`;
  }, []);

  return (
    <>
      <div className={classes.root}>
        <Box className={classes.page} aria-label={ARIA_ADMIN_PAGE}>
          <TopBarContainer>
            <Typography variant='h1' className={classes.pageTitle}>
              {ADMIN}
            </Typography>
          </TopBarContainer>
          {adminHasLoaded ? <AdminControl /> : <Loading />}
        </Box>
      </div>
      <ResponseSnackBar />
    </>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
  },
  page: {
    flex: '1 1 auto',
    backgroundColor: '#f5f5f5',
    overflowX: 'hidden',
  },
  pageTitle: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '2.25rem',
    marginLeft: '-2px',
    marginTop: '-2px',
    textStroke: '.8px #666',
    fontWeight: 'bold',
    letterSpacing: '-2px',
    textAlign: 'left',
    textTransform: 'lowercase',
    width: '100%',
  },
}));
