const translations = {
  ARIA_PAY_TYPE_TRANS_DETAILS: {
    description: 'aria label for transaction details per payment type',
    primaryValue: 'payment type transaction details',
    stringKey: 'ariaPayTypeTransDetails',
  },
  ALL_TRANS: {
    description: 'label on the Payments page for "All Transactions"',
    primaryValue: 'All Transactions',
    stringKey: 'allTransactions',
  },
  AMOUNT: {
    description: 'label on the Payments page for "Amount"',
    primaryValue: 'Amount',
    stringKey: 'amount',
  },
  AUTH_DATE: {
    description: 'label on the Payments page for "Auth Date"',
    primaryValue: 'Auth Date',
    stringKey: 'authDate',
  },
  AUTH_NUM: {
    description: 'label on the Payments page for "Auth #"',
    primaryValue: 'Auth #',
    stringKey: 'authNum',
  },
  DATE: {
    description: 'label on the Payments page for "Date"',
    primaryValue: 'Date',
    stringKey: 'date',
  },
  INVOICES: {
    description: 'label on the Payments page for "Invoices"',
    primaryValue: 'Invoices',
    stringKey: 'invoices',
  },
  PAYMENT_DETAILS: {
    description: 'accessible heading for the Payments page',
    primaryValue: 'Payment Details',
    stringKey: 'paymentDetails',
  },
  PAYMENT_SUMMARY: {
    description: 'label on the Payments page for "Summary" section',
    primaryValue: 'Payment Summary',
    stringKey: 'paymentSummary',
  },
  STATUS: {
    description: 'label on the Payments page for "Status"',
    primaryValue: 'Status',
    stringKey: 'status',
  },
  STATUS_DATE: {
    description: 'label on Details page for "status date"',
    primaryValue: 'Status Date',
    stringKey: 'statusDate',
  },
  SUMMARY: {
    description: 'label on the Payments page for "Summary" section',
    primaryValue: 'Summary',
    stringKey: 'summary',
  },
  TOTAL_TRANSF_IN: {
    description: 'label on the Payments page for "Total Transferred In"',
    primaryValue: 'Total Transferred In',
    stringKey: 'totalTransferredIn',
  },
  TOTAL_TRANSF_OUT: {
    description: 'label on the Payments page for "Total Transferred Out"',
    primaryValue: 'Total Transferred Out',
    stringKey: 'totalTransferredOut',
  },
  TOTALS: {
    description: 'label on the Payments page for "Totals"',
    primaryValue: 'Totals',
    stringKey: 'totals',
  },
  ARIA_TRANS_DETAILS: {
    description: 'aria label on the Payments page for the "transactions details" table',
    primaryValue: 'Transactions details',
    stringKey: 'ariaTransDetails',
  },
  ARIA_TRANS_TOTALS: {
    description: 'aria label on the Payments page for the "transactions totals" table',
    primaryValue: 'Transactions totals',
    stringKey: 'ariaTransTotals',
  },
  TYPE: {
    description: 'label on the Payments page for "Type"',
    primaryValue: 'Type',
    stringKey: 'type',
  },
  ADD_PAYMENT: {
    description: 'label for a button that adds a payment method to an order',
    primaryValue: 'ADD PAYMENT METHOD',
    stringKey: 'addPaymentMethod',
  },
  SUBMIT_PAYMENT: {
    description: 'label for button to submit a payment for an order',
    primaryValue: 'SUBMIT PAYMENT',
    stringKey: 'submitPayment',
  },
  REFUND: {
    description: 'title for refunds section of payments tab',
    primaryValue: 'Refund',
    stringKey: 'refund',
  },
  REFUNDED_TO: {
    description: 'label for refunded to on payments tab ',
    primaryValue: 'Refunded to',
    stringKey: 'refundedTo',
  },
  NEW_GC: {
    description: 'label for refund to new gift card on payments tab',
    primaryValue: 'New Gift Card',
    stringKey: 'newGiftCard',
  },
  OG_PAYMENT: {
    description: 'label for refund to original payment type on payments tab',
    primaryValue: 'Original Payment Method',
    stringKey: 'originalPaymentMethod',
  },
};

export default translations;
