import { AthleteContext } from '../../../store/contexts/athleteContext';
import Typography from '@material-ui/core/Typography';
import { NikeI18nContext } from '@nike/i18n-react';
import translations from './welcomeMessage.i18n';
import mapValues from 'lodash/mapValues';
import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core';

export default function WelcomeMessage() {
  const [athleteInfo] = useContext(AthleteContext);
  const { i18nString } = useContext(NikeI18nContext);
  const classes = useStyles();
  const { WELCOME_BACK } = mapValues(translations, i18nString);

  return (
    <Typography variant='h1' className={classes.welcomeMessage}>
      {WELCOME_BACK}, {athleteInfo.name}!
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  welcomeMessage: {
    fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif',
    fontSize: '1.5rem',
    marginLeft: '8px',
    marginTop: '5px',
    textAlign: 'left',
    width: '100%',
  },
}));
