import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useLazyQuery } from 'react-apollo';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/index';
import Table from '@material-ui/core/Table';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import translations from './emails.i18n';
import useSnacks from '../../../../hooks/useSnacks';
import BasicTableRow from '../../../shared/table/tableRow';
import NOTIFICATION_SUMMARY_QUERY from '../../../../queries/notificationSummary.query';
import { transformEmailData } from '../../../../utils/email';

const Emails = ({ orderId }) => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const { setError } = useSnacks();
  const location = useLocation();
  let history = useHistory();
  const [orderEmails, setOrderEmails] = useState(null);

  const {
    ARIA_EMAILS,
    ARIA_EMAIL_LOADING,
    CATEGORY,
    DATE,
    EMAILS,
    NO_EMAILS,
    ORDER_EMAILS_ERROR,
    RESEND,
    SENT_BY,
    SENT_TO,
    TYPE,
  } = mapValues(translations, i18nString);

  const [fetchOrderEmails, { data, loading, error }] = useLazyQuery(NOTIFICATION_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      if (!error?.message?.includes('404')) {
        console.error('Order Emails:', error);
        setError(`${ORDER_EMAILS_ERROR}: ${error.message}`);
      } else {
        setOrderEmails([]);
      }
    },
    onCompleted: () => {
      const emails = data?.orderNotificationSummary?.email;
      setOrderEmails(emails);
    },
  });

  const viewEmailDetails = (emailId) => {
    history.push(`/order/${orderId}/emails/${emailId}`);
  };

  useEffect(() => {
    if (location.pathname.includes('/emails') && !orderEmails) {
      fetchOrderEmails({
        variables: { orderNumber: orderId },
      });
    }
  }, [location.pathname]);

  const emailsHeaders = [DATE, SENT_BY, SENT_TO, CATEGORY, TYPE];

  return (
    <Card className={classes.cardSpacing} elevation={3}>
      <CardContent className={classes.cardContent}>
        <Toolbar variant={'dense'} className={classes.toolbar} disableGutters={true}>
          <CardHeader
            disableTypography
            classes={{ root: classes.cardHeaderRoot }}
            title={<h1 className={classes.cardHeading}>{EMAILS}</h1>}
          />
        </Toolbar>
        {loading && <CircularProgress aria-label={ARIA_EMAIL_LOADING} />}
        {!loading && Array.isArray(orderEmails) && (
          <Table aria-label={ARIA_EMAILS} data-testid={'emails-table'}>
            {orderEmails.length > 0 ? (
              <BasicTableRow header data={emailsHeaders} cellClassName={classes.tableHeader} />
            ) : (
              <BasicTableRow
                data={[
                  <Typography
                    variant='subtitle1'
                    id='noEmails'
                    className={classes.noResults}
                    data-testid={'no-results-message'}>
                    {NO_EMAILS}
                  </Typography>,
                ]}
                cellClassName={classes.tableHeader}
              />
            )}
            {orderEmails.map((email, i) => (
              <BasicTableRow
                key={i}
                data={transformEmailData(email, RESEND)}
                rowClassName={classes.tableRow}
                action={() => viewEmailDetails(orderEmails[i].request_id)}
              />
            ))}
          </Table>
        )}
      </CardContent>
    </Card>
  );
};

Emails.propTypes = {
  orderId: PropTypes.string.isRequired,
};

const useStyles = makeStyles((theme) => ({
  cardHeaderRoot: {
    paddingLeft: theme.spacing(1),
  },
  cardHeading: {
    margin: 0,
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: 0,
  },
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  noResults: {
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  tableRow: {
    '&:hover': {
      background: theme.palette.grey[200],
      cursor: 'pointer',
    },
  },
  tableHeader: {
    margin: 0,
    paddingTop: 0,
    padding: theme.spacing(1.2),
  },
  toolbar: {
    justifyContent: 'space-between',
  },
}));

export default Emails;
