/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import {
  DialogTypes,
  ResponseStatuses,
  ApiTimeOut,
  TimeOutErrorMessageFromGrand,
} from '../../../../constants/dialog.const';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory, withRouter } from 'react-router-dom';
import { NikeI18nContext } from '@nike/i18n-react';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import translations from '../dialog.i18n';
import { areReasonsMissingFrom, isQuantityMissingForLineItems } from '../../../../utils/dialog';
import RETURN_LABEL_MUTATION from '../../../../mutations/returnLabel.mutation';
import RETURN_CAPTURE_MUTATION from '../../../../mutations/returnCapture.mutation';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import mapValues from 'lodash/mapValues';
import RETURN_LOCATION_MUTATION from '../../../../mutations/returnLocation.mutation';
import { stepControlSharedStyles } from '../sharedStyles';
import { removeTypeNameFromObject } from './../../../../utils/reactUtils';
import getOrderRoutes from '../../../../routes';
import useSnacks from '../../../../hooks/useSnacks';
import { SendResendReturnLabel } from './../../../../constants/permissions.const';
import useHasPermission from './../../../../hooks/useHasPermission';
import {
  generateReturnCapturePayload,
  generateReturnLocationPayload,
} from './requestPayloads/payloads.CN';

/**
 * Component to handle step actions and form submission based on dialog state
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
function StepControl() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail] = useContext(OrderContext);
  const [consumerState] = useContext(ConsumerContext);
  const [, setOrderDetails] = useContext(OrderContext);
  const [isReadyToSubmitReturnOrder, setIsReadyToSubmitReturnOrder] = useState(false);
  const [queryDetailsCounter, setQueryDetailsCounter] = useState(1);
  const { hasPermission } = useHasPermission();
  const { setSlowLoading, setSnack, setError } = useSnacks();
  const [receivingNode, setReceivingNode] = useState('');
  const [standardCarrierAlphaCode, setStandardCarrierAlphaCode] = useState('');
  const [athleteInfo] = useContext(AthleteContext);
  let history = useHistory();
  const routes = getOrderRoutes();

  const {
    prevStep,
    nextStep,
    reset,
    setReturnOrderNumber,
    setShipToAddress,
    setHasTimedOut,
  } = dialogActions;

  const {
    selectedLines,
    activeStep,
    deliveryMethod,
    dialogType,
    submissionSteps,
    returnOrderNumber,
    lock,
    hasTimedOut,
  } = dialogState;

  const {
    BACK,
    CREATE_RETURN,
    ERROR,
    NEXT,
    RETURN_CREATION_FAILED,
    RETURN_ORDER_CREATED,
    RETURN_ORDER_NOT_AVAILABLE,
    RESEND_RETURN_LABEL,
    RETURN_LOCATION_FAILED,
    CREATE_RETURN_TIME_OUT_ERROR_MESSAGE,
    SUCCESS,
  } = mapValues(translations, i18nString);

  /**
   * Determines whether the next button, which are displayed on non-submission pages,
   * should be disabled or not based on validations.
   */
  const shouldNextButtonBeDisabled = () => {
    switch (activeStep) {
      case 0:
        return selectedItemKeys.length === 0;
      case 1:
        return (
          areReasonsMissingFrom(selectedLines, DialogTypes.RETURN) ||
          isQuantityMissingForLineItems(selectedLines, DialogTypes.RETURN)
        );
      // TODO eslint-disable-next-line no-fallthrough
      default:
        return true;
    }
  };

  /**
   * Updates url to match new return order if return order has been successfully made.
   */
  const updateUrlForNewReturn = () => {
    history.push(`/order/${returnOrderNumber}/${routes[0]}`);
  };

  const [sendReturnLabelNow] = useMutation(RETURN_LABEL_MUTATION, {
    onError: (err) => {
      setError(`${RESEND_RETURN_LABEL} ${ERROR} ${err.message}`);
    },
    onCompleted: () => {
      // todo - do something more appropriate when the call isn't timing out?
      dispatchReset();
      setSnack(`${SUCCESS}`);
    },
  });

  /**
   * Creates a query used to retrieve the OrderDetails that can be executed at a
   * different time. This is used for the retry calls in the event that the return
   * order has been created, but is not yet retrievable.
   */
  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: queryOrderDetailsError },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    onError: () => {
      if (queryDetailsCounter === 10) {
        setError(RETURN_ORDER_NOT_AVAILABLE);
        dispatchReset();
      } else {
        setQueryDetailsCounter(queryDetailsCounter + 1);
      }
    },
    onCompleted: () => {
      // Call Order details on successful inspection
      setOrderDetails(queryOrderDetailsData.orderDetail);
      dispatchReset();
      setSnack(RETURN_ORDER_CREATED);
      updateUrlForNewReturn();
    },
  });

  // Generate the function to execute the return location mutation
  const [submitReturnLocation] = useMutation(RETURN_LOCATION_MUTATION, {
    onError: (err) => {
      setError(RETURN_LOCATION_FAILED);
    },
    onCompleted: (response) => {
      setReceivingNode(response.returnLocation.receivingNode);
      setStandardCarrierAlphaCode(response.returnLocation.standardCarrierAlphaCode);
      // Update the shipTo field for any order except those from NIKEEUROPE.
      const { address, recipient } = response.returnLocation.shipTo;
      const shipTo = {
        address: {
          ...removeTypeNameFromObject(address),
        },
        company: recipient.company,
      };
      dialogDispatch(setShipToAddress(shipTo));
      setIsReadyToSubmitReturnOrder(true);
    },
  });

  // Generate the function to execute the return captures mutation.
  const [submitReturnOrder] = useMutation(RETURN_CAPTURE_MUTATION, {
    onError: (err) => {
      // Logging the error message so we do not lose track of it.
      console.error('Return Capture Error', err);
      if (err.message === TimeOutErrorMessageFromGrand) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else {
        // If the return order submission fails, we want to display a readable error and go back to the order screen.
        setError(RETURN_CREATION_FAILED);
      }
      dispatchReset();
    },
    onCompleted: (response) => {
      const { createReturn } = response;
      if ((createReturn.status = ResponseStatuses.COMPLETED && createReturn.response)) {
        if (createReturn.response.returnOrderNumber) {
          dialogDispatch(setReturnOrderNumber(createReturn.response.returnOrderNumber));
          // if display is locked, reset dialog, otherwise query order details + close dialog
          if (lock) {
            dialogDispatch(dialogActions.open(DialogTypes.ACTION_COMPLETE, true));
            setSnack(RETURN_ORDER_CREATED);
          } else {
            queryOrderDetails({
              variables: {
                orderNumber: createReturn.response.returnOrderNumber,
              },
            });
          }
        }
      } else if (
        createReturn.status === ResponseStatuses.IN_PROGRESS ||
        createReturn.status === ResponseStatuses.PENDING
      ) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (createReturn.error) {
        const errorMessageOnJobCompletion = `${createReturn.error.httpStatus}: ${createReturn.error.message}`;
        setError(errorMessageOnJobCompletion);
        dispatchReset();
      }
    },
  });

  // Need to wait for shipTo address to be populated in state before we can request a return
  useEffect(() => {
    if (isReadyToSubmitReturnOrder) {
      const returnCapturesInput = generateReturnCapturePayload(
        dialogState,
        consumerState,
        orderDetail,
        hasPermission(SendResendReturnLabel),
        receivingNode,
        standardCarrierAlphaCode,
        athleteInfo
      );
      submitReturnOrder({
        variables: {
          input: returnCapturesInput,
          region: orderDetail.omsRegionReference,
          timeout: ApiTimeOut,
        },
      });
    }
  }, [isReadyToSubmitReturnOrder]);

  /**
   * This useEffect contains the retry logic, for retrieving the order details after a successful
   * call to the return captures service. The retry conditions is that it will wait 1 second
   * between calls and it will retry up to 10 times.
   */
  useEffect(() => {
    if (
      queryDetailsCounter >= 0 &&
      queryDetailsCounter < 10 &&
      !queryOrderDetailsData &&
      queryOrderDetailsError
    ) {
      setTimeout(function() {
        queryOrderDetails({
          variables: {
            orderNumber: returnOrderNumber,
          },
        });
      }, 1500);
    }
  }, [queryOrderDetailsError]);

  const dispatchReset = () => {
    !lock && dialogDispatch(reset());
  };

  // Determines if we are on a submission step
  const determineIfSubmissionStep = () => {
    if (submissionSteps.includes(activeStep)) {
      if (dialogType === DialogTypes.RESEND_RETURN_LABEL) {
        return true;
      } else if (activeStep === 2) {
        return true;
      }
    }
    return false;
  };

  const isSubmissionStep = determineIfSubmissionStep();
  const selectedItemKeys = Object.keys(selectedLines);

  /**
   * Handles the submission of the createReturn mutation.
   */
  function handleReturnOrderSubmit() {
    const returnLocationInput = generateReturnLocationPayload(
      dialogState,
      consumerState,
      orderDetail
    );

    submitReturnLocation({ variables: { input: returnLocationInput } });
    setSlowLoading();

    /*
     * We need to wait for state to update - see useEffect for the submitReturnOrder call
     * which watches for dialogueState.shipTo address to update
     */
  }

  const handleNext = () => {
    dialogDispatch(nextStep());
  };

  const BackButton = () => (
    <Button
      disabled={activeStep === 0}
      aria-label={BACK.toLowerCase()}
      data-testid='return-back-button'
      onClick={() => dialogDispatch(prevStep())}
      classes={{
        root: classes.stepperButton,
        disabled: classes.backDisabled,
      }}>
      {BACK}
    </Button>
  );

  const NextButton = () => (
    <Button
      disabled={shouldNextButtonBeDisabled()}
      variant='contained'
      color='primary'
      data-testid='return-next-button'
      onClick={() => handleNext()}
      aria-label={NEXT.toLowerCase()}
      classes={{
        root: classes.stepperButton,
        disabled: classes.nextDisabled,
      }}>
      {NEXT}
    </Button>
  );

  const SubmitReturnCaptureButton = () => {
    return (
      <Button
        variant='contained'
        color='primary'
        type='submit'
        data-testid='return-submit-button'
        disabled={hasTimedOut}
        aria-label={CREATE_RETURN.toLowerCase()}
        onClick={() => handleReturnOrderSubmit()}
        classes={{
          root: classes.stepperButton,
          disabled: classes.nextDisabled,
        }}>
        {CREATE_RETURN}
      </Button>
    );
  };

  const ResendReturnLabelButton = () => (
    <Button
      variant='contained'
      color='primary'
      type='submit'
      aria-label={RESEND_RETURN_LABEL.toLowerCase()}
      data-testid='resend-return-label-button'
      onClick={() => {
        const resendReturnLabelInput = {
          returnOrderNumber: orderDetail.orderNumber,
          dispatchType: String(deliveryMethod).toUpperCase(),
        };
        sendReturnLabelNow({
          variables: {
            input: resendReturnLabelInput,
          },
        });
        setSlowLoading();
      }}
      classes={{
        root: classes.stepperButton,
        disabled: classes.nextDisabled,
      }}>
      {RESEND_RETURN_LABEL.toLowerCase()}
    </Button>
  );

  // action buttons, depending on dialog type
  const SubmissionButton = () => {
    switch (dialogType) {
      case DialogTypes.RETURN: {
        return <SubmitReturnCaptureButton />;
      }
      case DialogTypes.RESEND_RETURN_LABEL: {
        return <ResendReturnLabelButton />;
      }
      default:
        return;
    }
  };

  if (!isSubmissionStep) {
    return (
      <div className={classes.actionsContainer}>
        <BackButton />
        <NextButton />
      </div>
    );
  } else {
    return (
      <div className={classes.actionsContainer}>
        <BackButton />
        <SubmissionButton />
      </div>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
}));

export default withRouter(StepControl);
