import React, { createContext, useState, useEffect } from 'react';
import config from '../../utils/config';
import useAuth from '../../hooks/useAuth';

export const PermissionContext = createContext([{}, () => {}]);
const { Provider } = PermissionContext;

/**
 * A basic provider for a context with only a single value
 *
 * @param {Object} props  – React props
 */
export const PermissionProvider = ({ children }) => {
  const [state, setState] = useState([]);
  const { oktaToken } = useAuth();

  useEffect(() => {
    const fetchPermissions = async () => {
      const response = await fetch(config.foundry.activePermissions, {
        headers: {
          authorization: oktaToken,
        },
        cors: true,
      });
      const permissions = await response.json();
      // todo handle this in the back
      const lowerCasePermissions =
        (Array.isArray(permissions) && permissions.map((permission) => permission.toLowerCase())) ||
        [];
      setState(lowerCasePermissions);
    };

    if (oktaToken) {
      fetchPermissions();
    }
  }, [oktaToken]);

  return <Provider value={[state]}>{children}</Provider>;
};

export default PermissionContext;
