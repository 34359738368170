import React, { createContext, useState } from 'react';

export const AthleteContext = createContext([{}, () => {}]);
const { Provider } = AthleteContext;

const defaultState = {
  email: '',
  name: '',
};

/**
 * A read-only context for athlete information
 *
 * @param {Object} props  – React props
 */
export const AthleteProvider = ({ children }) => {
  const [state] = useState(() => {
    let details = defaultState;
    try {
      // grab the token from local storage
      const jsonToken = window.localStorage.getItem('okta-token-storage');
      const tokenDetails = jsonToken && JSON.parse(jsonToken);
      details.email = tokenDetails?.idToken?.claims?.email;
      details.name = tokenDetails?.idToken?.claims?.name;
    } finally {
      return details;
    }
  });

  return <Provider value={[state, () => {}]}>{children}</Provider>;
};

export default AthleteContext;
