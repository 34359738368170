import { Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import React, { useContext, useEffect, useState } from 'react';
import dialogActions from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { step1SharedStyles } from '../sharedStyles';
import translations from './cancelItems.i18n';
import { getOrderLineContainers, determineIfOrderIsCancellable } from '../../../../utils/order';
import { findKeyUsingValue, swapSpots } from '../../../../utils/dialog';
import { Partners } from '../../../../constants/origin.const.js';

export default function Step1() {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { i18nString } = useContext(NikeI18nContext);
  const [frontRowMap, setFrontRowMap] = useState({});

  const { selectLine } = dialogActions;
  const { selectedLines } = dialogState;

  const { ITEM_NOT_CANCELLABLE, VAS_NOT_CANCELLABLE, BOPIS_CANCEL_ALL } = mapValues(
    translations,
    i18nString
  );
  let { orderLines, omsRegionReference } = orderDetail;
  orderLines = Array.from(orderLines);
  // Map of inline item lineNumbers to vas service lineNumbers and vice versa.
  const inlineVasLineNumberMap = {};

  /*
    Iterate through the orderLines and add them to an object where the value is a boolean that 
    represents whether an item is of a service type or not. This map will be used to determine
    which items will be in the 'front row' or 'back row' in the UI.
  */
  useEffect(() => {
    let newFrontRowMap = {};
    orderLines.forEach((line) => {
      if (line.orderLineType !== 'SERVICE') {
        newFrontRowMap[line.lineNumber] = true;
      } else {
        newFrontRowMap[line.lineNumber] = false;
      }
    });
    setFrontRowMap(newFrontRowMap);
  }, []);

  /*
    If BOPIS order, iterates through the orderLines and adds cancellable items to selected lines
    because for BOPIS all items in the order must be cancelled.
  */
  useEffect(() => {
    if (orderDetail.omoboFlags.isBOPIS) {
      /*
      select line action acts as a toggle, so when we move from step 2 to step 1 it unselects the
      selected line items. To avoid this, we are dispatching selectLine only when selected
      lines are empty
      */
      if (Object.keys(dialogState.selectedLines).length === 0) {
        orderLines.forEach((ol) => {
          if (Boolean(ol.cancellableQuantity)) {
            dialogDispatch(selectLine(ol));
          }
        });
      }
    }
  }, [orderDetail]);

  /**
   * Handles the onClick events for items that selectable.
   * @param {object} line orderLine item the action is being executed against.
   */
  const handleSelectLine = (line, isVasItem) => () => {
    if (!orderDetail.omoboFlags.isBOPIS) {
      if (frontRowMap[line.lineNumber]) {
        // Item is already in the front, so it is being selected.
        dialogDispatch(selectLine(line));
      } else {
        // Item is not in the front and needs to be brought forth.
        const newFrontRowMap = swapSpots(
          frontRowMap,
          inlineVasLineNumberMap,
          line.lineNumber,
          isVasItem
        );
        setFrontRowMap(newFrontRowMap);
      }
    }
  };

  /**
   * Handles the onClick events for items that are not selectable.
   * @param {string} lineNumber unique identifier of the orderline being chosen.
   * @param {boolean} isVasItem boolean representing if an item is a vas item.
   */
  const unselectableOnClick = (lineNumber, isVasItem) => {
    const regularItemInBackRow = !isVasItem && !frontRowMap[lineNumber];
    const vasItemWithAppropriateReferences =
      isVasItem && findKeyUsingValue(inlineVasLineNumberMap, lineNumber);
    if (regularItemInBackRow || vasItemWithAppropriateReferences) {
      const newFrontRowMap = swapSpots(frontRowMap, inlineVasLineNumberMap, lineNumber, isVasItem);
      setFrontRowMap(newFrontRowMap);
    }
  };

  const wrapEachCard = (line, i) => {
    const isCancellable =
      Boolean(line.cancellableQuantity) && determineIfOrderIsCancellable(orderDetail);
    const isVasItem = line.orderLineType === 'SERVICE';
    const divKey = `return-item-thumb-${i}`;
    let cardClassName = !isVasItem ? classes.firstRowItem : classes.secondRowItem;
    // If the item belongs in the front, attach the primaryItem styling.
    if (frontRowMap[line.lineNumber]) {
      cardClassName = `${cardClassName} ${classes.primaryItem}`;
    }

    // if the item is cancellable, card is selectable
    if (isCancellable && !Partners[orderDetail?.channel]) {
      return (
        <div key={divKey} className={cardClassName}>
          <div
            role='checkbox'
            aria-checked={Boolean(selectedLines[line.lineNumber])}
            aria-label={line.item.itemDescription}
            onKeyPress={handleSelectLine(line, isVasItem)}
            onClick={handleSelectLine(line, isVasItem)}
            tabIndex={0}>
            <Card
              className={selectedLines[line.lineNumber] ? classes.selectedItem : classes.item}
              elevation={selectedLines[line.lineNumber] ? 3 : 1}
              data-testid={`item-to-select-${i}`}>
              <CardMedia
                className={classes.productThumb}
                image={getProductImageFromOrderLine(line)}
                title={line.item.itemDescription}
                children={
                  selectedLines[line.lineNumber] && (
                    <CheckIcon className={classes.productSelectCheckIcon} />
                  )
                }
              />
              <Box p={1}>
                <Typography noWrap gutterBottom>
                  {line.item.itemDescription}
                </Typography>
                <Typography noWrap variant='caption'>
                  {line.styleNumber + '-' + line.colorCode}
                </Typography>
              </Box>
            </Card>
          </div>
        </div>
      );
    } else {
      // if an item is not returnable, card's unselectable w/ tooltip
      const toolTipMessage = isVasItem ? VAS_NOT_CANCELLABLE : ITEM_NOT_CANCELLABLE;
      return (
        <div
          key={divKey}
          className={cardClassName}
          role='checkbox'
          aria-checked={false}
          aria-disabled={true}
          aria-label={line.item.itemDescription}
          tabIndex={0}
          onKeyPress={() => unselectableOnClick(line.lineNumber, isVasItem)}
          onClick={() => unselectableOnClick(line.lineNumber, isVasItem)}>
          <Tooltip
            title={toolTipMessage}
            placement='bottom'
            enterDelay={200}
            leaveDelay={100}
            classes={{ tooltip: classes.tooltip }}>
            <Card
              className={classes.unselectableItem}
              elevation={0}
              data-testid={`unselectable-item-${i}`}>
              <CardMedia
                className={classes.disabledProductThumb}
                image={getProductImageFromOrderLine(line)}
                title={line.item.itemDescription}
              />
              <Box p={1}>
                <Typography variant='body1' className={classes.halfOpacity} gutterBottom>
                  {line.item.itemDescription}
                </Typography>
                <Typography variant='caption' className={classes.halfOpacity}>
                  {line.styleNumber + '-' + line.colorCode}
                </Typography>
              </Box>
            </Card>
          </Tooltip>
        </div>
      );
    }
  };

  /**
   * Create a container with the appropriate className depending on whether it is a single item
   * or an item that has a VAS associated with it.
   * @param {string} key Unique identifer of the div
   * @param {React.Component} children Children objects to be displayed within this container.
   */
  const createContainer = (key, ...children) => {
    return (
      <div
        key={`'return-item-container-thumb-'${key}-container`}
        className={children.length > 1 ? classes.vasItemContainer : classes.singleItemContainer}>
        {children}
      </div>
    );
  };

  /**
   * Wraps each of the items that belong together. This will wrap vas items with their
   * item counterparts. This allows the vas items to be shown behind their counterparts
   * and to do the switching to make them visible when clicked.
   * @param {array} orderLines list of orderline items.
   */
  const createOrderLineContainers = (orderLines, omsRegionReference) => {
    const orderLineContainers = getOrderLineContainers(orderLines, omsRegionReference);
    const wrappedOrderLineContainers = [];

    for (let i = 0; i < orderLineContainers.length; i++) {
      const container = orderLineContainers[i];
      const WrappedInlineItem = wrapEachCard(container.item, `${i}a`);

      if (container.service) {
        const wrappedServiceItem = wrapEachCard(container.service, `serviceItem${i}`);
        inlineVasLineNumberMap[container.item.lineNumber] = container.service.lineNumber; // TODO: local ref.
        wrappedOrderLineContainers.push(createContainer(i, WrappedInlineItem, wrappedServiceItem));
      } else {
        // Inline item is standalone with no other references.
        wrappedOrderLineContainers.push(createContainer(i, WrappedInlineItem));
        // Added using an empty string to signal that there is no second row ui item.
        inlineVasLineNumberMap[container.item.lineNumber] = '';
      }
    }

    return wrappedOrderLineContainers;
  };

  const orderLineContainers = createOrderLineContainers(orderLines, omsRegionReference);

  return (
    <>
      <div className={classes.productGrid}>
        {orderLineContainers.map((line) => {
          return line;
        })}
      </div>
      <Typography paragraph variant='subtitle1' className={classes.selectItemsMessage}>
        {orderDetail.omoboFlags.isBOPIS ? BOPIS_CANCEL_ALL : ''}
      </Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  ...step1SharedStyles(theme),
}));
