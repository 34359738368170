import { formatAddress } from './addressFormatting';
import {
  canShippingBeDiscounted,
  determineIfOrderIsCancellable,
  determineIfOrderIsInspectable,
  determineIfOrderIsReInspectable,
  determineIfOrderIsReturnable,
  isOrderLineReturnable,
  isVASOrderLineReturnable,
  getCharges,
  getDiscount,
  getOrderLineParentLineNumber,
  getTax,
} from './order';
import {
  isBOPISOrder,
  isBopisReturnOrder,
  isPickUpPointOrder,
  isShipToStoreOrder,
} from './orderDetailFulfillmentScenarios';
import {
  isBOPISOrderLine,
  isPickUpPointOrderLine,
  isShipToStoreOrderLine,
} from './orderLineFulfillmentScenarios';
import getArrayProperty from './getArrayProperty';

/**
 * Decorates the orderDetail with information derived from it, to simplify the logic needed to
 * display it or to run through the various dialogs.  The decoration includes the nesting of VAS
 * orderLines under their respective regular orderLines, and setting of various flags such as
 * isReturnable, isCancellable, and isBOPISOrder.
 *
 * This function should be called once and only once, as soon as the orderDetail is received from
 * the order detail service.
 *
 * @param orderDetail {object} - The orderDetail to decorate.
 */
export const decorateOrderDetail = (orderDetail) => {
  /*
  OrderDetails that follow the v1 schema do not have an orderVersion field.  Those that follow the
  v2 schema DO have an orderVersion field, with a value of 'v2'. Here we will identify orderDetails
  with no orderVersion field as v1 schemas, and then set their orderVersion to 'v1' so that any
  following logic that depends on the schema version will read more clearly.
   */
  if (!orderDetail.orderVersion) {
    orderDetail.orderVersion = 'v1';
  }

  let { orderLines } = orderDetail;
  const isReturnOrder = orderDetail?.orderType === 'RETURN_ORDER';

  // Create an omoboFlags object at the top level to hold flags for the orderDetail.
  orderDetail.omoboFlags = {};

  if (orderDetail?.status.includes('Partially')) {
    // if orderLine has different statuses for different qtys, split them up accordingly
    orderLines.forEach((orderLine) => {
      // get the length of the statuses array
      const statusesLength = orderLine.statuses.length;
      if (statusesLength > 1) {
        // determine the sum of the status quantities
        const sumOfQtys = orderLine.statuses.reduce((acc, ele) => {
          acc += ele.quantity;
          return acc;
        }, 0);

        /*
        per Athena & Docs, orderLine quantity should always match
        the sum of quantities for each status.
        */
        if (orderLine.quantity === sumOfQtys) {
          // iterate over statuses, beginning with the 2nd object in the array
          for (let i = 1; i < statusesLength; i++) {
            // for each status object, create an orderLine reflective of said status and its qty.
            const newOrderLine = {
              ...orderLine,
              quantity: orderLine.statuses[i].quantity,
              statuses: [orderLine.statuses[i]],
              orderLineKey: `${orderLine.orderLineKey}-${i}`,
            };
            orderLines.push(newOrderLine);
          }
          /* reset the quantity/status values for the current orderLine, since we abstracted
          each status into a new synthetic orderLine */
          orderLine.quantity = orderLine.statuses[0].quantity;
          orderLine.statuses = [orderLine.statuses[0]];
        }
      }
    });
  }
  /*
  Create an omoboFlags object for each orderLine.  Also set the orderVersion and geo for each
  orderLine, so that the parent orderDetail need not be referenced for the orderVersion or geo.
  Also format the addresses in each orderLine for consistency.
   */
  for (let orderLine of orderLines) {
    orderLine.omoboFlags = {};
    orderLine.orderVersion = orderDetail.orderVersion;
    orderLine.geo = orderDetail.omsRegionReference;
    formatOrderLineAddresses(orderLine);
  }
  if (isReturnOrder) {
    orderDetail.geo = orderDetail.omsRegionReference;
  }

  // Format all addresses on the orderDetail besides the orderLines.
  formatShipmentsAddresses(orderDetail);
  formatPaymentMethodsAddresses(orderDetail);
  formatAddress(orderDetail.billTo);
  formatAddress(orderDetail.shipTo);

  // Nest the VAS orderLines under their corresponding regular orderLines.
  let regularOrderLines = orderLines.filter((orderLine) => {
    return orderLine.orderLineType !== 'SERVICE';
  });

  let vasOrderLines = orderLines.filter((orderLine) => {
    return orderLine.orderLineType === 'SERVICE';
  });
  nestVasOrderLines(regularOrderLines, vasOrderLines);

  // Set the flags on each orderLine to mark if they are returnable, cancellable, etc.
  for (let regularOrderLine of regularOrderLines) {
    setFlagsOnRegularOrderLine(regularOrderLine, orderDetail);
    for (let vasOrderLine of regularOrderLine.nestedVasOrderLines) {
      setFlagsOnVASOrderLine(vasOrderLine, orderDetail);
    }
  }

  // Now that the flags on the orderLines are set, set the flags on the orderDetail.
  orderDetail.omoboFlags.isReturnable = determineIfOrderIsReturnable(orderDetail);
  orderDetail.omoboFlags.isCancellable = determineIfOrderIsCancellable(orderDetail);
  orderDetail.omoboFlags.isBOPIS = isBOPISOrder(orderDetail);
  orderDetail.omoboFlags.isShipToStore = isShipToStoreOrder(orderDetail);
  orderDetail.omoboFlags.isPickUpPoint = isPickUpPointOrder(orderDetail);
  orderDetail.omoboFlags.isBopisReturn = isBopisReturnOrder(orderDetail);
  orderDetail.omoboFlags.isDiscountShippingAllowed = canShippingBeDiscounted(orderDetail);
  // TODO: don't rely on these flags until Modify, Exchange, and Inspect functionality is complete.
  orderDetail.omoboFlags.isInspectable = determineIfOrderIsInspectable(orderDetail);
  orderDetail.omoboFlags.isReInspectable = determineIfOrderIsReInspectable(orderDetail);
};

/**
 * Given the regular orderLines and the VAS orderLines, nests the VAS orderLines under their
 * corresponding regular orderLines.
 *
 * @param regularOrderLines {object[]} - The regular (non-VAS) orderLines
 * @param vasOrderLines {object[]} - The VAS orderLines
 */
const nestVasOrderLines = (regularOrderLines, vasOrderLines) => {
  // Map the VAS order lines and related information to the corresponding regular order lines.
  regularOrderLines.map((regularOrderLine) => {
    // Find and nest the VAS order lines for this regular order line.
    let inlineParentLineNumber = getOrderLineParentLineNumber(regularOrderLine);
    regularOrderLine.nestedVasOrderLines = vasOrderLines.filter((vasOrderLine) => {
      let vasParentLineNumber = getOrderLineParentLineNumber(vasOrderLine);
      return (
        inlineParentLineNumber &&
        vasParentLineNumber &&
        inlineParentLineNumber === vasParentLineNumber
      );
    });

    // Record a flag to quickly identify if there are any nested VAS order lines.
    regularOrderLine.hasNestedVasOrderLines = regularOrderLine.nestedVasOrderLines.length > 0;

    // Create a reference in each nested VAS order line to its parent order line.
    if (regularOrderLine.hasNestedVasOrderLines) {
      for (let nestedVasOrderLine of regularOrderLine.nestedVasOrderLines) {
        // remove the parent orderLine's reference to the child orderLine
        nestedVasOrderLine.parentOrderLine = { ...regularOrderLine, nestedVasOrderLines: null };
      }
    }

    // Calculate the line total, which includes vasItem totals.
    const orderLineTaxes = getTax(regularOrderLine);
    const orderLineDiscounts = getDiscount(regularOrderLine);
    const orderLineCharges = getCharges(regularOrderLine);
    regularOrderLine.lineTotal =
      regularOrderLine?.linePriceInformation?.unitPrice * regularOrderLine.quantity +
      orderLineCharges +
      orderLineTaxes -
      orderLineDiscounts;
    regularOrderLine.nestedVasOrderLines.map((nestedVasOrderLine) => {
      if (nestedVasOrderLine?.linePriceInformation?.unitPrice) {
        regularOrderLine.lineTotal +=
          nestedVasOrderLine.linePriceInformation.unitPrice * nestedVasOrderLine.quantity;
      }
      return nestedVasOrderLine;
    });
    return regularOrderLine;
  });
};

/**
 * Sets flags on regular (non-VAS) orderLines.
 *
 * @param regularOrderLine {object} - The regular (non-VAS) orderLine
 * @param orderDetail {object} - The orderDetail
 */
const setFlagsOnRegularOrderLine = (regularOrderLine) => {
  regularOrderLine.omoboFlags.isBOPIS = isBOPISOrderLine(regularOrderLine);
  regularOrderLine.omoboFlags.isShipToStore = isShipToStoreOrderLine(regularOrderLine);
  regularOrderLine.omoboFlags.isPickUpPoint = isPickUpPointOrderLine(regularOrderLine);
  regularOrderLine.omoboFlags.isReturnable = isOrderLineReturnable(regularOrderLine);
};

/**
 * Sets flags on regular (non-VAS) orderLines.
 *
 * @param vasOrderLine {object} - The VAS orderLine
 * @param orderDetail {object} - The orderDetail
 */
const setFlagsOnVASOrderLine = (vasOrderLine, orderDetail) => {
  vasOrderLine.omoboFlags.isReturnable = isVASOrderLineReturnable(vasOrderLine);
};

/**
 * Formats the addresses on each orderLine.
 *
 * @param orderLine {object} - the orderLine
 */
const formatOrderLineAddresses = (orderLine) => {
  if (orderLine?.shipTo?.address) {
    formatAddress(orderLine.shipTo.address);
  }
};

/**
 * Formats the addresses on each paymentMethod of the orderDetail.
 *
 * @param orderDetail {object} - the orderDetail containing the paymentMethods
 */
const formatPaymentMethodsAddresses = (orderDetail) => {
  getArrayProperty(orderDetail, 'paymentMethods')
    .filter((paymentMethod) => {
      return Boolean(paymentMethod?.billTo?.address);
    })
    .forEach((paymentMethod) => formatAddress(paymentMethod.billTo.address));
};

/**
 * Formats the addresses on each shipment of the orderDetail.
 *
 * @param orderDetail {object} - the orderDetail containing the shipments
 */
const formatShipmentsAddresses = (orderDetail) => {
  getArrayProperty(orderDetail?.shipments, 'objects')
    .filter((shipment) => {
      return Boolean(shipment?.shipTo);
    })
    .forEach((shipToAddress) => formatAddress(shipToAddress));
};
