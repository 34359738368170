/*
 This file determines which environment we're in, and provides the app with the appropriate config
 */
export const defaults = {
  axeEnv: false,
  imageBaseURI: 'http://images2.nike.com/is/image/DPILS/',
};

const foundryRoutes = (uri) => {
  let result = {
    uri: uri,
    get activePermissions() {
      return uri + '/permissions/v1/activePermissions';
    },
    get allPermissions() {
      return uri + '/permissions/v1/allPermissions';
    },
    get allGroups() {
      return uri + '/permissions/v1/allGroups';
    },
    get editPermissions() {
      return uri + '/permissions/v1/editPermissions';
    },
    get graphqlProxy() {
      return uri + '/graphql';
    },
  };
  return result;
};

export const dev = {
  axeEnv: true,
  foundry: foundryRoutes('http://localhost:9090'),
  cspUrl: 'https://ecn75-csr-store.nikedev.com/',
  giftCardUrl: 'https://omobo-test.nike.com/portlets/giftcard/implicit/details/',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  },
};

export const train_local = {
  foundry: foundryRoutes('http://localhost:9090'),
  mockUrl: 'http://localhost:8080/mockUrl/v1',
  giftCardUrl: 'https://omobo-test.nike.com/portlets/giftcard/implicit/details/',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  },
};

export const training = {
  foundry: foundryRoutes('https://foundry-test.nike.com'),
  mockUrl: 'https://mocks-ecs-us-west-2.forge-test.nikecloud.com/mockUrl/v1',
  giftCardUrl: 'https://omobo-test.nike.com/portlets/giftcard/implicit/info/',
  salesForceUrl: 'https://nikeconsumerservices--csstaging.lightning.force.com',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  },
};

export const test = {
  // temporarily changing this to non-akamaized endpoint in an effort to ameliorate Japan UAT issue
  axeEnv: true,
  foundry: foundryRoutes('https://foundry-test.nike.com'),
  cspUrl: 'https://ecn75-csr-store.nikedev.com/',
  giftCardUrl: 'https://omobo-test.nike.com/portlets/giftcard/implicit/info/',
  salesForceUrl: 'https://nikeconsumerservices--csstaging.lightning.force.com',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike-qa.oktapreview.com/oauth2/ausa0mcornpZLi0C40h7',
  },
};

export const dark = {
  foundry: foundryRoutes('https://foundry-dark.forge-prod.nikecloud.com'),
  cspUrl: 'https://csr-store.nike.com/',
  giftCardUrl: 'https://omobo.nike.com/portlets/giftcard/implicit/info/',
  salesForceUrl: 'https://nikeconsumerservices.lightning.force.com',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
  },
};

export const prod = {
  foundry: foundryRoutes('https://foundry.nike.com'),
  cspUrl: 'https://csr-store.nike.com/',
  giftCardUrl: 'https://omobo.nike.com/portlets/giftcard/implicit/info/',
  salesForceUrl: 'https://nikeconsumerservices.lightning.force.com',
  okta: {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
  },
};

const config = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'dev':
      return dev;
    case 'training':
      return training;
    case 'train_local':
      return train_local;
    case 'test':
      return test;
    case 'dark':
      return dark;
    default:
      return prod;
  }
};

const configObj = {
  ...defaults,
  ...config(),
};

if (window.Cypress) {
  configObj.okta = {
    clientId: 'nike.serve.omobo',
    issuer: 'https://nike.okta.com/oauth2/aus27z7p76as9Dz0H1t7',
  };
}

export default configObj;
