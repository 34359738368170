import React, { useContext, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import ListSubheader from '@material-ui/core/ListSubheader';
import { makeStyles, Typography } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import OrderSearchTable from './orderSearchTable';
import { useLazyQuery } from 'react-apollo';
import { useHistory } from 'react-router-dom';
import mapValues from 'lodash/mapValues';
import useSnacks from '../../hooks/useSnacks';
import Loading from './../shared/loading';
import translations from './orderSearch.i18n';
import { NikeI18nContext } from '@nike/i18n-react';
import { getDateByRelativeDays } from './../../utils/date';
import ORDER_SUMMARY_QUERY from './../../queries/orderSummary.query';
import searchActions from './../../store/actions/orderSearchActions';
import { SearchContext } from './../../store/contexts/orderSearchContext';
import USStates from './../../constants/address/USStates.const';
import OtherCountries from './../../constants/address/OtherCountries.const';
import EUCountries from './../../constants/address/EUCountries.const';
import CNStates from './../../constants/address/CNStates.const';
import JPStates from './../../constants/address/JPStates.const';
import { orderLineTypes } from './../../constants/order.const';
import { Partners } from './../../constants/origin.const';

const SearchForm = () => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const {
    setSearchFieldValues,
    resetSearchFieldValues,
    setSearchResults,
    setPagination,
    setAnchor,
    setShowSearchPage,
  } = searchActions;
  const {
    ADVANCED_SEARCH,
    ARIA_ADDRESS_TYPE,
    BILLING_ADDRESS,
    CLEAR_SEARCH,
    LAST_10_DAYS,
    LAST_30_DAYS,
    LAST_90_DAYS,
    LAST_120_DAYS,
    ORDER_SUMMARY_ERROR,
    ORDER_SEARCH,
    SEARCH_BUTTON,
    SEARCH_CITY,
    SEARCH_COUNTRY,
    SEARCH_DATE_RANGE,
    SEARCH_EMAIL,
    SEARCH_FIRST_NAME,
    SEARCH_GIFT_CARD,
    SEARCH_LAST_NAME,
    SEARCH_ORDER_NUMBER,
    SEARCH_ORDER_TYPE,
    SEARCH_PARTNER,
    SEARCH_RESULTS,
    SEARCH_SAP_ORDER_NUMBER,
    SEARCH_STATE,
    SEARCH_UPC,
    SEARCH_STYLE_NUMBER,
    SEARCH_ZIP,
    SHIPPING_ADDRESS,
    TODAY,
  } = mapValues(translations, i18nString);

  let history = useHistory();
  const { setError } = useSnacks();
  const [searchState, searchDispatch] = useContext(SearchContext);

  // Manage which search menu is displayed with local state.
  const [advancedOpen, setAdvancedOpen] = useState(false);
  // Store relative iso date-time strings for date-range select box.
  const [dateRangeOptions] = useState({
    [TODAY]: getDateByRelativeDays(0),
    [LAST_10_DAYS]: getDateByRelativeDays(10),
    [LAST_30_DAYS]: getDateByRelativeDays(30),
    [LAST_90_DAYS]: getDateByRelativeDays(90),
    [LAST_120_DAYS]: getDateByRelativeDays(120),
  });

  // Call to order summary api
  const [queryOrderSummary, { data, loading, error }] = useLazyQuery(ORDER_SUMMARY_QUERY, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      setError(`${ORDER_SUMMARY_ERROR} ${error.message}`);
    },
    onCompleted: () => {
      const { csOrderSummary } = data;
      searchDispatch(setSearchResults(csOrderSummary.objects));
      searchDispatch(setPagination(csOrderSummary.pages));

      if (csOrderSummary.objects.length === 1) {
        const orderNo = csOrderSummary.objects[0].orderNumber;
        searchDispatch(setShowSearchPage(false));
        history.push(`/order/${orderNo}/details`);
      }
    },
  });

  /**
   * Prepare search fields for query.
   *
   * @return {array} array of filters objects with key and value properties.
   */
  function prepSearchFilters() {
    // Extract searchFields from searchState in order to query based on user input.
    const newSearchFields = { ...searchState.searchFields };
    /* In order to pass the appropriate filter keys for address values, 
    we remove the address object from our filters object, and iterate over
    the address values in searchState setting new key names as we add the
    values to the filters argument for the GraphQL query. */
    delete newSearchFields.address;
    const filters = [];
    for (const fieldName in newSearchFields) {
      if (newSearchFields[fieldName]) {
        filters.push({
          key: fieldName,
          value: newSearchFields[fieldName],
        });
      }
    }
    const { address } = searchState.searchFields;
    for (const addressKey in address) {
      if (address[addressKey]) {
        /**
         * Using the getAddressFilterKey utility, we add the address value to the search query
         * renaming the key based on the address type selected by the user. For example, if the
         * key the user selected billing address and the key is 'city', then the key gets set here
         * as 'billTo.address.city'. If it were a shipping address, the key would get set as
         * 'orderLines.shipTo.address.city'.
         */
        filters.push({
          key: getAddressFilterKey(filters.addressIsBilling, addressKey),
          value: address[addressKey],
        });
      }
    }

    // Remove the addressIsBilling filter object prior to utilizing current filters.
    return filters.filter((filter) => filter.key !== 'addressIsBilling');
  }

  /**
   * Function that handles clicking submit button or pressing enter key
   * @param {*} event - JS event on clicking submit or pressing enter key
   */
  const handleSubmit = (event) => {
    /* Prevent submit if values have been added to address fields,
    but an address type has not been selected */
    if (!isFormReadyToSubmit()) {
      return;
    }
    searchDispatch(setAnchor(0));

    const filters = prepSearchFilters();

    if (event.key === 'Enter' || !event.key) {
      queryOrderSummary({
        variables: {
          filters,
          anchor: searchState.anchor || 0,
        },
      });
    }
  };

  /**
   * Based on the link that is passed, calls order summary api
   * @param {*} link - Prev or Next link
   */
  function handleSearchNavigation(link) {
    let updatedAnchor = '';
    if (link === 'prev') {
      updatedAnchor = searchState.anchor - 100;
      searchDispatch(setAnchor(searchState.anchor - 100));
    } else {
      updatedAnchor = searchState.anchor + 100;
      searchDispatch(setAnchor(searchState.anchor + 100));
    }
    const filters = prepSearchFilters();
    queryOrderSummary({
      variables: {
        filters,
        anchor: updatedAnchor || 0,
      },
    });
  }

  /**
   * Handle toggling basic/advanced search
   */
  function toggleAdvancedSearch() {
    setAdvancedOpen(!advancedOpen);
    searchDispatch(resetSearchFieldValues());
  }

  /**
   * Return appropriate searchField key for address data based on
   * whether or not the current selection is for billing/shipping address
   *
   * @param {bool} isBilling - Is current selection for billing address.
   * @param {string} id - ID of input for which key will be returned.
   * @return {string} key
   */
  function getAddressFilterKey(isBilling, id) {
    let key;
    switch (id) {
      case 'firstName':
        key = isBilling ? 'billTo.recipient.firstName' : 'orderLines.shipTo.recipient.firstName';
        break;
      case 'lastName':
        key = isBilling ? 'billTo.recipient.lastName' : 'orderLines.shipTo.recipient.lastName';
        break;
      case 'city':
        key = isBilling ? 'billTo.address.city' : 'orderLines.shipTo.address.city';
        break;
      case 'state':
        key = isBilling ? 'billTo.address.state' : 'orderLines.shipTo.address.state';
        break;
      case 'country':
        key = isBilling ? 'billTo.address.country' : 'orderLines.shipTo.address.country';
        break;
      case 'zipCode':
        key = isBilling ? 'billTo.address.zipCode' : 'orderLines.shipTo.address.zipCode';
        break;
      default:
        break;
    }
    return key;
  }

  /**
   * Check to see if the form is ready to submit based on values entered at the time
   */
  function isFormReadyToSubmit() {
    if (!advancedOpen) {
      return (
        searchState.searchFields.orderNumber ||
        searchState.searchFields['billTo.contactInformation.email']
      );
    }
    const filters = searchState.searchFields;

    // filter out addressIsBilling field
    const filterOutAddressBilling = Object.fromEntries(
      Object.entries(filters).filter(([key, value]) => key !== 'addressIsBilling')
    );
    // If user has entered a postal code, then a country selection is required.
    if (filterOutAddressBilling.address.zipCode) {
      return filterOutAddressBilling.address.country ? true : false;
    }
    /* If user has selected one of either product type or date range, 
    then prevent submit until BOTH are selected. */
    if (
      filterOutAddressBilling['orderLines.orderLineType'] ||
      filterOutAddressBilling.orderSubmitDateAfter
    ) {
      return (
        filterOutAddressBilling['orderLines.orderLineType'] &&
        filterOutAddressBilling.orderSubmitDateAfter
      );
    }
    return Object.values(filterOutAddressBilling).some((filterVal) => {
      if (typeof filterVal === 'object') {
        return Object.values(filterVal).some((nestedVal) => {
          return nestedVal !== '';
        });
      }
      return filterVal !== '';
    });
  }

  const clearSearch = () => {
    searchDispatch(resetSearchFieldValues());
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.searchFormPaper} variant='outlined' square>
        <Typography className={classes.searchFormHeading} variant='h2'>
          {ORDER_SEARCH}
        </Typography>
        <form className={classes.searchForm} id='search-form' noValidate autoComplete='off'>
          <Collapse in={!advancedOpen}>
            <TextField
              className={classes.searchFields}
              id='orderNumber'
              data-testid={'search-order-number'}
              value={searchState.searchFields.orderNumber || ''}
              label={SEARCH_ORDER_NUMBER}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={Boolean(searchState.searchFields['billTo.contactInformation.email'])}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    orderNumber: event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='email'
              data-testid={'search-email'}
              value={searchState.searchFields['billTo.contactInformation.email'] || ''}
              label={SEARCH_EMAIL}
              onKeyPress={(event) => handleSubmit(event)}
              disabled={Boolean(searchState.searchFields.orderNumber)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'billTo.contactInformation.email': event.target.value,
                  })
                );
              }}
            />
          </Collapse>
          <Collapse in={advancedOpen}>
            <TextField
              className={classes.searchFields}
              id='sapOrderNumber'
              data-testid={'search-sap'}
              value={searchState.searchFields['orderLines.statuses.contractNumber'] || ''}
              label={SEARCH_SAP_ORDER_NUMBER}
              onKeyPress={(event) => handleSubmit(event)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'orderLines.statuses.contractNumber': event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='channel'
              data-testid={'search-partner'}
              value={searchState.searchFields.channel || ''}
              label={SEARCH_PARTNER}
              onKeyPress={(event) => handleSubmit(event)}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    channel: event.target.value,
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              {Object.entries(Partners).map(([channel, label], index) => (
                <MenuItem value={channel} key={`${channel}-${index}`}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='giftCardNumber'
              data-testid={'search-gift-card'}
              value={searchState.searchFields['paymentMethods.displayCardNumber'] || ''}
              label={SEARCH_GIFT_CARD}
              onKeyPress={(event) => handleSubmit(event)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    // If user enters value for gift card number, we set paymentType
                    // to 'GIFT_CERTIFICATE', otherwise we leave it blank.
                    'paymentMethods.paymentType': event.target.value ? 'GIFT_CERTIFICATE' : '',
                    'paymentMethods.displayCardNumber': event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='firstName'
              data-testid={'search-first-name'}
              value={searchState.searchFields.address.firstName || ''}
              label={SEARCH_FIRST_NAME}
              onKeyPress={(event) => handleSubmit(event)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      firstName: event.target.value,
                    },
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='lastName'
              data-testid={'search-last-name'}
              value={searchState.searchFields.address.lastName || ''}
              label={SEARCH_LAST_NAME}
              onKeyPress={(event) => handleSubmit(event)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      lastName: event.target.value,
                    },
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='city'
              data-testid={'search-city'}
              value={searchState.searchFields.address.city || ''}
              label={SEARCH_CITY}
              onKeyPress={(event) => handleSubmit(event)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      city: event.target.value,
                    },
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='state'
              data-testid={'search-state'}
              value={searchState.searchFields.address.state || ''}
              label={SEARCH_STATE}
              onKeyPress={(event) => handleSubmit(event)}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      state: event.target.value,
                    },
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              <ListSubheader className={classes.listSubheader}>U.S.</ListSubheader>
              {USStates.map((state, key) => (
                <MenuItem data-key='state' value={state.abbreviation} key={key}>
                  {state.name}
                </MenuItem>
              ))}
              <ListSubheader className={classes.listSubheader}>China</ListSubheader>
              {CNStates.cn.map((state, key) => (
                <MenuItem data-key='state' value={state.abbreviation} key={key}>
                  {state.name} ({state.abbreviation})
                </MenuItem>
              ))}
              <ListSubheader className={classes.listSubheader}>Japan</ListSubheader>
              {JPStates.ja.map((state, key) => (
                <MenuItem data-key='state' value={state.abbreviation} key={key}>
                  {state.name} ({state.abbreviation})
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='country'
              data-testid={'search-country'}
              value={searchState.searchFields.address.country || ''}
              label={SEARCH_COUNTRY}
              onKeyPress={(event) => handleSubmit(event)}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      country: event.target.value,
                    },
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              {OtherCountries.map((country, key) => (
                <MenuItem value={country.abbreviation} key={key}>
                  {country.name}
                </MenuItem>
              ))}
              <ListSubheader>E.U.</ListSubheader>
              {EUCountries.map((country, key) => (
                <MenuItem value={country.abbreviation} key={key}>
                  {country.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='zipCode'
              data-testid={'search-zip'}
              value={searchState.searchFields.address.zipCode || ''}
              label={SEARCH_ZIP}
              onKeyPress={(event) => handleSubmit(event)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    address: {
                      ...searchState.searchFields.address,
                      zipCode: event.target.value,
                    },
                  })
                );
              }}
            />
            <FormControl component='fieldset'>
              <RadioGroup
                row
                aria-label={ARIA_ADDRESS_TYPE}
                className={classes.addressTypeRadioGroup}
                id='isBillingAddress'
                data-testid={'search-address-type'}
                value={searchState.searchFields.addressIsBilling || false}
                onChange={(event) => {
                  searchDispatch(
                    setSearchFieldValues({
                      ...searchState.searchFields,
                      addressIsBilling: event.target.value === 'true',
                    })
                  );
                }}>
                <FormControlLabel
                  value={false}
                  control={<Radio color='primary' />}
                  label={SHIPPING_ADDRESS}
                />
                <FormControlLabel
                  value={true}
                  control={<Radio color='primary' />}
                  label={BILLING_ADDRESS}
                />
              </RadioGroup>
            </FormControl>
            <TextField
              className={classes.searchFields}
              id='upc'
              data-testid={'search-upc'}
              value={searchState.searchFields['orderLines.item.universalProductCode'] || ''}
              label={SEARCH_UPC}
              onKeyPress={(event) => handleSubmit(event)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'orderLines.item.universalProductCode': event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='styleNumber'
              data-testid={'search-style-number'}
              value={searchState.searchFields['orderLines.styleNumber'] || ''}
              label={SEARCH_STYLE_NUMBER}
              onKeyPress={(event) => handleSubmit(event)}
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'orderLines.styleNumber': event.target.value,
                  })
                );
              }}
            />
            <TextField
              className={classes.searchFields}
              id='productType'
              data-testid={'search-product-type'}
              value={searchState.searchFields['orderLines.orderLineType'] || ''}
              label={SEARCH_ORDER_TYPE}
              onKeyPress={(event) => handleSubmit(event)}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    'orderLines.orderLineType': event.target.value,
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              {orderLineTypes.map((type) => (
                <MenuItem value={type.value} key={type.value}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              className={classes.searchFields}
              id='dateRange'
              data-testid={'search-date-range'}
              value={searchState.searchFields.orderSubmitDateAfter || ''}
              label={SEARCH_DATE_RANGE}
              onKeyPress={(event) => handleSubmit(event)}
              select
              onChange={(event) => {
                searchDispatch(
                  setSearchFieldValues({
                    ...searchState.searchFields,
                    orderSubmitDateAfter: event.target.value,
                  })
                );
              }}>
              <MenuItem value=''>—</MenuItem>
              {Object.entries(dateRangeOptions).map(([label, value], index) => (
                <MenuItem value={value} key={index}>
                  {label}
                </MenuItem>
              ))}
            </TextField>
          </Collapse>
          <Button
            className={classes.advancedSearchToggle}
            onClick={() => toggleAdvancedSearch()}
            data-testid={'advanced-search-toggle'}>
            {ADVANCED_SEARCH} {advancedOpen ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </Button>
          <Button
            data-testid={'search-button'}
            className={classes.searchButton}
            variant='contained'
            color='primary'
            disabled={!isFormReadyToSubmit()}
            onClick={(event) => handleSubmit(event)}>
            {SEARCH_BUTTON}
          </Button>

          {advancedOpen && (
            <Button
              className={classes.clearSearchButton}
              onClick={clearSearch}
              data-testid={'clear-search-button'}>
              {CLEAR_SEARCH}
            </Button>
          )}
        </form>
      </Paper>
      {loading && <Loading />}
      {searchState.searchResults && (
        <OrderSearchTable
          title={SEARCH_RESULTS}
          searchResults={searchState.searchResults}
          anchor={searchState.anchor}
          paginationLinks={searchState.pagination}
          handleNavigation={handleSearchNavigation}
        />
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    padding: theme.spacing(2),
    display: 'flex',
  },
  searchFormPaper: {
    position: 'sticky',
    top: 58,
    width: '20vw',
    minWidth: '215px',
    height: 'max-content',
    padding: theme.spacing(2),
    overflowX: 'hidden',
  },
  searchFormHeading: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: 1.75,
  },
  searchFields: {
    width: '100%',
  },
  advancedSearchToggle: {
    float: 'right',
    alignItems: 'center',
    textTransform: 'none',
    paddingRight: 0,
    marginRight: 0,
  },
  clearSearchButton: {
    float: 'right',
    alignItems: 'center',
    textTransform: 'none',
    marginRight: 0,
    marginTop: 25,
    textDecoration: 'underline',
    color: theme.palette.primary.main,
  },
  addressTypeRadioGroup: {
    color: theme.palette.common.black,
  },
  listSubheader: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
  },
  searchButton: {
    width: '6vw',
    marginTop: '25px',
    marginRight: '1px',
  },
  searchResults: {
    height: '30vh',
    display: 'inherit',
    padding: theme.spacing(1),
  },
}));

export default SearchForm;
