/** React / Utils */
import React from 'react';

/** Material UI */
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import { newWindowInSF } from '../../utils/link';

/**
 * Button that will open a new tab in browser or in Sales Force depending on environment
 * @param {*} label self explanatory
 * @param {*} ariaLabel accessibility label
 * @param {*} href url for the new tab
 * @returns a react component
 */
const NewTabButton = ({ label, ariaLabel = '', href }) => {
  const classes = useStyles();

  /*
  checking whether we are in Sales Force or native OMOBO app 
  by comparing window.self to window.top
  */
  return window.self === window.top ? (
    <Link
      className={classes.giftCardLink}
      target='_blank'
      rel='noopener noreferrer'
      aria-label={ariaLabel}
      href={href}>
      {label}
    </Link>
  ) : (
    <Button
      className={classes.giftCardLink}
      aria-label={ariaLabel}
      onClick={() => newWindowInSF(href)}>
      {label}
    </Button>
  );
};

const useStyles = makeStyles((theme) => ({
  giftCardLink: {
    color: theme.palette.primary.main,
  },
}));

export default NewTabButton;
