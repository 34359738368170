import { DiscountTypes } from './../constants/dialog.const';
import { getDiscount, getCharges } from '../utils/order';

/**
 * calculates new unit price of a line item after applying discount
 * @param {*} line - selected orderline
 */
export const getNewUnitPrice = (line) => {
  let discountedPrice = getUnitPrice(line);
  switch (line.discountType) {
    case DiscountTypes.PERCENT_OFF:
      discountedPrice = discountedPrice - (discountedPrice * line.discountValue) / 100;
      break;
    case DiscountTypes.AMOUNT_OFF:
      discountedPrice = discountedPrice - line.discountValue;
      break;
    case DiscountTypes.EXACT_PRICE:
      discountedPrice = line.discountValue;
      break;
    default:
      break;
  }
  return discountedPrice;
};

/**
 * return true if any if any of the price validation fails
 * @param {*} line - selected order line
 */
export const showDiscountValidationError = (line) => {
  const unitPrice = line.linePriceInformation.unitPrice;
  let invalidDiscount = false;

  /*
    Set discount validation error to true if
    a) the new unit price (after applying discount) is less than zero
    b) the discount value is greater than original price when discount type
    selected is either Exact Match/Dollar Off  
  */
  if (
    (line.discountType === DiscountTypes.AMOUNT_OFF ||
      line.discountType === DiscountTypes.EXACT_PRICE) &&
    line.discountValue > unitPrice
  ) {
    invalidDiscount = true;
  }
  return Boolean(getNewUnitPrice(line, line.linePriceInformation.unitPrice) < 0) || invalidDiscount;
};

/* this function returns sub totol of a line item
  sub total includes discount but does not include taxes
*/
export const getUnitPrice = (line) => {
  const listPriceTotal = line.linePriceInformation?.listPrice;
  const totalItemDiscount = getDiscount(line) / line.quantity;
  const charges = getCharges(line);
  const subtotal = listPriceTotal - totalItemDiscount + charges;
  return subtotal;
};
