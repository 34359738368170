import { logErrorAction } from '../newrelic/logErrorAction';
/**
 * This function safely stores a value in localStorage
 *
 * @param {string} key – a key under which to store a value in localStorage
 * @param {string} value – the value to be stored
 * @returns {boolean} – true for a successful put, false otherwise.
 */
export const putValue = (key, value) => {
  let result = false;
  // localStorage.setItem requires 2 arguments
  if (!key || !value) return result;
  try {
    const jsonValue = JSON.stringify(value);
    localStorage.setItem(key, jsonValue);
    result = true;
  } catch (error) {
    console.error(error);
    logErrorAction(error, { customMessage: 'putValue, cs-order-management' });
  } finally {
    return result;
  }
};

/**
 * This function safely retrieves a value form localStorage, parsing it as JSON if possible
 *
 * @param {string} key – a key to retrieve a value against from localStorage
 */
export const getValue = (key) => {
  let result = null;
  if (!key) return result;
  const stringValue = localStorage.getItem(key);
  try {
    result = JSON.parse(stringValue);
  } catch (error) {
    result = stringValue;
    logErrorAction(error, { customMessage: 'getValue, cs-order-management' });
  } finally {
    return result;
  }
};

/**
 * Util function to add current order in order context and region where it belongs to
 * @param {*} region - region to which the order belongs to
 */
export const setOrderAndRegion = (orderNumber, orderRegion) => {
  putValue('orderRegion', orderRegion);
  putValue('orderNumber', orderNumber);
};

/**
 * Util function to add return capture details to local storage
 * @param {object} returnCaptureDetails - details needed for return capture audit
 */
export const setReturnCaptureDetails = (returnCaptureDetails) => {
  putValue('returnCaptureDetails', returnCaptureDetails);
};

/**
 * Util function to add return inspection details to local storage
 * @param {object} returnInspectionDetails - details needed for return capture audit
 */
export const setReturnInspectionDetails = (returnInspectionDetails) => {
  putValue('returnInspectionDetails', returnInspectionDetails);
};
