import React from 'react';
import { NumberFormat } from '@nike/i18n-react';

/**
 * Shared component to display amount types formatted with the correct currency
 * ie amount = '4.00', currency = 'USD' this would return '$4.00'
 * @param {*} amount – the price or monetary value you want displayed
 * @param {String} currency – currency that the amount should be displayed with (USD, EUR, etc.)
 */
export function FormattedCurrency({ amount, currency }) {
  amount = Number(amount) || 0;
  return <NumberFormat number={amount} currency={currency} currencyDisplay='symbol' />;
}
