const translations = {
  ARIA_PAGE_HEADER: {
    stringKey: 'ariaPageHeader',
    primaryValue: 'page header',
    description: 'aria-label on the Top Bar for Page Header',
  },
  MODIFY: {
    stringKey: 'modify',
    primaryValue: 'Modify Price',
    description: 'label on the Top Bar for "Modify Price"',
  },
  CREATE_RETURN: {
    stringKey: 'createReturn',
    primaryValue: 'Create Return',
    description: 'label on the Top Bar for "Create Return"',
  },
  CANCEL_ITEMS: {
    stringKey: 'cancelItems',
    primaryValue: 'Cancel Items',
    description: 'label on the Top Bar for "Cancel Items"',
  },
  EXCHANGE: {
    stringKey: 'createExchange',
    primaryValue: 'Create Exchange',
    description: 'label on the Top Bar for "Create Exchange"',
  },
  INSPECT: {
    stringKey: 'inspect',
    primaryValue: 'Inspect Order',
    description: 'label on the Top Bar for "Return Order"',
  },
  REINSPECT: {
    stringKey: 'reinspect',
    primaryValue: 'Re-Inspect Order',
    description: 'label on the Top Bar for "Return Order"',
  },
  ORDER_SUMMARY: {
    stringKey: 'orderSummary',
    primaryValue: 'Order Summary',
    description: 'label on the Top Bar for "Order Summary"',
  },
  LOGO_BUTTON_SEARCH_ALT_TEXT: {
    stringKey: 'logoButtonSearchAltText',
    primaryValue: 'OMOBO application logo and link to search page',
    description:
      "Text for a button that leads to the search page. Page helps athletes search for a customer's orders",
  },
  DISCOUNT_SHIPPING: {
    stringKey: 'discountShipping',
    primaryValue: 'Discount Shipping',
    description: 'label for button to discount shipping for an order',
  },
};

export default translations;
