import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CreateReturnIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import InfoIcon from '@material-ui/icons/Info';
import ExchangeIcon from '@material-ui/icons/Shuffle';
import InspectIcon from '@material-ui/icons/Search';
import ModifyIcon from '@material-ui/icons/LocalOffer';
import ShippingIcon from '@material-ui/icons/LocalShipping';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import PropTypes from 'prop-types';
import config from '../../../utils/config';
import { DialogTypes } from '../../../constants/dialog.const';
import { Partners } from '../../../constants/origin.const.js';
import {
  CreateReturn,
  CancelOrders,
  InspectReturn,
  ReinspectReturn,
  ModifyPrice,
  DiscountShipping,
  ExchangeProduct,
  Developer,
} from '../../../constants/permissions.const';
import { actions as dialogActions } from '../../../store/actions/dialogActions';
import { DialogContext } from '../../../store/contexts/dialogContext';
import { OrderContext } from '../../../store/contexts/orderContext';
import HasPermission from '../../shared/hasPermission';
import useHasPermission from './../../../hooks/useHasPermission';
import DialogContainer from './../dialog';
import translations from './topBar.i18n';
import TopBarContainer from './topBarContainer';

/**
 * TopBar component housing order specific actions and controls for the InfoBar
 */
export default function TopBar({ handleInfoDrawer, infoDrawerOpen }) {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const { hasPermission } = useHasPermission();
  const [, dialogDispatch] = useContext(DialogContext);
  const cspUrl = `${config.cspUrl}csr/cloud/orderDetail?orderNumber=${orderDetail?.orderNumber}&orderHeaderKey=`;
  const {
    CREATE_RETURN,
    CANCEL_ITEMS,
    ORDER_SUMMARY,
    EXCHANGE,
    MODIFY,
    DISCOUNT_SHIPPING,
    INSPECT,
    REINSPECT,
  } = mapValues(translations, i18nString);

  const handleOpenDiscountShippingDialog = () => {
    // Till we complete the entire discount flow only developers are allowed to view the new flow.
    if (hasPermission(Developer)) {
      dialogDispatch(dialogActions.open(DialogTypes.DISCOUNT_SHIPPING));
    } else {
      window.open(cspUrl);
    }
  };

  const handleOpenCancelDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.CANCEL));
  };

  const handleOpenInspectDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.INSPECT));
  };

  const handleOpenReturnDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.RETURN));
  };

  const handleModifyPriceDialog = () => {
    // Till we complete the entire price mod flow only developers are allowed to view the new flow.
    if (hasPermission(Developer)) {
      dialogDispatch(dialogActions.open(DialogTypes.MODIFY_PRICE));
    } else {
      window.open(cspUrl);
    }
  };

  let buttons = {
    discountShipping: (
      <HasPermission permission={DiscountShipping}>
        <Button
          onClick={handleOpenDiscountShippingDialog}
          color='inherit'
          aria-label={DISCOUNT_SHIPPING.toLowerCase()}
          data-testid='discount-shipping-button'
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled,
          }}
          disabled={!orderDetail?.omoboFlags?.isDiscountShippingAllowed}>
          <ShippingIcon className={classes.buttonIcon} />
          {DISCOUNT_SHIPPING}
        </Button>
      </HasPermission>
    ),
    modify: (
      <HasPermission permission={ModifyPrice}>
        <Button
          color='inherit'
          target='_blank'
          aria-label={MODIFY.toLowerCase()}
          onClick={handleModifyPriceDialog}
          data-testid='modify-button'
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled,
          }}
          disabled={!orderDetail?.orderHeaderKey}>
          <ModifyIcon className={classes.buttonIcon} />
          {MODIFY}
        </Button>
      </HasPermission>
    ),
    cancel: (
      <HasPermission permission={CancelOrders}>
        <Button
          color='inherit'
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled,
          }}
          onClick={handleOpenCancelDialog}
          aria-label={CANCEL_ITEMS.toLowerCase()}
          data-testid='cancel-button'
          disabled={!orderDetail?.omoboFlags?.isCancellable}>
          <CancelIcon className={classes.buttonIcon} />
          {CANCEL_ITEMS}
        </Button>
      </HasPermission>
    ),
    createReturn: (
      <HasPermission permission={CreateReturn}>
        <Button
          color='inherit'
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled,
          }}
          onClick={handleOpenReturnDialog}
          disabled={!orderDetail?.omoboFlags?.isReturnable}
          aria-label={CREATE_RETURN.toLowerCase()}
          tabIndex={0}
          data-testid='create-return-button'>
          <CreateReturnIcon className={classes.buttonIcon} />
          {CREATE_RETURN}
        </Button>
      </HasPermission>
    ),
    exchange: (
      <HasPermission permission={ExchangeProduct}>
        <Button
          href={cspUrl}
          target='_blank'
          color='inherit'
          aria-label={EXCHANGE.toLowerCase()}
          className={classes.button}
          data-testid='exchange-button'>
          <ExchangeIcon className={classes.buttonIcon} />
          {EXCHANGE}
        </Button>
      </HasPermission>
    ),
    inspectReinspect: (
      <HasPermission
        permission={orderDetail?.omoboFlags?.isReInspectable ? ReinspectReturn : InspectReturn}>
        <Button
          color='inherit'
          onClick={handleOpenInspectDialog}
          aria-label={INSPECT.toLowerCase()}
          disabled={!orderDetail?.omoboFlags?.isInspectable}
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled,
          }}
          data-testid='inspection-button'>
          <InspectIcon className={classes.buttonIcon} />
          {orderDetail?.omoboFlags?.isReInspectable ? REINSPECT : INSPECT}
        </Button>
      </HasPermission>
    ),
  };

  // top bar data is set initially with the correct buttons for a basic sales order
  let topBarData = (
    <>
      {buttons.discountShipping}
      {buttons.modify}
      {buttons.cancel}
      {buttons.createReturn}
    </>
  );

  // conditional logic to display the top bar buttons
  // Sets topBarData to null if order is from a partner
  if (Partners[orderDetail?.channel]) {
    topBarData = null;
  } else if (orderDetail.orderClassification === 'STORE') {
    if (orderDetail.orderType === 'RETURN_ORDER') {
      topBarData = (
        <>
          {buttons.modify}
          {buttons.cancel}
          {buttons.exchange}
        </>
      );
    } else {
      // not a return order, so display the return button only
      topBarData = <>{buttons.createReturn}</>;
    }
  } else if (orderDetail.orderType !== 'SALES_ORDER') {
    // not a sales order, must be a return order

    // don't display exchange button on bopis order
    if (orderDetail?.omoboFlags?.isBopisReturn) {
      topBarData = (
        <>
          {buttons.modify}
          {buttons.cancel}
          {buttons.inspectReinspect}
        </>
      );
    } else {
      // standard return order
      topBarData = (
        <>
          {buttons.modify}
          {buttons.cancel}
          {buttons.exchange}
          {buttons.inspectReinspect}
        </>
      );
    }
  } else if (orderDetail?.parentReturnOrder) {
    // these buttons are for an exchange order
    topBarData = (
      <>
        {buttons.modify}
        {buttons.cancel}
        {buttons.createReturn}
      </>
    );
  }

  return (
    <>
      <TopBarContainer>
        {/* TODO: add accessibility labels to all of the actions */}
        {topBarData}
        <Button
          aria-label={`${ORDER_SUMMARY.toLowerCase()}`}
          data-testid='info-drawer-button'
          aria-expanded={infoDrawerOpen}
          color='inherit'
          onClick={handleInfoDrawer}
          classes={{
            root: classes.button,
            disabled: classes.buttonDisabled,
          }}>
          {infoDrawerOpen ? (
            <ChevronRightIcon />
          ) : (
            <>
              <InfoIcon className={classes.buttonIcon} /> {ORDER_SUMMARY}
            </>
          )}
        </Button>
      </TopBarContainer>
      <DialogContainer />
    </>
  );
}

TopBar.propTypes = {
  handleInfoDrawer: PropTypes.func,
  infoDrawerOpen: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  button: {
    'margin': theme.spacing(1),
    '&$buttonDisabled': {
      color: theme.palette.grey[500],
    },
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  buttonDisabled: {},
}));
