export const Geos = [
  {
    name: 'US',
    code: 'US',
  },
  {
    name: 'EUROPE',
    code: 'EU',
  },
  {
    name: 'CHINA',
    code: 'CN',
  },
  {
    name: 'JAPAN',
    code: 'JP',
  },
  {
    name: 'GLOBAL STORE',
    code: 'GS',
  },
  {
    name: 'PARTNERS',
    code: 'PARTNERS',
  },
];
