const translations = {
  ADD_COMMENT: {
    description: 'Title of Add Comment page',
    primaryValue: 'Add Comment',
    stringKey: 'addComment',
  },
  COMMENT_ON_ENTIRE_ORDER: {
    description: 'label for textbox to leave a comment on the entire order',
    primaryValue: 'Comment on Entire Order',
    stringKey: 'commentOnEntireOrder',
  },
  COMMENT_ON_INDIVIDUAL_ITEM: {
    description: 'label for textbox to leave a comment on an idividual item',
    primaryValue: 'Comment on an Individual Item',
    stringKey: 'commentOnIndividualItem',
  },
  EXIT: {
    description: 'exit button on dialog header',
    primaryValue: 'EXIT',
    stringKey: 'exit',
  },
  ITEM_LEVEL_COMMENT: {
    description: 'Label for item level comments text box',
    primaryValue: 'Item Level Comment',
    stringKey: 'itemLevelComment',
  },
  ORDER_LEVEL_COMMENT: {
    description: 'Label for order level comments text box',
    primaryValue: 'Order Level Comment',
    stringKey: 'orderLevelComment',
  },
  SELECT_ITEMS_TO_COMMENT_ON: {
    description: 'step title on add comments page',
    primaryValue: 'Select Items To Comment On',
    stringKey: 'selectItemsToCommentOn',
  },
  SUBMIT: {
    description: 'Label for submit button',
    primaryValue: 'Submit',
    stringKey: 'submit',
  },
  ADD_COMMENT_ERROR: {
    description: 'Error Message when comment is not submitted',
    primaryValue: 'Comment submit error. Please try again.',
    stringKey: 'addCommentError',
  },
  ADD_COMMENT_SUCCESS: {
    description: 'Success message when a comment is submitted',
    primaryValue: 'Comment submitted.',
    stringKey: 'addCommentSuccess',
  },
};

export default translations;
