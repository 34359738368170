import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import Box from '@material-ui/core/Box';
import { DialogContext } from '../../../store/contexts/dialogContext';
import { useHistory, withRouter } from 'react-router-dom';
import Comments from './comments';
import Details from './details';
import Charges from './charges';
import NavDrawer from './navDrawer';
import Emails from './emails';
import Payments from './payments';
import translations from './content.i18n.js';
import mapValues from 'lodash/mapValues';
import TabPanel from '../../shared/tabPanel';

/**
 * Container for navigable content, houses NavDrawer and SwipeableViews
 */
function Content({ match }) {
  let history = useHistory();
  const [dialogState] = useContext(DialogContext);
  const route = match && match.params && match.params.route;
  const orderId = match && match.params && match.params.orderId;
  const { i18nString } = useContext(NikeI18nContext);

  const routes = [
    { name: 'details', component: <Details /> },
    { name: 'charges', component: <Charges /> },
    { name: 'payments', component: <Payments /> },
    { name: 'emails', component: <Emails orderId={orderId} /> },
    { name: 'comments', component: <Comments /> },
  ];

  const routeIndex = routes.findIndex((routeObj) => routeObj.name === route);

  // tabs are zero indexed, default to details
  const index = routeIndex === -1 ? 0 : routeIndex;

  const handleChange = (event, newIndex) => {
    history.push(`/order/${orderId}/${routes[newIndex].name}`);
  };

  const { ARIA_TAB_NAV } = mapValues(translations, i18nString);

  return (
    <Box mx={2} display='flex' height='100%'>
      <NavDrawer
        routes={routes.map((route) => route.name)}
        currIndex={index}
        onChange={handleChange}
      />
      <Box role={dialogState.isOpen ? 'none' : 'main'} width='95%' aria-label={ARIA_TAB_NAV}>
        {routes.map((route, i) => (
          <TabPanel
            key={route.name}
            navIndex={i}
            route={route.name}
            currIndex={index}
            hidden={index !== i}>
            {route.component}
          </TabPanel>
        ))}
      </Box>
    </Box>
  );
}

export default withRouter(Content);
