import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './vendor/fullstory';
import './vendor/newrelic';
import { CssBaseline } from '@material-ui/core';
import config from './utils/config';

ReactDOM.render(
  <>
    <CssBaseline />
    <App />
  </>,
  document.getElementById('root')
);

// only if the environment is configured to run React Axe
if (config.axeEnv) {
  // TODO: replace timeout when reactAxe migrates to axe-core-npm and fixes issue #92
  // https://github.com/dequelabs/axe-core-npm/issues/92

  // forces axe to wait until page has loaded before running
  const axe = require('@axe-core/react');
  const context = { exclude: [['.emailBody']] };
  setTimeout(() => axe(React, ReactDOM, 1000, context), 1000);
}
