import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { Paper, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import PermissionsForm from './permissionsForm';

// Component responsible for showing horizontal and vertical tabs along with it's content
export const AdminTabs = ({
  orientation,
  tabIndex,
  labels,
  handleChange,
  geoList,
  groupList,
  activeGroup,
}) => {
  const classes = useStyles();
  const isGeoTab = Boolean(geoList);
  const tabList = isGeoTab ? geoList : groupList();
  /*
    Generates a dynamic tab panel component based on the index passed to it.
  */
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        className={classes.tabPanel}
        {...other}>
        {value === index && <Box className={classes.tabContent}>{children}</Box>}
      </div>
    );
  }

  return (
    <Paper className={classes.adminControl} elevation={2}>
      <Box className={!isGeoTab ? classes.groupTabsContainer : ''}>
        <Typography variant='h2' className={classes.title} aria-label={labels.groupAriaLabel}>
          {labels.titleText}
        </Typography>
        <div className={!isGeoTab ? classes.tabs : ''}>
          <Tabs
            orientation={orientation}
            value={tabIndex}
            onChange={handleChange}
            variant='scrollable'
            aria-label={labels.listAriaLabel}
            className={isGeoTab ? classes.geoTabs : classes.groupTabs}
            classes={{
              indicator: classes.tabIndicator,
              centered: classes.tabCentered,
            }}>
            {tabList.map((tabItem, index) => (
              <Tooltip title={tabItem.value} placement='left'>
                <Tab
                  classes={{
                    root: isGeoTab ? classes.geoTabItemRoot : classes.tabItemRoot,
                    selected: isGeoTab ? classes.geoTabItemSelected : classes.tabItemSelected,
                    wrapper: classes.tabItemWrapper,
                  }}
                  label={tabItem.name}
                  aria-label={
                    isGeoTab ? `active geo: ${tabItem.name}` : `active group: ${tabItem.name}`
                  }
                  key={index}
                  id={isGeoTab ? `horizontal-tab-${index}` : `vertical-tab-${index}`}
                  aria-controls={
                    isGeoTab ? `horizontal-tabpanel-${index}` : `vertical-tabpanel-${index}`
                  }
                />
              </Tooltip>
            ))}
          </Tabs>
        </div>
      </Box>
      {!isGeoTab &&
        tabList.map((group, i) => (
          <TabPanel value={tabIndex} index={i} key={i}>
            <PermissionsForm activeGroup={activeGroup} />
          </TabPanel>
        ))}
    </Paper>
  );
};

const useStyles = makeStyles((theme) => ({
  adminControl: {
    display: 'flex',
    flexGrow: 1,
    marginTop: '1%',
    marginLeft: `10%`,
    marginRight: `10%`,
    marginBottom: `1%`,
    minWidth: '1000px',
    overflowX: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    flexGrow: 1,
    maxHeight: 'calc(100vh - 112px)',
    height: 524,
    display: 'flex',
  },
  groupTabs: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingTop: 0,
    overflowY: 'scroll',
  },
  groupTabsContainer: {
    padding: theme.spacing(4),
    paddingRight: theme.spacing(3),
    overflowY: 'scroll',
  },
  geoTabs: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(15),
  },
  title: {
    textAlign: 'center',
    padding: `${theme.spacing(2)}px ${theme.spacing(1)}px`,
    textTransform: 'uppercase',
    color: theme.palette.grey[600],
    letterSpacing: '0.03em',
    fontSize: '1.25rem',
    fontWeight: 500,
  },
  tabPanel: { flexGrow: 1 },
  tabContent: {
    padding: theme.spacing(4),
    display: 'flex',
    height: '100%',
  },
  tabIndicator: {
    display: 'none',
  },
  tabCentered: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  tabItemRoot: {
    'borderRadius': '30px',
    'textAlign': 'center',
    'transition': 'all .5s',
    'padding': '10px 15px',
    'color': '#555555',
    'opacity': '1',
    'margin': '10px 10px 0 0',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 7px 10px -5px rgba(76, 175, 80, 0.4)',
    },
    '&:last-child': {
      marginBottom: theme.spacing(4),
    },
  },
  tabItemSelected: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 7px 10px -5px rgba(76, 175, 80, 0.4)',
  },
  tabItemWrapper: {
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: '500',
  },
  geoTabItemRoot: {
    'borderRadius': '30px',
    'textAlign': 'center',
    'transition': 'all .5s',
    'padding': '10px 15px 12px 13px',
    'color': '#555555',
    'opacity': '1',
    'margin': '10px 10px 0 0',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
      boxShadow: '0 7px 10px -5px rgba(76, 175, 80, 0.4)',
    },
  },
  geoTabItemSelected: {
    color: '#FFFFFF',
    backgroundColor: theme.palette.primary.main,
    boxShadow: '0 7px 10px -5px rgba(76, 175, 80, 0.4)',
  },
}));
