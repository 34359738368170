import React from 'react';

/**
 * This file contains a number of general use functions. Most are React-centric
 * but that is to be expected, as this is a React app...
 */

/**
 * This utility function provides the validity of its parameter as a child of a
 * React element.
 *
 * @param {*} child - the proposed child of a react element
 * @returns {Boolean} - validity of the parameter as the child of a React
 * element
 */
export const isValidReactChild = (child) => {
  if (Array.isArray(child)) {
    // if we have an array, we have to do some fancy footwork
    return isArrayValidReactChild(child);
  } else {
    // otherwise, we provide a simple check, as described below
    return contentisNonArrayValidReactChild(child);
  }
};

/**
 * This function is is the barebones check for validity of a parameter as a
 * chid of a React component. This function does not contain logic for arrays,
 * and will return false if an array is passed in.
 *
 * @param {*} child - the proposed child of a React element
 * @returns {Boolean} - validity of the parameter as the child of a React
 * element
 */
export const contentisNonArrayValidReactChild = (child) => {
  // we only allow a string, a number, React.Component or React.Element through.
  return typeof child === 'string' || typeof child === 'number' || React.isValidElement(child);
};

/**
 * This utility function provides the validity of an array as a child of a
 * React element. Sub-arrays are checked as well.
 *
 * @param {Array} child - the proposed child of a React element
 * @return {Boolean} - validity of the parameter as the child of a React
 * element
 */
export const isArrayValidReactChild = (child) => {
  // for safety's sake, we make sure we have an array.
  if (Array.isArray(child)) {
    return (
      child
        // flatten the array (to handle all sub-arrays)
        .flat(Infinity)
        // and then use our base function to ensure that the element is valid.
        .every(contentisNonArrayValidReactChild)
    );
  } else {
    console.warn(
      `isArrayValidReactChild called with non-array element: ${child}. returning false.`
    );
    return false;
  }
};

/**
 * This function extracts a key and value from an event, be it a native browser
 * event, or a synthetic event created by React. The value is derived from the
 * 'value' attribute on the DOM element which triggered the event. The key is
 * derived from an html-5 data attribute, passed in from the DOM element using
 * a 'data-key' attribute.
 *
 * @param {Object} event – the event object representing the action which
 * triggered the function
 */
export const getKeyValueFromEvent = (event) => {
  // first check for a valid native event
  const { key: possibleKey1, value: possibleValue1 } = getKeyValueFromBrowserEvent(event);
  // and bail out if one exists
  if (possibleKey1 && possibleValue1) {
    return {
      key: possibleKey1,
      value: possibleValue1,
    };
  }

  // if there is not a valid browser event, check for a React-generated event
  const { key: possibleKey2, value: possibleValue2 } = getNativeKeyValueFromReactEvent(event);

  /* 
  if there is a value for either of the 2s, we definitely have a React event,
  so we return them. otherwise, let's return the values from the original 
  browser event, even if it's incomplete.
  */
  if (possibleKey2 || possibleValue2) {
    return {
      key: possibleKey2,
      value: possibleValue2,
    };
  } else {
    return {
      key: possibleKey1,
      value: possibleValue1,
    };
  }
};

/**
 * This function extracts a key and value from a native browser event created
 * by React. The value is derived from the 'value' attribute on the DOM element
 * which triggered the event. The key is derived from an html-5 data attribute,
 * passed in from the DOM element using a 'data-key' attribute.
 *
 * @param {Object} event – the event object representing the action which
 * triggered the function
 */
export const getKeyValueFromBrowserEvent = ({
  target: { value = null, dataset: { key = null } = {} } = {},
} = {}) => ({ key, value });

/**
 * This function extracts a key and value from a synthetic event created by
 * React. The value is derived from the 'value' attribute on the DOM element
 * which triggered the event. The key is derived from an html-5 data attribute,
 * passed in from the DOM element using a 'data-key' attribute.
 *
 * @param {Object} event – the event object representing the action which
 * triggered the function
 */
export const getNativeKeyValueFromReactEvent = ({
  nativeEvent: { originalTarget: { dataset: { key = null, value = null } = {} } = {} } = {},
}) => ({ key, value });

/**
 * A very simple utility which, if supplied a (non-array) object, will return
 * a shallow copy, and if not, will return an empty object.
 * 
 @param {Object} [object] – an object to be copied
 */
export const safelyShallowCopyObject = (object) =>
  object instanceof Object && !Array.isArray(object)
    ? {
        ...object,
      }
    : {};

/**
 * Remove the '__typename' field from the objects in the array list provided.
 * @param {*} list List of objects.
 */
export const removeTypeNameFromArray = (list) => {
  return Array.isArray(list) ? list.map(removeTypeNameFromObject) : list;
};

/**
 * Remove the '__typename' field from existing object.
 * @param {*} obj An object.
 */
export const removeTypeNameFromObject = (obj) => {
  if (obj['__typename']) {
    delete obj['__typename'];
  }
  return obj;
};

/**
 * Remove the null values from existing object.
 * @param {*} obj An object.
 */
export const removeNullValuesFromObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null) {
      obj[key] = '';
    }
  });
  return obj;
};

/**
 * ComposeComponents allows you to provide an array of components to be used in your app, rather
 * than having to write out each provider individually.
 *
 * The initial usecase for this component was to combine application providers. This can
 * dramatically clean up applications with numerous providers.
 *
 * TODO test this – possibly using React-Testing-Library
 *
 * @param {array} components – an array of components to compose, in hierarchical order, with the
 * element in components[0] being the outermost parent, and so on
 * @param {React.Element} children – the child react component to render within our components
 */
export const ComposeComponents = ({ components = [], children = <></> }) => {
  return components.reverse().reduce((child, Component) => {
    return <Component>{child}</Component>;
  }, children);
};
