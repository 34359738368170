export const CreateReturn = 'Create Return';
export const CancelOrders = 'Cancel Orders';
export const MarkAsGiftReturn = 'Mark As Gift Return';
export const RefundToGiftCard = 'Refund to Gift Card';
export const SendResendReturnLabel = 'Send And Resend Return Label';
export const Developer = 'Is a Member of the Development Team';
export const ReturnGiftCard = 'Return Gift Card';
export const InspectReturn = 'Inspect Return';
export const ReinspectReturn = 'Reinspect Return';
export const ModifyPrice = 'Modify Price';
export const DiscountShipping = 'Discount Shipping';
export const ExchangeProduct = 'Exchange Product';
export const EditCommunicationMethod = 'Edit Communication Method';
export const LinkOrUnlinkOrder = 'Link Or Unlink Order';
export const RecalculateTax = 'Recalculate Tax';
export const AddPaymentMethod = 'Add Payment Method';
export const SubmitPayment = 'Submit Payment';
export const SuspendOrActivatePayment = 'Suspend Or Activate Payment';
export const EditPaymentInfo = 'Edit Payment Info';
export const ResendEmail = 'Resend Email';
export const AddComment = 'Add Comment';
