import { ThemeProvider } from '@material-ui/core/styles';
import { ApolloClient } from 'apollo-boost';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import React, { useState, useEffect } from 'react';
import { ApolloProvider } from 'react-apollo';
import { ConsumerProvider } from './store/contexts/consumerContext';
import { DialogProvider } from './store/contexts/dialogContext';
import { SearchResultsProvider } from './store/contexts/orderSearchContext';
import { OrderProvider } from './store/contexts/orderContext';
import { PermissionProvider } from './store/contexts/permissionContext';
import { AdminProvider } from './store/contexts/adminContext';
import { SnackProvider } from './store/contexts/snackContext';
import { I18nProvider } from './store/contexts/i18Context';
import theme from './theme';
import config from './utils/config';
import { ComposeComponents as AppProviders } from './utils/reactUtils';
import { AthleteProvider } from './store/contexts/athleteContext';
import useAuth from './hooks/useAuth';
import { useOktaAuth } from '@okta/okta-react';
import { getValue } from './utils/localStorage';
import getMockUrl from './utils/getMockUrl';
import { classifyOperation } from './utils/newRelic';

/**
 * A place to store all of our context providers for (otherwise) cleaner code.
 *
 * @param {object} props – the React.props object passed in to this component
 */
const Providers = ({ children }) => {
  const [client, setClient] = useState(null);
  const { oktaToken: localStorageToken } = useAuth();
  const { authState } = useOktaAuth();
  const { accessToken, isAuthenticated } = authState;

  /* 
  When authState is populated (by Okta), we'll grab the access token from it, and create our Apollo
  Client with it as our Authorization token.
  */
  useEffect(() => {
    let uri = '';
    let httpLink = '';

    // function that gets us the mock url based on operation name
    const customFetch = (uri, options) => {
      const { operationName, variables } = JSON.parse(options.body);
      return fetch(getMockUrl(operationName, variables), options);
    };

    // AuthLink is called every time an apollo call is made
    const authLink = setContext(async (req, { headers }) => {
      // on first load, the okta hook doesn't work, so we rely on the token from local storage
      let authorization = localStorageToken;
      // however, accessToken is auto-updating, where the localStorage is not, so we prefer it
      if (isAuthenticated) {
        authorization = accessToken;
      }
      return {
        headers: {
          ...headers,
          authorization,
          orderRegion: getValue('orderRegion') || '',
          orderNumber: getValue('orderNumber') || '',
          returnInspectionAuditDetails: JSON.stringify(getValue('returnInspectionDetails') || ''),
          returnCaptureAuditDetails: JSON.stringify(getValue('returnCaptureDetails') || ''),
        },
      };
    });

    // Call mock end point when in training env (both in local and aws)
    if (process.env.REACT_APP_ENV !== 'training' && process.env.REACT_APP_ENV !== 'train_local') {
      uri = config.foundry.graphqlProxy;
      httpLink = createHttpLink({
        uri,
      });
    } else {
      httpLink = createHttpLink({
        fetch: customFetch,
      });
    }

    // Called for every request, sends custom tracing to New Relic.
    const newRelicLink = setContext(async (req, { headers }) => {
      // Below: 'newrelic' is defined by injected snippet in index.js
      if (window.newrelic) {
        window.newrelic.addPageAction(req.operationName, {
          type: classifyOperation(req.operationName),
          status: 200,
        });
      }
    });

    const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
      if (graphQLErrors) {
        graphQLErrors.map((error) => {
          if (window.newrelic) {
            window.newrelic.addPageAction(`graphQLError`, {
              operation: operation?.operationName,
              message: error.message,
              status: error.extensions?.response?.status,
            });
          }
          return FS.log(`GraphQL Error: ${operation?.operationName} ${error.message}`);
        });
      }
      if (networkError) {
        FS.log(`Network Error: ${networkError.message}`);
      }
    });

    setClient(
      new ApolloClient({
        link: errorLink
          .concat(authLink)
          .concat(newRelicLink)
          .concat(httpLink),
        uri,
        connectToDevTools: true,
        cors: true,
        cache: new InMemoryCache(),
      })
    );
  }, [localStorageToken, authState]);

  const appProviders = [
    I18nProvider,
    AthleteProvider,
    PermissionProvider,
    SnackProvider,
    AdminProvider,
    OrderProvider,
    ConsumerProvider,
    DialogProvider,
    SearchResultsProvider,
  ];

  // once we have an Apollo Client, we populate the app with our providers, and their children
  return (
    client && (
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <AppProviders components={appProviders}>{children}</AppProviders>
        </ThemeProvider>
      </ApolloProvider>
    )
  );
};

export default Providers;
