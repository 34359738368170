import React, { useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { useLocation, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/index';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import translations from './emailBody.i18n';
import Loading from '../../../shared/loading';
import useSnacks from '../../../../hooks/useSnacks';
import buildAttachmentUrl from '../../../../utils/buildAttachmentUrl';
import NOTIFICATION_DETAILS_QUERY from '../../../../queries/notificationDetails.query';
import NOTIFICATION_RESEND_MUTATION from '../../../../mutations/resendEmail.mutation';
import { useMutation } from 'react-apollo';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import HasPermission from '../../../shared/hasPermission';
import { ResendEmail } from '../../../../constants/permissions.const';

/**
 * Page for viewing the contents of an email, including any attachments
 */
const EmailBody = () => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [athleteInfo] = useContext(AthleteContext);
  const { setSlowLoading, setSnack, setError } = useSnacks();
  const history = useHistory();
  const location = useLocation();
  const [emailDetails, setEmailDetails] = useState(null);
  const [emailAddr, setEmailAddr] = useState('');
  const [resendDisabled, setResendDisabled] = useState(false);

  const {
    ATTACHMENTS,
    EMAIL_DETAILS_ERROR,
    EXIT,
    NO_EMAIL_DETAILS_AVAILABLE,
    RESEND_EMAIL,
    RESEND_EMAIL_ERROR,
    RESEND_EMAIL_SUCCESS,
    RESEND_TO,
  } = mapValues(translations, i18nString);

  const path = location.pathname;
  const requestId = path.split('/').pop();
  const emailBody = emailDetails?.email_body[0]?.body;
  const attachments = emailDetails?.attachments;

  /**
   * This function extracts salesforce template variable extra characters such as [SET], %%, etc
   
   * @param {string} emailBody - emailDetails?.email_body from api response
  * @returns {string} emailRender - Parsed emailBody to render to page.
   */
  let emailRender = '';
  const returnRender = (emailBody) => {
    for (let i = 0; i < emailBody.length; i++) {
      if (emailBody[i] === '%' && emailBody[i + 1] === '%') {
        while (emailBody[i - 1] !== '@' && i < emailBody.length) {
          i++;
        }
        while (emailBody[i] !== ' ' && emailBody[i] !== ')') {
          emailRender += emailBody[i];
          i++;
        }
        while (emailBody[i - 2] !== '%' && emailBody[i - 1] !== '%') {
          i++;
        }
      } else {
        emailRender += emailBody[i];
      }
    }
    return emailRender.replace(feedBackSurvey, ' N/A');
  };

  /**
   * Uses feedBackSurvey string in a replace method to extract all excess feedback survey info
   * Without this replace, the unnecessary text will stretch the page
   */
  const feedBackSurvey =
    '_var_3<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_3 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>FEEDBACK_SURVEY_var_4<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_4 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>FEEDBACK_SURVEY_var_5<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_5 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>FEEDBACK_SURVEY_var_6<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_6 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>FEEDBACK_SURVEY_var_7<a target="_blank" href=httpgetwrap|FEEDBACK_SURVEY_var_7 style="text-decoration:none !important; text-decoration:none; color: #CCCCCC; font-size:36px; letter-spacing: 2px;">★</a>';

  const { data, loading, error } = useQuery(NOTIFICATION_DETAILS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { requestId: requestId },
    notifyOnNetworkStatusChange: true,
    onError: () => {
      if (!error?.message?.includes('404')) {
        console.error('Notification details:', error);
        setError(`${EMAIL_DETAILS_ERROR}: ${error.message}`);
      } else {
        setEmailDetails([]);
        setError(`${NO_EMAIL_DETAILS_AVAILABLE}: ${error.message}`);
      }
    },
    onCompleted: () => {
      const emailDetails = data?.notificationDetails?.email;
      const emailAddr = data?.notificationDetails?.email?.recipient_email;

      setEmailDetails(emailDetails);
      setEmailAddr(emailAddr);
    },
  });

  const backToEmails = () => {
    const orderId = emailDetails.order_id;
    history.push(`/order/${orderId}/emails`);
  };

  const validateEmail = (email) => {
    /* 
    regex here will check for a valid email:
    - valid email string before @ 
    - valid string for domain name
    - has '.' present at least once
    - prevents multiple @ signs
    - checks for at least 2 letters after the last '.'
    */

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  // handle email input disable/enable button when validating email
  const emailInput = (e) => {
    setEmailAddr(e.target.value);

    // validate email here
    if (!validateEmail(e.target.value)) {
      setResendDisabled(true);
    } else {
      setResendDisabled(false);
    }
  };

  // handles request submission when resend email button is clicked
  const handleSubmit = () => {
    setSlowLoading();
    backToEmails();
    const requestId = data?.notificationDetails?.email?.request_id;
    const input = {
      email: {
        recipient_email: emailAddr,
        initiator: athleteInfo.email,
      },
    };
    resendEmail({
      variables: { input, requestId },
    });
  };

  // Mutation that calls email resend service
  const [resendEmail] = useMutation(NOTIFICATION_RESEND_MUTATION, {
    onError: (err) => {
      setError(`${RESEND_EMAIL_ERROR} : ${err.message}`);
    },
    onCompleted: (response) => {
      const { notificationResend } = response;
      if (!notificationResend.errors) {
        // show success message
        setSnack(`${RESEND_EMAIL_SUCCESS}`);
      } else {
        // show error message
        setSnack(`${RESEND_EMAIL_ERROR}`);
      }
    },
  });

  const isResend = data?.notificationDetails?.email?.is_resend;

  return (
    <div>
      <Typography variant='h1' className='accessibly-hidden'>
        Email Details
      </Typography>
      {loading && <Loading />}

      {/* resend email section */}
      {!loading && (
        <>
          <section className={classes.emailHeader} role='banner'>
            {!isResend && (
              <HasPermission permission={ResendEmail}>
                <div>
                  <Typography className={classes.resendTitle} data-testid={'resend-title'}>
                    {RESEND_TO}
                  </Typography>
                  <TextField
                    className={classes.resendField}
                    data-testid={'resend-email-input'}
                    id='email-addr-input'
                    variant='outlined'
                    InputProps={{
                      classes: {
                        notchedOutline: classes.emailInputOutline,
                        input: classes.emailInput,
                      },
                    }}
                    value={emailAddr}
                    margin='dense'
                    style={{ width: 300 }}
                    onChange={emailInput}
                  />
                  <Button
                    className={classes.resendButton}
                    data-testid={'resend-email-button'}
                    disabled={resendDisabled}
                    role='button'
                    onClick={handleSubmit}>
                    {RESEND_EMAIL}
                  </Button>
                </div>
              </HasPermission>
            )}
            {!isResend && (
              <Button
                className={classes.exitButton}
                onClick={backToEmails}
                data-testid={'exit-button'}
                role='button'>
                {EXIT}
              </Button>
            )}
          </section>
        </>
      )}

      {/* set line break if both resend email and attachments exist */}
      {!loading && !isResend && attachments && attachments.length > 0 && (
        <section className={classes.lineBreak} role='banner'>
          <hr></hr>
        </section>
      )}

      {/* set exit button on its own if no resend email or attachments */}
      {!loading && isResend && attachments && attachments.length === 0 && (
        <section className={classes.emailHeader} role='banner'>
          <Button
            className={classes.exitButton}
            onClick={backToEmails}
            data-testid={'exit-button'}
            role='button'>
            {EXIT}
          </Button>
        </section>
      )}

      {/* attachment section */}
      {!loading && (
        <section className={classes.emailHeader} role='banner'>
          {attachments && attachments.length > 0 && (
            <div>
              <Typography className={classes.attachmentTitle} data-testid={'attachment-title'}>
                {ATTACHMENTS}
              </Typography>
              <div>
                {attachments.map((attachment, i) => (
                  <Link
                    key={i}
                    id={`attachment-{i}`}
                    variant='body1'
                    className={classes.attachments}
                    download={attachment.name}
                    href={buildAttachmentUrl(attachment, i)}>
                    {attachment.name}
                  </Link>
                ))}
              </div>
            </div>
          )}
          {isResend && Array.isArray(attachments) && attachments.length > 0 && (
            <Button
              className={classes.exitButton}
              onClick={backToEmails}
              data-testid={'exit-button'}
              role='button'>
              {EXIT}
            </Button>
          )}
        </section>
      )}

      {emailBody && (
        <div
          dangerouslySetInnerHTML={{
            __html: returnRender(emailBody),
          }}
          role='main'
          className={classes.emailBody}
          data-testid={'email-body'}></div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  attachmentTitle: {
    marginLeft: theme.spacing(1),
    fontSize: '1em',
  },
  attachments: {
    'display': 'inline-block',
    'border': `1px solid ${theme.palette.common.white}`,
    'color': theme.palette.common.white,
    'padding': `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    'margin': theme.spacing(1),
    '&:hover': {
      background: theme.palette.common.white,
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  emailHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  emailBody: {
    height: '100vh',
    padding: '20px 200px',
    background: theme.palette.grey[200],
  },
  exitButton: {
    color: theme.palette.common.white,
    marginLeft: 'auto',
  },
  lineBreak: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
  },
  resendButton: {
    color: theme.palette.common.white,
    marginLeft: '10px',
    marginTop: '10px',
    border: '1px solid white',
  },
  resendField: {
    marginLeft: theme.spacing(1),
  },
  emailInputOutline: {
    borderWidth: '1px',
    borderColor: 'white !important',
  },
  emailInput: {
    color: 'black',
    backgroundColor: 'white',
    borderRadius: 'inherit',
  },
  resendTitle: {
    marginLeft: theme.spacing(1),
    fontSize: '1em',
  },
}));
export default EmailBody;
