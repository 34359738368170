import React from 'react';
import './styles/App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import Order from './components/orders';
import Admin from './components/admin';
import OrderSearch from './components/orderSearch';
import Providers from './providers';
import ErrorBoundary from './components/error/errorBoundary';
import EmailBody from './components/orders/content/emailBody';
import config from './utils/config';
import RecentOrders from './components/recentOrders';
import useAuth from './hooks/useAuth';

/**
 * The main application component for CS Order Management
 *
 * @param {Object} props – React.props
 */
const App = (props) => {
  const { validateOktaToken } = useAuth();

  /*
  Checks for the okta token present in the local storage and removes it from local storage
  if it is expired. This wil help secure route to update the local storage with a new token
  */
  validateOktaToken();

  return (
    <BrowserRouter>
      <Security
        issuer={config.okta.issuer}
        clientId={config.okta.clientId}
        redirectUri={window.location.origin + '/implicit/callback/'}
        pkce={true}>
        <Providers>
          <ErrorBoundary>
            {/* this route is for Okta purposes */}
            <Route path='/implicit/callback/' component={LoginCallback} />
            {/* our routes */}
            <SecureRoute path='/' exact={true} component={OrderSearch} />
            {/* TODO: remove this route when lightning tea updates the end point */}
            <SecureRoute path='/order/search' exact={true} component={OrderSearch} />
            <SecureRoute path='/order/:orderId/:route' exact={true} component={Order} />
            <SecureRoute path='/admin' exact={true} component={Admin} />
            <SecureRoute path='/recent/orders' exact={true} component={RecentOrders} />
            <SecureRoute
              path='/order/:orderId/emails/:requestId'
              exact={true}
              component={EmailBody}
            />
          </ErrorBoundary>
        </Providers>
      </Security>
    </BrowserRouter>
  );
};

export default App;
