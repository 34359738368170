import SidebarItem from './sidebarItem';
import LinkIcon from '@material-ui/icons/Link';
import React, { useContext } from 'react';
import { OrderContext } from '../../../store/contexts/orderContext';
import DataTable from '../../shared/table/dataTable';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';
import { NikeI18nContext } from '@nike/i18n-react';
import translations from './relatedOrders.i18n';
import mapValues from 'lodash/mapValues';

/**
 * Component that displays related orders in the info drawer
 */
const RelatedOrders = () => {
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const { i18nString } = useContext(NikeI18nContext);
  const { csOrderSummary, exchangeOrderSummary } = orderDetail;
  const salesOrReturnOrdersMap = {};
  const exchangeOrdersMap = {};
  const { SALES_ORDER, RELATED_ORDERS, RETURN_ORDERS, EXCHANGE_ORDER } = mapValues(
    translations,
    i18nString
  );

  const isSalesOrReturnOrderAvailable = csOrderSummary && csOrderSummary.objects.length > 0;
  const isExchangeOrderAvailable = exchangeOrderSummary && exchangeOrderSummary.objects.length > 0;

  isSalesOrReturnOrderAvailable &&
    csOrderSummary.objects.map((order) => {
      const prettyDate = new Date(order.orderSubmitDate).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });

      return (salesOrReturnOrdersMap[order.orderNumber] = [order.status, prettyDate]);
    });

  isExchangeOrderAvailable &&
    exchangeOrderSummary.objects.map(
      (order) => (exchangeOrdersMap[order.orderNumber] = [order.status, order.orderSubmitDate])
    );

  const linkedOrderType = orderDetail.orderType === 'SALES_ORDER' ? RETURN_ORDERS : SALES_ORDER;

  const isLinkedOrdersAvailable =
    (csOrderSummary && csOrderSummary.objects.length > 0) ||
    (exchangeOrderSummary && exchangeOrderSummary.objects.length > 0);

  return (
    <>
      {isLinkedOrdersAvailable && (
        <SidebarItem title={RELATED_ORDERS} icon={<LinkIcon />}>
          {isSalesOrReturnOrderAvailable && (
            <>
              <Typography className={classes.linkedOrderHeader}>{linkedOrderType}</Typography>
              <DataTable map={salesOrReturnOrdersMap} tight isLink={true} />
            </>
          )}
          {isExchangeOrderAvailable && (
            <>
              <Typography className={classes.linkedOrderHeader}>{EXCHANGE_ORDER}</Typography>
              <DataTable map={exchangeOrdersMap} tight isLink='true' />
            </>
          )}
        </SidebarItem>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  relatedOrdersTypography: {
    whiteSpace: 'pre-line',
  },
  linkedOrderHeader: {
    fontSize: '0.875rem',
    fontWeight: 'strong',
  },
}));

export default RelatedOrders;
