import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import ErrorBoundary from '../../error/errorBoundary';

const TabPanel = ({ navIndex, currIndex, route, children }) => {
  const classes = useStyles();
  return (
    <ErrorBoundary>
      <Box
        className={classes.view}
        role='tabpanel'
        id={`nav-tabpanel-${navIndex}`}
        hidden={currIndex !== navIndex}
        aria-labelledby={`nav-tab-${navIndex}`}
        data-testid={getTestId(route)}>
        {children}
      </Box>
    </ErrorBoundary>
  );
};

const getTestId = (route) => {
  switch (route) {
    case 'details':
      return 'details-view';
    case 'payments':
      return 'payments-view';
    case 'emails':
      return 'emails-view';
    case 'charges':
      return 'charges-view';
    case 'comments':
      return 'comments-view';
    default:
      return 'default-view';
  }
};

TabPanel.propTypes = {
  hidden: PropTypes.bool.isRequired,
  route: PropTypes.string.isRequired,
  navIndex: PropTypes.number.isRequired,
  currIndex: PropTypes.number.isRequired,
};

const useStyles = makeStyles(() => ({
  view: { paddingBottom: '1vh' },
}));

export default TabPanel;
