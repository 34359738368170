import gql from 'graphql-tag';

const GC_BALANCE_MUTATION = gql`
  mutation getGiftCardBalance($input: GiftCardInputV1!) {
    giftCardBalance(input: $input) {
      currency
      balance
    }
  }
`;

export default GC_BALANCE_MUTATION;
