/** React/Utils */
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import React, { useContext } from 'react';

/** Material-UI */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

/** Local */
import { DialogTypes } from '../../../constants/dialog.const';
import Geo from '../../../constants/geos.const';
import {
  AddComment,
  CancelOrders,
  CreateReturn,
  SendResendReturnLabel,
  InspectReturn,
  ModifyPrice,
  ReinspectReturn,
  DiscountShipping,
} from './../../../constants/permissions.const';
import useHasPermission from './../../../hooks/useHasPermission';
import { actions as dialogActions } from '../../../store/actions/dialogActions';
import { DialogContext } from '../../../store/contexts/dialogContext';
import { OrderContext } from '../../../store/contexts/orderContext';
import ErrorBoundary from '../../error/errorBoundary';
import { NoPermission } from '../../shared/noPermission/noPermission';
import CommentStep1 from './addComment/commentStep1';
import CancelStep1 from './cancelItems/step1';
import CancelStep2 from './cancelItems/step2';
import CancelStepControl from './cancelItems/stepControl';
import ReturnStep1 from './createReturn/step1';
import ReturnStep2 from './createReturn/step2';
import ReturnStep3 from './createReturn/step3';
import ChinaReturnStep3 from './createReturn/step3.china';
import ReturnStep4 from './createReturn/step4';
import USReturnStepControl from './createReturn/stepControl.US';
import EUReturnStepControl from './createReturn/stepControl.EU';
import CNReturnStepControl from './createReturn/stepControl.CN';
import JPReturnStepControl from './createReturn/stepControl.JP';
import translations from './dialog.i18n';
import DiscountShippingStepControl from './discountShipping/stepControl';
import InspectStep1 from './inspectReturn/step1';
import InspectStep2 from './inspectReturn/step2';
import InspectStepControl from './inspectReturn/stepControl';
import ModifyPriceStep1 from './modifyPrice/step1';
import ModifyPriceStep2 from './modifyPrice/step2';
import ModifyPriceStepControl from './modifyPrice/stepControl';

/*
  transition for the mounting/dismounting of the dialog component
  TODO: fix bug where passing {... props} to Slide also passes props to children created by 
  transition.  Causes "non-boolean attribute" warning in console.
   */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

/**
 * Dialog container for handling different dialog types
 */
export default function DialogContainer() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { hasPermission } = useHasPermission();
  const {
    ADD_COMMENT,
    ARIA_RETURN_STEPS,
    ARIA_CANCEL_STEPS,
    ARIA_INSPECTION_STEPS,
    ARIA_MODIFY_PRICE_STEPS,
    ARIA_RESEND_STEPS,
    ARIA_DIALOG_HEADER,
    ADD_ITEM_DETAILS,
    DISCOUNT_SHIPPING,
    EXIT,
    CANCEL_ITEMS,
    CREATE_A_RETURN,
    INSPECT_RETURN,
    MODIFY_ITEM_PRICE,
    SELECT_ITEMS_TO_CANCEL,
    RESEND_RETURN_LABEL,
    SELECT_ITEMS_TO_RETURN,
    SELECT_REASON_FOR_DISCOUNT,
    ADD_RETURNEE_DETAILS,
    ADD_RETURN_LABEL_DETAILS,
    SELECT_ITEMS_TO_INSPECT,
    SELECT_ITEMS_TO_MODIFY_PRICE,
    ACTION_COMPLETE,
    CLOSE_TAB,
    RETURN_ORDER,
  } = mapValues(translations, i18nString);

  const { dialogType, isOpen, activeStep, lock } = dialogState;
  const geo = orderDetail.omsRegionReference;

  const handleCloseDialog = () => {
    // if we're locked, don't close the dialog
    !lock && dialogDispatch(dialogActions.close());
  };

  // Function that returns step control based on the GEO
  const GetStepControl = () => {
    switch (geo) {
      case Geo.US: {
        return <USReturnStepControl />;
      }
      case Geo.EUROPE: {
        return <EUReturnStepControl />;
      }
      case Geo.CHINA: {
        return <CNReturnStepControl />;
      }
      case Geo.JAPAN: {
        return <JPReturnStepControl />;
      }
      default: {
        return <USReturnStepControl />;
      }
    }
  };

  const getDialogDetails = () => {
    // Adding this method as we will be having differnt names for step3 based on Geo's very soon.
    const getStepName = () => {
      return ADD_RETURNEE_DETAILS;
    };

    switch (dialogType) {
      case DialogTypes.ADD_COMMENT: {
        return {
          label: ADD_COMMENT,
          content: hasPermission(AddComment) ? (
            <div style={{ marginLeft: 50, marginTop: 30 }}>
              <CommentStep1 />
            </div>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.RETURN: {
        return {
          label: CREATE_A_RETURN,
          content: hasPermission(CreateReturn) ? (
            <Stepper
              aria-label={ARIA_RETURN_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'>
              {/* step 1 */}
              <Step aria-label={SELECT_ITEMS_TO_RETURN} key={SELECT_ITEMS_TO_RETURN}>
                <StepLabel>{SELECT_ITEMS_TO_RETURN}</StepLabel>
                <StepContent>
                  <ReturnStep1 />
                  {GetStepControl()}
                </StepContent>
              </Step>
              {/* step 2 */}
              <Step aria-label={ADD_ITEM_DETAILS} key={ADD_ITEM_DETAILS}>
                <StepLabel>{ADD_ITEM_DETAILS}</StepLabel>
                <StepContent>
                  <ReturnStep2 />
                  {GetStepControl()}
                </StepContent>
              </Step>
              {/* step 3 */}
              <Step aria-label={getStepName()} key={ADD_RETURNEE_DETAILS}>
                <StepLabel>{getStepName()}</StepLabel>
                <StepContent>
                  {geo === Geo.CHINA ? <ChinaReturnStep3 /> : <ReturnStep3 />}
                  {GetStepControl()}
                </StepContent>
              </Step>
              {/* step 4 */}
              {orderDetail.omsRegionReference !== Geo.CHINA &&
                orderDetail.omsRegionReference !== Geo.JAPAN && (
                  <Step aria-label={ADD_RETURN_LABEL_DETAILS} key={ADD_RETURN_LABEL_DETAILS}>
                    <StepLabel>{ADD_RETURN_LABEL_DETAILS}</StepLabel>
                    <StepContent>
                      <ReturnStep4 />
                      {GetStepControl()}
                    </StepContent>
                  </Step>
                )}
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.CANCEL: {
        return {
          label: CANCEL_ITEMS,
          content: hasPermission(CancelOrders) ? (
            <Stepper
              aria-label={ARIA_CANCEL_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'>
              {/* step 1 */}
              <Step aria-label={SELECT_ITEMS_TO_CANCEL} key={SELECT_ITEMS_TO_CANCEL}>
                <StepLabel>{SELECT_ITEMS_TO_CANCEL}</StepLabel>
                <StepContent>
                  <CancelStep1 />
                  <CancelStepControl />
                </StepContent>
              </Step>
              {/* step 2 */}
              <Step aria-label={ADD_ITEM_DETAILS} key={ADD_ITEM_DETAILS}>
                <StepLabel>{ADD_ITEM_DETAILS}</StepLabel>
                <StepContent>
                  <CancelStep2 />
                  <CancelStepControl />
                </StepContent>
              </Step>
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.INSPECT: {
        return {
          label: INSPECT_RETURN,
          content: hasPermission(
            orderDetail?.omoboFlags?.isReInspectable ? ReinspectReturn : InspectReturn
          ) ? (
            <Stepper
              aria-label={ARIA_INSPECTION_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'>
              {/* step 1 */}
              <Step aria-label={SELECT_ITEMS_TO_INSPECT} key={SELECT_ITEMS_TO_INSPECT}>
                <StepLabel>{SELECT_ITEMS_TO_INSPECT}</StepLabel>
                <StepContent>
                  <InspectStep1 />
                  <InspectStepControl />
                </StepContent>
              </Step>
              {/* step 2 */}
              <Step aria-label={ADD_ITEM_DETAILS} key={ADD_ITEM_DETAILS}>
                <StepLabel>{ADD_ITEM_DETAILS}</StepLabel>
                <StepContent>
                  <InspectStep2 />
                  <InspectStepControl />
                </StepContent>
              </Step>
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.DISCOUNT_SHIPPING: {
        return {
          label: DISCOUNT_SHIPPING,
          content: hasPermission(DiscountShipping) ? (
            <Stepper
              aria-label={ARIA_CANCEL_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'>
              {/* step 1 */}
              <Step aria-label={SELECT_REASON_FOR_DISCOUNT} key={SELECT_REASON_FOR_DISCOUNT}>
                <StepLabel>{SELECT_REASON_FOR_DISCOUNT}</StepLabel>
                <StepContent>
                  <DiscountShippingStepControl />
                </StepContent>
              </Step>
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.RESEND_RETURN_LABEL: {
        return {
          label: RESEND_RETURN_LABEL,
          content: hasPermission(SendResendReturnLabel) ? (
            <Stepper
              aria-label={ARIA_RESEND_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'
              data-testid='resend-return-label-stepper'>
              <StepContent>
                <ReturnStep4 />
                {GetStepControl()}
              </StepContent>
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.MODIFY_PRICE: {
        return {
          label: MODIFY_ITEM_PRICE,
          content: hasPermission(ModifyPrice) ? (
            <Stepper
              aria-label={ARIA_MODIFY_PRICE_STEPS}
              activeStep={activeStep}
              orientation='vertical'
              active='true'
              last='false'
              completed='false'>
              {/* step 1 */}
              <Step aria-label={SELECT_ITEMS_TO_MODIFY_PRICE} key={SELECT_ITEMS_TO_MODIFY_PRICE}>
                <StepLabel>{SELECT_ITEMS_TO_MODIFY_PRICE}</StepLabel>
                <StepContent>
                  <ModifyPriceStep1 />
                  <ModifyPriceStepControl />
                </StepContent>
              </Step>
              {/* step 2 */}
              <Step aria-label={ADD_ITEM_DETAILS} key={ADD_ITEM_DETAILS}>
                <StepLabel>{ADD_ITEM_DETAILS}</StepLabel>
                <StepContent>
                  <ModifyPriceStep2 />
                  <ModifyPriceStepControl />
                </StepContent>
              </Step>
            </Stepper>
          ) : (
            <NoPermission />
          ),
        };
      }
      case DialogTypes.ACTION_COMPLETE: {
        return {
          label: ACTION_COMPLETE,
          content: (
            <Typography variant='subtitle1'>
              {CLOSE_TAB}
              {dialogState.returnOrderNumber &&
                ` ${RETURN_ORDER}: ${dialogState.returnOrderNumber}`}
            </Typography>
          ),
        };
      }
      default: {
        return {};
      }
    }
  };

  const dialogDetails = getDialogDetails();
  return (
    dialogType &&
    orderDetail && (
      <Dialog
        fullScreen
        open={lock || isOpen}
        onClose={handleCloseDialog}
        aria-labelledby='dialogTitle'
        TransitionComponent={!lock ? Transition : undefined}
        classes={{ root: classes.dialogRoot, paper: classes.noScroll }}>
        <div aria-label={ARIA_DIALOG_HEADER} className={classes.dialogBar}>
          <ErrorBoundary>
            <Toolbar>
              <Typography
                variant='h1'
                id='dialogTitle'
                color='inherit'
                className={classes.label}
                data-testid={`dialog-${dialogDetails.label}`}>
                {dialogDetails.label}
              </Typography>
              {// if we're locked, don't show the exit button
              !lock && (
                <Button
                  data-testid='exit-dialog-button'
                  color='inherit'
                  aria-label={EXIT.toLowerCase()}
                  onClick={handleCloseDialog}>
                  {EXIT}
                </Button>
              )}
            </Toolbar>
          </ErrorBoundary>
        </div>
        {dialogDetails.content}
      </Dialog>
    )
  );
}

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: '1.2rem',
    fontWeight: '500',
    flex: 1,
  },
  dialogRoot: { zIndex: `4 !important` },
  dialogBar: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
  },
  noScroll: { overflowX: 'hidden' },
}));
