/**
 * This util generates the shared StepControl styles
 *
 * @param {Object} theme – the Material UI Theme object
 */
export const stepControlSharedStyles = (theme) => ({
  actionsContainer: {
    marginTop: theme.spacing(1),
  },
  stepperButton: {
    'marginTop': theme.spacing(1),
    'marginRight': theme.spacing(1),
    '&$backDisabled': {
      color: theme.palette.grey[600],
    },
    '&$nextDisabled': {
      color: theme.palette.grey[700],
    },
  },
  nextDisabled: {},
  backDisabled: {},
});

/**
 * This util generates the shared product thumbnail styles
 *
 * @param {Object} theme – the Material UI Theme object
 */
const productThumb = (theme) => ({
  backgroundColor: 'white',
  backgroundSize: 'contain',
  height: 184,
  width: 184,
  gridArea: 'I',
  margin: theme.spacing(1),
});

/**
 * This util generates the shared tooltip styles
 *
 * @param {Object} theme – the Material UI Theme object
 */
const tooltip = (theme) => ({
  boxShadow: theme.shadows[1],
  fontSize: '0.92rem',
  margin: theme.spacing(1),
});

/**
 * This util generates styles shared by the first steps of each dialog type
 *
 * @param {Object} theme – the Material UI Theme object
 */
export const step1SharedStyles = (theme) => ({
  disabledProductThumb: {
    ...productThumb(theme),
    opacity: 0.3,
  },
  item: {
    backgroundColor: '#fafafa',
    height: 'auto',
    width: 200,
  },
  productGrid: {
    margin: '1em 0 2em 0',
    display: 'grid',
    gap: '3em',
    gridTemplateColumns: 'repeat(auto-fill, 200px)',
  },
  productThumb: productThumb(theme),
  selectedItem: {
    backgroundColor: '#9BC7F3',
    height: 'auto',
    width: 200,
  },
  giftCardDetails: {
    fontSize: '0.75rem',
  },
  giftCardDetailsWithHalfOpacity: {
    fontSize: '0.75rem',
    opacity: 0.3,
  },
  productSelectCheckIcon: {
    fill: 'green',
  },
  halfOpacity: {
    opacity: 0.3,
  },
  tooltip: tooltip(theme),
  unselectableItem: {
    backgroundColor: '#eee',
    height: 'auto',
    width: 200,
  },
  vasItemContainer: {
    position: 'relative',
  },
  singleItemContainer: {
    position: 'relative',
    width: '230px',
    display: 'grid',
    justifyContent: 'center',
  },
  firstRowItem: {
    zIndex: 5,
    position: 'relative',
    width: '200px',
  },
  secondRowItem: {
    zIndex: 5,
    position: 'absolute',
    top: '20px',
    left: '30px',
    width: '200px',
  },
  primaryItem: {
    zIndex: 6,
  },
  selectItemsMessage: {
    marginLeft: '1em',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
});

/**
 * This util generates styles shared by the second steps of each dialog type
 *
 * @param {Object} theme – the Material UI Theme object
 */
export const step2SharedStyles = (theme) => ({
  itemDetails: {
    maxWidth: 600,
    gridArea: 'D',
  },
  loading: {
    display: 'block',
  },
  itemDetailsCard: {
    backgroundColor: '#fafafa',
    display: 'grid',
    gridTemplateColumns: '200px 1fr',
    gridTemplateAreas: '"I D"',
    gap: '1rem',
    minHeight: 200,
    margin: theme.spacing(1),
    width: '100%',
    maxWidth: '840px',
  },
  itemDetailsContent: {
    '&:last-child': {
      paddingBottom: theme.spacing(1),
    },
    'padding': theme.spacing(1),
  },
  noDisplay: {
    display: 'none',
  },
  productThumb: productThumb(theme),
  quantitySelect: {
    display: 'inline-grid',
    gridAutoFlow: 'column',
    alignItems: 'center',
    paddingBottom: theme.spacing(0.5),
  },
  quantityLabel: {
    paddingRight: theme.spacing(1),
  },
  reasonCodeSelect: {
    display: 'block',
    maxWidth: 250,
    minWidth: 100,
  },
  tooltip: tooltip(theme),
});
