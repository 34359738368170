import React from 'react';
import EUAddressForm from './addressForm.eu';
import JPAddressForm from './addressForm.jp';
import USAddressForm from './addressForm.us';
import Geo from '../../constants/geos.const';

/**
 * Generic AddressForm to wrap a region-specific AddressForm.
 *
 * @param omsRegionReference {string} - the region
 * @param isGiftCardAddress {boolean} - whether or not this is a gift card address
 * @param className {string} - a class name
 * @returns {JSX.Element}
 * @constructor
 */
export const AddressForm = ({ region, isGiftCardAddress = false, className = '' }) => {
  if (region === Geo.CHINA) {
    // China doesn't need the edit address capability in return labels, so address will
    // be grabbed from the sales order.
    return null;
  } else if (region === Geo.EUROPE) {
    return <EUAddressForm isGiftCardAddress={isGiftCardAddress} className={className} />;
  } else if (region === Geo.JAPAN) {
    return <JPAddressForm isGiftCardAddress={isGiftCardAddress} className={className} />;
  } else if (region === Geo.US) {
    return <USAddressForm isGiftCardAddress={isGiftCardAddress} className={className} />;
  } else {
    // TODO: do we need to handle invalid omsRegionReferences?
    return '';
  }
};

export default AddressForm;
