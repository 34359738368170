import React from 'react';
import { formatDateTime } from './date';
import config from './config';

/**
 * A utility function that builds an image source uro for a style code
 *
 * @param {string/number} styleNumber – the style code for the product in
 * question, possibly already containing the color code
 * @param {string/number} [colorCode] – the color code for the product in
 * question
 * @returns {string} an image url
 */
export const getProductImageFromStyle = (styleNumber, colorCode) => {
  /*
  this direct-linking for images looks hacky, but is the proper implementation
  of a platform called Product Images Library. this is how I was directed to 
  implement it as per the PIL PM, Rachel Royce. This service will be replaced
  with a new one called Aurora in FY21, but we should need only to replace the
  URL when it rolls out.

  There is not really documentation for these services, and so I will explain
  here.

  adding a style-color to the base uri is your starting point.

  ex. http://images2.nike.com/is/image/DPILS/BA5957-010

  beyond this, different suffixes will get you different things:

  '-PV' will get you the Primary View (always available)
    
    ex. http://images2.nike.com/is/image/DPILS/BA5957-010-PV

  '-SV' will get you the Secondary View (not always available)
  
    ex. http://images2.nike.com/is/image/DPILS/BA5957-010-SV

  '?req=set,json' will get you a set of all the available images for a product,
    alongside some associated information, such as the path.
  
    ex. http://images2.nike.com/is/image/DPILS/BA5957-010-PHSRZ001
  */
  const imageUriSuffix = '-PV';
  const styleColor = prepareStyleColor(styleNumber, colorCode);

  return config.imageBaseURI + styleColor + imageUriSuffix;
};

/**
 * A utility function to build a style-color code for a product from it's
 * constituent parts.
 *
 * @param {string} styleNumber - a code which uniquely identifies a product
 * (you know, more or less "uniquely" :D)
 * @param {string} colorCode - a code which determines the color of a product
 * @returns {string} either a properly formatted style-color combination, or
 * the string INVALID, which will generate a "product unavailable" image
 * (really, any string that is not associated with a product will, but)
 */
export const prepareStyleColor = (styleNumber, colorCode) => {
  const imageUnavailable = 'INVALID';

  // we need this logic twice, and it's ugly, so we'll separate it out
  const isParameterInvalid = (parameter) =>
    (!Boolean(parameter) && colorCode !== 0) ||
    (typeof parameter !== 'string' && typeof parameter !== 'number');

  // if the styleNumber isn't gonna work for us, bail
  if (isParameterInvalid(styleNumber)) {
    return imageUnavailable;
  }

  if (typeof styleNumber === 'string') {
    styleNumber = styleNumber.trim();
  }
  if (typeof colorCode === 'string') {
    colorCode = colorCode.trim();
  }

  if (isParameterInvalid(colorCode)) {
    /*
    if the color code isn't good, let's finesse the styleNumber, just in case
    it's been included there.
    */
    if (styleNumber.indexOf(' ') > -1) {
      styleNumber = styleNumber.split(' ').join('-');
    }
    if (styleNumber.indexOf('_') > -1) {
      styleNumber = styleNumber.split('_').join('-');
    }
    return styleNumber;
  } else {
    /*
    some color codes come through as 2 or 1 digits. I'm not sure why, but
    let's pad the start with 0s, just in case. can't hurt.
    */
    colorCode = String(colorCode).padStart(3, '0');
    return `${styleNumber}-${colorCode}`;
  }
};

/**
 * A utility function to fetch a product image from an order line
 *
 * @param {object} orderLine - the order object from which to derive a product
 * image link
 * @returns {string} the url of a product image
 */
export const getProductImageFromOrderLine = (orderLine) => {
  const { styleNumber, colorCode, orderLineType } = orderLine;

  // If the order line is for a "Nike By You" product, then we
  // grab the image URL from the consumerDesigns property.
  if (orderLineType === 'NIKEID') {
    // We are currently only displaying one image per order line,
    // so for now we can target the first image returned by the service.
    const url = orderLine?.consumerDesigns?.objects?.[0]?.imagery?.[0]?.imageSourceURL ?? '';
    if (url) {
      return url;
    }
  }

  return getProductImageFromStyle(styleNumber, colorCode);
};

/**
 * A utility function to fetch the FTC date from an order line
 *
 * @param {object} orderLine - the order object from which to pull the FTC date
 * @returns {string} a properly formatted FTC date
 */
export const getFTCDateFromOrderLine = (orderLine, locale) => {
  if (!orderLine) return;
  const { lineDates } = orderLine;
  let ftcDate;

  if (Array.isArray(lineDates) && lineDates.length) {
    const lineDate = lineDates.find((date) => date.dateType === 'FTC_EVENT_DATE_1');

    if (lineDate) {
      ftcDate = formatDateTime(lineDate.date, locale);
    }
  }

  return ftcDate || undefined;
};

/**
 * A React Component for product images
 *
 * @param {object} props - a props object for the component. expects orderLine
 * (an order line object, obviously), and accepts an optional className.
 * @returns {React.Component} an img component
 */
export const ProductImage = (props) => (
  // if there is an image from src, grab it. else, get image the usual way
  <img
    src={props.src ? props.src : getProductImageFromOrderLine(props.orderLine)}
    className={props.className}
    alt={
      (props.orderLine && props.orderLine.item && props.orderLine.item.itemDescription) ||
      'Product Image'
    }
    data-testid='product-image'
  />
);
