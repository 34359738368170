import { actionTypes } from '../actions/orderSearchActions';
import { defaultState } from '../contexts/orderSearchContext';

/**
 * The reducer for the admin context
 *
 * @param {Object} state – the current state of the context
 * @param {string} action – an object which signifies the action to be taken.
 */
export const orderSearchReducer = (state = defaultState, action = {}) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_RESULTS: {
      return {
        ...state,
        searchResults: action.searchResults,
      };
    }
    case actionTypes.SET_SEARCH_FIELD_VALUES: {
      return {
        ...state,
        searchFields: action.searchFieldValues,
      };
    }
    case actionTypes.RESET_SEARCH_FIELD_VALUES: {
      return {
        ...state,
        searchFields: defaultState.searchFields,
      };
    }
    case actionTypes.SET_PAGINATION: {
      return {
        ...state,
        pagination: action.pagination,
      };
    }
    case actionTypes.SET_ANCHOR: {
      return {
        ...state,
        anchor: action.anchor,
      };
    }
    case actionTypes.SET_SHOW_SEARCH_PAGE: {
      return {
        ...state,
        showSearchPage: action.showSearchPage,
      };
    }
    default: {
      return state;
    }
  }
};
