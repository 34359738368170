import React from 'react';
import { formatDateTime } from './date';
import { FormattedCurrency } from '../components/shared/formatCurrency';

/**
 * Takes a chargeTransactionDetail and returns an array of values in the
 * order matching the table columns in Payments > All Transactions Table
 * @param {object} transaction - chargeTransactionDetail
 * @param {string} currency - sets the country currency code
 * @returns {array} [
 * Date,
 * Auth Date,
 * Type,
 * Amount,
 * Auth #,
 * Status,
 * Invoices
 * ]
 */
export const transformTransactionDetails = (transaction, currency) => {
  // note: invoices may need to be more complex than this
  let invoices =
    (Array.isArray(transaction.invoiceCollectionDetails) &&
      transaction.invoiceCollectionDetails.map((detail) => detail.invoiceNumber)) ||
    undefined;

  let paymentAmount;

  function formatCurrency(value) {
    return <FormattedCurrency amount={value} currency={currency} />;
  }
  // this sets the heirarchy of which value to display as the "Amount"
  if (transaction.bookAmount) {
    paymentAmount = transaction.bookAmount;
  } else if (transaction.requestAmount) {
    paymentAmount = transaction.requestAmount;
  } else {
    paymentAmount = transaction.debitAmount;
  }

  return [
    formatDateTime(transaction.transactionDate),
    transaction?.creditCardTransactions.length > 0
      ? formatDateTime(transaction?.creditCardTransactions[0].authorizationTime)
      : null,
    transaction.chargeReference,
    formatCurrency(paymentAmount),
    transaction.authorizationReference,
    transaction.status,
    invoices,
  ];
};

/**
 * Takes an array of chargeTransactionDetails and returns a TotalTransferAmounts object
 * with in and out values aggregated from the chargeTransactionDetails.
 * @param {array} chargeTransactionDetails
 * @returns {object} totalTransferAmounts
 */
export const calculateTotalTransferInAndOut = (chargeTransactionDetails) => {
  let totalTransferAmounts = {};
  totalTransferAmounts.in = 0;
  totalTransferAmounts.out = 0;
  if (chargeTransactionDetails != null) {
    for (let index = 0; index < chargeTransactionDetails.length; index++) {
      let chargeTransactionDetail = chargeTransactionDetails[index];
      if (
        chargeTransactionDetail.chargeReference === 'TRANSFER_IN' &&
        chargeTransactionDetail.creditAmount
      ) {
        totalTransferAmounts.in += chargeTransactionDetail.creditAmount;
      } else if (
        chargeTransactionDetail.chargeReference === 'TRANSFER_OUT' &&
        chargeTransactionDetail.creditAmount
      ) {
        totalTransferAmounts.out -= chargeTransactionDetail.creditAmount;
      }
    }
  }
  return totalTransferAmounts;
};

/**
 * This util derives an address from a payment method from an order object.
 *
 * @param {object} paymentMethod – an entry in the PaymentMethods array on an order object
 * @returns {string} – a multiline string containing a formatted address
 */
export const getPaymentAddress = (paymentMethod) => {
  const address = paymentMethod && paymentMethod.billTo && paymentMethod.billTo.address;
  if (!address) return;

  // TODO: get a schema of what all addresses can look like, and adjust logic accordingly

  const cityState =
    (address.city && address.state && `${address.city}, ${address.state}`) ||
    address.state ||
    address.city;

  // ? Question: do payment methods have alt first/last names?
  return [
    `${paymentMethod.firstName || ''} ${paymentMethod.lastName || ''}`.trim(),
    address.address1,
    address.address2,
    cityState,
    address.zip,
  ]
    .filter(Boolean)
    .join('\n')
    .trim();
};
