/** React/Utils */
import React, { useContext, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useHistory } from 'react-router-dom';
import mapValues from 'lodash/mapValues';
import { NikeI18nContext } from '@nike/i18n-react';

/** Material UI */
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import TodayIcon from '@material-ui/icons/Today';
import CircularProgress from '@material-ui/core/CircularProgress';

/** Local */
import OrderContext from '../../../../store/contexts/orderContext';
import { SearchContext } from '../../../../store/contexts/orderSearchContext';
import searchActions from './../../../../store/actions/orderSearchActions';
import STORE_VIEWS_QUERY from '../../../../queries/storeViews.query';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import { formatDateTime } from '../../../../utils/date';
import { getArrayProperty } from '../../../../utils/getArrayProperty';
import { getShipGroup } from '../../../../utils/shipment';
import { separateCancelledItems } from '../../../../utils/order';
import useSnacks from '../../../../hooks/useSnacks';
import { BopisOrderStatus } from './../../../../constants/order.const';
import ErrorBoundary from '../../../error/errorBoundary';
import ItemDetails from '../shared/itemDetails';
import translations from './details.i18n';
import ShipmentDetails from './shipmentDetails';
import ReturnLabelDetails from './returnLabelDetails';
import ExpandedItem from './expandedItem';

/**
 * Main react component housing the item details tab, calls itemDetails for the content of each card
 */
export default function Details() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [searchState, searchDispatch] = useContext(SearchContext);
  const { setShowSearchPage } = searchActions;
  const { setError } = useSnacks();
  let history = useHistory();

  let {
    orderLines,
    shipments: shippingDetails,
    csOrderSummary: linkedOrders,
    exchangeOrderSummary,
    orderClassification,
    orderHeaderKey,
    orderHolds,
    orderType,
    parentReturnOrder,
    status,
    store,
    locale,
  } = orderDetail;

  // TODO make all of this use getArrayObjectProperty or whatever I called it...
  orderLines = orderLines || [];

  // links will hold the linked orders data (csOrderSummary in order details).
  const links = getArrayProperty(linkedOrders, 'objects');

  // exchangeOrderLinks will hold the exchange orders data (exchangeOrderSummary in order details).
  const exchangeOrderLinks = getArrayProperty(exchangeOrderSummary, 'objects');

  // shipments contains shipping details of an order (shipments in order details).
  const shipments = getArrayProperty(shippingDetails, 'objects');

  const allShippedOrderLines = new Set();

  const {
    ARIA_SEARCH_BACK,
    ARIA_STORE_LOADING,
    ARIA_STORE_LABEL,
    BACK_TO_SEARCH_RESULTS,
    BOPIS_ORDER,
    ERROR_FROM_BOM_API,
    CANCELED_ITEMS,
    HOLD,
    ITEMS_AWAITING_SHIPMENT,
    ITEMS_READY_FOR_PICKUP,
    ORDER_DETAILS,
    PICKED_UP_ITEMS,
    PURCHASED_ITEMS,
    RETURN_LABEL,
    RETURN_ORDER,
    RETURNED_ITEMS,
    SHIPMENT,
    STORE_ERROR,
    STORE_ORDER,
    UNBRIDGED_ORDER_MESSAGE,
  } = mapValues(translations, i18nString);

  let isStoreOrder = null;
  let parentSalesOrderNumber = null;

  const isReturnOrder = Boolean(orderType === 'RETURN_ORDER');

  if (orderClassification) {
    isStoreOrder = orderClassification.includes('STORE');
  }

  // if return order, have to get the parent sales order to get the store id
  if (isReturnOrder && orderDetail) {
    parentSalesOrderNumber = orderDetail.orderLines[0].parentSalesOrderNumber;
  }

  /* making another order detail query for store return orders because
   they do NOT have the store id in their order detail payload (this pains me)
   therefore, the parent sales order of the return order has to be queried to get the store id */
  const { data: parentData, loading: parentLoading, error: parentError } = useQuery(
    ORDER_DETAIL_QUERY,
    {
      variables: {
        orderNumber: parentSalesOrderNumber,
      },
      skip: !parentSalesOrderNumber,
      notifyOnNetworkStatusChange: true,
    }
  );

  /* if order detail data is returned with NIKEID order lines that are missing BOM data,
   check to see, display error snack re: BOM data missing. */
  useEffect(() => {
    let isBOM404 = false;
    orderDetail?.orderLines?.forEach((line) => {
      if (line.orderLineType === 'NIKEID') {
        const bomDetails = line?.bomDetails?.GetBuildBOMConfigurationService?.bom?.comps?.comp;
        if (!bomDetails) {
          isBOM404 = true;
        }
      }
    });

    if (isBOM404) {
      setError(ERROR_FROM_BOM_API);
    }
  }, [orderDetail, parentError]);

  let storeIdToQuery = null;

  /* set the store id to query. 
   if sales store order, get from order details. 
   if return store order, get from the parent order details */
  if (isReturnOrder) {
    storeIdToQuery = parentData?.orderDetail?.store?.storeId;
  } else {
    storeIdToQuery = store?.storeId;
  }

  // query to get store name from store id
  const { data: storeViewsData, loading: storeViewsLoading, error: storeViewsError } = useQuery(
    STORE_VIEWS_QUERY,
    {
      variables: {
        id: storeIdToQuery,
      },
      skip: !storeIdToQuery,
      notifyOnNetworkStatusChange: true,
    }
  );

  let storeTitle = null;

  // conditional logic to set store title
  if ((storeViewsError && storeIdToQuery) || (parentError && parentSalesOrderNumber)) {
    // error, so display store error message
    storeTitle = STORE_ERROR;
  } else if (storeViewsLoading || parentLoading) {
    // loading, so display a spinner
    storeTitle = <CircularProgress aria-label={ARIA_STORE_LOADING} className={classes.loading} />;
  } else if (storeViewsData) {
    // else, set the store title in correct format
    storeTitle = `${STORE_ORDER} - ` + storeViewsData.storeViews.name + ' #' + store.storeNumber;
  }

  const orderLinesWithLinkedOrders = orderLines.map((line) => {
    /*
     Since all the order lines in a return order can only be created from the same sales order
     we are adding the sales order details to each order line with out any check
     */
    if (orderType === 'RETURN_ORDER' || parentReturnOrder) {
      line.linkedOrders = links;
      line.exchangeOrders = exchangeOrderLinks;
    } else {
      line.linkedOrders = links.filter((order) =>
        order.orderLines.some((lines) => {
          return (
            lines.parentSalesOrderLineKey &&
            lines.parentSalesOrderLineKey.includes(line.orderLineKey)
          );
        })
      );
    }
    return line;
  });

  /**
   * Drills down to the container level to get the orderLineKeys
   * associated with this shipment, and matches them to orderLineKeys in current OrderDetail
   */
  const shippingContainers = shipments.map(
    (shipment) =>
      Array.isArray(shipment.containers) &&
      [].concat(
        ...shipment.containers.map((container) => {
          return (
            Array.isArray(container.containerLines) &&
            container.containerLines.map((containerLine) => {
              let { orderLineKey: shipLineKey } = containerLine;

              /** for complex orderLine types, we need to check other logic:
               *    -for multi-statuses, we need to check which is in shipped status
               *    -for NikeId orders, we need to check all NikeId orderLines
               */

              const matchingOrderLines = orderLines.filter((line) => {
                if (line.orderLineType === 'NIKEID') {
                  return line.nikeIdLines?.filter((subLine) =>
                    subLine.orderLineKey.includes(shipLineKey)
                  );
                } else {
                  return line.orderLineKey.includes(shipLineKey);
                }
              });

              if (matchingOrderLines.length > 0) {
                matchingOrderLines.forEach((line) => {
                  // if any of the statuses are in the Shipped status (statusCode has 3700)
                  if (line.statuses.some((status) => status.statusCode?.includes('3700'))) {
                    shipLineKey = line.orderLineKey;
                    allShippedOrderLines.add(line.orderLineKey);
                  }
                });
              }
              return shipLineKey;
            })
          );
        })
      )
  );

  /*
   * nonShippedLineOrders are orderLines
   * a) that are not part of allShippedOrderLines
   * b) orderLines without orderLineKey (This condition is for newly created return orders)
   */
  const nonshippedOrderLines = orderLinesWithLinkedOrders.filter((line) => {
    return line.orderLineKey ? !allShippedOrderLines.has(line.orderLineKey) : true;
  });

  // filter the unshipped orderLines into separate arrays of cancelled, and uncancelled lines
  const [itemsAwaitingShipment, canceledItems] = separateCancelledItems(nonshippedOrderLines);

  // temporary array for shipped items showing up in the wrong category due to deprecated API
  const noHeaderShipments = [];

  /* Removing any lines from itemsAwaitingShipment that have a shipped status
 This can be removed once we switch to the new shipments API */
  if (itemsAwaitingShipment && itemsAwaitingShipment.length > 0) {
    itemsAwaitingShipment.forEach((line) => {
      /* if any of the statuses are in the Shipped status (statusCode has 3700) 
      then we add them to noHeadersShipments and remove it from itemsAwaitingShipment */
      if (line.statuses.some((status) => status.statusCode?.includes('3700'))) {
        itemsAwaitingShipment.splice(itemsAwaitingShipment.indexOf(line), 1);
        noHeaderShipments.push(line);
      }
    });
  }

  const isBOPIS = orderDetail?.omoboFlags?.isBOPIS;
  const areAllLineItemsCancelled = canceledItems.length === orderLines.length;

  const BOPISReadyForPickUp =
    Boolean(nonshippedOrderLines.length) && isBOPIS && status === BopisOrderStatus.ReadyForPickUp;

  const BOPISPartiallyReadyForPickUp =
    Boolean(nonshippedOrderLines.length) &&
    isBOPIS &&
    status === BopisOrderStatus.PartiallyReadyForPickUp;

  const BOPISPickedUp =
    Boolean(nonshippedOrderLines.length) && isBOPIS && status === BopisOrderStatus.PickedUp;

  const BOPISPartiallyPickedUp =
    Boolean(nonshippedOrderLines.length) &&
    isBOPIS &&
    status === BopisOrderStatus.PartiallyPickedUp;

  /**
   * Returns BOPIS label based on BOPIS order status
   * We are just showing table header as BOPIS order if the order status is
   * 'Partially ReadyForPickUp' or 'Partially PickedUp'
   */
  const getBopisHeader = () => {
    if (BOPISReadyForPickUp) {
      return ITEMS_READY_FOR_PICKUP;
    } else if (BOPISPartiallyReadyForPickUp) {
      return BOPIS_ORDER;
    } else if (BOPISPickedUp) {
      return PICKED_UP_ITEMS;
    } else if (BOPISPartiallyPickedUp) {
      return BOPIS_ORDER;
    }
  };

  /**
   * handles when "back to search results" link is clicked
   */
  const handleLinkClick = () => {
    searchDispatch(setShowSearchPage(true));
    history.push(`/`);
  };

  return (
    <ErrorBoundary>
      {Object.entries(searchState.searchResults).length > 1 && (
        <Typography className={classes.backToSearch}>
          <Link aria-label={ARIA_SEARCH_BACK} component='button' onClick={() => handleLinkClick()}>
            {'< '}
            {BACK_TO_SEARCH_RESULTS}
          </Link>
        </Typography>
      )}
      <h1 className='accessibly-hidden'>{ORDER_DETAILS}</h1>

      {/* For orders without an orderHeaderKey value, display message re: not synced to DOMS */}
      {!orderHeaderKey && (
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContentHold}>
            <CardHeader
              disableTypography
              classes={{ root: classes.cardHeaderRoot }}
              data-testid={`order-not-synced`}
              title={<h2 className={classes.cardHeadingHold}>{UNBRIDGED_ORDER_MESSAGE}</h2>}
            />
          </CardContent>
        </Card>
      )}

      {/* Display non resolved order holds if any. Filter out the resolved holds */}
      {orderHolds &&
        orderHolds
          .filter((hold) => hold.status !== 'Resolved')
          .map((holds, i) => (
            <Card className={classes.cardSpacing} key={i} elevation={3}>
              <CardContent className={classes.cardContentHold}>
                <CardHeader
                  disableTypography
                  classes={{ root: classes.cardHeaderRoot }}
                  data-testid={`order-hold-${i}`}
                  title={
                    <h2 className={classes.cardHeadingHold}>
                      {holds.type + ' ' + HOLD + ' '}
                      <Tooltip title={formatDateTime(holds.lastHoldDate, locale)}>
                        <TodayIcon name='hold' className={classes.holdIcon} />
                      </Tooltip>
                    </h2>
                  }
                />
              </CardContent>
            </Card>
          ))}

      {orderType !== 'RETURN_ORDER' &&
        Array.isArray(shipments) &&
        shipments.map((shipment, i) => (
          /**
           * Items that have been shipped for Original Sales Orders & Exchanges.
           */
          <Card className={classes.cardSpacing} key={i} elevation={3}>
            <CardContent className={classes.cardContent}>
              <CardHeader
                disableTypography
                classes={{ root: classes.cardHeaderRoot }}
                title={
                  <h2 className={classes.cardHeading}>
                    {SHIPMENT} {getShipGroup(shipment)}
                  </h2>
                }
              />
              <ShipmentDetails
                orderLines={orderLinesWithLinkedOrders}
                shipment={shipment}
                locale={locale}
              />
              <ItemDetails
                orderLines={orderLinesWithLinkedOrders.filter(
                  (line) => line.orderLineKey && shippingContainers[i].includes(line.orderLineKey)
                )}
                ExpandedContent={ExpandedItem}
              />
            </CardContent>
          </Card>
        ))}

      {orderType === 'RETURN_ORDER' && (
        /**
         * If the order is a RETURN_ORDER, then the details layout should differ slightly,
         * listing Return Label information as well as a "Resend Return Label" CTA, should
         * the user have the appropriate permission.
         */
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContent}>
            <CardHeader
              disableTypography
              classes={{ root: classes.cardHeaderRoot }}
              title={<h2 className={classes.cardHeading}>{RETURN_LABEL}</h2>}
            />
            {Array.isArray(shipments) && shipments.length > 0 ? (
              shipments.map((shipment) => (
                <ReturnLabelDetails
                  key={`return-label-${shipment.id}`}
                  shipment={shipment}
                  locale={locale}
                />
              ))
            ) : (
              <ReturnLabelDetails shipment={{}} locale={locale} />
            )}
          </CardContent>
        </Card>
      )}

      {/* Items that have not been shipped, but are not canceled. */}
      {Boolean(itemsAwaitingShipment.length) && !isBOPIS && !isStoreOrder && (
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContent}>
            {orderType === 'SALES_ORDER' && (
              <CardHeader
                disableTypography
                data-testid={`sales-order-details`}
                classes={{ root: classes.cardHeaderRoot }}
                title={<h2 className={classes.cardHeading}>{ITEMS_AWAITING_SHIPMENT}</h2>}
              />
            )}
            {orderType === 'RETURN_ORDER' && (
              <CardHeader
                disableTypography
                data-testid={`return-order-details`}
                classes={{ root: classes.cardHeaderRoot }}
                title={<h2 className={classes.cardHeading}>{RETURN_ORDER}</h2>}
              />
            )}
            <ItemDetails orderLines={itemsAwaitingShipment} ExpandedContent={ExpandedItem} />
          </CardContent>
        </Card>
      )}

      {/* Items that don't have a correct header because of the depricated API
      This can be removed once we switch to the new shipments API */}
      {Boolean(noHeaderShipments.length) && (
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContent}>
            <ItemDetails orderLines={noHeaderShipments} ExpandedContent={ExpandedItem} />
          </CardContent>
        </Card>
      )}

      {/* Store orders, so display correct title along with store header */}
      {isStoreOrder && (
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContent}>
            {orderType === 'SALES_ORDER' && (
              <div className={classes.headers}>
                <CardHeader
                  disableTypography
                  classes={{ root: classes.cardHeaderRoot }}
                  title={<h2 className={classes.cardHeading}>{PURCHASED_ITEMS}</h2>}
                />
                <CardHeader
                  disableTypography
                  classes={{ root: classes.cardHeaderRoot }}
                  title={
                    <h2 className={classes.cardHeading} aria-label={ARIA_STORE_LABEL}>
                      {storeTitle}
                    </h2>
                  }
                />
              </div>
            )}
            {orderType === 'RETURN_ORDER' && (
              <div className={classes.headers}>
                <CardHeader
                  disableTypography
                  classes={{ root: classes.cardHeaderRoot }}
                  title={<h2 className={classes.cardHeading}>{RETURNED_ITEMS}</h2>}
                />
                <CardHeader
                  disableTypography
                  classes={{ root: classes.cardHeaderRoot }}
                  title={
                    <h2 className={classes.cardHeading} aria-label={ARIA_STORE_LABEL}>
                      {storeTitle}
                    </h2>
                  }
                />
              </div>
            )}
            <ItemDetails orderLines={itemsAwaitingShipment} ExpandedContent={ExpandedItem} />
          </CardContent>
        </Card>
      )}

      {/* Below section handles BOPIS orders when all line items are not cancelled */}
      {isBOPIS && !areAllLineItemsCancelled && (
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContent}>
            {orderType === 'SALES_ORDER' && (
              <div className={classes.headers}>
                <CardHeader
                  disableTypography
                  classes={{ root: classes.cardHeaderRoot }}
                  data-testid={getBopisHeader()}
                  title={<h2 className={classes.cardHeading}>{getBopisHeader()}</h2>}
                />
                {/* checking the header to avoid duplicate labels */}
                {getBopisHeader() !== BOPIS_ORDER && (
                  <CardHeader
                    disableTypography
                    classes={{ root: classes.cardHeaderRoot }}
                    title={<h2 className={classes.cardHeading}>{BOPIS_ORDER}</h2>}
                  />
                )}
              </div>
            )}
            <ItemDetails orderLines={itemsAwaitingShipment} ExpandedContent={ExpandedItem} />
          </CardContent>
        </Card>
      )}

      {/* Items that have been canceled. */}
      {Boolean(canceledItems.length) && (
        <Card className={classes.cardSpacing} elevation={3}>
          <CardContent className={classes.cardContent}>
            {orderType === 'SALES_ORDER' && (
              <div className={classes.headers}>
                <CardHeader
                  disableTypography
                  classes={{ root: classes.cardHeaderRoot }}
                  title={<h2 className={classes.cardHeading}>{CANCELED_ITEMS}</h2>}
                />
                {/* Added below condition for BOPIS cancelled sales orders */}
                {isBOPIS && (
                  <CardHeader
                    disableTypography
                    classes={{ root: classes.cardHeaderRoot }}
                    data-testid={'bopisHeader'}
                    title={<h2 className={classes.cardHeading}>{BOPIS_ORDER}</h2>}
                  />
                )}
              </div>
            )}
            {orderType === 'RETURN_ORDER' && (
              <div className={classes.headers}>
                <CardHeader
                  disableTypography
                  classes={{ root: classes.cardHeaderRoot }}
                  title={<h2 className={classes.cardHeading}>{RETURN_ORDER}</h2>}
                />
                {/* Added below condition for BOPIS cancelled return orders */}
                {isBOPIS && (
                  <CardHeader
                    disableTypography
                    classes={{ root: classes.cardHeaderRoot }}
                    title={<h2 className={classes.cardHeading}>{BOPIS_ORDER}</h2>}
                  />
                )}
              </div>
            )}
            <ItemDetails orderLines={canceledItems} ExpandedContent={ExpandedItem} />
          </CardContent>
        </Card>
      )}

      {/* Return orders that don't meet the above criteria, still display order details */}
      {orderType === 'RETURN_ORDER' &&
        Boolean(orderLinesWithLinkedOrders.length) &&
        !Boolean(nonshippedOrderLines.length) && (
          <Card className={classes.cardSpacing} elevation={3}>
            <CardContent className={classes.cardContent}>
              <CardHeader
                disableTypography
                classes={{ root: classes.cardHeaderRoot }}
                title={<h2 className={classes.cardHeading}>{RETURN_ORDER}</h2>}
              />
              <ItemDetails orderLines={orderLinesWithLinkedOrders} ExpandedContent={ExpandedItem} />
            </CardContent>
          </Card>
        )}
    </ErrorBoundary>
  );
}

const useStyles = makeStyles((theme) => ({
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  cardHeaderRoot: {
    paddingLeft: theme.spacing(1),
  },
  backToSearch: {
    display: 'flex',
    paddingTop: '12px',
    paddingLeft: '10px',
    cursor: 'pointer',
  },
  cardHeading: {
    margin: 0,
    paddingBottom: theme.spacing(1),
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  headers: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardContent: {
    overflow: 'auto',
  },
  cardHeadingHold: {
    margin: 0,
    paddingBottom: theme.spacing(1),
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
    color: '#c23a36',
  },
  cardContentHold: {
    'overflow': 'auto',
    'paddingTop': '0px',
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  holdIcon: {
    paddingTop: '1px',
    marginBottom: '-4px',
    color: 'gray',
  },
}));
