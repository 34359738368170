const translations = {
  ITEM_CHARGES: {
    description: 'todo',
    primaryValue: 'Item Charges',
    stringKey: 'itemCharges',
  },
  ITEM_TAX: {
    description: 'label on Details page for "item tax"',
    primaryValue: 'Item Tax',
    stringKey: 'itemTax',
  },
  ORDER_CHARGES: {
    description: 'todo',
    primaryValue: 'Order Charges',
    stringKey: 'orderCharges',
  },
  SHIPPING_COST: {
    description: 'todo',
    primaryValue: 'Shipping Cost',
    stringKey: 'shippingCost',
  },
  SHIPPING_DISCOUNT: {
    description: 'todo',
    primaryValue: 'Shipping Discount',
    stringKey: 'shippingDiscount',
  },
  SHIPPING_TAX: {
    description: 'todo',
    primaryValue: 'Shipping Tax',
    stringKey: 'shippingTax',
  },
  SHIPPING_TOTAL: {
    description: 'todo',
    primaryValue: 'Shipping Total',
    stringKey: 'shippingTotal',
  },
  LIST_PRICE: {
    description: 'label on Details page for "list price"',
    primaryValue: 'List Price',
    stringKey: 'listPrice',
  },
  LIST_PRICE_TOTAL: {
    description: 'label on Details page for "list price total"',
    primaryValue: 'List Price Total',
    stringKey: 'listPriceTotal',
  },
  QUANTITY: {
    description: 'label on Details page for "quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  ITEM_DISCOUNT_TOTAL: {
    description: 'label on Details page for "item discount total"',
    primaryValue: 'Item Discount Total',
    stringKey: 'itemDiscountTotal',
  },
  MILITARY_DISCOUNT: {
    description: 'label on Details page for "military discount"',
    primaryValue: 'Military Discount',
    stringKey: 'militaryDiscount',
  },
  ITEM_DISCOUNT: {
    description: 'label on Details page for "item discount"',
    primaryValue: 'Item Discount',
    stringKey: 'itemDiscount',
  },
  SUBTOTAL: {
    description: 'label on Details page for the before-tax subtotal',
    primaryValue: 'Subtotal (before tax)',
    stringKey: 'subtotal',
  },
  TAX: {
    description: 'label on Details page for "tax"',
    primaryValue: 'Tax',
    stringKey: 'tax',
  },
  TAX_CODE: {
    description: 'label on Details page for the tax code',
    primaryValue: 'Tax Code',
    stringKey: 'taxCode',
  },
  TAX_DISCOUNT: {
    description: 'label on Details page for the tax discount',
    primaryValue: 'Tax Discount',
    stringKey: 'taxDiscount',
  },
  TAX_PRICE: {
    description: 'label on Details page for the tax price',
    primaryValue: 'Tax Price',
    stringKey: 'taxPrice',
  },
  TAX_RATE: {
    description: 'label on Details page for the tax rate',
    primaryValue: 'Tax Rate',
    stringKey: 'taxRate',
  },
  TIG_TAX_CODE: {
    description: 'label on Details page for the tig tax code',
    primaryValue: 'TIG Tax Code',
    stringKey: 'tigTaxCode',
  },
  PAYMENT_DETAILS: {
    description: 'label on Details page for "payment details"',
    primaryValue: 'Payment Details',
    stringKey: 'paymentDetails',
  },
  VAS_PAYMENT_DETAILS: {
    description: 'label on Details page for "VAS payment details"',
    primaryValue: 'VAS Payment Details',
    stringKey: 'vasPaymentDetails',
  },
  REASON: {
    description: 'label for description of discount reason on charge details',
    primaryValue: 'Reason',
    stringKey: 'reason',
  },
  ATHLETE: {
    description: 'label for athlete/agent associated with line charge',
    primaryValue: 'Athlete',
    stringKey: 'athlete',
  },
  VAS_DISCOUNT: {
    description: 'label on Details page for "VAS discount"',
    primaryValue: 'VAS Discount',
    stringKey: 'vasDiscount',
  },
  MANUAL_DISCOUNT: {
    description: 'label on Details page for "manual item discount"',
    primaryValue: 'Manual Item Discount',
    stringKey: 'manualDiscount',
  },
  EMPLOYEE_DISCOUNT: {
    description: 'label on Details page for "employee discount"',
    primaryValue: 'Employee Discount',
    stringKey: 'employeeDiscount',
  },
  SALE_DISCOUNT: {
    description: 'label on Details page for "sale price discount"',
    primaryValue: 'Sale Price Discount',
    stringKey: 'saleDiscount',
  },
  LINE_DISCOUNT: {
    description: 'label on Details page for "line level discount"',
    primaryValue: 'Line Level Discount',
    stringKey: 'lineDiscount',
  },
  PROMO_CODE: {
    description: 'label on Details page for “promo code”',
    primaryValue: 'Promo Code',
    stringKey: 'promoCode',
  },
  PROMO_LIST_HEADER: {
    description: 'label on charges page for list of promo and coupon codes',
    primaryValue: 'Coupons / Promotions',
    stringKey: 'promoListHeader',
  },
};

export default translations;
