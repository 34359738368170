import React from 'react';
import getArrayProperty from './getArrayProperty';
import Geo from '../constants/geos.const';

/**
 * This function takes in an orderLine and returns the address to which it was
 * shipped, multiline, and contained within a React Fragment
 *
 * @param {Object} line – the order line from which to derive an address
 */
export const prepareAddress = (line) => {
  if (
    !line ||
    (!Boolean(line.shipTo) && !Boolean(line.billTo)) ||
    (!Boolean(line.shipTo?.address) && !Boolean(line.billTo?.address))
  )
    return;

  const address = line.shipTo?.address || line.billTo?.address;
  const addressLines = [];
  const cityStatePostalLine = [];

  // Always include the consumer name, even if the address includes a store or pickup point name.
  const consumerName = getNameFromOrderLine(line);
  consumerName && addressLines.push(consumerName);

  // Some store or pickup point addresses have the location name as the first line of the address.
  // Only include the store or pickup point name if this is not the case, to avoid line duplication.
  address.locationName &&
    address.locationName !== address.address1 &&
    addressLines.push(address.locationName);

  address.address1 && addressLines.push(address.address1);
  address.address2 && addressLines.push(address.address2);
  address.address3 && addressLines.push(address.address3);
  address.address4 && addressLines.push(address.address4);

  address.city && cityStatePostalLine.push(address.city + ',');

  // Don't display the state if the country is in Europe.
  line.geo !== Geo.EUROPE && address.state && cityStatePostalLine.push(address.state);

  // Don't display the country if it is the USA.
  line.geo !== Geo.US && cityStatePostalLine.push(address.country);
  address.postalCode && cityStatePostalLine.push(address.postalCode);

  cityStatePostalLine.length && addressLines.push(cityStatePostalLine.join(' '));

  return <>{addressLines.join('\n')}</>;
};

/**
 * This function builds an appropriate name from the recipient field of a
 * shipTo of an orderLine.
 *
 * @param {Object} recipient – an object containing the recipient information
 * from which to pull name information
 */
export const getNameFromRecipient = (recipient) => {
  if (!recipient || typeof recipient !== 'object') return;
  const alternateFirstName = recipient.alternateFirstName,
    alternateLastName = recipient.alternateLastName,
    firstName = recipient.firstName,
    lastName = recipient.lastName,
    middleName = recipient.middleName;

  const nameElements = [];

  if (firstName) {
    nameElements.push(firstName);
  } else if (alternateFirstName) {
    nameElements.push(alternateFirstName);
  }

  if (middleName) {
    nameElements.push(middleName);
  }

  if (lastName) {
    nameElements.push(lastName);
  } else if (alternateLastName) {
    nameElements.push(alternateLastName);
  }

  return nameElements.join(' ');
};

/**
 * This function builds an appropriate name from the shipTo field of an
 * orderLine object.
 *
 * @param {Object} shipTo – an object containing the shipping information from
 * which to pull name information
 */
export const getNameFromShipTo = (shipTo) => {
  if (!shipTo || !shipTo.recipient) return;

  const recipient = shipTo.recipient;

  return getNameFromRecipient(recipient);
};

/**
 * This function builds an appropriate name from an orderLine object.
 *
 * @param {Object} line – the order line from which to pull name information
 */
export const getNameFromOrderLine = (line) => {
  if (!line || !line.shipTo) return;

  const shipTo = line.shipTo;

  return getNameFromShipTo(shipTo);
};

/**
 * This function extracts email data from a shipTo field, found on an orderLine
 * object.
 *
 * @param {shipTp} shipTo – an object containing shipping information from
 * which to pull an email
 */
export const getEmailFromShipTo = (shipTo) => {
  const email = shipTo?.contactInformation?.email;
  if (!email) return;
  return email;
};

/**
 * This function extracts email data from an orderLine object.
 *
 * @param {Object} line the order line from which to pull an email
 */
export const getEmailFromOrderLine = (line) => {
  const shipTo = line?.shipTo;

  if (!shipTo) return;

  return getEmailFromShipTo(shipTo);
};

/**
 * This function extracts a phone number from the shipTo field of an orderLine
 * object
 *
 * @param {Object} shipTo – the shipTo object from which to derive a phone
 * number
 */
export const getDayPhoneNumberFromShipTo = (shipTo) => {
  const phone = shipTo?.contactInformation?.dayPhoneNumber;
  if (!phone) return;
  return phone;
};

/**
 * This function extracts a phone number from an orderLine object
 *
 * @param {Object} line – the order line from which to pull an email
 */
export const getDayPhoneNumberFromOrderLine = (line) => {
  const shipTo = line?.shipTo;

  if (!shipTo) return;

  return getDayPhoneNumberFromShipTo(shipTo);
};

/**
 * This function extracts a phone number from the shipTo field of an orderLine
 * object
 *
 * @param {Object} shipTo – the shipTo object from which to derive a phone
 * number
 */
export const getEveningPhoneNumberFromShipTo = (shipTo) => {
  const phone = shipTo?.contactInformation?.eveningPhoneNumber;
  if (!phone) return;
  return phone;
};

/**
 * This function extracts a phone number from an orderLine object
 *
 * @param {Object} line – the order line from which to pull an email
 */
export const getEveningPhoneNumberFromOrderLine = (line) => {
  const shipTo = line?.shipTo;

  if (!shipTo) return;

  return getEveningPhoneNumberFromShipTo(shipTo);
};

/**
 * This function determines whether or not an address is to an Army Post Office
 * or a Fleet Post Office.
 *
 * @param {Object} address – an address to check for militariness
 */
export const isMilitary = (address) => {
  if (!address) return false;
  const city = (address.city || '').toLowerCase();
  return city === 'apo' || city === 'fpo';
};

/**
 * This function derives a billing address from an order object by safely
 * digging in to the payment methods therein.
 *
 * @param {Object} orderDetail - the order object from which to derive an
 * address
 */
export const getBillToAddressFromOrder = (orderDetail) => {
  if (!orderDetail) return;

  const paymentMethods = getArrayProperty(orderDetail, 'paymentMethods');

  if (!paymentMethods.length) return;

  /*
  safely pull name info from the first payment method, which is guaranteed to
  exist based on the length check above (and to be an array, by the contract
  of getArrayProperty).  Cannot use destructuring in case of null value.
  */
  const firstPaymentMethod = paymentMethods[0];
  const firstName = firstPaymentMethod.firstName || '';
  const lastName = firstPaymentMethod.lastName || '';
  const alternateFirstName = firstPaymentMethod.alternateFirstName || '';
  const alternateLastName = firstPaymentMethod.alternateLastName || '';

  // map payment methods to their billTo fields, and filter out undefineds
  const billTos = paymentMethods
    .map((paymentMethod) => {
      return paymentMethod.billTo;
    })
    .filter(Boolean);

  if (!billTos.length) return;

  const { address, contactInformation } = billTos[0];

  return {
    firstName,
    lastName,
    alternateFirstName,
    alternateLastName,
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    address3: address?.address3 || '',
    city: address?.city || '',
    state: address?.state || '',
    postalCode: address?.postalCode || '',
    country: address?.country || '',
    email: contactInformation?.email || '',
    dayPhoneNumber: contactInformation?.dayPhoneNumber || '',
  };
};

export const getShipToAddressFromOrderLines = (originalOrderLines) => {
  // filter out any lines which don't have the pertinent information
  let orderLines = originalOrderLines.filter((orderLine) => {
    return Boolean(
      orderLine && orderLine.shipTo && orderLine.shipTo.address && orderLine.shipTo.address.address1
    );
  });

  const line = orderLines?.[0];

  if (!line) return;

  const { address, recipient } = line.shipTo;

  return {
    firstName: recipient?.firstName || '',
    middleName: recipient?.middleName || '',
    lastName: recipient?.lastName || '',
    alternateFirstName: recipient?.alternateFirstName || '',
    alternateLastName: recipient?.alternateLastName || '',
    locationName: address?.locationName || '',
    address1: address?.address1 || '',
    address2: address?.address2 || '',
    address3: address?.address3 || '',
    address4: address?.address4 || '',
    city: address?.city || '',
    state: address?.state || '',
    postalCode: address?.postalCode || '',
    country: address?.country || '',
    dayPhoneNumber: getDayPhoneNumberFromOrderLine(line) || '',
    eveningPhoneNumber: getEveningPhoneNumberFromOrderLine(line) || '',
    email: getEmailFromOrderLine(line) || '',
  };
};

/**
 * This function derives a shipping address from the currently selected order
 * lines by safely digging in to the shipTo fields therein.
 *
 * @param {Object} selectedLines - an object containing the currently selected
 * order lines
 */
export const getShipToAddressFromSelectedLines = (selectedLines) => {
  if (typeof selectedLines !== 'object') return;

  let orderLines = Object.values(selectedLines);
  return getShipToAddressFromOrderLines(orderLines);
};

export const getShipToAddressFromOrder = (orderDetail) => {
  if (!orderDetail || !orderDetail.orderLines) return;

  let orderLines = orderDetail.orderLines;
  return getShipToAddressFromOrderLines(orderLines);
};

/**
 * Function to change given string to came case
 * @param {*} str - String that needs to be changed to camel case
 */
const titleCase = (str) => {
  return str
    .split(' ')
    .map((word) =>
      word
        .toLowerCase()
        .replace(/^(.{1})(.*)/, (match, offset, string) => `${offset.toLocaleUpperCase()}${string}`)
    )
    .join(' ');
};

/**
 * Helper function to normalize address.
 * Address Validator api needs certain address attributes to be in camel case.
 * @param {*} address - Address Object
 * returns - normalized address
 */
export const normalizeAddress = (address) => {
  const normalizedAddress = Object.keys(address).reduce((acc, key) => {
    acc[key] =
      key !== 'postalCode' && key !== 'state' && key !== 'country' && address[key] !== null
        ? titleCase(address[key])
        : address[key];

    return acc;
  }, {});
  return normalizedAddress;
};
