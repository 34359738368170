import {
  removeTypeNameFromArray,
  removeTypeNameFromObject,
} from '.././../../../../utils/reactUtils';

/**
 * Restructure the flat address object being used in the forms into the their
 * respective fields and objects that the Return Captures API is expecting.
 * @param {object} addr Flat object containing the address fields.
 * @param {object} dialogState - state object used to store dialog related details
 */

export const restructureAddress = (addr, dialogState) => {
  return {
    address: {
      address1: addr?.address1,
      address2: addr?.address2,
      address3: addr?.address3 || '',
      address4: addr?.address4 || '',
      city: addr?.city,
      country: addr?.country,
      county: addr?.county || '',
      state: addr?.state,
      zipCode: addr?.postalCode,
    },
    recipient: {
      firstName: addr?.firstName,
      lastName: addr?.lastName,
      middleName: addr?.middleName || '',
      alternateFirstName: addr?.alternateFirstName || '',
      alternateLastName: addr?.alternateLastName || '',
      companyName: addr?.companyName || '',
    },
    contactInformation: {
      dayPhoneNumber: addr?.dayPhoneNumber,
      email: dialogState.returnLabelEmail || getEmail(dialogState),
      eveningPhoneNumber: addr?.eveningPhoneNumber || '',
    },
  };
};

/**
 * Generate the orderLines being used in the return captures mutation.
 * @param {*} orderLinesMap
 */
export const generateOrderLines = (orderLinesMap) => {
  let result = [];

  Object.keys(orderLinesMap).forEach((key) => {
    const selectedLine = orderLinesMap[key];

    const orderLine = {};
    orderLine.quantity = selectedLine.quantityToReturn || 1;
    orderLine.stockKeepingUnitNumber = selectedLine.item.stockKeepingUnitId;
    orderLine.universalProductCode = selectedLine.item.universalProductCode;
    orderLine.reasonCode = selectedLine.returnReason.code;
    orderLine.reasonText = selectedLine.returnReason.text;

    orderLine.lineCharges = removeTypeNameFromArray(selectedLine.lineCharges);
    orderLine.linePriceInformation = removeTypeNameFromObject(selectedLine.linePriceInformation);
    orderLine.lineTaxes = removeTypeNameFromArray(selectedLine.lineTaxes);
    // removing fields that are not part of return capture request payload
    orderLine.lineCharges &&
      orderLine.lineCharges.forEach((lineCharge) => {
        delete lineCharge.additionalInformation;
        delete lineCharge.agentReference;
      });
    result.push(orderLine);
  });

  return result;
};

/**
 * Gets the bill to email from dialogState
 * @param {*} dialogState
 */
export const getBillToEmail = (dialogState) => {
  // we are getting dialogState.gcShippingAddress from order.billTo
  return dialogState.gcShippingAddress?.email || '';
};

/**
 * Gets the ship to email from dialogState
 * @param {*} dialogState
 */
export const getShipToEmail = (dialogState) => {
  // we are getting dialogState.address from order.orderLines[0]
  return dialogState.address?.email || '';
};

/**
 * Gets the email address with the below preference
 * 1. Giftee email
 * 2. ShipTo email
 * 3. BillTo email
 * @param {*} dialogState
 */
export const getEmail = (dialogState) => {
  return (
    dialogState.gifteeEmail || getShipToEmail(dialogState) || getBillToEmail(dialogState) || ''
  );
};
