import gql from 'graphql-tag';

// Mutation to resend email
const NOTIFICATION_RESEND_MUTATION = gql`
  mutation postEmailResend($input: NotificationResendInput!, $requestId: String!) {
    notificationResend(input: $input, requestId: $requestId) {
      id
    }
  }
`;

export default NOTIFICATION_RESEND_MUTATION;
