import React, { useContext } from 'react';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import { NikeI18nContext } from '@nike/i18n-react';
import { Link } from 'react-router-dom';
import translations from './topBar.i18n';
import mapValues from 'lodash/mapValues';

/**
 * TopBarContainer component containing top bar styling,
 * action buttons (optional) rendered as children
 */
export default function TopBarContainer({ children }) {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const { ARIA_PAGE_HEADER, LOGO_BUTTON_SEARCH_ALT_TEXT } = mapValues(translations, i18nString);

  return (
    <AppBar
      position='sticky'
      elevation={1}
      aria-label={ARIA_PAGE_HEADER}
      className={clsx(classes.appBar)}
      classes={{ root: clsx(classes.appBarRoot) }}>
      <Toolbar variant={'dense'} disableGutters={true} className={classes.toolbar}>
        <Box className={classes.logoContainer}>
          <Button
            component={Link}
            to='/'
            classes={{ root: classes.homeLink }}
            data-testid='logo-link'>
            <img
              src={process.env.PUBLIC_URL + '/assets/img/omobo.png'}
              className={classes.logo}
              alt={LOGO_BUTTON_SEARCH_ALT_TEXT}
            />
          </Button>
        </Box>
        {children}
      </Toolbar>
    </AppBar>
  );
}

const useStyles = makeStyles((theme) => ({
  logoContainer: {
    height: '55px',
    display: 'flex',
    justifyContent: 'center',
    marginRight: 'auto',
  },
  appBarRoot: {
    width: 'auto',
    overflowY: 'hidden',
  },
  toolbar: {
    overflowY: 'hidden',
  },
  appBar: {
    minHeight: '56px',
  },
  logo: {
    width: 'auto',
    height: '55px',
    padding: '5px',
    paddingLeft: '8px',
  },
  homeLink: {
    color: theme.palette.common.white,
  },
}));
