import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { NikeI18nContext } from '@nike/i18n-react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PhoneIcon from '@material-ui/icons/Phone';
import FaceIcon from '@material-ui/icons/Face';
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled';
import PaymentIcon from '@material-ui/icons/Payment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import BasicTableRow from '../../../shared/table/tableRow';
import translations from './paymentDetails.i18n';
import mapValues from 'lodash/mapValues';
import { PaymentTypesDict } from '../../../../constants/paymentTypes.const';
import Button from '@material-ui/core/Button';
import ModifyIcon from '@material-ui/icons/Edit';
import PauseIcon from '@material-ui/icons/Pause';
import config from '../../../../utils/config';
import HasPermission from '../../../shared/hasPermission';
import { EditPaymentInfo, SuspendOrActivatePayment } from '../../../../constants/permissions.const';

/**
 * Item details component.
 * Provide it:
 * the payment - the paymentMethod object used in this component
 * children - whatever you want to display in the context of this payment
 */
export default function PaymentDetails({
  payment,
  typeEntries,
  children,
  cardClass,
  cardSubHeadingClass,
  tableClassName,
  tableHeadingClass,
  cellClass,
  index,
  orderNumber,
  orderType,
  parentReturnOrder,
  partner,
}) {
  const { i18nString } = useContext(NikeI18nContext);
  const classes = useStyles();
  const cspUrl = `${config.cspUrl}csr/cloud/orderDetail?orderNumber=${orderNumber}&orderHeaderKey=`;

  const {
    AUTHORIZED,
    CHARGED,
    DETAILS,
    PAYMENT_TYPE,
    REFUNDED,
    REQUESTED_AUTH,
    REQUESTED_CHARGE,
    SUSPENDED,
    TRANSACTIONS,
    ARIA_PAYMENT_DETAILS_1,
    ARIA_PAYMENT_DETAILS_2,
    ARIA_PAYMENT_SUMMARY,
    ACTIVATE_PAYMENT,
    EDIT_PAYMENT,
    SUSPEND_PAYMENT,
  } = mapValues(translations, i18nString);

  const {
    displayCreditCardNumber,
    displayDebitCardNumber,
    displayGiftCardNumber,
    creditCardExpiration,
    firstName,
    middleName,
    lastName,
    billTo,
  } = payment;

  const address = (billTo && billTo.address) || {};

  const summaryHeaders = [REQUESTED_AUTH, AUTHORIZED, REQUESTED_CHARGE, CHARGED, REFUNDED];

  const summaryValues = [
    payment.requestedAuthorizationAmount,
    payment.totalAuthorizedAmount,
    payment.requestedChargeAmount,
    payment.totalChargedAmount,
    payment.totalRefundedAmount,
  ];

  const dayPhoneNum =
    billTo && billTo.contactInformation && billTo.contactInformation.dayPhoneNumber
      ? billTo.contactInformation.dayPhoneNumber
      : 'N/A';

  const isPaymentTypeSuspended = Boolean(typeEntries.find((entry) => entry.status === 'ERROR'));
  const PaymentTypeStatusText = () =>
    isPaymentTypeSuspended ? (
      <Typography
        className={classes.typeStatus}
        variant='h3'
        color='error'>{` (${SUSPENDED.toUpperCase()})`}</Typography>
    ) : (
      ''
    );
  const paymentType = PaymentTypesDict[payment.paymentType];

  return (
    <Card className={cardClass}>
      <CardContent className={classes.cardContent}>
        <Box className={classes.detailsGrid}>
          <CardHeader
            className={classes.detailsTitle}
            classes={{ root: classes.cardHeaderRoot }}
            disableTypography
            title={
              <>
                <Typography variant='h2' className={classes.typeStatus}>
                  {`${PAYMENT_TYPE}: ${paymentType}`}
                </Typography>
                <PaymentTypeStatusText />
              </>
            }
          />
          <Box p={1} className={classes.detailsButtons}>
            {/* only display a button if we have a non-exchange, non-partner sales order */}
            {orderType === 'SALES_ORDER' && !parentReturnOrder && !partner && (
              <>
                <HasPermission permission={SuspendOrActivatePayment}>
                  {/* ensure we display the correct button for Payment Suspension/Activation */}
                  {isPaymentTypeSuspended ? (
                    <Button
                      aria-label={ACTIVATE_PAYMENT.toLowerCase()}
                      className={classes.button}
                      href={cspUrl}
                      data-testid='activate-payment-button'
                      target='_blank'>
                      <PlayCircleFilled className={classes.buttonIcon} />
                      {ACTIVATE_PAYMENT}
                    </Button>
                  ) : (
                    <Button
                      aria-label={SUSPEND_PAYMENT.toLowerCase()}
                      className={classes.button}
                      href={cspUrl}
                      data-testid='suspend-payment-button'
                      target='_blank'>
                      <PauseIcon className={classes.buttonIcon} />
                      {SUSPEND_PAYMENT}
                    </Button>
                  )}
                </HasPermission>
                <HasPermission permission={EditPaymentInfo}>
                  <Button
                    color='default'
                    label={EDIT_PAYMENT.toLowerCase()}
                    className={classes.button}
                    href={cspUrl}
                    data-testid='edit-payment-button'
                    target='_blank'>
                    <ModifyIcon className={classes.buttonIcon} />
                    {EDIT_PAYMENT}
                  </Button>
                </HasPermission>
              </>
            )}
          </Box>

          <Box display='flex' className={classes.detailsDetails}>
            <List aria-label={ARIA_PAYMENT_DETAILS_1} className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <FaceIcon />
                </ListItemIcon>
                <ListItemText primary={`${firstName} ${middleName} ${lastName}`} />
              </ListItem>
              <ListItem alignItems='flex-start' className={classes.listItem}>
                <ListItemIcon>
                  <HomeWorkIcon />
                </ListItemIcon>
                <ListItemText
                  primary={address.address1}
                  secondary={`${address.city}, ${address.state} ${address.postalCode}`}
                />
              </ListItem>
            </List>
            <List aria-label={ARIA_PAYMENT_DETAILS_2} className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText primary={dayPhoneNum} />
              </ListItem>
              <ListItem alignItems='flex-start' className={classes.listItem}>
                <ListItemIcon>
                  <PaymentIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`**** **** **** ${displayCreditCardNumber ||
                    displayDebitCardNumber ||
                    displayGiftCardNumber}`}
                  secondary={creditCardExpiration}
                />
              </ListItem>
            </List>
          </Box>
        </Box>
        <Typography aria-label={DETAILS.toLowerCase()} variant='h3' className={cardSubHeadingClass}>
          {DETAILS}
        </Typography>
        <Table aria-label={ARIA_PAYMENT_SUMMARY} className={tableClassName}>
          <BasicTableRow header data={summaryHeaders} cellClassName={tableHeadingClass} />
          <BasicTableRow data={summaryValues} cellClassName={cellClass} />
        </Table>
      </CardContent>
      <Accordion elevation={0}>
        <AccordionSummary
          expandIcon={<ExpandIcon />}
          aria-controls={`payment-transactions-${index}`}
          classes={{ root: classes.accordionStyle, expanded: classes.accordionStyle }}>
          <Typography
            aria-label={TRANSACTIONS.toLowerCase()}
            variant='h3'
            className={cardSubHeadingClass}>
            {TRANSACTIONS}
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionStyle}>{children}</AccordionDetails>
      </Accordion>
    </Card>
  );
}

const useStyles = makeStyles((theme) => ({
  button: {
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.dark,
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '16em',
  },
  listItem: { minHeight: '1em' },
  typeStatus: {
    display: 'inline',
    fontSize: '1.3rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  detailsGrid: {
    display: 'grid',
    gridTemplateRows: 'repeat(2, auto)',
    gridTemplateColumns: '1.5fr 0.5fr',
    gridTemplateAreas: `
    "Title Buttons"
    "Details Buttons"`,
    paddingBottom: 0,
  },
  detailsButtons: {
    gridArea: 'Buttons',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  detailsDetails: {
    gridArea: 'Details',
  },
  detailsTitle: {
    gridArea: 'Title',
  },
  list: {
    margin: 0,
    paddingTop: theme.spacing(0.75),
  },
  accordionStyle: {
    'padding': '0 inherit',
    'margin': 0,
    '&:first-child': {
      margin: 0,
      paddingTop: 0,
    },
  },
  cardContent: {
    margin: 0,
    paddingBottom: 0,
  },
  buttonIcon: { marginRight: theme.spacing(1), color: theme.palette.primary.dark },
}));
PaymentDetails.propTypes = {
  cardClass: PropTypes.string.isRequired,
  cardSubHeadingClass: PropTypes.string.isRequired,
  cellClass: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  payment: PropTypes.shape({
    billTo: PropTypes.shape({
      address: PropTypes.shape({
        address1: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        postalCode: PropTypes.string,
      }),
      contactInformation: PropTypes.shape({
        dayPhoneNumber: PropTypes.string,
      }),
    }),
    creditCardExpiration: PropTypes.string,
    displayCreditCardNumber: PropTypes.string,
    displayDebitCardNumber: PropTypes.string,
    displayGiftCardNumber: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
    paymentType: PropTypes.string,
    requestedAuthorizationAmount: PropTypes.string,
    requestedChargeAmount: PropTypes.number,
    totalAuthorizedAmount: PropTypes.string,
    totalChargedAmount: PropTypes.number,
    totalRefundedAmount: PropTypes.number,
  }).isRequired,
  typeEntries: PropTypes.arrayOf(
    PropTypes.shape({
      find: PropTypes.string,
      status: PropTypes.string,
    })
  ).isRequired,
  tableClassName: PropTypes.string,
  tableHeadingClass: PropTypes.string.isRequired,
  orderNumber: PropTypes.string,
  orderType: PropTypes.string,
  parentReturnOrder: PropTypes.string,
  partner: PropTypes.string,
};

PaymentDetails.defaultProps = {
  payment: {
    billTo: {
      address: {
        address1: '',
        city: '',
        postalCode: '',
        state: '',
      },
      contactInformation: {
        dayPhoneNumber: '',
      },
    },
    creditCardExpiration: '',
    displayCreditCardNumber: '',
    displayDebitCardNumber: '',
    displayGiftCardNumber: '',
    firstName: '',
    lastName: '',
    middleName: '',
    requestedAuthorizationAmount: '',
    requestedChargeAmount: 0,
    totalAuthorizedAmount: '',
    totalChargedAmount: 0,
    totalRefundedAmount: 0,
  },
  typeEntries: {
    find: '',
    status: '',
  },
  tableClassName: '',
  orderNumber: '',
  partner: '',
};
