import React, { createContext, useReducer } from 'react';
import { dialogReducer } from '../reducers/dialogReducer';

export const DialogContext = createContext({});
const { Provider } = DialogContext;

export const defaultState = {
  submissionSteps: [],
  dialogType: null,
  isOpen: false,
  lock: false,
  activeStep: 0,
  selectedLines: {},
  retryCount: 0,
  address: {
    firstName: '',
    middleName: '',
    lastName: '',
    alternateFirstName: '',
    alternateLastName: '',
    locationName: '',
    address1: '',
    address2: '',
    address3: '',
    address4: '',
    city: '',
    state: '',
    postalCode: '',
    email: '',
    prevEmail: '',
    dayPhoneNumber: '',
    eveningPhoneNumber: '',
  },
  gcShippingAddress: {
    firstName: '',
    lastName: '',
    alternateFirstName: '',
    alternateLastName: '',
    locationName: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    dayPhoneNumber: '',
    email: '',
  },
  isAlaskanAddress: false,
  prevIsAlaskanAddress: false,
  hasUserEditedAddress: false,
  hasTimedOut: false,
  isMilitaryAddress: false,
  shippingOption: 'none',
  prevShippingOption: 'none',
  gifteeEmail: '',
  deliveryMethod: 'email',
  isGifteeEmailError: true,
  isGiftReturn: false,
  prevReturnPaymentOption: null,
  returnPaymentOption: 'original',
  returnOrderNumber: '',
  validateAddress: false,
  areAllOrderLinesGiftCards: false,
  shipTo: {
    address: {
      locationName: '',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    },
    recipient: {
      firstName: '', // actually the value of company name
    },
  },
  shippingDiscountReason: null,
};

export const DialogProvider = ({ children }) => {
  const [state, reducer] = useReducer(dialogReducer, defaultState);

  return <Provider value={[state, reducer]}>{children}</Provider>;
};
