import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import {
  getCarrier,
  getShipGroup,
  getShipmentDate,
  getShippingMethod,
  getStatus,
  getTrackingLink,
  isDelivered,
  getOrderLineKey,
} from '../../../../utils/shipment';
import { getOrderLineShipmentDetails } from '../../../../utils/orderLine';
import translations from './details.i18n';

const ShipmentDetails = ({ orderLines, shipment, locale }) => {
  const { i18nString } = useContext(NikeI18nContext);
  const delivered = isDelivered(shipment);
  const classes = useStyles();
  const { fulfiller, estimatedDeliveryDate } = getOrderLineShipmentDetails(
    orderLines,
    getOrderLineKey(shipment)
  );

  const {
    CARRIER,
    EDD,
    FULFILLER,
    SHIP_GROUP,
    SHIPPING_METHOD,
    STATUS,
    TRACKING_NUMBER,
  } = mapValues(translations, i18nString);

  return (
    <Table className={classes.parentTable}>
      <TableHead>
        <TableRow className={classes.headerRow}>
          <TableCell className={classes.tableHeader}>{TRACKING_NUMBER}</TableCell>
          <TableCell className={classes.tableHeader}>{CARRIER}</TableCell>
          <TableCell className={classes.tableHeader}>{SHIPPING_METHOD}</TableCell>
          <TableCell className={classes.tableHeader}>{SHIP_GROUP}</TableCell>
          <TableCell className={classes.tableHeader}>{STATUS}</TableCell>
          {// we only want this column if the order has not yet been delivered
          !delivered && <TableCell className={classes.tableHeader}>{EDD}</TableCell>}

          <TableCell className={classes.tableHeader}>{FULFILLER}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow className={classes.contentRow}>
          <TableCell className={classes.tableCell}>{getTrackingLink(shipment)}</TableCell>
          <TableCell className={classes.tableCell}>{getCarrier(shipment)}</TableCell>
          <TableCell className={classes.tableCell}>{getShippingMethod(shipment)}</TableCell>
          <TableCell className={classes.tableCell}>{getShipGroup(shipment)}</TableCell>
          <TableCell className={classes.tableCell}>
            {getStatus(shipment)}{' '}
            {getShipmentDate(shipment, locale) && ' on ' + getShipmentDate(shipment, locale)}
          </TableCell>
          {// we only want this column if the order has not yet been delivered
          !delivered && (
            <TableCell className={classes.tableCell}>{estimatedDeliveryDate}</TableCell>
          )}
          <TableCell className={classes.tableCell}>{fulfiller}</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

ShipmentDetails.propTypes = {
  orderLines: PropTypes.arrayOf(PropTypes.object),
  shipment: PropTypes.object,
  locale: PropTypes.string,
};

const useStyles = makeStyles((theme) => ({
  contentRow: {
    height: '30px',
  },
  headerRow: {
    height: '30px',
    fontSize: '0.75rem',
  },
  parentTable: {
    marginBottom: '40px',
  },
  tableCell: {
    borderBottom: 0,
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
  tableHeader: {
    padding: 0,
    paddingLeft: theme.spacing(1),
  },
}));

export default ShipmentDetails;
