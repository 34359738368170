const translations = {
  SALES_ORDER: {
    description: 'Sales Order Header',
    primaryValue: 'Sales Order:',
    stringKey: 'salesOrder',
  },
  RETURN_ORDER: {
    description: 'Return Order Header',
    primaryValue: 'Return Order(s):',
    stringKey: 'returnOrders',
  },
  EXCHANGE_ORDER: {
    description: 'Exchange Order Header',
    primaryValue: 'Exchange Order:',
    stringKey: 'exchangeOrder',
  },
  RELATED_ORDERS: {
    description: 'Related Order Header',
    primaryValue: 'Related Orders',
    stringKey: 'relatedOrders',
  },
};
export default translations;
