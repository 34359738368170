import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import CircularProgress from '@material-ui/core/CircularProgress';
import Alert from '@material-ui/lab/Alert';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { OrderContext } from '../../../../store/contexts/orderContext';
import AthleteContext from '../../../../store/contexts/athleteContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { NikeI18nContext } from '@nike/i18n-react';
import { I18nContext } from '../../../../store/contexts/i18Context';
import { Query } from 'react-apollo';
import REASON_CODES_QUERY from '../../../../queries/reasonCodes.query';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import translations from './inspectReturn.i18n.js';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { ReasonCodeTypes, ReasonCodeAppId } from '../../../../constants/dialog.const';
import mapValues from 'lodash/mapValues';
import { useQuery } from '@apollo/react-hooks';
import { step2SharedStyles } from '../sharedStyles';
import clsx from 'clsx';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import { setReturnInspectionDetails } from './../../../../utils/localStorage';

/**
 * For a given reasonId and a given array of ReasonCodes,
 * returns the description of the ReasonCode that has the given id.
 *
 * @param (String|Number} id – the id which we're searching for
 * @param {Array} reasonCodes – the reason codes in which to search
 */
export const findReasonTextById = (id, reasonCodes) => {
  const reason = reasonCodes.find((element) => element.id === id);
  return reason && reason.description;
};

export default function Step2() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [i18State] = useContext(I18nContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [athleteInfo] = useContext(AthleteContext);
  const { data: reasonData } = useQuery(REASON_CODES_QUERY, {
    variables: {
      appId: ReasonCodeAppId,
      omsRegionReference: orderDetail.omsRegionReference,
      type: ReasonCodeTypes.RETURN,
      language: i18State.language,
    },
    notifyOnNetworkStatusChange: true,
  });

  const {
    setInspectionReason,
    setInspectReasonCodeType,
    setReturnDisposition,
    setEscalationNotes,
  } = dialogActions;
  const {
    ARIA_OPEN_STYLE,
    QUANTITY,
    CONSUMER_RETURN_REASON,
    INSPECTION_REASON,
    REASON_UNAVAILABLE,
    SELECT_INSPECTION_STATUS,
    SELECT_RETURN_DISPOSITION,
    REASON_CODES_LOADING,
    REJECT,
    ESCALATE,
    APPROVE,
    SIZE,
  } = mapValues(translations, i18nString);
  const { selectedLines } = dialogState;

  // pull the currency code from the details, for formatting purposes
  const { currency } = orderDetail;

  /* set reason codes here so there won't be console error 
  about rendering component and setting a state simultanouesly */
  useEffect(() => {
    Object.values(selectedLines).map((line) => {
      if (!line.inspectReasonCodeType) {
        dialogDispatch(setInspectReasonCodeType(line, ReasonCodeTypes.INSPECTION_PASS));
      }
      return null;
    });
  });

  // This use effect sets the details needed for return inspection audit in local storage
  useEffect(() => {
    setReturnInspectionDetails({
      returnOrderNumber: orderDetail.orderNumber,
      returnOrderAssistingAgentReference: orderDetail.assistingAgentReference,
      customerProfileReference: orderDetail.customerProfileReference,
      athleteName: athleteInfo.name,
      returnCreationDate: orderDetail.orderCreateDate,
    });
  }, []);

  return (
    <>
      {Object.values(selectedLines).map((line, i) => {
        const quantityToInspect = line.quantity;
        // -1 is the code for the default, unselectable reason
        const reason = line.inspectionReason || { code: '-1' };
        const dispositionReason = line.disposition || { code: '-1' };

        return (
          <Card key={i} className={clsx(classes.itemDetailsCard)}>
            <CardMedia
              className={classes.productThumb}
              image={getProductImageFromOrderLine(line)}
              title={line.itemDescription}
            />
            <div className={classes.itemDetails}>
              <CardContent className={classes.itemDetailsContent}>
                <Typography variant='body1'>
                  <Link
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label={ARIA_OPEN_STYLE}
                    href={
                      'https://store.nike.com/us/en_us/pw/n/1j7?sl=' +
                      line.styleNumber +
                      '-' +
                      line.colorCode
                    }>
                    {line.styleNumber + '-' + line.colorCode}
                  </Link>
                </Typography>
                <Typography noWrap variant='body1'>
                  {line.item && line.item.itemDescription}
                </Typography>
                <Typography variant='body1'>{line.colorDescription}</Typography>
                <Typography variant='body1'>
                  {SIZE} {line.displaySize}
                </Typography>
                <Typography variant='body1'>
                  <FormattedCurrency
                    amount={line.linePriceInformation.unitPrice}
                    currency={currency}
                  />
                </Typography>
                <Typography variant='body1'>
                  {reasonData && reasonData.reasonCodesV2.reasons && (
                    <>
                      {` ${CONSUMER_RETURN_REASON}: ${line.returnReason ||
                        ''} - ${findReasonTextById(
                        line.returnReason,
                        reasonData.reasonCodesV2.reasons
                      ) || ''}`}
                    </>
                  )}
                </Typography>
                <Typography variant='body1'>
                  {QUANTITY}: {quantityToInspect || ''}
                </Typography>
                <FormControl className={classes.reasonCodeType} component='fieldset'>
                  <FormLabel component='legend'>{SELECT_INSPECTION_STATUS}</FormLabel>
                  <RadioGroup
                    row
                    value={line.inspectReasonCodeType || ReasonCodeTypes.INSPECTION_PASS}
                    onChange={(event) => {
                      dialogDispatch(setInspectReasonCodeType(line, event.target.value));
                      dialogDispatch(setInspectionReason(line, { code: '-1' }));
                      dialogDispatch(setReturnDisposition(line, { code: '-1' }));
                    }}>
                    <FormControlLabel
                      value={ReasonCodeTypes.INSPECTION_PASS}
                      control={<Radio />}
                      label={APPROVE}
                      data-testid={'inspection-approve'}
                    />
                    <FormControlLabel
                      value={ReasonCodeTypes.REJECT}
                      control={<Radio />}
                      label={REJECT}
                      data-testid={'inspection-reject'}
                    />
                    <FormControlLabel
                      value={ReasonCodeTypes.ESCALATION}
                      control={<Radio />}
                      label={ESCALATE}
                      data-testid={'inspection-escalate'}
                    />
                  </RadioGroup>
                </FormControl>
                <Query
                  query={REASON_CODES_QUERY}
                  variables={{
                    appId: ReasonCodeAppId,
                    omsRegionReference: orderDetail.omsRegionReference,
                    type: line.inspectReasonCodeType || ReasonCodeTypes.INSPECTION_PASS,
                    language: i18State.language,
                  }}>
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <CircularProgress aria-label={REASON_CODES_LOADING} title='progress-bar' />
                      );
                    if (error) return <Alert severity='warning'>{error.message}</Alert>;
                    if (data.reasonCodesV2 && data.reasonCodesV2.reasons) {
                      const reasonCodes = data.reasonCodesV2.reasons;
                      return (
                        <FormControl>
                          <Select
                            className={classes.reasonCodeSelect}
                            value={reason.code || -1}
                            name={line.lineNumber}
                            data-testid={`select-inspection-reason-${i}`}
                            onChange={(event) => {
                              // save both the reason code and the reason description
                              const selectedReason = reasonCodes.find(
                                (reason) => reason.id === event.target.value
                              );
                              dialogDispatch(
                                setInspectionReason(line, {
                                  code: selectedReason.id,
                                  text: selectedReason.description,
                                })
                              );
                            }}>
                            <MenuItem value='-1' disabled>
                              {INSPECTION_REASON}
                            </MenuItem>
                            {reasonCodes.map((reason, j) => (
                              <MenuItem
                                key={j}
                                value={reason.id}
                                name={reason.fault}
                                data-testid={`inspection-reason-${j}`}>
                                {reason.description}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    } else {
                      return <Alert severity='warning'>{REASON_UNAVAILABLE}</Alert>;
                    }
                  }}
                </Query>
                <>
                  {(line.inspectReasonCodeType === ReasonCodeTypes.INSPECTION_PASS ||
                    line.inspectReasonCodeType === ReasonCodeTypes.REJECT) && (
                    <Query
                      query={REASON_CODES_QUERY}
                      variables={{
                        appId: ReasonCodeAppId,
                        omsRegionReference: orderDetail.omsRegionReference,
                        type: ReasonCodeTypes.RETURN_DISPOSITION,
                        language: i18State.language,
                      }}>
                      {({ loading, error, data }) => {
                        if (loading)
                          return (
                            <CircularProgress
                              aria-label={REASON_CODES_LOADING}
                              title='progress-bar'
                            />
                          );
                        if (error) return <Alert severity='warning'>{error.message}</Alert>;
                        if (data.reasonCodesV2 && data.reasonCodesV2.reasons) {
                          const dispositionReasonCodes = data.reasonCodesV2.reasons;
                          return (
                            <FormControl className={classes.reasonCodeContainer}>
                              <Select
                                className={classes.reasonCodeSelect}
                                value={dispositionReason.code}
                                name={line.lineNumber}
                                data-testid={`select-return-disposition-${i}`}
                                onChange={(event) => {
                                  /**
                                   * save both the reason code and the reason
                                   * description
                                   */
                                  const selectedDisposition = dispositionReasonCodes.find(
                                    (dispositionReason) =>
                                      dispositionReason.id === event.target.value
                                  );
                                  dialogDispatch(
                                    setReturnDisposition(line, {
                                      code: selectedDisposition.id,
                                      text: selectedDisposition.description,
                                    })
                                  );
                                }}>
                                <MenuItem value='-1' disabled>
                                  {SELECT_RETURN_DISPOSITION}
                                </MenuItem>
                                {dispositionReasonCodes.map((dispositionReason, j) => (
                                  <MenuItem
                                    key={j}
                                    value={dispositionReason.id}
                                    name={dispositionReason.fault}
                                    data-testid={`return-disposition-${j}`}>
                                    {dispositionReason.description}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          );
                        } else {
                          return <Alert severity='warning'>{REASON_UNAVAILABLE}</Alert>;
                        }
                      }}
                    </Query>
                  )}
                </>
                {line.inspectReasonCodeType === ReasonCodeTypes.ESCALATION && (
                  <TextField
                    className={classes.notes}
                    data-testid={'inspection-notes'}
                    required
                    id='outlined-textarea'
                    label='Inspection Notes (required)'
                    placeholder='Inspection Notes'
                    multiline
                    rows='4'
                    variant='outlined'
                    onChange={(event) =>
                      dialogDispatch(setEscalationNotes(line, event.target.value))
                    }
                  />
                )}
              </CardContent>
            </div>
          </Card>
        );
      })}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  ...step2SharedStyles(theme),
  reasonCodeContainer: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  reasonCodeSelect: {
    minWidth: 300,
    maxWidth: 300,
  },
  // todo? add notes grid area?
  notes: {
    marginTop: theme.spacing(2),
    maxWidth: 420,
    width: '100%',
  },
  reasonCodeType: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    minWidth: 500,
  },
}));
