/* React/Utils */
import React from 'react';
import PropTypes from 'prop-types';
/* Material-UI */
import { TableCell, TableRow } from '@material-ui/core';
/* Local */
import Mock from './../../constants/mock.const.js';
import NewTabButton from '../shared/newTabButton';
import { protocolAndHost } from '../../utils/link';

/**
 * This component is for rows of the search order table
 *
 * @param {object} props – React props object
 * @param {object} props.order - Order data for this row's search result
 */
const OrderSearchResultsRow = ({ order }) => {
  const { orderLines } = order;
  const styleNumbers = orderLines.map((orderLine) => orderLine.styleNumber);
  // Consumer Display Name
  const lastName = order?.billTo?.recipient?.lastName
    ? `${order?.billTo?.recipient?.lastName}, `
    : '';
  const firstName = order?.billTo?.recipient?.firstName || '';
  const consumerName = lastName + firstName;
  // Bill To Address
  const city = order?.billTo?.address?.city ? `${order?.billTo?.address?.city}, ` : '';
  const state = order?.billTo?.address?.state ? `${order?.billTo?.address?.state}, ` : '';
  const country = order?.billTo?.address?.country || '';
  const billToAddress = city + state + country;

  /**
   * returns route for clicked on order
   * @param {*} orderNumber - Order number that is clicked
   * @return {String} - returns route for clicked on order
   */
  const orderUrl = (orderNumber) => {
    // This is to handle order click in Mock search page.
    if (process.env.REACT_APP_ENV === 'training' || process.env.REACT_APP_ENV === 'train_local') {
      return `/order/${Mock.DefaultOrder}/details`;
    } else {
      return `/order/${orderNumber}/details`;
    }
  };

  return (
    <>
      <TableRow key={order.orderNumber}>
        <TableCell component='th' scope='row'>
          <NewTabButton
            href={`${protocolAndHost()}${orderUrl(order.orderNumber)}`}
            label={order.orderNumber}
          />
        </TableCell>
        {/* Todo: Add time zone e.g PST */}
        <TableCell align='left'>
          {order?.orderSubmitDate && new Date(order.orderSubmitDate).toLocaleString()}
        </TableCell>
        <TableCell align='left'>{consumerName}</TableCell>
        <TableCell align='left'>{billToAddress}</TableCell>
        {/* Todo: currency */}
        <TableCell align='left'>{order.totalAmount}</TableCell>
        <TableCell align='left'>{order.status}</TableCell>
        <TableCell align='left'>{styleNumbers.join(', ')}</TableCell>
      </TableRow>
    </>
  );
};

OrderSearchResultsRow.propTypes = {
  order: PropTypes.shape({
    billTo: PropTypes.shape({
      address: PropTypes.shape({
        city: PropTypes.string,
        state: PropTypes.string,
        country: PropTypes.string,
      }),
      recipient: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    }),
    orderLines: PropTypes.arrayOf(
      PropTypes.shape({
        styleNumber: PropTypes.string,
      })
    ),
    orderNumber: PropTypes.string,
    orderSubmitDate: PropTypes.string,
    status: PropTypes.string,
    totalAmount: PropTypes.number,
  }).isRequired,
};

export default OrderSearchResultsRow;
