/** React / Utils */
import React, { useContext } from 'react';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';

/** Material UI */
import AddIcon from '@material-ui/icons/AddCircle';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Toolbar from '@material-ui/core/Toolbar';

/** Local */
import { AddComment } from '../../../../constants/permissions.const';
import OrderContext from '../../../../store/contexts/orderContext';
import { transformComment } from '../../../../utils/comments';
import getArrayProperty from '../../../../utils/getArrayProperty';
import BasicTableRow from '../../../shared/table/tableRow';
import HasPermission from '../../../shared/hasPermission';
import translations from './comments.i18n';
import ErrorBoundary from '../../../error/errorBoundary';
import { DialogTypes } from '../../../../constants/dialog.const';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';

const Comments = () => {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [, dialogDispatch] = useContext(DialogContext);
  const orderNotes = getArrayProperty(orderDetail, 'orderNotes');
  const orderLines = getArrayProperty(orderDetail, 'orderLines');

  const {
    ADD_COMMENT,
    COMMENTS,
    CONTACT_TYPE,
    DATE,
    DETAILS,
    PRIME_LINE,
    REASON,
    REFERENCE,
    USER,
    ARIA_COMMENTS,
  } = mapValues(translations, i18nString);

  const handleOpenAddCommentDialog = () => {
    dialogDispatch(dialogActions.open(DialogTypes.ADD_COMMENT));
  };

  /**
   * Collecting the order level notes
   * Concatenating them to the orderLine level notes
   * Adding a line number value (empty for order level notes)
   * Sorting final array by the contact date
   */
  const comments = orderNotes
    .map((note) => Object.assign(note, { line: '' }))
    .concat(
      ...orderLines
        .filter((line) => {
          // remove lines without line notes to avoid errors
          return Boolean(line.lineNotes);
        })
        .map((line) => {
          return line.lineNotes.map((note) => Object.assign(note, { line: line.lineNumber }));
        })
    )
    .sort((a, b) => Date.parse(b.contactDate) - Date.parse(a.contactDate));

  const commentsHeaders = [DATE, PRIME_LINE, USER, REASON, CONTACT_TYPE, REFERENCE, DETAILS];

  return (
    <ErrorBoundary>
      <Card className={classes.cardSpacing} elevation={3}>
        <CardContent className={classes.cardContent}>
          <Toolbar variant={'dense'} className={classes.toolbar} disableGutters={true}>
            <CardHeader
              disableTypography
              classes={{ root: classes.cardHeaderRoot }}
              data-testid='comments-title'
              title={<h1 className={classes.cardHeading}>{COMMENTS}</h1>}
            />
            <HasPermission permission={AddComment}>
              <Button
                color='default'
                className={`${classes.button} ${classes.buttonBotItem}`}
                onClick={handleOpenAddCommentDialog}
                data-testid={'add-comment-button'}>
                <AddIcon className={classes.buttonIcon} />
                {ADD_COMMENT}
              </Button>
            </HasPermission>
          </Toolbar>
          <Table aria-label={ARIA_COMMENTS} data-testid='comments-table'>
            <BasicTableRow header data={commentsHeaders} cellClassName={classes.tableHeader} />
            {comments.map((comment, i) => (
              <BasicTableRow
                key={i}
                data={transformComment(comment)}
                cellRootClassName={classes.tableCell}
              />
            ))}
          </Table>
        </CardContent>
      </Card>
    </ErrorBoundary>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    justifyContent: 'space-between',
  },
  button: {
    margin: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  buttonIcon: {
    marginRight: theme.spacing(1),
  },
  cardSpacing: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(1.5),
  },
  cardHeaderRoot: {
    paddingLeft: '9px',
  },
  cardHeading: {
    margin: 0,
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  },
  tableCell: {
    padding: theme.spacing(1.2),
  },
  tableHeader: {
    margin: 0,
    paddingTop: 0,
    padding: theme.spacing(1.2),
  },
}));

export default Comments;
