import sharedTranslations from './shared.i18n';

const translations = {
  ARIA_OPEN_STYLE: {
    description: 'aria label for open style',
    primaryValue: 'open style in store',
    stringKey: 'ariaOpenStyle',
  },
  ARIA_RETURN_REASON: {
    description: 'aria label for select return reason',
    primaryValue: 'select return reason',
    stringKey: 'ariaReturnReason',
  },
  BACK: sharedTranslations.BACK,
  NEXT: sharedTranslations.NEXT,
  ONLY_1_AVAILABLE: {
    description: 'label on the returns wizard for "Only 1 Available"',
    primaryValue: 'Only 1 Available',
    stringKey: 'only1Available',
  },
  QUANTITY: {
    description: 'label on the returns wizard for "Quantity"',
    primaryValue: 'Quantity',
    stringKey: 'quantity',
  },
  REASON_FOR_RETURN: {
    description: 'label on the returns wizard for "Reason for Return"',
    primaryValue: 'Reason for Return',
    stringKey: 'reasonForReturn',
  },
  SIZE: {
    description: 'label on the size',
    primaryValue: 'Size',
    stringKey: 'size',
  },
  SELECT_QUANTITY: {
    description: 'label on the dropdown to select quantity',
    primaryValue: 'Select Quantity',
    stringKey: 'selectQuantity',
  },
  REASON_CODES_LOADING: {
    description: 'aria label for reason code loading spinner',
    primaryValue: 'reason codes loading',
    stringKey: 'ariaReasonCodes',
  },
};

export default translations;
