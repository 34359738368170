import { useQuery } from '@apollo/react-hooks';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import React, { useContext, useEffect } from 'react';
import { ReasonCodeTypes, ReasonCodeAppId } from '../../../../constants/dialog.const';
import REASON_CODES_QUERY from '../../../../queries/reasonCodes.query';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { I18nContext } from '../../../../store/contexts/i18Context';
import { getProductImageFromOrderLine } from '../../../../utils/product';
import { step2SharedStyles } from '../sharedStyles';
import translations from './cancelItems.i18n.js';
import { FormattedCurrency } from '../../../shared/formatCurrency';
import Warning from '../../../shared/warning';

export default function Step2() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [orderDetail] = useContext(OrderContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [i18State] = useContext(I18nContext);
  const { setCancelReason, setQuantityToCancel } = dialogActions;
  const {
    ONLY_1_AVAILABLE,
    REASON_FOR_CANCEL,
    REASON_UNAVAILABLE,
    SIZE,
    QUANTITY,
    SELECT_QUANTITY,
    ARIA_OPEN_STYLE,
    ARIA_CANCEL_REASON,
    ARIA_REASON_CODES_LOADING,
  } = mapValues(translations, i18nString);
  const { selectedLines } = dialogState;
  const { data: reasonCodeData, loading: reasonCodeLoading, error: reasonCodeError } = useQuery(
    REASON_CODES_QUERY,
    {
      variables: {
        appId: ReasonCodeAppId,
        omsRegionReference: orderDetail.omsRegionReference,
        type:
          orderDetail.orderType === 'SALES_ORDER'
            ? ReasonCodeTypes.CANCEL
            : ReasonCodeTypes.RETURN_CANCEL,
        language: i18State.language,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  // pull the currency code from the details, for formatting purposes
  const { currency } = orderDetail;

  /*
  Set the cancellable quantity of a line item to 1 upon entering step 2
  for the first time if the total quantity available for cancellation is 1. 
  */
  useEffect(() => {
    Object.values(selectedLines).forEach((selectedLine) => {
      if (!Boolean(selectedLine.quantityToCancel)) {
        if (selectedLine.quantity === 1) {
          dialogDispatch(setQuantityToCancel(selectedLine, 1));
        }
      }
    });
  }, []);

  return (
    <>
      {Object.values(selectedLines).map((line, i) => {
        const quantityArray = Array.from({ length: line.quantity }, (v, k) => k + 1);
        const quantityToCancel = line.quantityToCancel;
        // -1 is the code for the default, unselectable reason
        const reason = line.cancelReason || { code: '-1' };
        return (
          <Card key={i} className={classes.itemDetailsCard}>
            <CardMedia
              className={classes.productThumb}
              image={getProductImageFromOrderLine(line)}
              title={line.itemDescription}
            />
            <div className={classes.itemDetails}>
              <CardContent className={classes.itemDetailsContent}>
                <Typography variant='body1'>
                  <Link
                    target='_blank'
                    rel='noopener noreferrer'
                    aria-label={ARIA_OPEN_STYLE}
                    href={
                      'https://store.nike.com/us/en_us/pw/n/1j7?sl=' +
                      line.styleNumber +
                      '-' +
                      line.colorCode
                    }>
                    {line.styleNumber + '-' + line.colorCode}
                  </Link>
                </Typography>
                <Typography noWrap variant='body1'>
                  {line.item && line.item.itemDescription}
                </Typography>
                <Typography variant='body1'>{line.colorDescription}</Typography>
                <Typography variant='body1'>
                  {SIZE} {line.displaySize}
                </Typography>
                <Typography variant='body1'>
                  {
                    <FormattedCurrency
                      amount={line.linePriceInformation.unitPrice}
                      currency={currency}
                    />
                  }
                </Typography>
                <FormControl className={classes.quantitySelect}>
                  <Tooltip
                    title={ONLY_1_AVAILABLE}
                    placement='right'
                    enterDelay={200}
                    leaveDelay={100}
                    classes={
                      quantityArray.length === 1
                        ? { tooltip: classes.tooltip }
                        : { tooltip: classes.noDisplay }
                    }>
                    <>
                      {quantityArray.length === 1 && (
                        <Typography
                          variant='body1'
                          component='span'
                          id='chooseQuantity'
                          className={classes.quantityLabel}>
                          {QUANTITY}
                          {':'}
                        </Typography>
                      )}
                      <Select
                        value={quantityToCancel || -1}
                        aria-labelledby='chooseQuantity'
                        labelId='chooseQuantity'
                        name={line.lineNumber.toString()}
                        onChange={(event) =>
                          dialogDispatch(setQuantityToCancel(line, event.target.value))
                        }
                        disabled={quantityArray.length === 1 || orderDetail.omoboFlags.isBOPIS}
                        id={`select-cancellable-quantity-${i}`}
                        data-testid={`select-cancellable-quantity-${i}`}>
                        <MenuItem value='-1' disabled>
                          {SELECT_QUANTITY}
                        </MenuItem>
                        {quantityArray.map((quantity, k) => (
                          <MenuItem
                            key={k}
                            value={quantity}
                            name={quantity}
                            data-testid={`cancellable-quantity-${quantity}`}>
                            {quantity}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  </Tooltip>
                </FormControl>
                {reasonCodeLoading && <CircularProgress aria-label={ARIA_REASON_CODES_LOADING} />}
                {reasonCodeError && <Warning message={reasonCodeError.message} />}
                {reasonCodeData && reasonCodeData.reasonCodesV2.reasons ? (
                  <FormControl className={classes.reasonCodeSelect}>
                    <Select
                      value={reason.code}
                      name={line.lineNumber.toString()}
                      data-testid={`select-cancel-reason-${i}`}
                      aria-label={ARIA_CANCEL_REASON}
                      aria-labelledby='chooseReason'
                      labelId='chooseReason'
                      onChange={(event) => {
                        // save both the reason code and description
                        const selectedReason = reasonCodeData.reasonCodesV2.reasons.find(
                          (reason) => reason.id === event.target.value
                        );
                        dialogDispatch(
                          setCancelReason(line, {
                            code: selectedReason.id,
                            text: selectedReason.description,
                          })
                        );
                      }}>
                      <MenuItem
                        className={classes.reasonCodeSelect}
                        id='chooseReason'
                        value='-1'
                        disabled>
                        {REASON_FOR_CANCEL}
                      </MenuItem>
                      {reasonCodeData.reasonCodesV2.reasons.map((reason, j) => (
                        <MenuItem
                          key={j}
                          value={reason.id}
                          name={reason.fault}
                          data-testid={`cancel-reason-${j}`}>
                          {reason.description}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  <Warning message={REASON_UNAVAILABLE} />
                )}
              </CardContent>
            </div>
          </Card>
        );
      })}
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  ...step2SharedStyles(theme),
}));
