export const translations = {
  AUTHORIZED: {
    description: 'label on the Payment Details page for "authorized"',
    primaryValue: 'Authorized',
    stringKey: 'authorized',
  },
  CHARGED: {
    description: 'label on the Payment Details page for "charged"',
    primaryValue: 'Charged',
    stringKey: 'charged',
  },
  DETAILS: {
    description: 'label on the Payment Details page for "details" of each payment type',
    primaryValue: 'Details',
    stringKey: 'details',
  },
  EDIT_PAYMENT: {
    description: 'label on the Payment Details page for "editPayment"',
    primaryValue: 'Edit Payment',
    stringKey: 'editPayment',
  },
  PAYMENT_TYPE: {
    description: 'label on the Payment Details page for "paymentType"',
    primaryValue: 'Payment Type',
    stringKey: 'paymentType',
  },
  REQUESTED_AUTH: {
    description: 'label on the Payment Details page for "requestedAuth"',
    primaryValue: 'Requested Auth',
    stringKey: 'requestedAuth',
  },
  REQUESTED_CHARGE: {
    description: 'label on the Payment Details page for "requestedCharge"',
    primaryValue: 'Requested Charge',
    stringKey: 'requestedCharge',
  },
  SUSPEND_PAYMENT: {
    description: 'label on the Payment Details page for "suspendPayment"',
    primaryValue: 'Suspend Payment',
    stringKey: 'suspendPayment',
  },
  SUSPENDED: {
    description: 'label on the Payment Details page for payment types in suspended status',
    primaryValue: 'Suspended',
    stringKey: 'suspended',
  },
  TRANSACTIONS: {
    description: 'label on the Payment Details page for "transactions"',
    primaryValue: 'Transactions',
    stringKey: 'transactions',
  },
  ACTIVATE_PAYMENT: {
    description: 'label for button that activates a payment on an order',
    primaryValue: 'ACTIVATE PAYMENT',
    stringKey: 'activatePayment',
  },
};

export default translations;
