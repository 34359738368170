import React from 'react';
import useHasPermission from './../../hooks/useHasPermission';
/**
 * A function which checks a parameter for being both a string, and non-empty.
 *
 * @param {any} param – the item to check the non-empty stringiness of
 */
const isNonemptyString = (param) => typeof param === 'string' && param.length;

/**
 * This is a Higher Order Component which will render its children only if the current user has the
 * provided requisite permission(s). Examples follow.
 *
 * Basic usage:
 *    <HasPermission permission="Example Permission">
 *      <p>
 *        this text will only render if the current user has the permission "Example Permission"
 *      </p>
 *    </HasPermission>
 *
 * Multiple permissions, of which the user must have all
 *    <HasPermission allOf={['Example Permission 1', 'Example Permission 2']}>
 *      ...
 *
 * Multiple permissions, of which the user must have only one
 *    <HasPermission anyOf={['Example Permission 1', 'Example Permission 2']}>
 *      ...
 *
 * Two lists of permissions, of which the user must have all of one, AND any one of the other
 *    <HasPermission
 *        allOf={['Example Permission 1', 'Example Permission 2']}
 *        and
 *        anyOf={['Example Permission 3', 'Example Permission 4']}>
 *      ...
 *
 * Two lists of permissions, of which the user must have all of one, OR any one of the other
 *    <HasPermission
 *        allOf={['Example Permission 1', 'Example Permission 2']}
 *        or
 *        anyOf={['Example Permission 3', 'Example Permission 4']}>
 *      ...
 *
 * @param {object} props – named React props
 * @param {string} permission – a permissions to check that a user has
 * @param {array} anyOf – a list of permissions to check that a user has any of
 * @param {array} allOf –a list of permissions to check that a user has all of
 * @param {boolean} or – a boolean flag to send in to indicate that it is sufficient that a user
 * has all of one list OR any of the other
 * @param {boolean} and – an unused flag, added for pretty autocompletion in props
 * @param {React.Component} children – the children of the HOC
 */
export const HasPermissionComponent = ({ permission, anyOf, allOf, or, and, children }) => {
  const { hasPermission, hasAnyOfPermissions, hasAllOfPermissions } = useHasPermission();
  let userHasPermission = false;

  if (!or && !and) {
    if (isNonemptyString(permission)) {
      userHasPermission = hasPermission(permission);
    } else if (Array.isArray(allOf)) {
      userHasPermission = hasAllOfPermissions(allOf);
    } else if (Array.isArray(anyOf)) {
      userHasPermission = hasAnyOfPermissions(anyOf);
    }
  }

  if (or) {
    userHasPermission = hasAllOfPermissions(allOf) || hasAnyOfPermissions(anyOf);
  } else if (and) {
    userHasPermission = hasAllOfPermissions(allOf) && hasAnyOfPermissions(anyOf);
  }
  return userHasPermission ? <>{children}</> : <></>;
};

export default HasPermissionComponent;
