const translations = {
  ARIA_CARRIER: {
    description: 'aria label for carrier selection',
    primaryValue: 'choose carrier',
    stringKey: 'ariaCarrier',
  },
  BACK: {
    stringKey: 'back',
    primaryValue: 'Back',
    description: 'label on the returns wizard for "Back"',
  },
  CREATE_RETURN: {
    description: 'label on the returns wizard for "Create Return"',
    primaryValue: 'Create Return',
    stringKey: 'createReturn',
  },
  NEXT: {
    stringKey: 'next',
    primaryValue: 'Next',
    description: 'label on the returns wizard for "Next"',
  },
  RETURN: {
    description: 'cancel action type in submission response message',
    primaryValue: 'Return',
    stringKey: 'return',
  },
  ERROR: {
    description: 'label for alerting the user that an action was unsuccessful',
    primaryValue: 'unsuccessful. Please try again!',
    stringKey: 'dialogError',
  },
  PARTIAL_SUCCESS: {
    description: 'label for alerting the user that an action only partially succeeded',
    primaryValue: 'Partially successful.',
    stringKey: 'dialogPartialSuccess',
  },
  SUCCESS: {
    description: 'label for alerting the user that an action was successful',
    primaryValue: 'successful!',
    stringKey: 'dialogSuccess',
  },
};

export default translations;
