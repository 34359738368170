import { useState } from 'react';

/**
 * This is a custom hook to to provide access to the Okta JWT stored in local storage.
 */
export default function useAuth(defaultValue) {
  const [expiresAt, setExpiresAt] = useState(0);

  /**
   * This function grabs the okta token its expiration time from local storage.
   * returns oktaToken and set oktaExpiration to local state
   */
  const getOktaFromLocalStorage = () => {
    let oktaToken;
    try {
      // grab the token from local storage and try to parse it
      const jsonToken = window.localStorage.getItem('okta-token-storage');
      const tokenDetails = jsonToken && JSON.parse(jsonToken);
      setExpiresAt(tokenDetails?.accessToken?.expiresAt);
      oktaToken = tokenDetails?.accessToken?.value;
    } finally {
      return oktaToken;
    }
  };

  const [oktaToken] = useState(() => getOktaFromLocalStorage());

  /**
   * This function checks if the okta token is expired on not.
   * returns true if expired otherwise returns false
   */
  const isOktaExpired = () => {
    if (oktaToken && expiresAt) {
      if (parseInt(expiresAt, 10) < parseInt(Math.trunc(Date.now()) / 1000, 10)) {
        return true;
      }
    }
    return false;
  };

  /**
   * This function removes the invalid and expired oktaToken from local storage.
   */
  const validateOktaToken = () => {
    if (!oktaToken || isOktaExpired()) {
      window.localStorage.removeItem('okta-token-storage');
    }
  };

  return {
    oktaToken,
    validateOktaToken,
  };
}
