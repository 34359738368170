/**
 * This file serves to contain constants related to an order's origin.
 */
export const Partners = {
  'dsg.digital.web': "Dick's Sporting Goods",
  'eastbay.digital.web': 'East Bay',
  'fanatics.digital.web': 'Fanatics',
  'footlocker.digital.web': 'Foot Locker',
  'finishline.digital.web': 'Finish Line',
  'hibbett.digital.web': 'Hibbetts',
  'jdsports.digital.web': 'JD Sports',
  'jet.digital.web': 'Jet US',
  'nordstrom.digital.web': 'Nordstrom',
  'sportamore.digital.web': 'SPORTAMORE',
  'unisport.digital.web': 'UNISPORT',
};

export const Enterprises = {
  JETUS: 'JET_US',
  JDSPORTS: 'JD Sports',
  PERRY: 'Perry',
  PRODIRECT: 'Pro:Direct',
  ZALANDOEU: 'Zalando',
  WHOLESALEUS: 'WHOLESALE_US',
  NIKEELITE: 'Nike Elite',
};

export const Apps = {
  'com.cultivator.store.web': 'NBY Workshop',
};

export const WQDivisions = {
  600: 'BM Transactions',
  56: 'Catalog Store',
  300: 'EMEA Nike Store',
  301: 'EMEA Retail Studio',
  303: 'EMEA Sneakr App',
  304: 'EMEA Sneakr App',
  500: 'Japan Nike Store',
  501: 'Japan Retail Studio',
  503: 'Japan Sneakr App',
  504: 'Japan Sneakr App',
  40: 'NikeFind',
  24: 'Omega Orders',
  2: 'Sneakr App',
  605: 'Sneakr App',
  606: 'Sneakr App',
  55: 'Swoosh',
  25: 'Swoosh OMEGA',
  3: 'Swoosh SNKRS',
  302: 'Umbro',
  0: 'US Niketown',
  1: 'US Niketown',
  201: 'US Retail Studio',
};
