import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Fab from '@material-ui/core/Fab';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import TabButton from './tabButton';
import clsx from 'clsx';
import translations from './navDrawer.i18n';

const drawerCloseWidth = 60;
const drawerOpenWidth = 125;
const slideTabWidth = 30;

/**
 * Drawer holding tabbed navigation, and controlling swipeable views
 */
export default function NavDrawer({ currIndex, onChange, routes }) {
  const classes = useStyles();
  const [isDrawerOpen, setDrawer] = useState(false);
  const { i18nString } = useContext(NikeI18nContext);

  const openNavDrawer = () => {
    setDrawer(true);
  };
  const closeNavDrawer = () => {
    setDrawer(false);
  };
  const toggleNavDrawer = () => {
    setDrawer(!isDrawerOpen);
  };

  const { ARIA_NAV_DRAWER, ARIA_SECONDARY_NAV, ARIA_EXPAND_NAV } = mapValues(
    translations,
    i18nString
  );

  return (
    <SwipeableDrawer
      variant='permanent'
      PaperProps={{ elevation: 6 }}
      classes={{
        paper: clsx(classes.drawerPaper, isDrawerOpen ? classes.drawerOpen : classes.drawerClose),
        root: classes.navRoot,
      }}
      className={clsx(classes.navDrawer, isDrawerOpen ? classes.drawerOpen : classes.drawerClose)}
      open={isDrawerOpen}
      role='navigation'
      aria-label={ARIA_NAV_DRAWER}
      onOpen={openNavDrawer}
      onClose={closeNavDrawer}>
      <Tabs
        classes={{ flexContainer: classes.tabsContainer }}
        value={currIndex}
        onChange={onChange}
        variant='fullWidth'
        indicatorColor='primary'
        aria-label={ARIA_SECONDARY_NAV}
        textColor='primary'
        aria-expanded={isDrawerOpen}
        orientation='vertical'
        centered>
        {routes.map((route, i) => {
          return <TabButton key={i} index={i} open={isDrawerOpen} route={route} tabIndex='0' />;
        })}
      </Tabs>
      <div className={clsx(classes.slideTabWrapper, isDrawerOpen ? classes.open : classes.close)}>
        <Fab
          onClick={toggleNavDrawer}
          size='small'
          color='inherit'
          data-testid='open-nav-button'
          aria-label={ARIA_EXPAND_NAV}
          aria-expanded={isDrawerOpen}
          classes={{ root: classes.slideTab }}>
          {isDrawerOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </Fab>
      </div>
    </SwipeableDrawer>
  );
}

NavDrawer.propTypes = {
  currIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

const useStyles = makeStyles((theme) => ({
  navRoot: { zIndex: 2 },
  navDrawer: {
    position: 'relative !important',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  drawerPaper: {
    position: 'fixed !important',
    zIndex: 'unset !important',
    paddingTop: theme.spacing(7),
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    display: 'grid',
    gridAutoFlow: 'column',
    alignContent: 'center',
  },
  drawerOpen: {
    minWidth: `${drawerOpenWidth}px`,
  },
  drawerClose: {
    minWidth: `${drawerCloseWidth}px`,
  },
  slideTabWrapper: {
    position: 'fixed',
    top: 'calc(50% + (31px / 2))',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  open: {
    left: `calc(${drawerOpenWidth}px - ${slideTabWidth}px / 2)`,
  },
  close: {
    left: `calc(${drawerCloseWidth}px - ${slideTabWidth}px / 2)`,
  },
  slideTab: {
    'minHeight': 'unset',
    'width': `${slideTabWidth}px`,
    'height': `${slideTabWidth}px`,
    'boxShadow':
      '0px 3px 4px -2px rgba(0,0,0,0.2),0px 6px 15px -1px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '&:hover': {
      color: '#FFFFFF',
      backgroundColor: theme.palette.primary.main,
    },
  },
  tabsContainer: {
    gap: '10px',
  },
}));
