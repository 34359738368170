const translations = {
  CANNOT_ENTER_SPECIFIED_FLOW: {
    stringKey: 'cannotEnterSpecifiedFlow',
    primaryValue: 'The following flow is not available:',
    description: 'Error message to display when the specified flow is not available.',
  },
  ERROR_FROM_GIFT_CARD_API: {
    stringKey: 'errorFromGiftCardAPI',
    primaryValue: 'Error from gift card API',
    description: 'Error message from gift card api service',
  },
};

export default translations;
