import React, { createContext } from 'react';

import { NikeI18nProvider } from '@nike/i18n-react';
import translations from '../../lib/translations.json';
import { supportedLanguages, sanitizeTranslations } from '../../utils/translations';

export const I18nContext = createContext({});
const { Provider } = I18nContext;

// TODO extract out language reducer so that language can be set after application launch
// TODO make and move to Providers folder
/**
 * This is a provider for the Nike i18n context, with the requisite attributes filled in.
 *
 * @param {Object} React.props – a React props object
 */
export const I18nProvider = ({ children }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  // a map of the values provided by SwooshDesk and their i18n-compatible equivalents
  const languageMap = {
    'en_US': 'en',
    'ja': 'ja',
    'zh_CN': 'zh-Hans',
    'es-419': 'es-419',
  };

  // TODO remove the conditional below when SD fixes it on their side
  const language = urlParams.get('lang') || urlParams.get('langLocale');
  const i18NnLang = languageMap[language];
  const currentLanguage = i18NnLang || 'en';

  return (
    <Provider value={[{ language: currentLanguage }]}>
      <NikeI18nProvider
        currentLanguageTag={currentLanguage}
        supportedLanguages={supportedLanguages}
        translations={sanitizeTranslations(translations)}>
        {children}
      </NikeI18nProvider>
    </Provider>
  );
};

export default I18nProvider;
