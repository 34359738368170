import { makeStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { NikeI18nContext } from '@nike/i18n-react';
import clsx from 'clsx';
import mapValues from 'lodash/mapValues';
import React, { useContext, useEffect } from 'react';
import InputMask from 'react-input-mask';
import dialogActions from '../../store/actions/dialogActions';
import { DialogContext } from '../../store/contexts/dialogContext';
import translations from './addressForm.i18n';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FirstNameField, LastNameField, LocationNameField } from './nameFields';
import { handleFieldChange } from './addressFieldChangeHandler';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import EUCountries from '../../constants/address/EUCountries.const';
import { getOmoboFlags } from '../../utils/orderLine';

/**
 * This is a reusable component for EMEA consumer addresses.
 *
 * @param {React.props} props – React props containing optional class name
 */
const EUAddressForm = ({ isGiftCardAddress = false, className = '' }) => {
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const { i18nString } = useContext(NikeI18nContext);
  const classes = useStyles();

  const { setAddressField, setGCShippingAddressField } = dialogActions;
  const { isBOPIS, isShipToStore, isPickUpPoint } = getOmoboFlags(dialogState.selectedLines);
  const address = isGiftCardAddress ? dialogState.gcShippingAddress : dialogState.address;
  const { locationName, address1, address2, city, country, postalCode } = address;
  const dayPhoneNumber =
    address.dayPhoneNumber ||
    dialogState.gcShippingAddress?.dayPhoneNumber ||
    dialogState.address?.dayPhoneNumber;
  const { ADDRESS_1, ADDRESS_2, CITY, COUNTRY, POSTAL_CODE, PHONE_NUMBER } = mapValues(
    translations,
    i18nString
  );

  // Updating the address and gcShippingAddress (billing address) phone numbers
  useEffect(() => {
    if (dayPhoneNumber && !dialogState.address?.dayPhoneNumber) {
      dialogDispatch(setAddressField('dayPhoneNumber', dayPhoneNumber));
    } else if (dayPhoneNumber && !dialogState.gcShippingAddress?.dayPhoneNumber) {
      dialogDispatch(setGCShippingAddressField('dayPhoneNumber', dayPhoneNumber));
    }
  }, [dayPhoneNumber]);

  /**
   * This function offers a concise means of handling basic changes.
   *
   * @param {Object} event – the event object created by the change
   */
  const handleChange = (keyParam) => (event) => {
    handleFieldChange(keyParam, isGiftCardAddress, event, dialogDispatch);
  };

  const isStoreOrPickupAddress = (isBOPIS || isShipToStore || isPickUpPoint) && !isGiftCardAddress;
  const isDisabledBecauseBOPIS = isBOPIS && !isGiftCardAddress;
  const addressGridClassName = isStoreOrPickupAddress
    ? classes.storeOrPickupAddressGrid
    : classes.addressGrid;

  return (
    <div className={clsx(addressGridClassName, className)}>
      {isStoreOrPickupAddress && locationName ? (
        <LocationNameField
          classes={classes}
          isGiftCardAddress={isGiftCardAddress}
          required={true}
          disabled={isDisabledBecauseBOPIS}
        />
      ) : (
        <>
          <FirstNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            disabled={isDisabledBecauseBOPIS}
          />
          <LastNameField
            classes={classes}
            isGiftCardAddress={isGiftCardAddress}
            required={true}
            disabled={isDisabledBecauseBOPIS}
          />
        </>
      )}
      <FormControlLabel
        aria-label={ADDRESS_1}
        className={classes.address1}
        control={
          <TextField
            required
            disabled={isDisabledBecauseBOPIS}
            value={address1}
            onChange={handleChange('address1')}
            label={ADDRESS_1}
            className={classes.wideField}
          />
        }
      />
      <FormControlLabel
        aria-label={ADDRESS_2}
        className={classes.address2}
        control={
          <TextField
            disabled={isDisabledBecauseBOPIS}
            value={address2 || undefined}
            onChange={handleChange('address2')}
            label={ADDRESS_2}
            aria-labelledby={ADDRESS_2}
            className={classes.wideField}
          />
        }
      />
      <FormControlLabel
        aria-label={CITY}
        className={classes.city}
        control={
          <TextField
            disabled={isDisabledBecauseBOPIS}
            required
            value={city}
            onChange={handleChange('city')}
            label={CITY}
            className={classes.wideField}
          />
        }
      />
      <FormControl
        className={classes.country}
        aria-label={COUNTRY}
        required
        disabled={isDisabledBecauseBOPIS}>
        <InputLabel id='chooseCountry' shrink>
          {COUNTRY}
        </InputLabel>
        <Select
          value={country || 'none'}
          onChange={handleChange('country')}
          aria-labelledby='chooseCountry'
          labelId='chooseCountry'>
          <MenuItem disabled value='none'>
            —
          </MenuItem>
          {EUCountries.map((country, key) => (
            <MenuItem data-key='country' value={country.abbreviation} key={key}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControlLabel
        aria-label={POSTAL_CODE}
        className={classes.postalCode}
        control={
          <TextField
            required
            disabled={isDisabledBecauseBOPIS}
            value={postalCode}
            onChange={handleChange('postalCode')}
            label={POSTAL_CODE}
            className={classes.widthField}
          />
        }
      />
      {!isStoreOrPickupAddress && (
        <InputMask
          mask='(999) 999-9999'
          value={dayPhoneNumber}
          onChange={handleChange('dayPhoneNumber')}>
          {() => (
            <FormControlLabel
              aria-label={PHONE_NUMBER}
              className={classes.phoneField}
              control={
                <TextField
                  required
                  aria-required={true}
                  inputProps={{ 'data-key': 'dayPhoneNumber' }}
                  label={PHONE_NUMBER}
                  className={classes.wideField}
                />
              }
            />
          )}
        </InputMask>
      )}
    </div>
  );
};

export default EUAddressForm;

const useStyles = makeStyles({
  addressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridTemplateAreas: `
      " TY TY .. .. .. .. .. .. "
      " FN FN FN LN LN LN .. .. "
      " A1 A1 A1 A1 A1 A1 .. .. "
      " A2 A2 A2 A2 A2 A2 .. .. "
      " CI CI CO CO PO PO .. .. "
      " PH PH .. .. .. .. .. .. "
    `,
    gridGap: '1rem 3rem',
  },
  storeOrPickupAddressGrid: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(8, 1fr)',
    gridTemplateAreas: `
      " TY TY .. .. .. .. .. .. "
      " LO LO LO LO LO LO .. .. "
      " A1 A1 A1 A1 A1 A1 .. .. "
      " A2 A2 A2 A2 A2 A2 .. .. "
      " CI CI CO CO PO PO .. .. "
    `,
    gridGap: '1rem 3rem',
  },
  addressType: {
    gridArea: 'TY',
    display: 'inline',
  },
  firstNameField: {
    gridArea: 'FN',
    margin: 0,
  },
  lastNameField: {
    gridArea: 'LN',
  },
  locationNameField: {
    gridArea: 'LO',
    margin: 0,
  },
  address1: {
    gridArea: 'A1',
    width: '100%',
    margin: 0,
  },
  address2: {
    gridArea: 'A2',
    width: '100%',
    margin: 0,
  },
  city: {
    gridArea: 'CI',
    width: '100%',
    margin: 0,
  },
  country: {
    gridArea: 'CO',
    width: '100%',
    margin: 0,
  },
  postalCode: {
    gridArea: 'PO',
    width: '100%',
    margin: 0,
  },
  phoneField: {
    gridArea: 'PH',
    margin: 0,
  },
  wideField: {
    width: '100%',
  },
});
