import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import LinkIcon from '@material-ui/icons/Link';
import { NikeI18nContext } from '@nike/i18n-react';
import mapValues from 'lodash/mapValues';
import translations from './member.i18n';
import SidebarItem from './sidebarItem';
import { useMutation } from '@apollo/react-hooks';
import IDENTITY_USER_QUERY from '../../../mutations/identityUser.mutation';
import OrderContext from '../../../store/contexts/orderContext';
import { ConsumerContext } from '../../../store/contexts/consumerContext';
import useSnacks from '../../../hooks/useSnacks';
import { OrderDetailVersion, UserType } from '../../../constants/order.const';
import { getConsumerName, getConsumerEmail } from '../../../utils/order';
import consumerActions from '../../../store/actions/consumerActions';
import { setPersonNames } from '../../../utils/orderDetailPersonNames';
import config from '../../../utils/config';
import HasPermission from '../../shared/hasPermission';
import { EditCommunicationMethod, LinkOrUnlinkOrder } from '../../../constants/permissions.const';
import { Partners } from '../../../constants/origin.const';

const Member = () => {
  const { i18nString } = useContext(NikeI18nContext);
  const {
    ERROR,
    GUEST,
    ORDERED_BY,
    BRAND_MEMBER,
    SWOOSH_MEMBER,
    ARIA_PROFILE_INFO,
    EDIT_EMAIL,
    UNLINK_PROFILE,
    LINK_PROFILE,
  } = mapValues(translations, i18nString);
  const { setError } = useSnacks();
  const classes = useStyles();
  const [orderDetail] = useContext(OrderContext);
  const [, consumerDispatch] = useContext(ConsumerContext);
  const {
    setIdentityConsumerType,
    setIdentityConsumerEmail,
    setIdentityConsumerName,
    setIdentityKanaFirstName,
    setIdentityKanaLastName,
  } = consumerActions;
  const cspUrl = `${config.cspUrl}csr/cloud/orderDetail?orderNumber=${orderDetail?.orderNumber}&orderHeaderKey=`;

  const [orderDetailVersion, setOrderDetailVersion] = useState();
  const [consumerType, setConsumerType] = useState('');
  const [consumerName, setConsumerName] = useState('');
  const [consumerEmail, setConsumerEmail] = useState('');
  const [idnUser, setIdnUser] = useState();

  const [getIdentityUser] = useMutation(IDENTITY_USER_QUERY, {
    // If the error is 404, consumerType is GUEST, otherwise show error in SNACK.
    onError: (err) => {
      if (err.message && err.message.includes('error: 404')) {
        if (orderDetailVersion === OrderDetailVersion.V1) {
          setConsumerType(UserType.GUEST);
        }
      } else {
        setError(`${ERROR}`);
      }
      setConsumerName(getConsumerName(orderDetail));
      setConsumerEmail(getConsumerEmail(orderDetail));
    },
    // On completed, if orderDetail is V1, set the consumerType. (If V2, we already set it.)
    onCompleted(idnData) {
      setConsumerName(getConsumerName(orderDetail, idnData));
      setConsumerEmail(getConsumerEmail(orderDetail, idnData));
      setIdnUser(idnData.identityUser);

      if (orderDetailVersion === OrderDetailVersion.V1) {
        // The presence of a upmId tells us this consumer is either SWOOSH or BRAND, not GUEST.
        if (idnData?.identityUser?.upmId) {
          // AS per existing CSP logic, if IDN type is not SWOOSH then set consumerType to BRAND.
          setConsumerType(
            idnData.identityUser.userType === 'SWOOSH' ? UserType.SWOOSH : UserType.BRAND
          );
          consumerDispatch(setIdentityKanaFirstName(idnData.identityUser.name?.kana?.given));
          consumerDispatch(setIdentityKanaLastName(idnData.identityUser.name?.kana?.family));
        } else {
          // The data returned from IDN was empty, so consumerType is GUEST.
          setConsumerType(UserType.GUEST);
        }
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  const partner = orderDetail?.channel ? Partners[orderDetail?.channel] : '';

  /**
   * This useEffect calls IDN (provided there is a customerProfileReference) and sets the
   * consumerName, consumerEmail, and consumerType.
   */
  useEffect(() => {
    // Don't try anything unless the fields in orderDetail are actually populated.
    if (orderDetail?.customerProfileReference || getConsumerName(orderDetail)) {
      // Set the orderDetailVersion based on the presence of the userType.
      if (orderDetail.userInfo?.userType) {
        setOrderDetailVersion(OrderDetailVersion.V2);
        // If V2, consumerType is set from orderDetail.
        setConsumerType(orderDetail.userInfo.userType);
      } else {
        setOrderDetailVersion(OrderDetailVersion.V1);
      }

      if (orderDetail.customerProfileReference) {
        // Regardless of orderVersion, call IDN if customerProfileReference is present.
        getIdentityUser({
          variables: {
            id: orderDetail.customerProfileReference,
            input: {
              fields: [
                'name.latin.given',
                'name.latin.middle',
                'name.latin.family',
                'name.kana.given',
                'name.kana.family',
                'contact.email.address',
                'upmId',
                'userType',
              ],
            },
          },
        });
      } else {
        // customerProfileReference is not present, so consumerType is GUEST.
        setConsumerType(UserType.GUEST);
        setConsumerName(getConsumerName(orderDetail));
        setConsumerEmail(getConsumerEmail(orderDetail));
      }
    }
  }, [orderDetail]);

  /**
   * This use effect is used to set up consumer context with
   * a) consumer type (Swoosh, brand, guest
   * b) consumer name
   * c) consumer email
   */
  useEffect(() => {
    consumerDispatch(setIdentityConsumerType(consumerType));
    consumerDispatch(setIdentityConsumerName(consumerName));
    consumerDispatch(setIdentityConsumerEmail(consumerEmail));
  }, [consumerName, consumerType, consumerEmail]);

  /**
   * This useEffect triggers the setPersonNames function to cross-populate the name fields in the
   * orderDetail and also to set the kana names from the idnUser, if available.
   */
  useEffect(() => {
    setPersonNames(orderDetail, idnUser);
  }, [idnUser]);

  /**
   * Gets the consumerMemberInfo for display.
   *
   * @returns {Object} the consumerMemberInfo for display
   */
  const displayConsumerMemberInfo = () => {
    let consumerTypeLabel = '';
    if (consumerType === UserType.SWOOSH) {
      consumerTypeLabel = SWOOSH_MEMBER;
    } else if (consumerType === UserType.BRAND) {
      consumerTypeLabel = BRAND_MEMBER;
    }

    if (consumerType !== UserType.GUEST) {
      return (
        <>
          <span>
            {consumerTypeLabel + ' '}
            <Link>{orderDetail.customerProfileReference}</Link>
          </span>
          {!partner && (
            <HasPermission permission={LinkOrUnlinkOrder}>
              <Button
                target='_blank'
                href={cspUrl}
                aria-label={UNLINK_PROFILE.toLowerCase()}
                data-testid='unlink-profile-button'
                className={classes.button}>
                <LinkOffIcon className={classes.buttonIcon} />
                {UNLINK_PROFILE}
              </Button>
            </HasPermission>
          )}
        </>
      );
    } else {
      return (
        <>
          <span>{GUEST}</span>
          {!partner && (
            <HasPermission permission={LinkOrUnlinkOrder}>
              <Button
                target='_blank'
                href={cspUrl}
                aria-label={LINK_PROFILE.toLowerCase()}
                data-testid='link-profile-button'
                className={classes.button}>
                <LinkIcon className={classes.buttonIcon} />
                {LINK_PROFILE}
              </Button>
            </HasPermission>
          )}
        </>
      );
    }
  };

  return (
    <SidebarItem dataTestId='orderedBySection' title={ORDERED_BY} icon={<AccountCircleIcon />}>
      <Typography
        aria-label={ARIA_PROFILE_INFO}
        className={classes.addressTypography}
        component='address'>
        {consumerName + '\n' + consumerEmail}
        {!partner && (
          <HasPermission permission={EditCommunicationMethod}>
            <Button
              target='_blank'
              href={cspUrl}
              aria-label={EDIT_EMAIL.toLowerCase()}
              data-testid='edit-email-button'
              className={classes.button}>
              <EditIcon className={classes.buttonIcon} />
              {EDIT_EMAIL}
            </Button>
          </HasPermission>
        )}
        {'\n'}
        {consumerType ? displayConsumerMemberInfo() : ''}
      </Typography>
    </SidebarItem>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  buttonIcon: {
    marginRight: theme.spacing(0.5),
  },
  addressTypography: {
    whiteSpace: 'pre-line',
    lineHeight: 2,
  },
}));

export default Member;
