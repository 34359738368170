export const actionTypes = {
  SET_SEARCH_RESULTS: 'SET_SEARCH_RESULTS',
  SET_SEARCH_FIELD_VALUES: 'SET_SEARCH_FIELD_VALUES',
  RESET_SEARCH_FIELD_VALUES: 'RESET_SEARCH_FIELD_VALUES',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_ANCHOR: 'SET_ANCHOR',
  SET_SHOW_SEARCH_PAGE: 'SET_SHOW_SEARCH_PAGE',
};

export const actions = {
  setSearchResults:
    /**
     * This action sets the search results
     *
     * @param {Object} searchResults – the searchResults to be set in state
     */
    (searchResults) => ({
      type: actionTypes.SET_SEARCH_RESULTS,
      searchResults,
    }),

  setSearchFieldValues:
    /**
     * This action sets the search field values
     *
     * @param {Object} searchFieldValues – the search field values to be set in state
     */
    (searchFieldValues) => ({
      type: actionTypes.SET_SEARCH_FIELD_VALUES,
      searchFieldValues,
    }),

  resetSearchFieldValues:
    /**
     * This actions resets all search field values to their initial value
     */
    () => ({
      type: actionTypes.RESET_SEARCH_FIELD_VALUES,
    }),

  setPagination:
    /**
     * This action sets the pagination links
     *
     * @param {Object} pagination – the pagination to be set in state
     */
    (pagination) => ({
      type: actionTypes.SET_PAGINATION,
      pagination,
    }),

  setAnchor:
    /**
     * This action sets the anchor on search results
     *
     * @param {String} anchor – the anchor to be set in state
     */
    (anchor) => ({
      type: actionTypes.SET_ANCHOR,
      anchor,
    }),

  setShowSearchPage:
    /**
     * This action sets the boolean show search results
     *
     * @param {Boolean} showSearchPage – the anchor to be set in state
     */
    (showSearchPage) => ({
      type: actionTypes.SET_SHOW_SEARCH_PAGE,
      showSearchPage,
    }),
};

export default actions;
