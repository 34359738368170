/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useMutation, useLazyQuery } from 'react-apollo';
import {
  DialogTypes,
  AddressValidationStatus,
  ResponseStatuses,
  ApiTimeOut,
  TimeOutErrorMessageFromGrand,
  CountriesExemptFromAddressValidation,
} from '../../../../constants/dialog.const';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useHistory, withRouter } from 'react-router-dom';
import { NikeI18nContext } from '@nike/i18n-react';
import { OrderContext } from '../../../../store/contexts/orderContext';
import { DialogContext } from '../../../../store/contexts/dialogContext';
import { AthleteContext } from '../../../../store/contexts/athleteContext';
import { ConsumerContext } from '../../../../store/contexts/consumerContext';
import { actions as dialogActions } from '../../../../store/actions/dialogActions';
import translations from '../dialog.i18n';
import {
  areReasonsMissingFrom,
  isQuantityMissingForLineItems,
  isReturnLabelNotRequired,
  addressHasRequiredFields,
  getEmailForReturn,
} from '../../../../utils/dialog';
import email from './../../../../utils/email';
import { normalizeAddress } from '../../../../utils/address';
import RETURN_LABEL_MUTATION from '../../../../mutations/returnLabel.mutation';
import ADDRESS_VALIDATION_MUTATION from '../../../../mutations/addressValidation.mutation';
import RETURN_CAPTURE_MUTATION from '../../../../mutations/returnCapture.mutation';
import ORDER_DETAIL_QUERY from '../../../../queries/orderDetail.query';
import mapValues from 'lodash/mapValues';
import RETURN_LOCATION_MUTATION from '../../../../mutations/returnLocation.mutation';
import { v4 as uuidv4 } from 'uuid';
import AddressValidator from './../../../shared/addressValidator';
import { stepControlSharedStyles } from '../sharedStyles';
import {
  removeNullValuesFromObject,
  removeTypeNameFromObject,
} from './../../../../utils/reactUtils';
import getOrderRoutes from '../../../../routes';
import useSnacks from '../../../../hooks/useSnacks';
import { SendResendReturnLabel } from './../../../../constants/permissions.const';
import useHasPermission from './../../../../hooks/useHasPermission';
import { areLineItemsDigitalGiftCards } from '../../../../utils/order';
import {
  generateReturnCapturePayload,
  generateReturnLocationPayload,
  generateAddressValidationPayload,
} from './requestPayloads/payloads.EU';

/**
 * Component to handle step actions and form submission based on dialog state for EU region
 * Possible states and what's shown:
 *      Steps before the last: Back and Next buttons
 *      Last step, pre-submit: Back and Submit buttons
 */
function StepControl() {
  const classes = useStyles();
  const { i18nString } = useContext(NikeI18nContext);
  const [dialogState, dialogDispatch] = useContext(DialogContext);
  const [orderDetail] = useContext(OrderContext);
  const [consumerState] = useContext(ConsumerContext);
  const [, setOrderDetails] = useContext(OrderContext);
  const [isReadyToSubmitReturnOrder, setIsReadyToSubmitReturnOrder] = useState(false);
  const [queryDetailsCounter, setQueryDetailsCounter] = useState(1);
  const [isOrderDetailsUpdated, setIsOrderDetailsUpdated] = useState(null);
  const [isDispatchReturnLabelComplete, setIsDispatchReturnLabelComplete] = useState(false);
  const [dispatchReturnLabelAttemptCounter, setDispatchReturnLabelAttemptCounter] = useState(0);
  const [dispatchReturnLabelError, setDispatchReturnLabelError] = useState(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [addressFromAddressValidation, setAddressFromAddressValidation] = useState('');
  const [addressToValidate, setAddressToValidate] = useState('');
  const [sendReturnLabel, setSendReturnLabel] = useState(false);
  const { hasPermission } = useHasPermission();
  const { setSlowLoading, setSnack, setError } = useSnacks();
  const [receivingNode, setReceivingNode] = useState('');
  const [standardCarrierAlphaCode, setStandardCarrierAlphaCode] = useState('');
  const [bypassReturnLabelCreation, setBypassReturnLabelCreation] = useState(false);
  const [athleteInfo] = useContext(AthleteContext);
  let history = useHistory();
  const routes = getOrderRoutes();

  const { consumerEmail } = consumerState;

  const {
    prevStep,
    nextStep,
    reset,
    setReturnOrderNumber,
    setValidateAddress,
    setHasTimedOut,
  } = dialogActions;

  const {
    selectedLines,
    activeStep,
    deliveryMethod,
    dialogType,
    submissionSteps,
    returnOrderNumber,
    address,
    gcShippingAddress,
    validateAddress,
    lock,
    hasTimedOut,
  } = dialogState;

  const {
    ADDRESS_VALIDATION_ERROR,
    BACK,
    CREATE_RETURN,
    ERROR,
    NEXT,
    RETURN_CREATION_FAILED,
    RETURN_CREATION_LABEL_FAILED,
    RETURN_ORDER_CREATED,
    RETURN_ORDER_NOT_AVAILABLE,
    RESEND_RETURN_LABEL,
    RETURN_LOCATION_FAILED,
    CREATE_RETURN_TIME_OUT_ERROR_MESSAGE,
    PARTIAL_SUCCESS,
    SUCCESS,
  } = mapValues(translations, i18nString);
  const maxAttemptsToDispatchLabel = 2;

  /**
   * A util function to determine if the submit action is ready
   */
  const shouldSubmissionButtonBeDisabled = () => {
    const geo = orderDetail.omsRegionReference;
    const email = getEmailForReturn(address, gcShippingAddress, consumerEmail);
    let addressToValidate = { ...address, email };
    if (dialogState.activeStep !== 2 && dialogState.deliveryMethod !== 'mail') {
      return !addressHasRequiredFields(geo, addressToValidate);
    } else if (dialogState.activeStep === 2 && dialogState.returnPaymentOption === 'giftcard') {
      addressToValidate = { ...gcShippingAddress };
      // Email is not required field in gift card payment form
      delete addressToValidate.email;
      return !addressHasRequiredFields(geo, addressToValidate);
    } else if (dialogState.activeStep === 3 && dialogState.deliveryMethod === 'mail') {
      // Email is not required field in gift card payment form
      delete addressToValidate.email;
      return !addressHasRequiredFields(geo, addressToValidate);
    } else {
      return false;
    }
  };

  /**
   * Determines whether the next button, which are displayed on non-submission pages,
   * should be disabled or not based on validations.
   */
  const shouldNextButtonBeDisabled = () => {
    const geo = orderDetail.omsRegionReference;
    switch (activeStep) {
      case 0:
        return selectedItemKeys.length === 0;
      case 1:
        return (
          areReasonsMissingFrom(selectedLines, DialogTypes.RETURN) ||
          isQuantityMissingForLineItems(selectedLines, DialogTypes.RETURN)
        );
      case 2:
        let isGifteeEmailAvailable = true;
        if (dialogState.isGiftReturn) {
          if (!email(dialogState.gifteeEmail)) {
            isGifteeEmailAvailable = false;
          }
        }
        // Check address for required fields only if we are in gift card address form
        if (dialogState.returnPaymentOption === 'giftcard') {
          let gcShippingAddressToBeValidated = { ...gcShippingAddress };
          // email is not required in new gift card address form in return step 3
          delete gcShippingAddressToBeValidated.email;
          return (
            !addressHasRequiredFields(geo, gcShippingAddressToBeValidated) ||
            !isGifteeEmailAvailable
          );
        } else {
          // Disable next button if the email address is not provided for gift return orders
          return !isGifteeEmailAvailable;
        }
      // TODO eslint-disable-next-line no-fallthrough
      default:
        return true;
    }
  };

  /**
   * Updates url to match new return order if return order has been successfully made.
   */
  const updateUrlForNewReturn = () => {
    history.push(`/order/${returnOrderNumber}/${routes[0]}`);
  };

  const [attemptToDispatchReturnLabel] = useMutation(RETURN_LABEL_MUTATION, {
    onError: (err) => {
      setDispatchReturnLabelAttemptCounter(dispatchReturnLabelAttemptCounter + 1);
      setDispatchReturnLabelError(err);
      setIsDispatchReturnLabelComplete(
        dispatchReturnLabelAttemptCounter >= maxAttemptsToDispatchLabel
      );
    },
    onCompleted: () => {
      setDispatchReturnLabelError(null);
      setIsDispatchReturnLabelComplete(true);
    },
  });

  const [sendReturnLabelNow] = useMutation(RETURN_LABEL_MUTATION, {
    onError: (err) => {
      setError(`${RESEND_RETURN_LABEL} ${ERROR} ${err.message}`);
    },
    onCompleted: () => {
      // todo - do something more appropriate when the call isn't timing out?
      dispatchReset();
      setSnack(`${SUCCESS}`);
    },
  });

  const [doAddressValidation] = useMutation(ADDRESS_VALIDATION_MUTATION, {
    onError: (err) => {
      setError(`${ADDRESS_VALIDATION_ERROR} ${err.message}`);
    },
    onCompleted: (data) => {
      const addressFromResponse = normalizeAddress(data.addressValidator.address);
      const status = data.addressValidator.verificationCode;
      const score = data.addressValidator.score;
      removeNullValuesFromObject(addressFromResponse);
      setVerificationCode(status);
      setAddressFromAddressValidation(addressFromResponse);
      const shouldSubmitOnCurrentStep =
        activeStep !== 2 ||
        (activeStep === 2 && bypassReturnLabelCreation) ||
        (activeStep === 2 && !hasPermission(SendResendReturnLabel));
      /*
       * Show address validation component only for
       * a) Addresses that are not in verified status
       * b) Verified address with score less than 95
       * For verified address if the step is return creation step
       * then submit return or move to next step
       */
      if (status === AddressValidationStatus.VERIFIED && score >= 95 && shouldSubmitOnCurrentStep) {
        handleReturnOrderSubmit();
      } else if (status === AddressValidationStatus.VERIFIED && score >= 95 && activeStep === 2) {
        dialogDispatch(nextStep());
      } else {
        dialogDispatch(setValidateAddress(true));
      }
    },
  });

  /**
   * Creates a query used to retrieve the OrderDetails that can be executed at a
   * different time. This is used for the retry calls in the event that the return
   * order has been created, but is not yet retrievable.
   */
  const [
    queryOrderDetails,
    { data: queryOrderDetailsData, error: queryOrderDetailsError },
  ] = useLazyQuery(ORDER_DETAIL_QUERY, {
    fetchPolicy: 'network-only',
    onError: () => {
      if (queryDetailsCounter === 10) {
        setError(RETURN_ORDER_NOT_AVAILABLE);
        dispatchReset();
      } else {
        setQueryDetailsCounter(queryDetailsCounter + 1);
      }
    },
    onCompleted: () => {
      // Call Order details on successful inspection
      setOrderDetails(queryOrderDetailsData.orderDetail);
      setIsOrderDetailsUpdated(true);
      if (!hasPermission(SendResendReturnLabel) || bypassReturnLabelCreation) {
        dispatchReset();
        setSnack(RETURN_ORDER_CREATED);
        updateUrlForNewReturn();
      }
    },
  });

  // Generate the function to execute the return location mutation
  const [submitReturnLocation] = useMutation(RETURN_LOCATION_MUTATION, {
    onError: (err) => {
      setError(RETURN_LOCATION_FAILED);
    },
    onCompleted: (response) => {
      setReceivingNode(response.returnLocation.receivingNode);
      setStandardCarrierAlphaCode(response.returnLocation.standardCarrierAlphaCode);
      setIsReadyToSubmitReturnOrder(true);
    },
  });

  /*
  Based on the return step and shipping/ billing country we either perform or skip
  address validation
  Skip Address Validation in the below scenarios 
   a) In step2, if return payment option is not gift card
   b) In step2, if the billing country falls under countries that are exempted from address validation
   c) In step3, if the shipping country falls under countries that are exempted from address validation
  Perform address validation in all other scenarios
  */
  const isAddressValidationRequired = () => {
    if (activeStep < 2) return false;
    if (activeStep === 2 && dialogState.returnPaymentOption === 'giftcard') {
      // don't verify address on step 3 refund to gift card
      return !CountriesExemptFromAddressValidation.includes(gcShippingAddress.country);
    } else if (activeStep === 3) {
      return !CountriesExemptFromAddressValidation.includes(address.country);
    }
    return false;
  };

  // Generate the function to execute the return captures mutation.
  const [submitReturnOrder] = useMutation(RETURN_CAPTURE_MUTATION, {
    onError: (err) => {
      // Logging the error message so we do not lose track of it.
      console.error('Return Capture Error', err);
      if (err.message === TimeOutErrorMessageFromGrand) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else {
        // If the return order submission fails, we want to display a readable error and go back to the order screen.
        setError(RETURN_CREATION_FAILED);
      }
      dispatchReset();
    },
    onCompleted: (response) => {
      const { createReturn } = response;
      if ((createReturn.status = ResponseStatuses.COMPLETED && createReturn.response)) {
        if (createReturn.response.returnOrderNumber) {
          dialogDispatch(setReturnOrderNumber(createReturn.response.returnOrderNumber));
          // if display is locked, send return label req now, otherwise query order details + close dialog
          lock
            ? setSendReturnLabel(true)
            : queryOrderDetails({
                variables: {
                  orderNumber: createReturn.response.returnOrderNumber,
                },
              });
        }
      } else if (
        createReturn.status === ResponseStatuses.IN_PROGRESS ||
        createReturn.status === ResponseStatuses.PENDING
      ) {
        setError(CREATE_RETURN_TIME_OUT_ERROR_MESSAGE);
        dialogDispatch(setHasTimedOut(true));
      } else if (createReturn.error) {
        const errorMessageOnJobCompletion = `${createReturn.error.httpStatus}: ${createReturn.error.message}`;
        setError(errorMessageOnJobCompletion);
        dispatchReset();
      }
    },
  });

  /**
   * When the selectedLines is updated, determine if all of the reason codes for the return items
   * does not require return label. Set the byPassReturnLabelCreation flag to true if they are and
   * false if they are not.
   */
  useEffect(() => {
    setBypassReturnLabelCreation(
      isReturnLabelNotRequired(dialogState.selectedLines, orderDetail.omsRegionReference)
    );
  }, [dialogState.selectedLines]);

  // Need to wait for shipTo address to be populated in state before we can request a return
  useEffect(() => {
    if (isReadyToSubmitReturnOrder) {
      const returnCapturesInput = generateReturnCapturePayload(
        dialogState,
        consumerState,
        orderDetail,
        hasPermission(SendResendReturnLabel),
        receivingNode,
        standardCarrierAlphaCode,
        athleteInfo
      );
      submitReturnOrder({
        variables: {
          input: returnCapturesInput,
          region: orderDetail.omsRegionReference,
          timeout: ApiTimeOut,
        },
      });
    }
  }, [isReadyToSubmitReturnOrder]);

  /**
   * This useEffect attempts to dispatch the return label.  It is triggered at first when
   * the order details are updated after successfully creating a return order, and then
   * subsequently each time that dispatching the return label fails but has not yet
   * exceeded the maximum number of attempts.
   */
  useEffect(() => {
    if (hasPermission(SendResendReturnLabel) && !bypassReturnLabelCreation) {
      if ((isOrderDetailsUpdated || sendReturnLabel) && !isDispatchReturnLabelComplete) {
        const returnLabelInput = {
          returnOrderNumber: dialogState.returnOrderNumber,
          dispatchType: String(deliveryMethod).toUpperCase(),
        };
        attemptToDispatchReturnLabel({
          variables: {
            input: returnLabelInput,
          },
        });
      }
    } else if (isOrderDetailsUpdated) {
      // Since there is no return label, set this to true to trigger the reset and confirmation snack.
      setIsDispatchReturnLabelComplete(true);
    } else if (returnOrderNumber && lock) {
      /* we land here when athlete comes from swoosh desk, and a return order is created but
       the return label is not required either because of
       a) Athlete doesn't have permission to send return label
       b) Reason code selected doesn't warrant return label
       */
      dialogDispatch(dialogActions.open(DialogTypes.ACTION_COMPLETE, true));
      setSnack(RETURN_ORDER_CREATED);
    }
  }, [
    isOrderDetailsUpdated,
    dispatchReturnLabelAttemptCounter,
    bypassReturnLabelCreation,
    sendReturnLabel,
    returnOrderNumber,
  ]);

  /**
   * This useEffect resets the dialog and dispatches a confirmation snack indicating either complete
   * success, or partial success if the dispatching of the return label failed.  It is triggered when
   * the call to dispatch the return label is complete, or if there is no return label to dispatch
   * then it is triggered right after the order details are updated.
   */
  useEffect(() => {
    if (isDispatchReturnLabelComplete & (activeStep !== 2)) {
      let isDispatchReturnLabelFailed =
        hasPermission(SendResendReturnLabel) &&
        dispatchReturnLabelError &&
        dispatchReturnLabelAttemptCounter > 0;
      dispatchReset();
      if (isDispatchReturnLabelFailed) {
        setSnack(
          `${PARTIAL_SUCCESS} ${RETURN_CREATION_LABEL_FAILED} ${dispatchReturnLabelError.message}`,
          'yellow'
        );
      } else {
        /**
         * If display is locked, we will navigate to close tab message.
         * If display is NOT locked, we will update the URL for new return order number
         */
        lock
          ? dialogDispatch(dialogActions.open(DialogTypes.ACTION_COMPLETE, true))
          : updateUrlForNewReturn();
        setSnack(RETURN_ORDER_CREATED);
      }
    }
  }, [isDispatchReturnLabelComplete]);

  /**
   * This useEffect contains the retry logic, for retrieving the order details after a successful
   * call to the return captures service. The retry conditions is that it will wait 1 second
   * between calls and it will retry up to 10 times.
   */
  useEffect(() => {
    if (
      queryDetailsCounter >= 0 &&
      queryDetailsCounter < 10 &&
      !queryOrderDetailsData &&
      queryOrderDetailsError
    ) {
      setTimeout(function() {
        queryOrderDetails({
          variables: {
            orderNumber: returnOrderNumber,
          },
        });
      }, 1500);
    }
  }, [queryOrderDetailsError]);

  const dispatchReset = () => {
    !lock && dialogDispatch(reset());
  };

  /**
   * Determines if we are on a submission step, but also whether we are submitting the return
   * order at step 2 or 3, which is dependent on if the user wants a return label sent.
   */
  const determineIfSubmissionStep = () => {
    if (submissionSteps.includes(activeStep)) {
      if (dialogType === DialogTypes.RESEND_RETURN_LABEL) {
        return true;
      } else if (activeStep === 2 && bypassReturnLabelCreation) {
        return true;
      } else {
        return (activeStep === 2 && !hasPermission(SendResendReturnLabel)) || activeStep === 3;
      }
    }
    return false;
  };

  const isSubmissionStep = determineIfSubmissionStep();
  const selectedItemKeys = Object.keys(selectedLines);

  /**
   * Function that calls address validation api
   */
  const handleAddressValidation = () => {
    const { address, gcShippingAddress, activeStep } = dialogState;
    const addressToBeSentToAPI =
      // If this is step 2 or the selected lines are all digital gift cards, use gift card address
      activeStep === 2 || areLineItemsDigitalGiftCards(selectedLines) ? gcShippingAddress : address;
    setAddressToValidate(addressToBeSentToAPI);
    const addressValidationPayload = generateAddressValidationPayload(
      addressToBeSentToAPI,
      orderDetail.locale
    );

    doAddressValidation({
      variables: {
        input: addressValidationPayload,
        guid: uuidv4(),
      },
    });
  };

  // Object that contains address entered in the form and suggested address from service
  const suggestedAndEnteredAddress = {
    suggestedAddress: removeTypeNameFromObject(addressFromAddressValidation),
    addressEntered: addressToValidate,
  };

  /**
   * Handles the submission of the createReturn mutation.
   */
  function handleReturnOrderSubmit() {
    const returnLocationInput = generateReturnLocationPayload(
      dialogState,
      consumerState,
      orderDetail
    );

    submitReturnLocation({ variables: { input: returnLocationInput } });
    setSlowLoading();

    /*
     * We need to wait for state to update - see useEffect for the submitReturnOrder call
     * which watches for dialogueState.shipTo address to update
     */
  }

  const handleNext = () => {
    if (isAddressValidationRequired()) {
      return handleAddressValidation();
    }
    dialogDispatch(nextStep());
  };

  /**
   * This function will determine whether to call address validation or directly
   * submit returns with out address validation
   */
  const determineReturnSubmissionPath = () => {
    if (isAddressValidationRequired()) {
      return handleAddressValidation();
    }
    return handleReturnOrderSubmit();
  };

  const BackButton = () => (
    <Button
      disabled={activeStep === 0}
      aria-label={BACK.toLowerCase()}
      data-testid='return-back-button'
      onClick={() => dialogDispatch(prevStep())}
      classes={{
        root: classes.stepperButton,
        disabled: classes.backDisabled,
      }}>
      {BACK}
    </Button>
  );

  const NextButton = () => (
    <Button
      disabled={shouldNextButtonBeDisabled()}
      variant='contained'
      color='primary'
      data-testid='return-next-button'
      onClick={() => handleNext()}
      aria-label={NEXT.toLowerCase()}
      classes={{
        root: classes.stepperButton,
        disabled: classes.nextDisabled,
      }}>
      {NEXT}
    </Button>
  );

  const SubmitReturnCaptureButton = () => {
    return (
      <Button
        variant='contained'
        color='primary'
        type='submit'
        data-testid='return-submit-button'
        disabled={shouldSubmissionButtonBeDisabled() || hasTimedOut}
        aria-label={CREATE_RETURN.toLowerCase()}
        onClick={() => determineReturnSubmissionPath()}
        classes={{
          root: classes.stepperButton,
          disabled: classes.nextDisabled,
        }}>
        {CREATE_RETURN}
      </Button>
    );
  };

  const ResendReturnLabelButton = () => (
    <Button
      variant='contained'
      color='primary'
      type='submit'
      aria-label={RESEND_RETURN_LABEL.toLowerCase()}
      disabled={shouldSubmissionButtonBeDisabled()}
      data-testid='resend-return-label-button'
      onClick={() => {
        const resendReturnLabelInput = {
          returnOrderNumber: orderDetail.orderNumber,
          dispatchType: String(deliveryMethod).toUpperCase(),
        };
        sendReturnLabelNow({
          variables: {
            input: resendReturnLabelInput,
          },
        });
        setSlowLoading();
      }}
      classes={{
        root: classes.stepperButton,
        disabled: classes.nextDisabled,
      }}>
      {RESEND_RETURN_LABEL.toLowerCase()}
    </Button>
  );

  // action buttons, depending on dialog type
  const SubmissionButton = () => {
    switch (dialogType) {
      case DialogTypes.RETURN: {
        return <SubmitReturnCaptureButton />;
      }
      case DialogTypes.RESEND_RETURN_LABEL: {
        return <ResendReturnLabelButton />;
      }
      default:
        return;
    }
  };

  if (!isSubmissionStep) {
    return (
      <>
        <div className={classes.actionsContainer}>
          <BackButton />
          <NextButton />
        </div>
        {/* Display address validator component based on address validator api response */
        validateAddress && (
          <AddressValidator
            address={suggestedAndEnteredAddress}
            status={verificationCode}
            createReturnOrder={handleReturnOrderSubmit}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <div className={classes.actionsContainer}>
          <BackButton />
          <SubmissionButton />
        </div>
        {/* Display address validator component based on address validator api response */
        validateAddress && (
          <AddressValidator
            address={suggestedAndEnteredAddress}
            status={verificationCode}
            createReturnOrder={handleReturnOrderSubmit}
            endOnActiveStep2={bypassReturnLabelCreation}
          />
        )}
      </>
    );
  }
}

const useStyles = makeStyles((theme) => ({
  ...stepControlSharedStyles,
}));

export default withRouter(StepControl);
